import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options', 'chartData'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, {
                responsive: true,
                tooltips: {
                    mode: 'index'
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    // yAxes: [{
                    //     display: true,
                    //     stacked: true,
                    //     ticks: {
                    //         min: 0,
                    //         maxTicksLimit: 5
                    //     }
                    // }]
                }
            });
        }
    },
    mounted () {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            type: 'line',
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                // yAxes: [{
                //     display: true,
                //     stacked: true,
                //     ticks: {
                //         min: 0,
                //         maxTicksLimit: 5
                //     }
                // },
                // {
                //     display: true,
                //     stacked: true,
                //     ticks: {
                //         min: 0,
                //         maxTicksLimit: 5
                //     }
                // }
                // ]
            }
        }); // this.options
    }
}
