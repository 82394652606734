import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: ['options', 'chartData'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label;
                            let labelData = tooltipItem.yLabel;

                            return label + ': ' + labelData + '%';
                        }
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        display: false,
                        padding: {
                            left: 0,
                            right: 0
                        }
                    }],
                    xAxes: [{
                        padding: {
                            left: 0,
                            right: 0
                        },
                        afterFit: (axis) => {
                            axis.paddingRight = 0;
                            axis.paddingLeft = 0;
                        }
                    }]
                },
                layout: {
                    height: 260
                }
            });
        }
    },
    mounted () {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            type: 'bar',
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function(tooltipItem, data) {
                        let label = data.datasets[tooltipItem.datasetIndex].label;
                        let labelData = tooltipItem.yLabel;

                        return label + ': ' + labelData + '%';
                    }
                }
            },
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    display: false,
                    padding: {
                        left: 0,
                        right: 0
                    }
                }],
                xAxes: [{
                    padding: {
                        left: 0,
                        right: 0
                    },
                    afterFit: (axis) => {
                        axis.paddingRight = 0;
                        axis.paddingLeft = 0;
                    }
                }]
            },
            layout: {
                height: 260
            }
        }); // this.options
    }
}
