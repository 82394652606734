import Vue from 'vue';
import VueRouter from 'vue-router';

import AdUnitList from './components/adUnit/List';
import AdUnitAdd from './components/adUnit/Add';
import AdUnitEdit from './components/adUnit/Edit';
import Users from './components/users/List';
import ModerationUsers from './components/users/ModerationList';
import UsersReports from './components/usersreports/List';
import ApplicationList from './components/application/List';
import ApplicationAdd from './components/application/Add';
import ApplicationEdit from './components/application/Edit';
import QueueList from './components/queue/List';
import QueueAdd from './components/queue/Add';
import QueueEdit from './components/queue/Edit';
import GroupList from './components/group/List';
import GroupAdd from './components/group/Add';
import GroupEdit from './components/group/Edit';

import RateappReports from './components/report/RateappReports';
import RatemessageReports from './components/report/RatemessageReports';
import RateappInventory from './components/report/RateappInventory';
import RatemessageInventory from './components/report/RatemessageInventory';

import ExchangeList from './components/exchange/List';
import ExchangeAdd from './components/exchange/Add';
import ExchangeEdit from './components/exchange/Edit';

import TypeList from './components/type/List';
import TypeAdd from './components/type/Add';
import NetworkList from './components/network/List';
import NetworkAdd from './components/network/Add';
import RelationTypeNetworkList from './components/relationTypeNetwork/List';

import ResetAccount from './components/resetAccount/List';

import UserInfo from './components/userSettings/UserInfo';
import CardPay from './components/userSettings/CardPay';
import ReportError from './components/userSettings/ReportError';

import Moderator from './components/moderator/Moderator';
import StatisticsShowsClicks from './components/report/StatisticsShowsClicks';
import StatisticsAdunit from './components/report/StatisticsAdunit';
import StatisticsInstalls from './components/report/StatisticsInstalls';
import Cagorts from './components/report/Cagorts';
import GraphicUsers from './components/report/GraphicUsers';
import Online from './components/report/Online';
import Graphics from './components/report/Graphics';
import Advertiser from './components/report/Advertiser';
import Tutorial from './components/tutorial/Tutorial';
import HowItWorks from './components/tutorial/HowItWorks';
import GetSDK from './components/tutorial/GetSDK';
import Login from './components/auth/Login';
// import Register from './components/auth/Register';
// import UserAdded from './components/auth/UserAdded';
// import Confirm from './components/auth/Confirm';
// import Reset from './components/auth/Reset';
import E404 from './components/E404';
import ComingSoon from './components/ComingSoon';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: { name: 'users' }, meta: { requiresAuth: true } },
        { path: '/index', name: 'index', component: ApplicationList, meta: { requiresAuth: true } },
        // { path: '/adunits', name: 'adunit', component: AdUnitList, meta: { requiresAuth: true } },
        // { path: '/adunitadd', name: 'adunitAdd', component: AdUnitAdd, meta: { requiresAuth: true } },
        // { path: '/adunitedit', name: 'adunitEdit', component: AdUnitEdit, meta: { requiresAuth: true } },

        { path: '/application', name: 'applications', component: ApplicationList, meta: { requiresAuth: true } },
        { path: '/usersreports', name: 'usersreports', component: UsersReports, meta: { requiresAuth: true } },
        { path: '/users', name: 'users', component: Users, meta: { requiresAuth: true } },
        { path: '/moderationusers', name: 'moderationusers', component: ModerationUsers, meta: { requiresAuth: true } },
        // { path: '/applicationadd', name: 'applicationAdd', component: ApplicationAdd, meta: { requiresAuth: true } },
        // { path: '/applicationedit', name: 'applicationEdit', component: ApplicationEdit, meta: { requiresAuth: true } },
        // { path: '/queues', name: 'queue', component: QueueList, meta: { requiresAuth: true } },
        // { path: '/queueadd', name: 'queueAdd', component: QueueAdd, meta: { requiresAuth: true } },
        // { path: '/queueedit', name: 'queueEdit', component: QueueEdit, meta: { requiresAuth: true } },
        // { path: '/rules', name: 'group', component: GroupList, meta: { requiresAuth: true } },
        // { path: '/ruleadd', name: 'groupAdd', component: GroupAdd, meta: { requiresAuth: true } },
        // { path: '/ruleedit', name: 'groupEdit', component: GroupEdit, meta: { requiresAuth: true } },

        { path: '/rateappreports', name: 'rateappreports', component: RateappReports, meta: { requiresAuth: true } },
        { path: '/rateappinventory', name: 'rateappinventory', component: RateappInventory, meta: { requiresAuth: true } },
        { path: '/ratemessagereports', name: 'ratemessagereports', component: RatemessageReports, meta: { requiresAuth: true } },
        { path: '/ratemessageinventory', name: 'ratemessageinventory', component: RatemessageInventory, meta: { requiresAuth: true } },

        // { path: '/comingsoon', name: 'comingSoon', component: ComingSoon, meta: { requiresAuth: true } },
        { path: '/exchange', name: 'exchange', component: ExchangeList, meta: { requiresAuth: true } },
        // { path: '/exchangeadd', name: 'exchangeAdd', component: ExchangeAdd, meta: { requiresAuth: true } },
        // { path: '/exchangeedit', name: 'exchangeEdit', component: ExchangeEdit, meta: { requiresAuth: true } },

        { path: '/type', name: 'type', component: TypeList, meta: { requiresAuth: true } },
        { path: '/typeadd', name: 'typeAdd', component: TypeAdd, meta: { requiresAuth: true } },
        { path: '/network', name: 'network', component: NetworkList, meta: { requiresAuth: true } },
        { path: '/networkadd', name: 'networkAdd', component: NetworkAdd, meta: { requiresAuth: true } },
        { path: '/relationtypenetwork', name: 'relationTypeNetwork', component: RelationTypeNetworkList, meta: { requiresAuth: true } },

        { path: '/resetaccount', name: 'resetAccount', component: ResetAccount, meta: { requiresAuth: true } },

        { path: '/userinfo', name: 'userInfo', component: UserInfo, meta: { requiresAuth: true } },
        { path: '/cardpay', name: 'cardPay', component: CardPay, meta: { requiresAuth: true } },
        { path: '/reporterror', name: 'reportError', component: ReportError, meta: { requiresAuth: true } },

        { path: '/moderator', name: 'moderator', component: Moderator, meta: { requiresAuth: true } },
        // { path: '/shows', name: 'statisticsShows', component: StatisticsShowsClicks, meta: { requiresAuth: true } },
        // { path: '/statisticsadunit', name: 'statisticsAdunit', component: StatisticsAdunit, meta: { requiresAuth: true } },
        // { path: '/installs', name: 'statisticsInstalls', component: StatisticsInstalls, meta: { requiresAuth: true } },
        { path: '/online', name: 'online', component: Online, meta: { requiresAuth: true } },
        // { path: '/cagorts', name: 'cagorts', component: Cagorts, meta: { requiresAuth: true } },
        // { path: '/graphicusers', name: 'graphicUsers', component: GraphicUsers, meta: { requiresAuth: true } },
        // { path: '/graphics', name: 'graphics', component: Graphics, meta: { requiresAuth: true } },
        { path: '/advertiser', name: 'advertiser', component: Advertiser, meta: { requiresAuth: true } },
        // { path: '/tutorial', name: 'tutorial', component: Tutorial, meta: { requiresAuth: true } },
        // { path: '/howitworks', name: 'howItWorks', component: HowItWorks, meta: { requiresAuth: true } },
        // { path: '/get-sdk', name: 'getsdk', component: GetSDK, meta: { requiresAuth: true } },
        { path: '/print', name: 'login', component: Login, meta: { notRequiresAuth: true } },
        //{ path: '/register', name: 'register', component: Register, meta: { notRequiresAuth: true } },
        //{ path: '/useradded', name: 'userAdded', component: UserAdded, meta: { notRequiresAuth: true } },
        //{ path: '/confirm', name: 'confirm', component: Confirm, meta: { notRequiresAuth: true } },
        //{ path: '/reset', name: 'reset', component: Reset, meta: { notRequiresAuth: true } },
        { path: '/404', name: '404', component: E404, meta: { notRequiresAuth: true } },
        { path: '*', name: 'e404', component: E404, meta: { requiresAuth: true } }
    ]
});

router.beforeEach((to, from, next) => {
    const accessToken = localStorage.getItem('admin_token');

    if (to.matched.some(record => record.meta.requiresAuth )) {
        if (!accessToken) {
            next({
                path: '/404',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.notRequiresAuth )) {
        if( accessToken && (to.path == '/print') ){
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router;
