<template>
    <div class="form-group" :class="noBorder ? 'no-border' : ''" :style="styleBlockSelect">
        <label class="control-label"
                :class="labelClass"
                :title="categoriesAlt + (countItem == 3 ? ' (' + getLang.max3options + ')' : '')"
                :style="categoriesStyle"
                v-if="categoriesTitle.length > 0"
                >
            <span class="bs-label label-danger" v-if="isRequired && isDefaultRequired">{{ getLang.required }}</span>
            <span style="color: red;" :title="getLang.required" v-if="isRequired && isDefaultRequired == false">*</span>
            {{ categoriesTitle }}:
            <i class="glyph-icon font-gray icon-question" :title="categoriesAlt + (countItem == 3 ? ' (' + getLang.max3options + ')' : '')" data-original-title=".icon-question"></i>
        </label>

        <div :class="categoriesTitle.length > 0 ? categoriesClass : 'col-sm-12'" :style="styleInput">
            <div class="chosen-container chosen-container-multi chosen-with-drop chosen-container-active" :style="catStatus ? 'box-shadow: 0 0 2px rgba(68, 139, 235, 1); outline: none; border-color: #448beb;' : ''" @click.stop="''">

                <input
                    type="hidden"
                    :value="opened"
                />

                <ul class="chosen-choices"
                    style="min-height: 43px!important;"
                    @click="setCategoryStatus()"
                    v-if="viewCount"
                >
                    <li class="search-choice ms-hover"
                        style="display: flex; align-items: center; height: 36px; max-width: 100%;"
                        @dblclick.prevent.stop="removeCategory($event, 0)"
                        v-if="categories.length >= 1"
                    >
                        <img :src="getCategoryImage(categories[0])" style="height: 36px; width: auto;"/>
                        <i class="glyph-icon tooltip-button demo-icon icon-question" style="height: 18px; width: 18px; font-size: 13px; line-height: 18px; margin: 0px 3px 0px 0px;" v-if="!getCategoryImage(categories[0]) && isIcon"></i>
                        <span class="short_text" style="padding-left: 3px;" v-html="getCategory(categories[0])"></span>
                        <a class="search-choice-close" @click.stop.prevent="removeCategory($event, 0)" v-if="!isDisabled && !isDisabledClose && (categories.length > limitCount)"></a>
                    </li>
                    <li class="search-choice ms-hover"
                        style="position: absolute; right: 0px; height: 36px; display: flex; align-items: center;"
                        :style="categories.length > 1 ? '' : 'padding-right: 5px;'"
                        v-if="categories.length > 1"
                    >
                        <span>+{{ categories.length - 1 }}</span>
                        <!-- <span v-else>{{ getLang.statistics.all }}</span> -->
                        <a class="search-choice-close" @click.stop.prevent="(limitCount === 0 ? categories = [] : categories = categories.splice(0, limitCount)); $emit('setCategory', categories); $refs.searchInput.focus()" v-if="categories.length > 0 && !isDisabled"></a>
                    </li>

                    <div v-show="catStatus || categories.length < 1"
                        style="width: 100%;"
                        :style="catStatus
                                ? 'position: absolute; top: 44px; z-index: 1010; background-color: #fff; border-left: 1px solid rgb(223, 232, 241); border-right: 1px solid rgb(223, 232, 241); box-sizing: content-box; left: -1px; right: -1px;'
                                : ''"
                    >
                        <li class="search-field ms-hover"
                            style="color: #bbb; width: 100%;"
                            :style="isSearchShow && catStatus
                                    ? 'line-height: 25px;'
                                    : 'line-height: 38px;'"
                        >
                            <input type="text"
                                    ref="searchInput"
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    :class="{
                                        height25: isSearchShow,
                                    }"
                                    style="width: 100%; padding-right: 10px;"
                                    :style="searchValue.length > 0 ? '' : (isBigPlaceholder ? 'font-weight: bold; font-size: 22px;' : '')"
                                    v-model="searchValue"
                                    @click="preventAndStop($event)"
                                    @keydown="keysSelect($event)"
                                    @input="searchCategory($event)"
                                    @blur="searchBlurCategory($event)"
                                    v-if="isSearchShow"
                            >
                            <input type="text"
                                    ref="searchInput"
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    :class="{
                                        height38: true,
                                    }"
                                    style="width: 100%; padding-right: 10px;"
                                    :style="searchValue.length > 0 ? '' : (isBigPlaceholder ? 'font-weight: bold; font-size: 22px;' : '')"
                                    @click="preventAndStop($event)"
                                    @keydown="keysSelect($event)"
                                    @input="() => this.$refs.searchInput.value = ''"
                                    v-else
                            >
                            <a class="btn search_clear"
                                :style="isSearchShow
                                        ? 'top: 0px;'
                                        : 'top: 10px;'"
                                v-if="searchValue.length > 0"
                                @click.stop.prevent="searchClear()"
                                >
                                <i class="glyph-icon icon-remove"></i>
                            </a>
                        </li>
                    </div>
                    <!-- <li class="clear"></li> -->
                </ul>
                <ul class="chosen-choices"
                    style="min-height: 43px!important;"
                    @click="setCategoryStatus()"
                    v-else
                    >
                    <li class="search-choice ms-hover"
                        v-for="(item, inx) in getCategories"
                        :key="inx"
                        style="display: flex; align-items: center; justify-content: center; padding: 3px 3px; width: 36px; height: 36px; position: relative;"
                        @dblclick.prevent.stop="removeCategory($event, inx)"
                        @mouseover="() => setCategoryShow(inx, true)"
                        @mouseout="() => setCategoryShow(inx, false)"
                    >
                        <img :src="getCategoryImage(item)" style="height: 30px; width: 30px; margin-right: 0px; opacity: 1!important; display: block!importnat; visibility: visible!important;"/>
                        <span
                            v-if="isCategoriesShowTitle && categoriesShowTitle[inx]"
                            style="word-break: keep-all;
                                    white-space: nowrap;
                                    position: absolute;
                                    z-index: 1001;
                                    background-color: rgba(255, 255, 255, .9);
                                    top: -35px;
                                    left: 38px;
                                    padding: 7px;
                                    border: 1px solid #ccc;
                                    border-radius: 3px;"
                            v-html="getCategory(item)"
                        ></span>
                    </li>

                    <div v-show="catStatus || categories.length < 1"
                        style="width: 100%;"
                        :style="catStatus
                                ? 'position: absolute; top: 44px; z-index: 1010; background-color: #fff; border-left: 1px solid rgb(223, 232, 241); border-right: 1px solid rgb(223, 232, 241); box-sizing: content-box; left: -1px; right: -1px;'
                                : ''"
                    >
                        <li class="search-field ms-hover"
                            style="color: #bbb; width: 100%;"
                            :style="isSearchShow && catStatus
                                    ? 'line-height: 25px;'
                                    : 'line-height: 38px;'"
                        >
                            <input type="text"
                                    ref="searchInput"
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    :class="{
                                        height25: isSearchShow,
                                    }"
                                    style="width: 100%; padding-right: 10px;"
                                    :style="searchValue.length > 0 ? '' : (isBigPlaceholder ? 'font-weight: bold; font-size: 22px;' : '')"
                                    v-model="searchValue"
                                    @click="preventAndStop($event)"
                                    @keydown="keysSelect($event)"
                                    @input="searchCategory($event)"
                                    @blur="searchBlurCategory($event)"
                                    v-if="isSearchShow"
                            >
                            <input type="text"
                                    ref="searchInput"
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    :class="{
                                        height38: true,
                                    }"
                                    style="width: 100%; padding-right: 10px;"
                                    :style="searchValue.length > 0 ? '' : (isBigPlaceholder ? 'font-weight: bold; font-size: 22px;' : '')"
                                    @click="preventAndStop($event)"
                                    @keydown="keysSelect($event)"
                                    @input="() => this.$refs.searchInput.value = ''"
                                    v-else
                            >
                            <a class="btn search_clear"
                                :style="isSearchShow
                                        ? 'top: 0px;'
                                        : 'top: 10px;'"
                                v-if="searchValue.length > 0"
                                @click.stop.prevent="searchClear()"
                                >
                                <i class="glyph-icon icon-remove"></i>
                            </a>
                        </li>
                    </div>
                    <!-- <li class="clear"></li> -->
                </ul>

                <div class="chosen-drop"
                    :style="isSearchShow
                            ? 'margin-top: 31px;'
                            : 'top: 44px;'"
                    v-show="catStatus"
                >
                    <ul class="chosen-results" ref="listItems">
                        <li class="active-result group-option"
                            v-for="(el, key) in getCategoryLoc"
                            :value="el.id"
                            :key="key"
                            :style="el.icon || el.image || el.image_landscape || isBorder ? 'border: 1px solid #eee; border-radius: 5px; margin-right: 10px;' : ''"
                            :class="isCategory(el.id)"
                            @click.prevent="setCategory($event, el.id, key)"
                            @dblclick.prevent="setDblCategory($event, el.id, key)"
                        >
                            <img style="height: 18px!important; width: auto;" :src="el.iconActive" v-if="el.iconActive"/>
                            <img style="height: 40px; width: auto;" :src="el.icon" v-if="el.icon"/>
                            <img style="height: 40px; width: auto;" :src="el.image_landscape" v-else-if="el.image_landscape"/>
                            <img style="height: 40px; width: auto;" :src="el.image" v-else-if="el.image"/>
                            <i class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px;" v-else-if="isIcon"></i>

                            <span
                                :class="{
                                    letCap: ['banner', 'interstitial', 'rewarded'].includes(el.name),
                                    disabled: el.id === categoryIdOut
                                }"
                                :style="isIcon || el.icon || el.image || el.image_landscape ?
                                    'padding-left: 3px; display: flex; flex-direction: column; justify-content: flex-end; position: relative;'
                                    : el.iconActive ? 'padding-left: 4px;' : ''"
                                v-html="clearHtmlTags(el.name, ['p', 'strong', 'img'])"
                            ></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
import Lang from '../lang/message';
import Lib from '../mixins/lib';

export default {
    mixins: [Lib],
    props: {
        getCategoryList: {
            type: Array,
            default: []
        },
        isUpdateCategoryList: {
            type: Boolean,
            default: false
        },
        categoriesAlt: String,
        setDefaultCategory: Array,
        isRequired: {
            type: Boolean,
            default: true
        },
        isDefaultRequired: {
            type: Boolean,
            default: true
        },
        isBorder: {
            type: Boolean,
            default: false
        },
        isSearch: {
            type: Boolean,
            default: true,
        },
        isSearchShow: {
            type: Boolean,
            default: false
        },
        isCategoriesShowTitle: {
            type: Boolean,
            default: false
        },
        isIcon: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        isDisabledClose: {
            type: Boolean,
            default: false
        },
        limitCount: {
            type: Number,
            default: 0
        },
        isBigPlaceholder: {
            type: Boolean,
            default: false
        },
        categoryIdOut: {
            type: (Number|String),
            default: ''
        },
        categoriesTitle: String,
        categoriesStyle: {
            type: String,
            default: ''
        },
        styleBlockSelect: {
            type: String,
            default: ''
        },
        countItem: {
            type: Number,
            default: 3
        },
        labelClass: {
            type: String,
            default: 'col-sm-3'
        },
        categoriesClass: {
            type: String,
            default: 'col-sm-9'
        },
        placeholder: {
            type: String,
            default: ''
        },
        categoriesStatus: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        styleInput: {
            type: String,
            default: ''
        },
        viewCount: {
            type: Boolean,
            default: false
        },
        opened: String,
    },
    data(){
        return {
            lang: Lang,

            categoryListAll: [],
            categoryList: [],
            categoryListForSearch: [],

            searchValue: '',

            listItemsLi: null,
            listItemIndex: 0,

            categories: this.setDefaultCategory,
            categoriesShowTitle: [],
            catStatus: this.categoriesStatus,
        }
    },
    mounted(){
        let _this = this;

        this.$nextTick(function(){
            document.addEventListener('click', function(){
                if(_this.catStatus) _this.catStatus = false;
            });

            _this.listItemsLi = _this.$refs.listItems;

            //_this.activeFocus(null, _this.listItemIndex);
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getCategories() {
            if (this.categoriesShowTitle.length === 0) {
                this.categories.forEach((item, inx) => {
                    this.categoriesShowTitle[inx] = false;
                });
            }

            return this.categories;
        },

        getCategoryLoc(){
            if (this.isSearch == false) this.categoryList = this.getCategoryList;
            else if (this.categoryList.length == 0 && this.searchValue == '') this.categoryList = this.getCategoryList;

            return this.categoryList;
        },

        getCategoryAll(){
            this.categoryListAll = this.getCategoryList;

            return this.categoryListAll;
        }
    },
    watch: {
        setDefaultCategory(){
            this.categories = this.setDefaultCategory;
        },

        opened(){
            this.categories = this.setDefaultCategory;
        },

        categoriesStatus(){
            this.catStatus = this.categoriesStatus;

            if(this.catStatus && this.listItemIndex == 0) {
                this.activeFocus(null, this.listItemIndex);
            }
        },

        getCategoryList(){
            if(this.isUpdateCategoryList) {
                this.categoryList = this.getCategoryList;
                let catIds = [];

                for(let k in this.categoryList){
                    catIds.push(this.categoryList[k]['id']);
                }

                this.categories = this.categories.filter(val => catIds.indexOf(val) != -1);

                this.$emit('setCategory', this.categories);
            }
        }

        // listItemsLi(){
        //     if(this.listItemsLi.children != undefined && this.listItemsLi.children.length > 0) {
        //         this.activeFocus(null, this.listItemIndex);
        //     }
        // }
    },
    methods: {
        preventAndStop(e){
            if (this.categories.length > 0) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        setCategoryShow(inx, val){
            this.$set(this.categoriesShowTitle, inx, val);
        },

        // category
        setDblCategory(e, key, ind = null){
            this.setCategory(e, key, ind);
            this.catStatus = false;
        },

        setCategory(e, key, ind = null){
            if (this.categoryIdOut === key) return;

            // set active focus
            if(ind != null) this.activeFocus(null, ind);

            if ((this.categories.length > this.limitCount) && this.categories.find(inx => inx == key) != undefined) {
                this.categories.splice(this.categories.indexOf(key), 1);
                this.$emit('setCategory', this.categories);
                if(this.$refs.searchInput != undefined) this.$refs.searchInput.focus();
                return true;
            }

            //console.log(this.categoryListForSearch, this.getCategoryLoc);

            if(this.countItem == 1) {
                this.$set(this.categories, 0, key);
                //this.categories[0] = key;
                this.$emit('setCategory', this.categories);
            }

            if((this.categories.length > (this.countItem - 1)) && this.countItem != -1 ) return true;

            if (this.categories.find(inx => inx == key) == undefined) {
                this.categories.push(key);

                this.$emit('setCategory', this.categories);
                if(this.$refs.searchInput != undefined) this.$refs.searchInput.focus();
            }
        },
        removeCategory(e, inx){
            if (this.isDisabled) return;
            this.categories.splice(inx, 1);
            this.$emit('removeCategory', this.categories);
            this.$refs.searchInput.focus();
        },
        isCategory(key){
            if(this.categories.find(inx => inx == key) != undefined) return 'highlighted';

            return '';
        },

        getShortTitle(title){
            if (title.length >= 30) {
                title = "..." + title.slice(-30);
            }

            return title;
        },

        keysSelect(e){
            if (!this.isSearchShow) return;

            if(this.catStatus) {
                // close Select
                if(e.keyCode == 27) {
                    this.catStatus = false;
                }

                // prev or next active focus item in select
                if((this.listItemsLi.children != undefined && this.listItemsLi.children.length > 0) && (e.keyCode == 40 || e.keyCode == 38)) {
                    if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.scrollTop = this.listItemsLi.children[this.listItemIndex].offsetTop - 100;

                    this.activeFocus(e);
                }

                // click enter and set active item
                if(e.keyCode == 13) {
                    e.preventDefault();

                    if(this.listItemsLi.children[this.listItemIndex] != undefined) {
                        let val = this.listItemsLi.children[this.listItemIndex].getAttribute('value');

                        if(val.match(/^\d+$/g)) val = Number(val);

                        this.setCategory(null, val);
                    }
                }
            } else {
                if(e.keyCode == 27) this.$store.commit('setIsEscClose', true);
                else this.catStatus = true;
            }
        },

        activeFocus(event, index = null){
            let styleBorder = '';
            let styleActiveFocus = 'box-shadow: 0px 0px 3px 1px #888888;';

            if(this.isBorder) styleBorder = 'border: 1px solid #eee; border-radius: 5px; margin-right: 10px;';

            if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.children[this.listItemIndex].style = styleBorder;

            if(index != null) this.listItemIndex = index;
            else if(event.keyCode == 40 && this.listItemIndex < this.listItemsLi.children.length-1) this.listItemIndex++;
            else if(event.keyCode == 38 && this.listItemIndex > 0) this.listItemIndex--;

            if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.children[this.listItemIndex].style = styleBorder + styleActiveFocus;
        },

        /* search by table data */
        searchCategory(e){
            let _this = this;
            let res;

            //if(this.viewCount == false && this.categories.length > 0) return;

            if(!this.categoryListForSearch.length) this.categoryListForSearch = this.getCategoryLoc;

            res = this.categoryListForSearch.filter(function(item){
                let isName = false;

                if(item != undefined && item.name != undefined && item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isName;
            });

            this.categoryList = res;
        },

        searchBlurCategory(e){
            if(e.target.value == '' && this.categoryListForSearch.length > 0) {
                this.categoryList = this.categoryListForSearch;
                this.categoryListForSearch = [];
            }
        },

        searchClear(){
            this.searchValue = '';
            //this.$refs.searchInput.value = '';
            this.searchCategory({target: {value: ''}});

            this.$refs.searchInput.focus();
        },
        /* end search table data */

        // get name category by id
        getCategory(inx){
            let item = '';

            if (this.getCategoryAll.length) {
                item = this.getCategoryAll.find(x => x.id === inx);
                item = (item != undefined && item.name != undefined ? item.name : '');
            }

            return item;
        },

        getCategoryImage(inx){
            let item = '';
//console.log('inx = ', inx)
            if (this.getCategoryAll.length) {
                item = this.getCategoryAll.find(x => x.id === inx);
            }

            return item == undefined ? '' : (item.iconActive || item.icon);
        },
        /* end Category */

        setCategoryStatus(){
            if(this.isDisabled) return true;

            this.catStatus = !this.catStatus;

            if(this.catStatus) {
                this.$refs.searchInput.focus();
                this.$store.commit('setIsEscClose', false);
            } else {
                this.$store.commit('setIsEscClose', true);
            }

            this.$emit('setCategoryStatus', this.catStatus);
        }
    },
}
</script>

<style scoped>
.chosen-container-multi .chosen-choices{
    z-index: 1;
    overflow: visible;
}

.chosen-results li{
    display: flex!important;
    margin-left: 1px;
    margin-right: 1px;
}

    .active-result{
        padding-left: 1px!important;
        padding-right: 0px!important;
    }

.chosen-container-multi .chosen-choices li.search-choice{
    margin: 3px 0 3px 4px;
}

.chosen-results li::-moz-focus-inner, .chosen-results li:focus{
    outline: 1px dashed #ccc;
}

    .chosen-results li span{
        word-break: break-all;
        word-wrap: break-word;
        width: 100%;
    }

.search_clear{
    position: absolute;
    right: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0px;
}

.search-choice-close{
    z-index: 1;
}

.letCap {
    text-transform: capitalize;
}

.icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

.height25{
    height: 25px!important;
}

.height38{
    height: 38px!important;
}

.disabled{
    cursor: default!important;
}
</style>
