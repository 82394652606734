<template>
    <section>
        <div class="panel app-list">
            <div class="panel-body">
                <div class="app-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-header" id="datatable-app">
                            <thead>

                                <tr>
                                    <th class="col_img">
                                        <!-- <router-link :to="{name: 'applicationAdd'}"
                                                    tag="button"
                                                    class="btn btn-success btn-add"
                                                    >
                                            <i class="glyph-icon icon-plus"></i>
                                        </router-link> -->
                                        <div class="table-head-content">
                                            {{ getLang.menu.users }}
                                            <!-- {{ $store.getters.getPagesList }} -->
                                        </div>
                                    </th>

                                    <transition name="list-complete">
                                        <th class="col_title">
                                            <div class="table-head-content float-right">
                                                {{ getLang.ID }}
                                            </div>
                                        </th>
                                    </transition>

                                    <transition name="list-complete">
                                        <th class="col_pack_name">
                                            <div class="table-head-content">
                                                <!-- <i class="glyph-icon font-gray icon-sort"></i> -->
                                                <!-- <a href="#" @click.prevent="sortByPackName()"> -->
                                                    {{ getLang.account.email }}
                                                <!-- </a> -->
                                            </div>
                                        </th>
                                    </transition>

                                    <th class="col_cat1">
                                        <div class="table-head-content">
                                            {{ getLang.account.state }}
                                        </div>
                                    </th>

                                    <th class="col_cat3">
                                        <div class="block_select_search">
                                            <!-- <div class="col-sm-5" style="padding-left: 0px; padding-right: 0px;">
                                                <MultiSelectIcons categories-alt="categoriy type list"
                                                            :categories-title="''"
                                                            :get-category-list="getTypeCategories"
                                                            :is-update-category-list="true"
                                                            :setDefaultCategory="getTypeCategoryStatus"
                                                            :is-default-required="false"
                                                            :count-item="1"
                                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                                            :style-block-select="'margin-bottom: 0px;'"
                                                            :is-disabled-close="true"
                                                            @setCategory="val => setTypeCategoryStatus(val)"
                                                            @removeCategory="val => setTypeCategoryStatus(val)"
                                                            >
                                                </MultiSelectIcons>
                                            </div>
                                            <div id="datatable_filter" class="dataTables_filter col-sm-6">
                                                <input class="form-control outlight"
                                                        style="padding-right: 30px; z-index: 100;"
                                                        :placeholder="getLang.search.search+'...'"
                                                        aria-controls="datatable-example"
                                                        type="search"
                                                        ref="searchInput"
                                                        @input="searchApp($event)"
                                                        @blur="searchBlurApp($event)"
                                                        >
                                                <a class="btn search_clear"
                                                    v-if="searchValue.length > 0"
                                                    @click.prevent="searchClear()"
                                                    >
                                                    <i class="glyph-icon icon-remove"></i>
                                                </a>
                                            </div> -->
                                        </div>
                                        <div class="table-head-content">
                                            {{ getLang.account.role }}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-content" id="datatable-app2">
                            <tbody id="app_list">

                                <tr class="odd gradeX"
                                    v-for="(item, index) in getUserView"
                                    :key="index"
                                >
                                    <td class="v-top col_img" style="position: relative;">
                                        <img :src="getIcon(item.email)" class="icon" v-if="getIcon(item.email)">
                                        <i class="glyph-icon tooltip-button demo-icon icon-question" v-else></i>
                                        <!-- <span v-if="!item.useiTunes" style="color: #f90; word-break: keep-all; white-space: nowrap; position: absolute; top: 0px; font-size: 9px;">IOS</span>
                                        <span v-if="!item.usePlayMarket"  style="color: #f90;word-break: keep-all; white-space: nowrap; position: absolute; top: 14px; font-size: 9px;">Android</span> -->
                                    </td>

                                    <transition name="list-complete">
                                        <td class="v-bottom col_title">
                                            <div class="short_text float-right" v-html="item.id"></div>
                                        </td>
                                    </transition>

                                    <transition name="list-complete">
                                        <td class="v-bottom col_pack_name">
                                            <div class="short_text">
                                                <a href="#" @click.prevent="copyData(item.email)"><i class="glyph-icon tooltip-button icon-copy"></i></a>&nbsp;
                                                <a
                                                    @click.prevent="setUsersRoles($event, getLang.users.editRolesTitle, '/admin/api/roles/edit/', item.id, item.email, item.is_chat, item.role, item.state)"
                                                    href="#"
                                                    v-if="item.role.toLowerCase() !== $store.getters.getRoleSuperAdmin.toLowerCase()"
                                                >
                                                    {{ item.email }}
                                                </a>
                                                <p style="display: inline;" v-else>
                                                    {{ item.email }}
                                                </p>
                                            </div>
                                        </td>
                                    </transition>

                                    <td class="v-bottom col_cat1">
                                        <div :title="item.state">
                                            <div class="short_text" v-html="item.state"></div>
                                        </div>
                                    </td>

                                    <transition name="width">
                                        <td class="v-bottom col_cat3">
                                            <button class="btn btn-alt btn-hover btn-default btn-edit btn_app_edit"
                                                    @click="login(item)"
                                            >
                                                <span>{{ getLang.login }}</span>
                                                <i class="glyph-icon icon-arrow-right"></i>
                                            </button>
                                            <div class="short_text" style="margin-right: 120px;" v-html="item.role"></div>
                                        </td>
                                    </transition>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.appStart = val"
                                @setEnd="val => pagination.appEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                                >
                    </pagination>
                </div>
                <popupUsersRoles
                        :url-added="popupRolesUrlEdit"
                        :accId="popupRolesId"
                        :accEmail="popupRolesEmail"
                        :accIsChat="popupRolesIsChat"
                        :accRole="popupRolesRole"
                        :accState="popupRolesState"
                        url-redirect="users"
                        :title="popupRolesTitle"
                        :opened="opened"
                        @setUpdata="val => isAdd = val"
                >
                </popupUsersRoles>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import MD5 from 'crypto-js/md5';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import PopupUsersRoles from '../plugins/PopupUsersRoles';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            pageId: 20,

            userList: [],
            userView: [],
            userListForSearch: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 75,

            logosAppType: null,

            categoryList: [],
            genderList: {
                ANY: 'ANY',
                M: 'Male',
                F: 'Female'
            },

            typeCategoryStatus: ['game'],
            typeCategories: [{id: 'game', name: 'Game'}, {id: 'application', name: 'Application'}, {id: 'site', name: 'Site'}],

            stateConfirmed: 'Confirmed',
            stateNotConfirmed: 'NotConfirmed',
            stateBlocked: 'Blocked',

            searchValue: '',

            // sorting
            sortingDatatables: {
                packNameFlag: true,
                contentRatingFlag: true,
            },

            isAdd: false,
            opened: null,

            popupRolesUrlEdit: '',
            popupRolesTitle: '',
            popupRolesId: null,
            popupRolesEmail: null,
            popupRolesIsChat: null,
            popupRolesRole: null,
            popupRolesState: null,

            // pagination
            pagination: {
                appStart: 0,
                appEnd: 0,
                countLength: 0,
                countList: [10,15,25,50,100],
                activeCount: 1,
                activePage: 0
            }
        }
    },
    watch: {
        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationApplicationsAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationApplicationsPageAction', parseInt(this.pagination.activePage));
        },

        getApplicationsSearch(){
            this.$store.dispatch('setRemeberApplicationsSearchAction', this.searchValue);
        },

        isAdd(){
            if (this.isAdd) this.getUsersListDB();
            this.isAdd = false;
        }
    },
    created(){
        this.pagination.activeCount = this.$store.getters.getRemeberPaginationApplications;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationApplicationsPage;
        this.searchValue = this.$store.getters.getRemeberApplicationsSearch;

        let isVisibleP = this.getPageVisiblePromise(this.pageId);

        isVisibleP.then(res => {
            if (res) {
                this.getUsersListDB();
            } else {
                this.$router.push({name: '404'});
            }
        });
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        this.$nextTick(() => {
            // this.$refs.searchInput.value = this.searchValue;
        });

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        getTypeCategories(){
            this.typeCategories.forEach((val, index) => {
                this.typeCategories[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogosAppType[val.name]};
            });

            return this.typeCategories;
        },

        getLogosAppType(){
            this.logosAppType = this.$store.state.logos.appTypeIcons;

            return this.logosAppType;
        },

        getApplicationsSearch(){
            return this.searchValue;
        },

        /* users */
        getUserView(){
            this.userView = this.userList;

            this.pagination.countLength = this.userView.length; // paginations

            this.userView = this.userView.slice(this.pagination.appStart, this.pagination.appEnd);

            return this.userView;
        },
        /* end users */

        // get category lists
        getCategoryList(){
            this.categoryList = this.$store.getters.getCategoryList;

            return this.categoryList;
        },

        getTypeCategoryStatus() {
            this.typeCategoryStatus = this.$store.getters.getRemeberApplicationsTypeBanner;

            return this.typeCategoryStatus;
        }
    },
    methods: {
        isModerator(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() == this.$store.getters.getRoleAdmin) || (this.getRole.toLowerCase() == this.$store.getters.getRoleModerator);
        },

        getIcon(email){
            let icon = this.$store.getters.getGravatarUrl + MD5(email).toString();

            return icon;
        },

        copyData(link){
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
console.log('clipboard');
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
            } else if (document.queryCommandSupported('copy')) {
                let textArea = document.createElement("textarea");
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                let res = document.execCommand('copy');

                document.body.removeChild(textArea);
console.log('exec command copy');
            }
        },

        login(user){
            let data = JSON.stringify({
                id: user.id,
                admin_id: Number(this.getUserId),
                email: user.email,
                api_token: user.api_token,
                icon: user.icon,
                name: user.name,
                is_chat: user.is_chat,
                phone: user.phone,
                country: user.country,
                zip_code: user.zip_code,
                address1: user.address1,
                address2: user.address2,
                duns: user.duns,
                role: user.role,
            });

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/edit/user/immitation/?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let iconUrl = this.$store.getters.getGravatarUrl + MD5(user.email).toString();

                // localStorage.setItem('user_id', user.id);
                // localStorage.setItem('api_token', user.api_token);
                // localStorage.setItem('access_token', response.data.access_token);
                // localStorage.setItem('email', user.email);
                // localStorage.setItem('iconUrl', iconUrl);
                // localStorage.setItem('role', user.role);

                //this.clearRemeberData();
                //this.setAuthData();

                window.open(this.$store.getters.getDashUrl + `/immitation?admin_user_id=${this.getUserId}&user_id=${user.id}&api_token=${user.api_token}&access_token=${response.data.access_token}&email=${user.email}&iconUrl=${iconUrl}&role=${user.role}&name=${user.name}&is_chat=${user.is_chat}&phone=${user.phone}&country=${user.country}&zip_code=${user.zip_code}&address1=${user.address1}&address2=${user.address2}&duns=${user.duns}`, "_blank");
            })
            .catch(e => {
                console.log("error");
                console.log(e);
            })
        },

        // get list Users
        getUsersListDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/accounts?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.userList = response.data;

                this.pagination.countLength = this.userList.length; // paginations
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // set type banner filter
        setTypeCategoryStatus(val){
            this.$store.dispatch('setRemeberApplicationsTypeBannerAction', val);
            this.typeCategoryStatus = val;
        },

        // add id Application for edit
        setAppEdit(e, id){
            this.$store.commit('setAppEditId', id);
            this.$router.push({name: 'applicationEdit'});
        },

        // set height for table list Applications
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        /* search by table data */
        searchApp(e){
            let _this = this;
            let res;

            if(!this.userListForSearch.length) this.userListForSearch = this.userList;

            res = this.userListForSearch.filter(function(item){
                let isName = false;
                _this.searchValue = e.target.value || '';

                if(item.package_name != null) isName = (item.package_name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isName;
            });

            this.userList = res;
            this.pagination.countLength = this.userList.length; // paginations
        },

        searchBlurApp(e){
            if(e.target.value == '' && this.userListForSearch.length > 0) {
                this.userListForSearch = [];
            }
        },

        searchClear(){
            this.$refs.searchInput.value = '';
            this.searchApp({target: {value: ''}});
        },
        /* end search table data */

        /* sorting */
        sortByPackName(){
            let _this = this;

            this.userList = this.userList.sort(function(a, b) {
                let frst = a.package_name ? a.package_name.toLowerCase().trim() : '';
                let sec = b.package_name ? b.package_name.toLowerCase().trim() : '';

                if(_this.sortingDatatables.packNameFlag) return (frst > sec) ? 1 : (frst < sec) ? -1 : 0;
                else return (frst < sec) ? 1 : (frst > sec) ? -1 : 0;
            });

            this.sortingDatatables.packNameFlag = !this.sortingDatatables.packNameFlag;
        },

        sortByContentRating(){
            let _this = this;

            this.userList = this.userList.sort(function(a, b) {
                let frst = a.contentRating ? a.contentRating.toLowerCase().trim() : '';
                let sec = b.contentRating ? b.contentRating.toLowerCase().trim() : '';

                if(_this.sortingDatatables.contentRatingFlag) return (frst > sec) ? 1 : (frst < sec) ? -1 : 0;
                else return (frst < sec) ? 1 : (frst > sec) ? -1 : 0;
            });

            this.sortingDatatables.contentRatingFlag = !this.sortingDatatables.contentRatingFlag;
        },
        /* end sorting */

        setUsersRoles(e, title, urlAdd, id, email, isChat, role, state){
            this.popupRolesTitle = title;
            this.popupRolesUrlEdit = urlAdd;
            this.popupRolesId = id;
            this.popupRolesEmail = email;
            this.popupRolesIsChat = isChat;
            this.popupRolesRole = role;
            this.popupRolesState = state;

            this.opened = this.getUniqueId();

            this.$store.commit('modals/setPopupDelete', true);
        }
    },
    components: {
        Pagination,
        PopupUsersRoles,
        MultiSelectIcons,
        MultiSelect
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    .block_select_search{
        width: 335px;
        position: absolute;
        top: 6px;
        right: 13px;
    }

    /*---*/
    .col_img{
        width: 11%;
        width: calc(170px); /* 225px */
    }

        .col_img .icon-question{
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0;
        }

    /* @media (max-width: 1100px) {
        .col_action{
            width: 34%;
            width: calc(360px);
        }
    }

    @media (min-width: 1101px) and (max-width: 1400px) {
        .col_action{
            width: 34%;
            width: calc(395px);
        }
    }
    @media (min-width: 1401px) and (max-width: 1600px) {
        .col_action{
            width: 34%;
            width: calc(442px);
        }
    }
    @media (min-width: 1601px) {
        .col_action{
            width: 34%;
            width: calc(460px);
        }
    } */

    .col_action_body{
        width: 12%;
        width: calc(150px);
    }

    .col_title{
        width: 6%;
    }

    .col_pack_name{
        width: 26%;

    }

    /* .col_back{
        width: 11%;
    } */
    td.col_back{
        vertical-align: middle!important;
    }
    th.col_back{
        vertical-align: bottom!important;
    }
        td.col_back>div{
            width: 100%;
            height: 28px;
        }

    .col_cat1{
        width: 12%;
    }

    /* .col_cat2{
        width: 12%;
    } */

    .col_cat3{
        width: 55%;
    }

    td.col_cat3{
        position: relative;
    }

    .col_cat3_body{
        width: 42%;
    }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .app-list{
        border: 0px none;
    }

        .app-list .panel-body{
            padding: 0px;
        }

    .app-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 170px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 170px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 170px;
        }

    .table-app th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        padding: 0px;
    }

    .btn-add{
        width: 144px;
    }

    .btn-edit{
        margin-right: 8px;
        width: 100px;
        position: absolute;
        top: 3px;
        right: 13px;
    }

    .btn_app_edit{
        width: 100px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-app-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-app-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-app-header th input{
                width: 170px; /*144px*/
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-app-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-app-content tr:first-child td{
        border-top: 0px none;
    }

    .table-app-content td.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-app-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-app-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .search_clear{
        position: absolute;
        right: 10px;
        z-index: 100;
        top: 12px;
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        width: auto;
        min-width: auto;
        padding: 0px;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
            background-color: #ffff00!important;
        }

    /* animation */

    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter{

    }
    .width-enter-active{
        transition: all .3s;
    }
    .width-enter-to{

    }
    .width-leave-active{
        transition: all .3s reverse;
    }
    .width-leave-to{

    }

</style>
