<template>
    <div class="form-group" :style="groupStyle">
        <label class="col-sm-3 control-label"
                :title="categoriesAlt"
                :style="categoriesStyle"
                v-if="categoriesTitle.length > 0"
                >
            <span class="bs-label label-danger" v-if="isRequired">{{ getLang.required }}</span>
            {{ categoriesTitle }}:
            <i class="glyph-icon font-gray icon-question" :title="categoriesAlt" data-original-title=".icon-question"></i>
        </label>

        <div :class="categoriesTitle.length > 0 ? 'col-sm-9' : 'col-sm-12'">
            <div class="chosen-container chosen-container-multi chosen-with-drop chosen-container-active" @click.stop="''">
                <ul class="chosen-choices">

                    <span v-if="categories.length < 1" class="choose-title" @click.prevent="setCategoryStatus()">{{ getLang.add }}</span>

                    <a href="#"
                        class="float-right"
                        @click.prevent="setCategoryStatus()"
                        v-if="categories.length < Object.keys(getCategoryList).length"
                        >
                        <i class="glyph-icon font-gray icon-plus"></i>
                    </a>

                    <li class="search-choice ms-hover" v-for="(item, inx) in categories" :key="inx">
                        <span v-html="getCategory(item)"></span>
                        <a class="chose-item"
                            @click.stop.prevent="setSelfCategoryStatus(item)"
                            v-if="notCategoryGroup.indexOf(item) == -1"
                            :style="isClosed ? 'right: 20px;' : 'right: 5px;'"
                        >
                            <i class="glyph-icon font-gray icon-arrows-v"></i>
                        </a>
                        <a class="search-choice-close" @click.stop.prevent="removeCategory($event, inx)" v-if="isClosed"></a>
                    </li>
                </ul>

                <div class="chosen-drop" v-show="catStatus">
                    <ul class="chosen-results" v-for="(category, catKey) in getCategoryList" :key="catKey">

                        <li class="active-result group-option"
                            v-for="(el, key) in category"
                            :key="key"
                            :class="isCategory(el)"
                            @click.prevent="setCategory($event, el)"
                            >
                            <span v-html="el[$store.getters.getLanguageList[$store.getters.getLanguageActive].key].name"></span>
                        </li>
                        <hr v-if="category.find(val => notCategoryGroup.indexOf(val.id) == -1)"/>
                    </ul>

                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
import Lang from '../lang/message';

export default {
    props: {
        getCategoryList: Object,
        notCategoryGroup: {
            type: Array,
            default: []
        },
        categoriesAlt: String,
        setDefaultCategory: Array,
        isRequired: {
            type: Boolean,
            default: true
        },
        isClosed: {
            type: Boolean,
            default: true
        },
        groupStyle: {
            type: String,
            default: ''
        },
        categoriesTitle: String,
        categoriesStyle: {
            type: String,
            default: ''
        },
        countItem: {
            type: Number,
            default: 3
        },
        placeholder: {
            type: String,
            default: ''
        },
        categoriesStatus: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            lang: Lang,

            categories: this.setDefaultCategory,
            catStatus: this.categoriesStatus,

            selfGroup: {
                stat: false,
                val: null
            }
        }
    },
    mounted(){
        let _this = this;

        this.$nextTick(function(){
            document.addEventListener('click', function(){
                if(_this.catStatus) _this.catStatus = false;
            });
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },
    },
    watch: {
        setDefaultCategory(){
            this.categories = this.setDefaultCategory;
        },
        categoriesStatus(){
            this.catStatus = this.categoriesStatus;
        }
    },
    methods: {
        // category
        removeCategory(e, inx){
            this.categories.splice(inx, 1);
            this.$emit('removeCategory', this.categories);
            this.setCategoryStatus('false');
        },
        setCategory(e, el){
            if((this.categories.length > (this.countItem - 1)) && this.countItem != -1 ) return;

            let tmp;
            let _this = this;

            if (this.selfGroup.stat) {
                tmp = this.getCategoryList[el.group].find(val => _this.selfGroup.val == val.id);

                if (tmp) {
                    this.categories.splice(this.categories.indexOf(_this.selfGroup.val), 1, el.id);

                    this.$emit('setCategory', _this.categories);

                    this.setCategoryStatus();
                }
            } else {
                tmp = this.getCategoryList[el.group].find(val => _this.categories.indexOf(val.id) !== -1);

                if (!tmp) {
                    this.categories.push(el.id);
                    this.$emit('setCategory', _this.categories);

                    this.setCategoryStatus();
                }
            }

        },
        isCategory(el){
            let tmp;
            let _this = this;

            if (this.selfGroup.stat){
                tmp = this.getCategoryList[el.group].find(val => _this.selfGroup.val == val.id);

                if (!tmp) return 'active';
            } else {
                tmp = this.getCategoryList[el.group].find(val => _this.categories.indexOf(val.id) !== -1);

                if (tmp && tmp.group == el.group) return 'active';
            }

            return '';
        },
        // get name category by id
        getCategory(inx){
            let item;

            for(let key in this.getCategoryList) {
                item = '';

                item = this.getCategoryList[key].find(x => x.id === inx);

                if(item) break;
            }

            return item[this.$store.getters.getLanguageList[this.$store.getters.getLanguageActive].key].name;
        },
        /* end Category */

        isCategoryStatus(status){
            if (status == 'false') {
                this.catStatus = false;
            } else if (status == 'true') {
                this.catStatus = true;
            } else {
                this.catStatus = !this.catStatus;
            }

            this.$emit('setCategoryStatus', this.catStatus);
        },

        setCategoryStatus(status){
            this.selfGroup.stat = false;
            this.selfGroup.val = null;

            this.isCategoryStatus(status);
        },

        setSelfCategoryStatus(el){
            this.selfGroup.stat = true;
            this.selfGroup.val = el;

            this.isCategoryStatus();
        }
    },
}
</script>

<style scoped>
.chosen-choices i.icon-plus{
    margin-left: 10px;
}

    .chosen-choices i.icon-plus::before{
        line-height: 41px;
    }

.search-choice-close{
    right: 3px!important;
}

.chose-item{
    position: absolute;
    top: 3px;
    cursor: pointer;
    opacity: .8;
}

.chose-item:hover{
    opacity: 1;
}

li.active{
    cursor: default!important;
}

    li.active span{
        color: #aaa;
    }

.chosen-container{
    min-width: 100px;
    width: auto!important;
    padding-right: 10px;
}

    .chosen-container-multi .chosen-choices li.search-choice{
        padding: 3px 35px 3px 5px;
    }

.choose-title{
    line-height: 38px;
    margin-left: 10px;
    color: #bbb;
    cursor: pointer;
}

i.icon-plus:before{
    font-weight: bold;
    font-size: 24px;
}
</style>
