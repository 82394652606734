<template>
    <div class="user-account-btn dropdown">
        <a href="#" :title="getLang.myAccount" class="user-profile clearfix" data-toggle="dropdown" @click.stop.prevent="setShow()">
            <img width="28" :src="iconBtn" alt="Profile image">
        </a>
        <transition name="fade">
            <div class="dropdown-menu float-right" :class="getIsShow ? 'show' : ''" v-show="getIsShow" @click.stop="''">
                <div class="box-sm">
                    <div class="login-box clearfix">
                        <div class="user-img">
                            <a href="#" title="" class="change-img">{{ getLang.changePhoto }}</a>
                            <img :src="iconUrl" alt="">
                        </div>
                        <div class="user-info">
                            <span>
                                {{ getEmail }}
                                <i>{{ getLang.ID }}: <b>{{ getUserId }}</b>&nbsp;<a href="#" @click.prevent="copyData(getUserId)"><i class="glyph-icon tooltip-button icon-copy"></i></a></i>
                                <i style="word-break: break-all;">{{ getLang.token }}: <b>{{ getApiToken }}</b>&nbsp;<a href="#" @click.prevent="copyData(getApiToken)"><i class="glyph-icon tooltip-button icon-copy"></i></a></i>
                                <i style="word-break: break-all; margin-top: 10px;">{{ getLang.timeZone }}: <b>{{ timezone }}</b></i>
                            </span>
                            <a href="#" :title="getLang.editProfile" @click.prevent="''">{{ getLang.editProfile }}</a>
                            <a href="#" :title="getLang.updateData" @click.prevent="updateData()" v-if="getRole.toLowerCase() == $store.getters.getRoleAdmin">{{ getLang.updateData }}</a>
                        </div>
                    </div>
                    <div class="pad5A button-pane button-pane-alt text-center" v-if="getAccessToken">
                        <a @click.prevent="logout()" class="btn display-block font-normal btn-danger">
                            <i class="glyph-icon icon-power-off"></i>
                            {{ getLang.logout }}
                        </a>
                    </div>
                    <div class="pad5A button-pane button-pane-alt text-center" v-else>
                        <router-link :to="{ name: 'login' }" class="btn display-block font-normal btn-danger"><i class="glyph-icon icon-power-off"></i> login</router-link>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';

export default {
    mixins: [Authentication],
    data() {
        return {
            lang: Lang,
            isShow: false,
            iconUrl: '',
            iconBtn: '',
            timezone: '',

            logosHeader: null
        }
    },
    created(){
        if (this.$store.getters.getAuthIconUrl === 'https://www.gravatar.com/avatar/3cccb9fd89a57250376f23435685338d') this.iconBtn = this.getLogosHeader.btnOff;
        else this.iconBtn = this.$store.getters.getAuthIconUrl;

        if (this.$store.getters.getAuthIconUrl === 'https://www.gravatar.com/avatar/3cccb9fd89a57250376f23435685338d') this.iconUrl = this.getLogosHeader.btnOffBig;
        else this.iconUrl = this.$store.getters.getAuthIconUrl;

        this.getTimeZone();
    },
    computed: {
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },
        getIsShow(){
            this.isShow = this.$store.state.modals.userProfile;
            return this.isShow;
        },

        getLogosHeader(){
            this.logosHeader = this.$store.state.logos.headerIcons;

            return this.logosHeader;
        },
    },
    methods: {
        copyData(link){
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
console.log('clipboard');
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
            } else if (document.queryCommandSupported('copy')) {
                let textArea = document.createElement("textarea");
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                let res = document.execCommand('copy');

                document.body.removeChild(textArea);
console.log('exec command copy');
            }
        },

        setShow(){
            this.isShow = !this.isShow;
            this.$store.commit('modals/setUserProfile', this.isShow);

            if(this.$store.state.modals.language == true) this.$store.commit('modals/setLanguage', false);
            if(this.$store.state.modals.notification == true) this.$store.commit('modals/setNotification', false);
            if(this.$store.state.modals.exclamation == true) this.$store.commit('modals/setExclamation', false);
            if(this.$store.state.modals.info == true) this.$store.commit('modals/setInfo', false);
        },

        getTimeZone(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/playacc/gettimezone?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.data.timezone != undefined) this.timezone = response.data.timezone;
            })
            .catch(e => {
                console.log("error update data");

                if(e.message != undefined) console.log(e.message);
                if(e.response != undefined && e.response.data != undefined) console.log(e.response.data);
                if(e.response != undefined && e.response.data.Error != undefined) this.auth.error = e.response.data.Error;
            });
        },

        updateData(){
            axios({
                url: this.$store.getters.getBaseUrl+'/acc/admin/reset/data?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(response.statusText != undefined) console.log(response.statusText);
                location.reload();
            })
            .catch(e => {
                console.log("error update data");

                if(e.message != undefined) console.log(e.message);
                if(e.response != undefined && e.response.data != undefined) console.log(e.response.data);
                if(e.response != undefined && e.response.data.Error != undefined) this.auth.error = e.response.data.Error;

                if(this.auth.error == this.$store.getters.getError.needToLogin){
                    this.logout();
                }
            });
        }
    }
}
</script>

<style scoped>
.dropdown-menu{
    border: 1px solid #ccc;
}

section{
    width: 200px;
    background-color: #eee;
}
.fade-enter{
    opacity: 0;
    height: 0px;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
    height: auth;
}
.fade-leave-active{
    opacity: 0;
    height: 0px;
    transition: all .5s reverse;
}

.box-sm{
    width: 470px;
}
</style>
