<template>
    <section>
        <div class="panel queue-list">
            <div class="panel-body">
                <div class="queue-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-queue table-queue-header" id="datatable-queue">
                            <thead>
                                <tr>
                                    <th class="col_queues col_img">
                                        <router-link :to="{name: 'queueAdd'}" tag="button" class="btn btn-success btn-add">
                                            <i class="glyph-icon icon-plus"></i>
                                        </router-link>
                                    </th>

                                    <th class="col_rules col_title">
                                        <div class="add_group_to_queue">
                                            <div class="row">
                                                <div class="col-sm-3 col-md-3 col-lg-3">
                                                    <div style="float: left; width: 100%">
                                                        <router-link :to="{name: 'groupAdd'}" tag="button" class="btn btn-success btn-add" style="width: 100%;">
                                                            <i class="glyph-icon icon-plus"></i>
                                                        </router-link>
                                                    </div>
                                                </div>

                                                <div class="col-sm-9 col-md-9 col-lg-9 list-group" ref="groups">
                                                    <multi-select-queue-group :categories-alt="popupGroup.alt"
                                                                :categories-title="''"
                                                                :get-category-list="getGroupSelectView(groupNotUsedList)"
                                                                :setDefaultCategory="getGroupValue"
                                                                style-block-select="margin-bottom: 0px;"
                                                                categories-style="line-height: 40px;"
                                                                :is-required="false"
                                                                :is-search="false"
                                                                :is-border="true"
                                                                :view-count="true"
                                                                :category-id-out="Number(queueActive.value)"
                                                                :categories-status='categoriesStatus'
                                                                :count-item="-1"
                                                                @setCategory="val => popupGroup.value = val"
                                                                @removeCategory="val => popupGroup.value = val"
                                                                @setCategoryStatus="val => categoriesStatus = val"
                                                                @addGroup="group => addGroupQueueDB(group)"
                                                                >
                                                    </multi-select-queue-group>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </th>

                                    <th class="col_adunits">
                                        <button class="btn btn-success btn-add"
                                                @click.prevent="setAdunitGroupAdd($event, groupActive.value, getLang.group.addAdunitTitle, getLang.group.addAdunitMessage, '/api/queue/group/adblock/add', typeBannerActive.id)"
                                                v-if="isCrosspromo"
                                        >
                                            <i class="glyph-icon icon-plus"></i>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="flex-container-between">
                        <div class="row" style="width: 100%;">
                            <!-- Scenarios -->
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="nav" ref="navScenario" id="navScenatio">
                                    <div class="btn-group btn-group-justified" style="table-layout: auto;">
                                        <a class="btn btn-default"
                                            role="button"
                                            v-for="(item, inx) in getTypeBannerList"
                                            :key="inx"
                                            :class="typeBannerActive.name == item.name ? 'active' : ''"
                                            @click.prevent="searchByTypeBanner($event, item.id)"
                                        >
                                            {{ item.name }}
                                        </a>
                                    </div>
                                </div>

                                <div class="scroll"
                                    :style="'height: ' + getQueueColScrollHeight + 'px;'"
                                    style=" direction: rtl; padding-right: 0px; padding-left: 10px; margin-right: 0px;"
                                >

                                    <transition-group name="list-complete" style="direction: ltr;">
                                    <div v-for="(item, index) in getQueueViewSort"
                                        :key="item.position"
                                        class="flex-block-queue"
                                        style="position: relative; height: 130px;"
                                    >
                                        <transition name="skide" mode="in-out">
                                            <div class="col-md-2"
                                                style="border: 0px none; position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px;"
                                                v-show="queueActive.value == item.idx"
                                            >
                                                <a href="#"
                                                    style="width: 100%; display: block; text-align: center; font-size: 40px; height: 40px; position: absolute; top: 0px; left: 0px;"
                                                    :style="index == 0 ? 'display: none;' : ''"
                                                    @click.prevent="updateQueuePosition(index, 'minus')"
                                                >
                                                    <i class="glyph-icon icon-sort-desc" style="height:40px; line-height: 40px; display: block;"></i>
                                                </a>
                                                <a href="#"
                                                    style="width: 100%; display: block; text-align: center; font-size: 40px; height: 40px; position: absolute; bottom: 10px; left: 0px;"
                                                    :style="index == queueView.length-1 ? 'display: none;' : ''"
                                                    @click.prevent="updateQueuePosition(index, 'plus')"
                                                >
                                                    <i class="glyph-icon icon-sort-asc" style="height:40px; line-height: 40px; display: block;"></i>
                                                </a>
                                            </div>
                                        </transition>
                                        <div class="queue_item list-complete-item-queue"
                                            :class="queueActive.value == item.idx ? 'col-md-10 col-md-offset-2' : ''"
                                            :style="queueActive.value == item.idx ? 'border: 1px solid #bbb; background-color: #BBDDDD;' : 'cursor: pointer;'"
                                            @click.prevent="setQueueActive(item.idx)"
                                            >
                                            <div class="logo">
                                                <span class="bs-badge badge-success float-right" v-if="item.isactive == 1">&nbsp;</span>
                                                <span class="bs-badge badge-danger float-right" v-else>&nbsp;</span>
                                            </div>
                                            <div v-if="item.name" class="title short_text">
                                                {{ getLang.queue.name }}: {{ item.name }}
                                            </div>
                                            <hr/>
                                            <div class="description">
                                                <div class="col-sm-7 col-md-7">
                                                    <div class="short_text" v-html="getLang.queue.application + ': ' + getParseJoin(item.targetingbundle)"></div>
                                                    <div class="short_text">{{ getLang.queue.country }}: {{ getParseJoin(item.targetingcountry) }}</div>
                                                    <div class="short_text">
                                                        {{ getLang.queue.cycle }}:
                                                        <span v-if="item.cycle">{{ getLang.on }}</span>
                                                        <span v-else>{{ getLang.off }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5 col-md-5" style="display: flex; flex-direction: column; align-items: flex-end;">
                                                    <!-- <button class="btn btn-alt btn-hover btn-default edit_queue"
                                                            style="margin-bottom: 5px;"
                                                            data-toggle="modal"
                                                            data-target=".bs-example-modal-sm"
                                                            :disabled="item.isdefault == 1"
                                                            @click.prevent="showAdunitScenario($event, item.idx)"
                                                    >
                                                        <span v-if="queueActive.value == item.idx"><i class="glyph-icon icon-image"></i></span>
                                                        <span v-else>{{ getLang.showAdunit }}</span>
                                                        <i class="glyph-icon icon-arrow-right"></i>
                                                    </button> -->

                                                    <button class="btn btn-alt btn-hover btn-default edit_queue"
                                                            data-toggle="modal"
                                                            data-target=".bs-example-modal-sm"
                                                            :disabled="item.isdefault == 1"
                                                            @click.prevent="setQueueEdit($event, item.idx)"
                                                    >
                                                        <!-- <span v-if="queueActive.value == item.idx"><i class="glyph-icon icon-ellipsis-h"></i></span>
                                                        <span v-else>{{ getLang.edit }}</span> -->
                                                        <span>{{ getLang.edit }}</span>
                                                        <i class="glyph-icon icon-arrow-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="actions">
                                                <!-- <div class="col-sm-12"> -->

                                                    <!-- <button class="btn btn-alt btn-hover btn-danger delete_queue"
                                                            data-toggle="modal"
                                                            data-target=".bs-example-modal-sm"
                                                            :disabled="item.isdefault == 1"
                                                            @click.prevent="setPopupDelete($event, item.idx, item.name, getLang.queue.deleteTitle, getLang.queue.deleteMessage, '/api/queue/delete/', 'queue')"
                                                    >
                                                        <span>{{ getLang.delete }}</span>
                                                        <i class="glyph-icon icon-arrow-right"></i>
                                                    </button> -->

                                                <!-- </div> -->
                                                <div class="clear"></div>
                                            </div>
                                            <div class="clear"></div>
                                        </div>
                                        <div class="clear" v-show="queueActive.value == item.idx" style="border: 0px none;"></div>
                                    </div>
                                    </transition-group>

                                </div>
                                <!-- Default Scenario -->
                                <div class="flex-block-queue"
                                    style="position: absolute; padding: 0 20px 0 0;"
                                    :style="getQueueColHeight > getQueueColScrollHeight ? 'bottom: 0px;' : 'top: ' + getQueueDefaultTop + 'px;'"
                                    ref="queues"
                                    v-show="queueDefault.length > 0"
                                >
                                    <div class="queue_item"
                                        :class="''"
                                        :style="queueActive.value == item.idx ? 'border: 1px solid #bbb; background-color: #BBDDDD;' : 'background-color: #EEEEEE; cursor: pointer;'"
                                        style="margin-top: 10px;"
                                        v-for="item in queueDefault"
                                        :key="item.idx"
                                        @click.prevent="setQueueActive(item.idx)"
                                    >
                                        <div v-if="'name'" class="title short_text">
                                            {{ getLang.queue.name }}: {{ item.name }}
                                        </div>
                                        <hr/>
                                        <div class="description">
                                            <div class="col-sm-12 col-md-12">
                                                <div class="short_text" v-html="getLang.queue.application + ': ' + getParseJoin(item.targetingbundle)"></div>
                                                <div class="short_text">{{ getLang.queue.country }}: {{ getParseJoin(item.targetingcountry) }}</div>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            <div class="clear"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </div>
                                <!-- End default Scenario -->
                            </div>
                            <!-- End Scenarios -->

                            <!-- Rules -->
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="scroll" :style="'height: ' + queueListHeight + 'px; padding-right: 0px; margin-right: 0px;'">
                                    <!-- <draggable v-model="groupQueue" :component-data="getComponentData()"> -->
                                        <transition-group name="list-complete">

                                            <div v-for="(item, index) in getGroupQueueSort"
                                                :key="item.position"
                                                style="position: relative;"
                                            >
                                                <transition name="skide" mode="in-out">
                                                    <div class="col-md-2"
                                                        style="border: 0px none; position: absolute; top: 0px; left: 0px; bottom: 0px; right: 0px;"
                                                        v-show="groupActive.value == item.idx"
                                                    >
                                                        <button
                                                            style="width: 100%; display: block; text-align: center; font-size: 40px; height: 40px; position: absolute; top: 0px; left: 0px; border: 0px none; background-color: transparent; outline: 0px none;"
                                                            :style="index == 0 ? 'display: none;' : ''"
                                                            :disabled="isSorting"
                                                            @click.prevent="updateGroupPosition(index, 'minus')"
                                                        >
                                                            <i class="glyph-icon icon-sort-desc sort_icon"></i>
                                                        </button>
                                                        <button
                                                            style="width: 100%; display: block; text-align: center; font-size: 40px; height: 40px; position: absolute; bottom: 10px; left: 0px; border: 0px none; background-color: transparent; outline: 0px none;"
                                                            :style="index == groupQueue.length - 1 ? 'display: none;' : ''"
                                                            :disabled="isSorting"
                                                            @click.prevent="updateGroupPosition(index, 'plus')"
                                                        >
                                                            <i class="glyph-icon icon-sort-asc sort_icon"></i>
                                                        </button>
                                                    </div>
                                                </transition>

                                                <div class="flex-block-group list-complete-item"
                                                    :class="groupActive.value == item.idx ? 'col-md-10 col-md-offset-2' : ''"
                                                    :style="groupActive.value == item.idx ? 'border-radius: 5px; background-color: #BBDDDD;' : 'cursor: pointer;'"
                                                    @click.prevent="getAdunitByRule(item.idx, item.network)"
                                                >
                                                    <div style="min-height: 100px; position: relative;" :class="typeNetworkRelationDB.find(val => val.adtype == item.type && val.network == item.network) ? '' : 'is_type_network'">
                                                        <div v-if="item.name" class="title short_text">
                                                            <span class="bs-badge badge-success float-right" v-if="item.enabled">&nbsp;</span>
                                                            <span class="bs-badge badge-danger float-right" v-else>&nbsp;</span>
                                                            {{ clearHtmlTags(item.name) }}
                                                        </div>

                                                        <div class="logo">
                                                            <img :src="getNetworkLogo(item.network)" />
                                                            <div style="min-width: 130px;">
                                                                <div class="short_text" v-if="typeBannerActive.name == stateBanner">{{ getLang.group.showTimeInBox }}: {{ item.show_time }}</div>
                                                                <div class="short_text" v-if="typeBannerActive.name == stateInter">{{ getLang.group.showClosedInBox }}: {{ item.show_time }}</div>
                                                                <div class="short_text"
                                                                    v-if="(typeBannerActive.name == stateBanner || typeBannerActive.name == stateInter || typeBannerActive.name == stateRewarded)"
                                                                    :style="(getNetworkName(item.network) == values.network.crosspromo) ? 'color: #999;' : ''"
                                                                >
                                                                    {{ getLang.group.adUnitPriceInBox }}: {{ getAdunitPrice(item.adunitPrice) }}
                                                                </div>
                                                                <div class="short_text" v-if="getNetworkName(item.network) != values.network.crosspromo">
                                                                    {{ getLang.queue.networkCount }}:
                                                                    <span v-if="item.network_count == -1">&infin;</span>
                                                                    <span v-else>{{ item.network_count }} </span>
                                                                </div>
                                                                <div class="short_text" v-if="getNetworkName(item.network) == values.network.crosspromo">{{ getLang.group.showType }}: {{ item.show_type }}</div>
                                                            </div>
                                                            <button class="btn btn-alt btn-hover btn-default edit_group float-right"
                                                                    data-toggle="modal"
                                                                    data-target=".bs-example-modal-sm"
                                                                    @click.prevent="setGroupEdit($event, item.idx, typeBannerActive.name)"
                                                                    >
                                                                <span>{{ getLang.group.edit }}</span>
                                                                <i class="glyph-icon icon-arrow-right"></i>
                                                            </button>
                                                        </div>
                                                        <!-- <hr>
                                                        <div class="flex-container-between description">
                                                            <div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
                                                                <div class="short_text" v-if="getNetworkName(item.network) == values.network.crosspromo">{{ getLang.group.showType }}: {{ item.show_type }}</div>
                                                                <div class="short_text" v-if="getNetworkName(item.network) == values.network.crosspromo && typeBannerActive.name == stateBanner">{{ getLang.group.showTimeInBox }}: {{ item.show_time }}</div>
                                                                <div class="short_text" v-if="getNetworkName(item.network) != values.network.crosspromo">
                                                                    {{ getLang.queue.networkCount }}:
                                                                    <span v-if="item.network_count == -1">&infin;</span>
                                                                    <span v-else>{{ item.network_count }} </span>
                                                                </div>
                                                            </div>

                                                            <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                                                                <button class="btn btn-alt btn-hover btn-danger delete_group float-right"
                                                                        @click.prevent="setPopupDelete($event, item.idx, item.name, getLang.group.deleteTitle, getLang.queue.deleteMessageGroup, '/api/queue/group/delete/' + queueActive.value + '/', 'group')"
                                                                        >
                                                                    <span>{{ getLang.group.delete }}</span>
                                                                    <i class="glyph-icon icon-arrow-right"></i>
                                                                </button>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="clear" v-show="groupActive.value == item.idx" style="border: 0px none;"></div>
                                            </div>
                                        </transition-group>
                                    <!-- </draggable> -->
                                </div>
                            </div>
                            <!-- End Rules -->

                            <!-- Adunit in Rule -->
                            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <div class="scroll" :style="'height: ' + queueListHeight + 'px;'">
                                <div v-for="item in adunitInRule"
                                    :key="item.order"
                                    class="flex-block-adunit-group flex-block-adunit-group-added list-complete-item"
                                >
                                    <div class="inner_block">
                                        <table class="adunit_in_group_added">
                                            <tr>
                                                <td :class="typeBannerActive.id != 0 ? 'icon_other_type' : 'icon'">
                                                    <div :style="typeBannerActive.id == 0 ? 'width: 163px;' : ''" style="display: flex; overflow: hidden; height: 51px;">
                                                        <img :src="item.adblock_icon" v-if="item.adblock_icon && (!item.image_only || typeBannerActive.id != 0)" class="icon" :style="typeBannerActive.id == 0 ? 'margin-right: 10px;' : 'margin-right: 0px;'" alt="">

                                                        <img :src="item.adblock_image" v-if="item.adblock_image && typeBannerActive.id == 0" :class="item.image_only ? 'image_only' : 'image'" alt="">
                                                        <img :src="item.adblock_image43" v-else-if="item.adblock_image43 && typeBannerActive.id == 0" class="image" alt="">
                                                        <img :src="item.adblock_image11" v-else-if="item.adblock_image11 && typeBannerActive.id == 0" class="image" alt="">
                                                        <img :src="item.adblock_image34" v-else-if="item.adblock_image34 && typeBannerActive.id == 0" class="image" alt="">
                                                        <img :src="item.adblock_image916" v-else-if="item.adblock_image916 && typeBannerActive.id == 0" class="image" alt="">
                                                    </div>
                                                </td>

                                                <td :class="typeBannerActive.id != 0 ? 'info_other_type' : 'info'">
                                                    <div style="position: absolute; right: 0px; padding-right: 5px; text-align: center;">
                                                        <div class="status">
                                                            <span class="bs-badge" :class="item.enabled ? 'badge-success' : 'badge-danger'">&nbsp;</span>
                                                        </div>
                                                        <span>{{ item.count }}</span>
                                                    </div>

                                                    <div class="info__description">
                                                        <div class="info__name short_text_adunit" v-if="item.application_url">
                                                            ({{ item.adblock_title }})
                                                        </div>
                                                        <div class="short_text_adunit" :title="item.adblock_title">
                                                            {{ item.application_url }}
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!-- End Adunit in Rule -->
                        </div>
                    </div>
                </div>

                <!-- <popup-adunit-scenario
                        :opened="opened"
                        :queue-id="popupQueueId"
                        :rules="popupRulesInScenario"
                        @setUpdata="''"
                        >
                </popup-adunit-scenario> -->

                <popup :popup-title="popupName"
                        :edit-id="popupId"
                        :url-delete="popupUrlDelete"
                        url-redirect="queue"
                        :title="popupTitle"
                        :message="popupMsg"
                        @setUpdata="val => {isUpdate = val; isStatusUpdate = 'delete';}"
                        >
                </popup>

                <popup-adunit-group :popup-title="popupAddName"
                        :edit-id="popupAddId"
                        :url-delete="popupAddUrlDelete"
                        url-redirect="group"
                        :title="popupAddTitle"
                        :message="popupAddMsg"
                        :type-banner="popupAddTypeBanner"
                        @setUpdata="val => isUpdate = val"
                        >
                </popup-adunit-group>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import Popup from '../plugins/Popup';
import PopupAdunitGroup from '../plugins/PopupAdunitGroup';
import PopupAdunitScenario from '../plugins/PopupAdunitScenario';
import MultiSelectQueueGroup from '../plugins/MultiSelectQueueGroup';
import Draggable from 'vuedraggable';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            queueListHeight: 0,
            headerHeight: 70,
            othersHeight: 86,
            addGroupHeight: 54,
            queueDefautHeight: 0,
            marginSpaceHeight: 10,
            navScenarioHeight: 34,
            queueColScrollHeight: 0,
            queueColHeight: 0,

            queueDefaultTop: 34,
            calcQueueBlockHeight: 130,
            calcDefaultTopHeight: 34,

            isRemeber: false,
            opened: null,
            popupQueueId: null,

            navScenarioDiv: '',
            navUnderLine: 10,

            queueList: [],
            queueView: [],
            queueDefault: [],
            queueDiv: '',
            queueActive: {
                value: 0,
                typeBanner: '',
            },

            stateBanner: 'banner',
            stateInter: 'interstitial',
            stateRewarded: 'rewarded',

            values: {
                network: {
                    crosspromo: 'crosspromotion',
                    crossId: 0,
                    exchange: 'exchange',
                    exchangeId: 1,
                    advertisement: 'advertisement',
                    advertisementId: 2,
                    admob: 'admob',
                    admobId: 3,
                    facebook: 'facebook',
                    facebookId: 4,
                    unityads: 'unityads',
                    unityadsId: 5
                }
            },

            networkList: [],
            typeBannerList: [],
            typeBannerActive: {
                name: '',
                id: 0,
            },

            groupList: [],
            groupNotUsedList: [],
            groupDiv: '',
            groupQueueDB: [],
            groupQueue: [],
            groupOrder: 1,
            groupActive: {
                value: 0,
                network: -1
            },

            adunitInRule: [],

            osActive: 1,
            popupId: 0,
            popupName: '',
            popupUrlDelete: '',
            popupType: '',
            popupTitle: '',
            popupMsg: '',
            popupGroup: {
                value: [],
                alt: ''
            },
            popupRulesInScenario: [],

            popupAddId: 0,
            popupAddName: '',
            popupAddTypeBanner: 0,
            popupAddUrlDelete: '',
            popupAddTitle: '',
            popupAddMsg: '',
            popupAdunitGroup: {
                value: [],
                alt: ''
            },

            applicationList: [],

            typeNetworkRelationDB: [],

            logos: {},

            categoriesStatus: false,
            isSorting: false,
            isUpdate: false,
            isStatusUpdate: '',
        }
    },
    watch: {
        isUpdate(){
            if(this.popupType == 'queue') this.getQueueListDB('update');
            if(this.popupType == 'group') {
                if (this.isStatusUpdate == 'delete') {
                    this.setGroupActive(0, -1);
                    this.adunitInRule = [];
                }

                let prom = this.getGroupListDB(); // получаем с БД группы
                prom.then(() => {
                    return this.getGroupQueueDB();
                }) // выводим обновленные группы
                .then(resolve => {
                    setTimeout(() => {
                        this.isSorting = false;
                    }, 1000);
                });
            }
            if (this.popupType == 'adblock') {
                this.getAdunitByRule(this.groupActive.value, this.groupActive.network);
            }

            this.isUpdate = false;
            this.isStatusUpdate = '';
        },
        getGroupValue(){
            let _this = this;

            this.$nextTick(function(){
                _this.addGroupHeight = _this.groupDiv.offsetHeight;
            });
        },
        getOsActive(){
            this.searchByTypeBanner({}, this.typeBannerActive.id, this.typeBannerActive.name);
        }
    },
    created(){
        this.getQueueListDB(); // получаем с БД очереди
        this.getGroupListDB(); // получаем с БД группы
        this.getApplicationDB(); // получаем с БД приложения
        this.getTypeNetworkRelationDB(); // получаем с БД связь network и adtype
    },
    mounted(){
        let _this = this;
        this.setQueueListHeight(); // задаем высоту для блока queue

        window.addEventListener('resize', function(){
            _this.setQueueListHeight();
        });

        this.$nextTick(() => {
            this.groupDiv = this.$refs.groups;
            this.queueDiv = this.$refs.queues;

            this.addGroupHeight = this.groupDiv.offsetHeight; // определяем высоту блока group
            this.queueDefautHeight = this.queueDiv.offsetHeight; // определяем высоту дефолтного блока сценария
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        isCrosspromo(){
            return this.groupActive.value != 0 && this.getNetworkName(this.groupActive.network) == this.values.network.crosspromo;
        },

        // для добавления новых групп
        getGroupValue(){
            return this.popupGroup.value;
        },

        getGroupQueueId(){
            let ids = [];

            this.groupQueue.forEach(function(item, i){
                ids[i] = item.idx;
            });

            return ids;
        },

        getQueueViewSort(){
            this.queueView.sort((a, b) => a.position - b.position);

            return this.queueView;
        },

        getGroupQueueSort(){
            this.groupQueue.sort((a, b) => a.position - b.position);

            return this.groupQueue;
        },

        // max id sort group in queue
        getGroupQueueMaxSort(){
            let maxValue = 0;

            this.groupQueue.forEach(function(item){
                if(item.order > maxValue) maxValue = item.order;
            });

            maxValue = (maxValue === 0 ? 1 : ++maxValue);

            return maxValue;
        },

        getOsActive(){
            this.osActive = this.$store.getters.getOsActive;

            return this.osActive;
        },

        // get type banner list
        getTypeBannerList(){
            this.typeBannerList = this.$store.getters.getTypeBannerList;

            return this.typeBannerList;
        },

        // get relation adtype and array networks by id adtype
        getTypeNetworkRelation(){
            let relation = [];

            this.typeNetworkRelationDB.forEach(function(item, i){
                if(relation[item.adtype] == undefined) relation[item.adtype] = [];

                relation[item.adtype][item.network] = item.network;
            });

            //relation = this.setUnique(relation, true);

            return relation;
        },

        getQueueColScrollHeight(){
            this.queueColScrollHeight = (this.queueListHeight - (this.queueDefautHeight + this.getNavScenarioHeight + this.navUnderLine));

            return this.queueColScrollHeight;
        },

        getQueueColHeight(){
            let count = this.getQueueViewSort.length;
            this.queueColHeight = count * this.calcQueueBlockHeight;

            return this.queueColHeight;
        },

        getQueueDefaultTop(){
            if (this.getQueueColHeight === 0) {
                this.queueDefaultTop = this.calcDefaultTopHeight;
            } else if (this.getQueueColHeight > this.queueColScrollHeight) {
                this.queueDefaultTop = this.queueColHeight + this.calcDefaultTopHeight;
            } else {
                this.queueDefaultTop = this.getQueueColHeight + this.calcDefaultTopHeight;
            }

            return this.queueDefaultTop;
        },

        getNavScenarioHeight(){
            this.$nextTick(function() {
                this.navScenarioDiv = this.$refs.navScenario;

                if(this.navScenarioDiv.offsetHeight > 0) this.navScenarioHeight = this.navScenarioDiv.offsetHeight; // определяем высоту навигации типов блоков сценария
            });

            return this.navScenarioHeight;
        },

        // get logo list
        getLogos(){
            this.logos = this.$store.state.logos.icons;

            return this.logos;
        },

        // get network list
        getNetworkList(){
            this.networkList = this.$store.getters.getNetworkList;

            return this.networkList;
        }
    },
    methods: {
        // draggable
        // inputEnd() {
        //     let data = [];

        //     this.groupQueue.forEach(function(item, i, arr){
        //         arr[i].order = i+1;

        //         data[i] = {group: arr[i].idx, order: arr[i].order};
        //     });

        //     this.updateGroupSortDB(data);
        // },
        // getComponentData() {
        //     return {
        //         on: {
        //             end: this.inputEnd
        //         }
        //     };
        // },

        // position
        updateQueuePosition(id, sign){
            let positionAr = [];

            positionAr = this.queueView.
                                    map((val, index) => {
                                        if (sign == 'plus') {
                                            if(index == id) return {'queue': val.idx, 'order': (val.position + 1)};
                                            else if(index == id + 1) return {'queue': val.idx, 'order': (val.position - 1)};
                                        }

                                        if (sign == 'minus') {
                                            if(index == id) return {'queue': val.idx, 'order': (val.position - 1)};
                                            else if(index == id - 1) return {'queue': val.idx, 'order': (val.position + 1)};
                                        }

                                        return;
                                    }).
                                    filter(val => val != null);

            this.updateQueueSortDB(positionAr);
        },

        // position
        updateGroupPosition(id, sign){
            let positionAr = [];

            positionAr = this.groupQueue.
                                    map((val, index) => {
                                        if (sign == 'plus') {
                                            if(index == id) return {'group': val.idx, 'order': (val.position + 1)};
                                            else if(index == id + 1) return {'group': val.idx, 'order': (val.position - 1)};
                                        }

                                        if (sign == 'minus') {
                                            if(index == id) return {'group': val.idx, 'order': (val.position - 1)};
                                            else if(index == id - 1) return {'group': val.idx, 'order': (val.position + 1)};
                                        }

                                        return;
                                    }).
                                    filter(val => val != null);

            this.updateGroupSortDB(positionAr);
        },

        // update sort queue to DB
        updateQueueSortDB(queueOrder){
            let data = JSON.stringify({
                adtypeid: Number(this.groupActive.value),
                queue_pairs: queueOrder
            });

            axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/edit/position?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your queue is update sort!'});

                this.queueList = this.queueList.map(val => {
                    let find = queueOrder.find(item => {
                        return item.queue == val.idx;
                    });

                    if(find) val.position = find.order;

                    return val;
                });

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error update sorting queue');
            });
        },

        /*--- Parses ---*/
        getParse(data){
            let tmpArr = JSON.parse(data);
            return (tmpArr ? tmpArr : '');
        },

        getParseJoin(data){
            let tmpArr = JSON.parse(data);
            return (tmpArr ? tmpArr.join() : '');
        },
        /*--- End Parses ---*/

        /*--- Popup adunit scenario ---*/
        showAdunitScenario(e, id){
            new Promise(resolve => {
                return resolve(this.setQueueActive(id));
            })
            .then(resolve => {
                let crosspromo = [];
                for(let i = 0; i < resolve.length; i++){
                    let name = this.getNetworkName(resolve[i].network);
                    if (name != this.values.network.crosspromo) continue;
                    crosspromo.push(resolve[i]);
                }

                return crosspromo;
            })
            .then(resolve => {
                this.opened = this.getUniqueId();
                this.popupQueueId = id;
                this.popupRulesInScenario = resolve;
                this.$store.commit('modals/setPopupAdd', true);
            });
        },
        /*--- End popup adunit scenario ---*/

        // add id group for edit
        setGroupEdit(e, id, typeBanner){
            this.$store.commit('setGroupEditId', id);
            this.$router.push({name: 'groupEdit', query: {isqueue: 'queue', typebanner: typeBanner, queueActive: this.queueActive.value}});
        },
        // set active group
        setGroupActive(ruleId, network){
            this.groupActive.value = ruleId;
            this.groupActive.network = network;

            this.$store.dispatch('setRemeberQueueGroupIdAction', ruleId);
            this.$store.dispatch('setRemeberQueueGroupNetworkAction', network);
        },

        getAdunitPrice(level){
            let res;

            if (level == 1) res = this.getLang.btnStateMedium;
            else if (level == 2) res = this.getLang.btnStateHigh;
            else res = this.getLang.btnStateLow;

            return res;
        },

        // get network name by id
        getNetworkName(id){
            let name = this.getNetworkList.find(item => item.id == id);

            name = ((name != undefined && name.name != undefined) ? name.name : '');
            name = name.toLowerCase();

            return name;
        },

        // get network by Id
        getNetworkLogo(inx){
            let name = '';
            let logo = '';

            if(this.getNetworkList.length){
                name = this.getNetworkList.find(item => item.id === inx);
                if(name != undefined && name.name != undefined) logo = this.getLogos[name.name];
            }

            return logo;
        },

        /*----- type -----*/

        // get name type banner by id
        getTypeBannerName(id){
            let data = this.getTypeBannerList.find(item => item.id == id);

            return data ? (data.name ? data.name : '') : '';
        },

        getTypeNetworkRelationDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/network/relations/?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.typeNetworkRelationDB = response.data;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get relation network and adtype');
            });
        },
        /*----- end type -----*/

        // get applications string
        getApplication(app){
            let result = 'ALL';
            let delim = '';

            if(app == null || app.length < 1) return result;

            let data = this.applicationList.filter(function(item){
                return app.indexOf(item.id) !== -1;
            });

            if(data.length > 0) {
                result = '';

                data.forEach(function(item){
                    result += delim + item.package_name;
                    delim = ', ';
                });
            }

            return result;
        },

        // get list Applications
        getApplicationDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.applicationList = response.data.applications;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // get list Queue
        getQueueListDB(status){
            status = status || 'default';

            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.queueList = response.data.queue;
                this.queueView = this.queueList;

                if (status == 'update') {
                    // выводим обновленные очереди с баннерами
                    if(this.getTypeBannerList.length) this.searchByTypeBanner({}, this.typeBannerActive.id, this.typeBannerActive.name);
                } else {
                    // записываем дефолтные значения
                    let tmpTypeBannerId = this.getTypeBannerList[0].id;
                    let tmpTypeBannerName = this.getTypeBannerList[0].name;

                    // если запомнили предыдущие настройки, выводим
                    if (this.$store.getters.getRemeberQueueTypeBannerId != null && this.$store.getters.getRemeberQueueTypeBannerName != null) {
                        this.isRemeber = true;
                        tmpTypeBannerId = this.$store.getters.getRemeberQueueTypeBannerId;
                        tmpTypeBannerName = this.$store.getters.getRemeberQueueTypeBannerName;
                    } else {
                        this.isRemeber = false;
                    }

                    // делаем по умолчанию активным пункт banner type и выводим очереди с группами
                    if(this.getTypeBannerList.length) this.searchByTypeBanner({}, tmpTypeBannerId, tmpTypeBannerName);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error get queue list');
            });
        },

        /*----- Groups -----*/
        getGroupSelectView(groupSelect){
            let _this = this;

            let relationNetwork = this.getTypeNetworkRelation[this.typeBannerActive.id]; // получаем список id network для активного type banner
            if(relationNetwork != undefined) relationNetwork = this.setUnique(relationNetwork); // убираем пустые элементы в массиве

            let data = groupSelect.filter(function(val) {
                if(relationNetwork != undefined && (relationNetwork.indexOf(val.network) == -1)) return false; // пров. network у группы на список разрешенных
                return _this.typeBannerActive.id == val.type && _this.getOsActive == val.osid; // пров. на тип и os
                // (_this.getGroupQueueId.indexOf(val.id) == -1) // наличие уже доб. в очереди
                // return true;
            });

            return data;
        },

        // get list Group
        getGroupListDB(status){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/groups?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                this.groupList = response.data.groups;

                this.groupNotUsedList = this.groupList.filter(val => (val.queueid == undefined || val.queueid == 0));
            })
            .catch(e => {
                this.errorsMessage(e, 'error get group list');
            });
        },

        getAdunitByRule(ruleId, network){
            this.setGroupActive(ruleId, network);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/group/adblocks/' + this.groupActive.value + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adunitInRule = response.data.Adblock;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get adunit list by rule id');
            });
        },

        // get group list with queue group
        getGroupQueue(){
            this.groupQueue = [];
            let _this = this;
            let data = [];

            //this.groupQueueDB; {group, order, queue}
            let order = 0;

            this.groupList.forEach(function(item){
                let group = _this.groupQueueDB.find(val => val == item.idx);

                if(group) {
                    let order = item.position;
                    //order++;

                    data[order-1] = item;
                    data[order-1].queue = _this.queueActive.value; // group.queue;
                    data[order-1].order = order;
                    data[order-1].fixed = false;
                }
            });

            data = this.setUnique(data);

            this.groupQueue = data;

            return this.groupQueue;
        },
        // get group list by id queue from DB
        getGroupQueueDB(){
            this.groupQueueDB = [];

            return axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/groups/queue/' + this.queueActive.value + '?at=' + this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.groupQueueDB = response.data.queue_group.map(val => val.group);
                return this.getGroupQueue();
            })
            .catch(e => {
                this.errorsMessage(e, 'error get group by queue');
            });
        },
        // set groups in queue to DB
        addGroupQueueDB(group){
            let _this = this;
            this.groupOrder = this.getGroupQueueMaxSort;

            this.getGroupValue.forEach(function(group, i, arr){
                let data = JSON.stringify({
                    queue: Number(_this.queueActive.value),
                    group: group,
                    order: _this.groupOrder++
                });

                axios({
                    url: _this.$store.getters.getBaseUrl + '/api/queue/group/add?at=' + _this.getAccessToken,
                    method: 'POST',
                    data: data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    if (i == arr.length-1) {
                        _this.popupGroup.value = [];

                        let uniqueId = _this.getUniqueId();
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your rules is added to queue!'});

                        setTimeout(function(){
                            _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                        }, 3000);

                        // выводим обновленные группы
                        _this.popupType = 'group';
                        _this.isUpdate = true;
                        _this.isStatusUpdate = 'add';
                    }
                })
                .catch(e => {
                    _this.errorsMessage(e, 'error set group to queue');
                });
            });
        },
        // update sort group in queue to DB
        updateGroupSortDB(groupOrder){
            let data = JSON.stringify({
                queue: Number(this.queueActive.value),
                order_pairs: groupOrder
            });

            axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/group/edit?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your group is update sort!'});
//console.log('groupOrder = ', groupOrder);
//console.log('groupList = ', this.groupList);
                // this.groupList = this.groupList.map(val => {
                //     let find = groupOrder.find(item => {
                //         return item.group == val.idx;
                //     });

                //     if(find) val.position = find.order;

                //     return val;
                // });

                for(let i = 0; i < this.groupList.length; i++){
                    let find = groupOrder.find(item => {
                        return item.group == this.groupList[i].idx;
                    });

                    if(find) this.$set(this.groupList[i], 'position', find.order);
                }

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error update sorting group in queue');
            });
        },
        /*----- end Group -----*/

        // set active queue
        setQueueActive(id){
            // если запомнили настройки, выводим, иначе задаем текущие
            if (this.isRemeber) {
                if(this.$store.getters.getRemeberQueueQueueName != null) this.queueActive.typeBanner = this.$store.getters.getRemeberQueueQueueName;
                else this.queueActive.typeBanner = this.typeBannerActive.name;

                if(this.$store.getters.getRemeberQueueQueueId != null) this.queueActive.value = this.$store.getters.getRemeberQueueQueueId;
                else this.queueActive.value = id;

                this.groupActive.value = this.$store.getters.getRemeberQueueGroupId;
                this.groupActive.network = this.$store.getters.getRemeberQueueGroupNetwork;
                this.getAdunitByRule(this.groupActive.value, this.groupActive.network);

                this.isRemeber = false;
            } else {
                this.queueActive.typeBanner = this.typeBannerActive.name;
                this.$store.dispatch('setRemeberQueueQueueNameAction', this.queueActive.typeBanner);

                this.queueActive.value = id;
                this.$store.dispatch('setRemeberQueueQueueIdAction', this.queueActive.value);

                this.setGroupActive(0, -1); // убираем активное правило
                this.adunitInRule = []; // очищаем рекламные блоки
            }

            this.popupGroup.value = [];

            return this.getGroupQueueDB();
        },

        // add id Queue for edit
        setQueueEdit(e, id){
            this.$store.commit('setQueueEditId', id);
            this.$router.push({name: 'queueEdit'});
        },

        // set height for table list Queue
        setQueueListHeight(){
            this.queueListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.queueListHeight < 0) this.queueListHeight = 0;

            return this.queueListHeight;
        },

        // search by type banner
        searchByTypeBanner(e, id, name){
            this.queueView = this.queueList.filter(item => {
                return item.adtypeid == id && item.isdefault == 0 && item.osid == this.getOsActive;
            });

            // default queue
            this.queueDefault = this.queueList.filter(item => item.adtypeid == id && item.isdefault == 1 && item.osid == this.getOsActive);

            this.typeBannerActive.name = name || e.target.innerText;
            this.$store.dispatch('setRemeberQueueTypeBannerNameAction', this.typeBannerActive.name);

            this.typeBannerActive.id = id;
            this.$store.dispatch('setRemeberQueueTypeBannerIdAction', this.typeBannerActive.id);

            // hidden group list
            this.categoriesStatus = false;

            if(this.queueDefault.length > 0) this.setQueueActive(this.queueDefault[0].idx);
            else this.setQueueActive(-1);

            // update height default queue
            this.$nextTick(function(){
                this.queueDefautHeight = this.queueDiv.offsetHeight;
            });

            // если у активного пункта очереди, type banner совпадает с активным type banner тогда выводим очередь и группы, если нет, тогда прячем группы
            // if (this.queueActive.typeBanner == this.typeBannerActive.name) {
            //     this.setQueueActive(this.queueActive.value);
            // } else {
            //    this.setQueueActive(id);

            //    this.groupQueueDB = [];
            //    this.getGroupQueue();
            //}
        },

        // set popup enable and set data
        setPopupDelete(e, id, name, title, msg, urlDelete, type){
            this.isSorting = true;

            this.popupId = id;
            this.popupName = name;
            this.popupTitle = title;
            this.popupMsg = msg;
            this.popupType = type;
            this.popupUrlDelete = urlDelete;

            this.$store.commit('modals/setPopupDelete', true);
        },

        setAdunitGroupAdd(e, id, title, msg, urlDelete, type){
            let tmp = this.getGroupQueueSort.filter(val => val.idx == this.groupActive.value);
            let name = ((tmp[0] && tmp[0].name) ? tmp[0].name : '');

            this.popupAddId = Number(id);
            this.popupAddName = name;
            this.popupAddTypeBanner = Number(type);
            this.popupAddTitle = title;
            this.popupAddMsg = msg;
            this.popupAddUrlDelete = urlDelete;
            this.popupType = 'adblock';

            this.$store.commit('modals/setPopupAdd', true);
        }
    },
    components: {
        Pagination,
        Popup,
        PopupAdunitGroup,
        PopupAdunitScenario,
        MultiSelectQueueGroup,
        Draggable
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    .nav{
        margin-bottom: 10px;
    }

        .nav a{
            z-index: 2;
        }

    /*---*/
    .col_queues, .col_rules, .col_adunits{
        width: 33%;
    }

        .col_img .icon-question{
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0;
        }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    section section{
        width: 300px;
    }

    .queue-list{
        border: 0px none;
    }

        .queue-list .panel-body{
            padding: 0px;
        }

    .queue-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .table-queue th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    .type_banner_menu a{
        padding: 10px 30px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .is_type_network{
        background-color: #999;
    }

        .is_type_network button.edit_group{
            background-color: #ccc;
            border-color: #ddd;
        }

    .table-queue-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-queue-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-queue-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

    td.center, th.center{
        text-align: center;
    }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .short_text_adunit{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: inherit;
    }

    a.active{
        color: #014c8c;
        cursor: default;
        text-decoration: none;
    }

    .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
        border-bottom-left-radius: 0;
    }

    .btn-group > .btn:last-child:not(:first-child) {
        border-bottom-right-radius: 0;
    }

    .nav a.btn{
        border-bottom: 0px none;
    }

    .nav a.btn:not(:last-child){
        border-right: 0px none;
    }

    /* flexbox */
    .flex-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .flex-container-center{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 13px 0;
    }

        .flex-block-queue{
            width: 100%;
            padding: 0 0 10px 0;
        }

            .flex-block-queue>div{
                border: 1px solid #ddd;
                border-radius: 5px;
                height: 100%;
            }

            .flex-block-queue .logo, .flex-block-queue .title, .flex-block-queue .description, .flex-block-queue .actions{
                box-sizing: border-box;
                padding: 0 8px;
                margin-top: 10px;
            }

            .flex-block-queue .logo{
                margin-top: 5px;
            }

                .flex-block-queue .logo img{
                    border: 1px solid #ddd;
                    border-radius: 10px;
                }

        .flex-block-group{
            width: 100%;
            /* height: 190px; */
            padding: 0;
            margin-bottom: 10px;
            position: relative;
            display: inline-block;
        }

            .flex-block-group>div{
                border: 1px solid #ddd;
                border-radius: 5px;
                height: 100%;
                padding-bottom: 10px;
            }

            .flex-block-group .logo, .flex-block-group .title, .flex-block-group .description{
                box-sizing: border-box;
                padding: 0 8px;
            }

            .flex-block-group .title{
                margin-top: 5px;
            }

            .flex-block-group .logo{
                margin-top: 5px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                overflow: hidden;
                min-height: 56px;
            }

            .flex-block-group hr{
                margin-top: 10px;
                margin-bottom: 10px;
            }

                .flex-block-group .logo img{
                    border: 1px solid #ddd;
                    border-radius: 10px;
                }

    .flex-container-between{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 10px 3px 0 13px;
    }

        .actions{
            margin-bottom: 10px;
        }

        .description div{
            font-size: 14px;
            line-height: 19px;
        }

        .flex-block-group .description>div{
            box-sizing: border-box;
            /* padding: 0 10px 0 0;
            width: 50%; */
        }

            .description>div:last-child a{
                width: 60px;
                font-size: 14px;
            }
    /* end flexbox */

.edit_group{
    /* margin-right: 10px; */
    padding: 0 17px;
}

@media (max-width: 1280px) {
    .edit_group{
        padding: 0 5px;
    }
}

.status_msg{
    position: absolute;
    bottom: 1%;
    right: 5%;
}

.btn-hover span{
    padding: 0 10px;
}

    .btn-hover span .glyph-icon{
        opacity: 1;
        position: static;
    }

.btn-alt{
    font-size: 10px;
}

.sort_icon{
    height:40px;
    line-height: 40px;
    display: block;
    color: #999;
}

.sort_icon:hover{
    color: #58b;
}

/* Adunit in Rule */
.flex-block-adunit-group-added td.icon{
    width: 170px!important;
    width: 40%;
    width: calc(170px);
}

.flex-block-adunit-group-added td.icon_other_type{
    width: 70px!important;
    width: 17%;
    width: calc(70px);
}

    .flex-block-adunit-group-added img.icon{
        width: 51px;
        height: 51px;
    }

    .flex-block-adunit-group-added .image{
        width: 102px;
        height: auto;
    }

    .flex-block-adunit-group-added .image_only{
        /* width: 164px;
        height: auto; */
        width: auto;
        height: 51px;
    }

    .flex-block-adunit-group-added .image_only:hover{
        position: absolute;
        top: 1px;
    }

.info{
    width: 60%;
    box-sizing: border-box;
    padding: 0 10px;
    /* position: relative; */
}

.info_other_type{
    width: 83%;
    box-sizing: border-box;
    padding: 0 10px;
}

        .info__description{
            width: 90%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

            .info__name{
                color: #888;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

    .action{
        width: 30px;
        vertical-align: top;
    }

    .action_add{
        width: 60px;
        vertical-align: middle;
    }

.add_adunit_in_group{
    line-height: 30px;
    height: 30px;
    min-width: 56px!important;
    width: 56px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .add_adunit_in_group i::before{
        font-size: 18px;
    }

.adunit_in_group_added{
    position: relative;
}

.flex-block-adunit-group-added{
    padding: 0 0px 4px!important;
}

    .inner_block{
        display: flex;
        align-items: center;
    }

        .inner_block .action{
            align-self: end;
            display: flex;
            justify-content: flex-end;
        }

    .flex-block-adunit-group-added div.status{
        vertical-align: middle;
    }

.flex-block-adunit-group{
    padding: 0 13px 10px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-right: 8px;
    }

        .edit_adunit i::before{
            font-size: 16px;
        }

    .flex-block-adunit-group table{
        border: 1px solid #ddd;
        border-radius: 5px;
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }
/* End Adunit in Rule */

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    transition: all .5s reverse;
}
.fade-leave-to{
    opacity: 0;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-item-queue {
    transition: all .5s;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}

.skide-enter-active {
      animation: skide-in .5s;
    }
    .skide-leave-active {
      animation: skide-in .5s reverse;
    }
    @keyframes skide-in {
        0% {
            opacity: 0;
            /* left: 100vw; */
        }
        50% {
            /* left: -3vw; */
        }
        100% {
            opacity: 1;
            /* left: 0; */
        }
    }
/* end animation */

</style>
