import axios from 'axios';

const authentication = {
    data(){
        return {
            auth: {
                accessToken: this.$store.getters.getAuthAccessToken,
                apiToken: this.$store.getters.getAuthApiToken,
                userId: this.$store.getters.getAuthUserId,
                email: this.$store.getters.getAuthEmail,
                iconUrl: this.$store.getters.getAuthIconUrl,
                role: this.$store.getters.getAuthRole,
                error: ''
            }
        }
    },
    created(){
        this.setAuthData();
    },
    computed: {
        getUserId(){
            this.auth.userId = this.$store.getters.getAuthUserId;
            return this.auth.userId;
        },
        getAccessToken(){
            this.auth.accessToken = this.$store.getters.getAuthAccessToken;
            return this.auth.accessToken;
        },
        getApiToken(){
            this.auth.apiToken = this.$store.getters.getAuthApiToken;
            return this.auth.apiToken;
        },
        getEmail(){
            this.auth.email = this.$store.getters.getAuthEmail;
            return this.auth.email;
        },
        getIconUrl(){
            this.auth.iconUrl = this.$store.getters.getAuthIconUrl;
            return this.auth.iconUrl;
        },
        getRole(){
            this.auth.role = this.$store.getters.getAuthRole;
            return this.auth.role;
        },
        getRoles(){
            return this.$store.getters.getRolesList;
        }
    },
    methods: {
        setAuthData(){
            this.auth.userId = localStorage.getItem('user_id');
            this.auth.accessToken = localStorage.getItem('admin_token');
            this.auth.apiToken = localStorage.getItem('api_token');
            this.auth.email = localStorage.getItem('email');
            this.auth.iconUrl = localStorage.getItem('iconUrl');
            this.auth.role = localStorage.getItem('role');

            this.$store.commit('setAuthData', {userId: localStorage.getItem('user_id'), accessToken: localStorage.getItem('admin_token'), apiToken: localStorage.getItem('api_token'), email: localStorage.getItem('email'), iconUrl: localStorage.getItem('iconUrl'), role: localStorage.getItem('role')});
        },
        getPageVisible(pageId){
            if (this.getRole === 'SuperAdmin') return true;

            let roles = this.getRoles.find(val => val.pageid === pageId);
            if (roles && roles.visible === 0) return false;

            return true;
        },
        getPageVisiblePromise(pageId, type='visible'){
            return axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/roles/get/' + this.getUserId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setRolesList', response.data.accounts_roles);
                return response.data.accounts_roles;
            })
            .then(res => {
                if (this.getRole === 'SuperAdmin') return true;

                let roles = res.find(val => val.pageid === pageId);
                if (roles && roles[type] === 0) return false;

                return true;
            })
            .catch(e => {
                this.errorsMessage(e, "error roles check");
            });
        },
        getPageEdit(pageId){
            if (this.getRole === 'SuperAdmin') return true;

            let roles = this.getRoles.find(val => val.pageid === pageId);
            if (roles && roles.editPage === 0) return false;

            return true;
        },
        getPageAdd(pageId){
            if (this.getRole === 'SuperAdmin') return true;

            let roles = this.getRoles.find(val => val.pageid === pageId);
            if (roles && roles.addPage === 0) return false;

            return true;
        },
        getPageDelete(pageId){
            if (this.getRole === 'SuperAdmin') return true;

            let roles = this.getRoles.find(val => val.pageid === pageId);
            if (roles && roles.deletePage === 0) return false;

            return true;
        },
        getRolesDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/roles/get/' + this.getUserId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setRolesList', response.data.accounts_roles);
            })
            .catch(e => {
                this.errorsMessage(e, "error roles list");
            });
        },
        clearRemeberData(){
            localStorage.removeItem('remeberQueueTypeBannerId');
            localStorage.removeItem('remeberQueueTypeBannerName');
            localStorage.removeItem('remeberQueueQueueId');
            localStorage.removeItem('remeberQueueQueueName');
            localStorage.removeItem('remeberQueueGroupId');
            localStorage.removeItem('remeberQueueGroupNetwork');

            this.$store.commit('setRemeberQueueTypeBannerId', null);
            this.$store.commit('setRemeberQueueTypeBannerName', null);
            this.$store.commit('setRemeberQueueQueueId', null);
            this.$store.commit('setRemeberQueueQueueName', null);
            this.$store.commit('setRemeberQueueGroupId', null);
            this.$store.commit('setRemeberQueueGroupNetwork', null);
        },
        logout(){
            localStorage.removeItem('user_id');
            localStorage.removeItem('admin_token');
            localStorage.removeItem('api_token');
            localStorage.removeItem('email');
            localStorage.removeItem('iconUrl');
            localStorage.removeItem('role');

            this.setAuthData();

            axios({
                url: this.$store.getters.getBaseUrl+'/acc/logout/'+this.auth.accessToken,
                method: 'GET',
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if(document.querySelector('.popover')) document.querySelector('.popover').remove();
                this.$router.push({name: 'login'});
            })
            .catch(e => {
                console.log("error logout");
                this.auth.error = e.response.data.Error;
            })
        },
        errorsMessage(e, msg){
            console.log(msg);

            if (e != undefined) {
                if(e.response != undefined && e.response.data != undefined) console.log(e.response.data);
                else if(e.message != undefined) console.log(e.message);

                if(e.response != undefined && e.response.data.Error != undefined) this.auth.error = e.response.data.Error;
            }

            if(this.auth.error == this.$store.getters.getError.needToLogin){
                this.logout();
            }
        }
    }
};

export default authentication;
