import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: ['options', 'chartData'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, {
                legend: {
                    display: false
                },

                responsive: true,
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return data.labels[tooltipItem.index];
                        }
                    }
                }
            });
        }
    },
    mounted () {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, {
            legend: {
                display: false
            },
            responsive: true,
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return data.labels[tooltipItem.index];
                    }
                }
            },
            maintainAspectRatio: false,
            type: 'line',
        }); // this.options
    }
}
