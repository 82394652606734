const Logos = {
    namespaced: true,

    state: {
        resourcesIcons: {
            Crosspromo: 'assets/logos/resources/crossPromo.png',
            Exchange: 'assets/logos/resources/exchange.png',
            Advertiser: 'assets/logos/resources/yovoAdvertising.png',
            Organic: 'assets/logos/resources/organic.png',
            GoogleAds: 'assets/logos/resources/gogleAds.png',
            Youtube: 'assets/logos/resources/youYube.png',
            Alleyoop: 'assets/logos/resources/btn-ques.png',
            Others: 'assets/logos/resources/others.png',
            Count: 'assets/logos/resources/count.png',
        },
        appTypeIcons: {
            Application: 'assets/logos/application_type/icon-application.png',
            Game: 'assets/logos/application_type/icon-game.png',
            Site: 'assets/logos/application_type/icon-site.png'
        },
        statesIcons: {
            New: 'assets/logos/states/new.png',
            Confirmed: 'assets/logos/states/confirmed.png',
            Reject: 'assets/logos/states/reject.png',
            Blocked: 'assets/logos/states/blocked.png',
            Changed: 'assets/logos/states/changed.png'
        },
        rolesIcons: {
            Admin: 'assets/logos/roles/admin.png',
            Moderator: 'assets/logos/roles/moderator.png',
            User: 'assets/logos/roles/user.png'
        },
        headerIcons: {
            btnOff: 'assets/logos/btn-off.png',
            btnOffBig: 'assets/logos/btn-off-big.png',
            btnUp: 'assets/logos/btn-up.png',
            btnDown: 'assets/logos/btn-down.png',
            eng: 'assets/logos/eng.png',
            rus: 'assets/logos/rus.png',
            btnQues: 'assets/logos/btn-ques.png',
            btnExcl: 'assets/logos/btn-excl.png',
            btnRing: 'assets/logos/btn-ring.png'
        },
        networkAndTypeIcons: {
            banner: 'assets/logos/banner.png',
            interstitial: 'assets/logos/interstitial.png',
            rewarded: 'assets/logos/rewarded.png',
            Admob: 'assets/logos/gmob.png',
            CrossPromotion: 'assets/logos/crossPromo.png',
            Exchange: 'assets/logos/exchange.png',
            Facebook: 'assets/logos/facebook.png',
            UnityAds: 'assets/logos/unityAds.png',
            Advertisement: 'assets/logos/yovoAdvertising.png',
        },
        networkAndTypeIconsLocal: {
            banner: 'src/assets/logos/banner.png',
            interstitial: 'src/assets/logos/interstitial.png',
            rewarded: 'src/assets/logos/rewarded.png',
            Admob: 'src/assets/logos/gmob.png',
            CrossPromotion: 'src/assets/logos/crossPromo.png',
            Exchange: 'src/assets/logos/exchange.png',
            Facebook: 'src/assets/logos/facebook.png',
            UnityAds: 'src/assets/logos/unityAds.png',
            Advertisement: 'src/assets/logos/yovoAdvertising.png',
        },
        icons: {
            Admob: 'assets/logos/icon_admob.png',
            CrossPromotion: 'assets/logos/icon_crosspromo.png',
            //YovoAds: 'assets/logos/icon_yovoads.png',
            Advertisement: 'assets/logos/icon_yovoads.png',
            Exchange: 'assets/logos/icon_exchange.png',
            UnityAds: 'assets/logos/icon_unity.png',
            Facebook: 'assets/logos/icon_facebook.png',
            Chartboost: 'assets/logos/icon_chartboost.png',
            Kidoz: '',
            AdColony: ''
        }
    }
};

export default Logos;
