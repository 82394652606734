<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_app" ref="saveNetwork" @click.prevent="saveNetwork()" :disabled="!isValidForm">
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right reopen_app" ref="reopenNetwork" @click.prevent="reopenNetwork()" :disabled="!isValidForm">
                                            <span>{{ getLang.reopen }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-warning float-right close_app" @click.prevent="closeNetworkForm()">
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + networkFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="networkData.title.alt"><span class="bs-label label-primary">{{ getLang.max20Chars }}</span> <span class="bs-label label-danger">{{ getLang.required }}</span> {{ getLang.network.title }}: <i class="glyph-icon font-gray icon-question" :title="networkData.title.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <input class="form-control outlight" id="" :placeholder="getLang.network.title+'...'" type="text" v-model="networkData.title.value">
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            sendFormStatus: '',

            networkList: [],

            networkFormHeight: 0,
            headerHeight: 60,
            othersHeight: 84,

            backPrevLink: '',

            saveNetworkDiv: '',
            reopenNetworkDiv: '',

            networkData: {
                title: {
                    value: '',
                    alt: 'title'
                }
            }
        }
    },
    created(){
        if(!this.isAdmin()) this.$router.push({name: 'index'});
    },
    mounted(){
        let _this = this;
        this.setNetworkFormHeight(); // задаем высоту для формы network

        window.addEventListener('resize', function(){
            _this.setNetworkFormHeight();
        });

        this.$nextTick(function(){
            this.saveNetworkDiv = this.$refs.saveNetwork;
            this.reopenNetworkDiv = this.$refs.reopenNetwork;
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        // validation form
        isValidForm(){
            let result = false;

            result = (this.networkData.title.value != '');

            return result;
        }
    },
    methods: {
        isAdmin(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() === this.$store.getters.getRoleSuperAdmin);
        },

        backToPrev(){
            this.$router.back();
        },

        // default form
        setFormDefault(){
            this.networkData.title.value = '';
        },

        // close Network form
        closeNetworkForm(){
            this.$router.push({ name: 'network' });
        },

        saveNetwork(){
            this.saveNetworkDiv.disabled = true;

            this.setNetworkDB('save');
        },

        reopenNetwork(){
            this.reopenNetworkDiv.disabled = true;

            this.setNetworkDB('reopen');
        },

        getNetworks(status){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/group/types?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.networkList = response.data.types;

                this.$store.commit('setNetworkList', this.networkList);

                if(status == 'save') this.$router.push({ name: 'network' });
            })
            .catch(e => {
                this.errorsMessage(e, 'error get networks');
            });
        },

        // save form data and add new network to DB
        setNetworkDB(status){
            let data = JSON.stringify({
                name: this.networkData.title.value,
            });

            //console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/superadmin/sysmanage/network/create?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                if (status == 'save') {
                    this.saveNetworkDiv.disabled = false;

                    this.getNetworks('save');

                } else if (status == 'reopen') {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Network added!'});
                    this.setFormDefault();

                    this.getNetworks();

                    this.reopenNetworkDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

            })
            .catch(e => {
                if (e.response != undefined && e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveNetworkDiv.disabled = false;
                    this.reopenNetworkDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error set network to DB");
            })
        },

        // set height for table form Network
        setNetworkFormHeight(){
            this.networkFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.networkFormHeight < 0) this.networkFormHeight = 0;

            return this.networkFormHeight;
        },
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                /* width: 233px; */
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        border-bottom: 0px none;
        padding-bottom: 13px;
    }

    .add_app{
        padding: 0 52px;
    }

    .close_app, .reopen_app{
        margin-right: 65px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
