<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_app" ref="saveApp" @click.prevent="saveApplication()" :disabled="!isValidForm">
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right reopen_app" ref="reopenApp" @click.prevent="reopenApplication()" :disabled="!isValidForm">
                                            <span>{{ getLang.reopen }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <!-- <button class="btn btn-alt btn-hover btn-warning float-right close_app" @click.prevent="closeAppForm()">
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button> -->
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="appData.title.alt + ' (' + getLang.max50Chars + ')'">
                                            {{ getLang.app.title }}
                                        </label>
                                        <input class="form-control outlight" id="" :placeholder="''" type="text" v-model="appData.title.value">
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.title + ' (' + getLang.max50Chars + ')'" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: center;">
                                    <span class="alert alert-danger" v-if="validatePackageName" style="line-height: 40px;">{{ getLang.app.identifierAlreadyExists }}</span>
                                    <span class="alert alert-danger" v-if="validateWrongSymbol" style="line-height: 40px;">{{ getLang.app.identifierWrongSymbol }}</span>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="appData.packageName.alt + ' (' + getLang.maxChars + ')'">
                                            {{ getLang.app.packageName }}
                                        </label>
                                        <input class="form-control outlight"
                                                id=""
                                                :placeholder="''"
                                                type="text"
                                                v-model="appData.packageName.value"
                                                required
                                                @keyup="isValidatePackageName($event)"
                                        >
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.packageName + ' (' + getLang.maxChars + ')'" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: right;">
                                    <label class="control-label label_on_input" style="top: -18px;" :style="$store.getters.getLanguageActive == 1 ? 'right: 29px;' : 'right: 68px;'">
                                        {{ getLang.app.added }}
                                    </label>
                                    <div :class="appData.playmarketPackageName.state ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        :style="appData.playmarketPackageName.disabled ? 'cursor: default!important;' : ''"
                                        @click.prevent="changePlaymarketPackageName()"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary" :style="appData.playmarketPackageName.disabled ? 'cursor: default!important;' : ''">On</span><label class="bootstrap-switch-label" :style="appData.playmarketPackageName.disabled ? 'cursor: default!important;' : ''">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;" :style="appData.playmarketPackageName.disabled ? 'cursor: default!important;' : ''">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon" style="display: flex;">
                                        <span class="icon_required" :title="getLang.required" v-if="isVisiblePlaymarketPackageName">*</span>
                                        <label class="control-label label_on_input" :title="appData.playmarketPackageName.alt + ' (' + getLang.max255Chars + ')'">
                                            {{ getLang.app.playmarketPackageName }}
                                        </label>
                                        <input class="form-control outlight"
                                                id=""
                                                :placeholder="''"
                                                type="text"
                                                v-model="appData.playmarketPackageName.value"
                                                @keyup="valuePlaymarketPackageName($event)"
                                        >
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.playmarketPackageName + ' (' + getLang.max255Chars + ')'" data-original-title=".icon-question"></i>
                                        <br/>
                                        <span class="alert alert-danger" v-if="validateWrongSymbolG" style="line-height: 40px;">{{ getLang.app.identifierWrongSymbol }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: right;">
                                    <label class="control-label label_on_input" style="top: -18px;" :style="$store.getters.getLanguageActive == 1 ? 'right: 29px;' : 'right: 68px;'">
                                        {{ getLang.app.added }}
                                    </label>
                                    <div :class="appData.iTunesID.state ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        :style="appData.iTunesID.disabled ? 'cursor: default!important;' : ''"
                                        @click.prevent="changeITunesID()"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary" :style="appData.iTunesID.disabled ? 'cursor: default!important;' : ''">On</span><label class="bootstrap-switch-label" :style="appData.iTunesID.disabled ? 'cursor: default!important;' : ''">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;" :style="appData.iTunesID.disabled ? 'cursor: default!important;' : ''">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon" style="display: flex;">
                                        <span class="icon_required" :title="getLang.required" v-if="isVisibleITunesID">*</span>
                                        <label class="control-label label_on_input" :title="appData.iTunesID.alt + ' (' + getLang.max45Chars + ')'">
                                            {{ getLang.app.iTunesID }}
                                        </label>
                                        <input class="form-control outlight"
                                                id=""
                                                :placeholder="''"
                                                type="text"
                                                v-model="appData.iTunesID.value"
                                                @keyup="valueITunesID($event)"
                                        >
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.iTunesID + ' (' + getLang.max45Chars + ')'" data-original-title=".icon-question"></i>
                                        <br/>
                                        <span class="alert alert-danger" v-if="validateWrongSymbolA" style="line-height: 40px;">{{ getLang.app.identifierWrongSymbol }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="appData.age.alt">
                                            {{ getLang.app.age }}
                                        </label>
                                        <div class="selector" style="width: 82px;">
                                            <span style="width: 60px; -moz-user-select: none;">{{ appData.age.text }}</span>
                                            <select name="" class="custom-select" @change="selectAge($event)" v-model="appData.age.value">
                                                <option :value="el.id" v-for="(el, ind) in getAgeList" :key="ind">{{ el.name }}</option>
                                            </select>
                                            <i class="glyph-icon icon-caret-down"></i>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="appData.age.alt" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <div class="form-group padding_for_icon">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="appData.sex.alt">
                                            {{ getLang.app.sex }}
                                        </label>
                                        <div class="selector" style="width: 82px;">
                                            <span style="width: 60px; -moz-user-select: none;">{{ appData.sex.text }}</span>
                                            <select name="" class="custom-select" @change="selectSex($event)" v-model="appData.sex.value">
                                                <option :value="el.id" v-for="(el, ind) in getSexList" :key="ind">{{ el.name }}</option>
                                            </select>
                                            <i class="glyph-icon icon-caret-down"></i>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="appData.sex.alt" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>

                                <div class="col-sm-2" style="padding-right: 25px;">
                                    <label class="control-label label_on_input">
                                        {{ getLang.app.typeResource }}
                                    </label>
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTypeCategoryStatus }}</span>
                                        <select name="" class="custom-select" @change="setTypeCategory($event)" v-model="typeCategoryStatus">
                                            <option :value="typeCategoryApps">{{ getLang.app.applications }}</option>
                                            <option :value="typeCategoryGames">{{ getLang.app.games }}</option>
                                            <option :value="typeCategorySites">{{ getLang.app.sites }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                    <i class="glyph-icon font-gray icon-question-circle" style="right: 10px;" :title="getLang.app.categories" data-original-title=".icon-question"></i>
                                </div>

                                <div class="col-sm-7">
                                    <div class="form-group padding_for_icon">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="appData.categories.alt">
                                            {{ getLang.app.categories }}
                                        </label>
                                        <multi-select categories-alt="categories list"
                                                    :categories-title="''"
                                                    :get-category-list="getCategoryList"
                                                    :is-update-category-list="true"
                                                    :setDefaultCategory="appData.categories.value"
                                                    :is-default-required="false"
                                                    style-input="padding-left: 0px; padding-right: 0px;"
                                                    placeholder="+"
                                                    :is-big-placeholder="true"
                                                    @setCategory="val => appData.categories.value = val"
                                                    @removeCategory="val => appData.categories.value = val"
                                                    >
                                        </multi-select>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.categories" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap_big">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                    <span class="bs-badge badge-absolute badge-danger"
                                            style="cursor: pointer; left: 96px; top: -5px;"
                                            @click.prevent="clearIcon()"
                                            v-if="(appData.iconFile.viewFile && !iconFileStatus) || (appData.iconUrl.value && iconFileStatus)"
                                    >
                                        <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                    </span>
                                    <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.iconFile }}</label>
                                    <img :src="appData.iconUrl.value" v-if="appData.iconUrl.value && iconFileStatus"/>
                                    <img :src="appData.iconFile.viewFile" v-else-if="appData.iconFile.viewFile && !iconFileStatus"/>
                                    <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 96px; height: 96px; font-size: 60px; line-height: 80px;"></i>

                                    <transition name="fade">
                                        <span class="bs-label label-danger" v-show="mesIconStatus">{{ getLang.messages.imageSizeNotMatch }}</span>
                                    </transition>
                                    <transition name="fade">
                                        <span class="bs-label label-danger" v-show="mesIconStatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                    </transition>
                                    <transition name="fade">
                                        <span class="bs-label label-danger" v-show="mesIconStatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                    </transition>
                                </label>
                                <div class="col-sm-7 col-md-7">
                                    <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                        <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                        <input type="file" class="outlight" name="..." @change="setIconFile">
                                    </span>
                                    <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                        <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" style="left: 12px;  ">{{ getLang.size96x96 }}&nbsp;{{ getLang.max500kb }}</label>
                                        <div class="fileinput fileinput-new" data-provides="fileinput" style="display: inline;">
                                            <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ appData.iconFile.nameFile }}</span>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="appData.iconFile.alt" data-original-title=".icon-question"></i>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: right;">
                                    <label class="control-label label_on_input" style="top: -18px;" :style="$store.getters.getLanguageActive == 1 ? 'right: 33px;' : 'right: 49px;'">{{ getLang.app.limitation }}</label>
                                    <div :class="appData.rewardedOnly.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        @click.prevent="appData.rewardedOnly.value = !appData.rewardedOnly.value"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>

                                <transition name="bounce" mode="out-in">
                                    <div class="col-sm-3 col-md-3">
                                        <div class="form-group padding_for_icon">
                                            <label class="control-label label_on_input" :title="appData.countPerDay.alt">{{ getLang.app.countPerDay }}</label>
                                            <input class="form-control outlight"
                                                    id=""
                                                    :placeholder="''"
                                                    value="0"
                                                    type="number"
                                                    step="1"
                                                    min="0"
                                                    :disabled="!appData.rewardedOnly.value"
                                                    v-model="appData.countPerDay.value"
                                            >
                                            <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.countPerDay" data-original-title=".icon-question"></i>
                                        </div>
                                    </div>
                                </transition>

                                <div class="col-sm-1 col-md-1">&nbsp;</div>

                                <div class="col-sm-2 col-md-2">&nbsp;</div>

                                <transition name="bounce" mode="out-in">
                                    <div class="col-sm-3 col-md-3">
                                        <div class="form-group padding_for_icon">
                                            <label class="control-label label_on_input" :title="appData.minimumPeriod.alt">{{ getLang.app.minimumPeriod }}</label>
                                            <input class="form-control outlight"
                                                    id=""
                                                    :placeholder="''"
                                                    value="0"
                                                    type="number"
                                                    step="1"
                                                    min="0"
                                                    :disabled="!appData.rewardedOnly.value"
                                                    v-model="appData.minimumPeriod.value"
                                            >
                                            <i class="glyph-icon font-gray icon-question-circle" :title="getLang.app.minimumPeriod" data-original-title=".icon-question"></i>
                                        </div>
                                    </div>
                                </transition>

                                <div class="col-sm-1 col-md-1">&nbsp;</div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
//import AppColor from '../plugins/Color';
import AppUploadFile from '../plugins/UploadFile';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            sendFormStatus: '',

            iconFileStatus: false,
            validatePackageName: false,
            validateWrongSymbol: false,
            validateWrongSymbolG: false,
            validateWrongSymbolA: false,

            iconFileClass: ['file', 'file_active active'],

            typeCategoryStatus: 'game',
            typeCategoryApps: 'application',
            typeCategoryGames: 'game',
            typeCategorySites: 'site',

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 83,
            osLength: 5,

            backPrevLink: '',

            saveAppDiv: '',
            reopenAppDiv: '',

            categoryList: {},
            sexList: [],
            ageList: [],
            appList: [],

            imgSize: {
                bannerIcon: ['96', '96'],
            },

            mesIconStatus: false,
            mesIconStatusUrl: false,
            mesIconStatusBlocked: false,

            appDefault: {},
            duplicate: {
                id: 0,
                isDuplicate: false
            },

            appData: {
                title: {
                    value: '',
                    alt: 'title'
                },
                packageName: {
                    value: '',
                    alt: 'package name'
                },
                playmarketPackageName: {
                    value: '',
                    state: false,
                    disabled: true,
                    visible: true,
                    alt: 'Playmarket PackageName'
                },
                iTunesID: {
                    value: '',
                    state: false,
                    disabled: true,
                    visible: true,
                    alt: 'iTunes ID'
                },
                // icon
                iconUrl: {
                    value: '',
                    alt: 'icon url'
                },
                iconFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: 'icon file',
                },

                rewardedOnly: {
                    value: false,
                    alt: 'Settings for Rewarded Ad'
                },
                minimumPeriod: {
                    value: '',
                    alt: 'minimum period',
                },
                countPerDay: {
                    value: '',
                    alt: 'count per day',
                },

                // needBackground: {
                //     value: false,
                //     alt: 'need background'
                // },
                // background: {
                //     value: '#000000'
                // },

                categories: {
                    value: [],
                    alt: 'Categories'
                },
                cat1:{
                    value: '',
                },
                cat2:{
                    value: '',
                },
                cat3:{
                    value: '',
                },
                sex: {
                    value: '',
                    alt: 'Sex',
                    text: ''
                },
                age: {
                    value: '',
                    alt: 'Age',
                    text: ''
                },
            }
        }
    },
    created(){
        this.$store.commit('setBackPrevLink', 'applications');

        this.$nextTick(() => {
            let prom = this.getApplicationListDB();
            prom.then(() => this.setAppDefault());
        });
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы application

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(function(){
            this.saveAppDiv = this.$refs.saveApp;
            this.reopenAppDiv = this.$refs.reopenApp;
        });
    },
    destroyed(){
        this.$store.commit('setBackPrevLink', '');
        this.$store.commit('setAppDefault', {});
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        getAppDefault(){
            this.appDefault = this.$store.getters.getAppDefault;

            return this.appDefault;
        },

        // get sex list
        getSexList(){
            this.sexList = this.$store.getters.getSexList;

            return this.sexList;
        },

        isVisibleDevices(){
            if(this.appData.iTunesID.value.length < 1 && this.appData.playmarketPackageName.value.length < 1) {
                this.appData.playmarketPackageName.visible = true;
                this.appData.iTunesID.visible = true;
            }

            if (this.appData.iTunesID.value.length > 0 && this.appData.playmarketPackageName.value.length < 1) {
                this.appData.playmarketPackageName.visible = false;
                this.appData.iTunesID.visible = true;
            }
            if (this.appData.playmarketPackageName.value.length > 0 && this.appData.iTunesID.value.length < 1) {
                this.appData.iTunesID.visible = false;
                this.appData.playmarketPackageName.visible = true;
            }
        },

        isVisibleITunesID(){
            this.isVisibleDevices;

            return this.appData.iTunesID.visible;
        },

        isVisiblePlaymarketPackageName(){
            this.isVisibleDevices;

            return this.appData.playmarketPackageName.visible;
        },

        // get age list
        getAgeList(){
            this.ageList = this.$store.getters.getAgeList;

            return this.ageList;
        },

        // category lists
        getCategoryList(){
            this.categoryList = this.$store.getters.getCategoryList.filter(val => {
                if (this.typeCategoryStatus == this.typeCategoryGames) return val.is_game == true;
                if (this.typeCategoryStatus == this.typeCategoryApps) return val.is_game == false;
            });

            return this.categoryList;
        },
        getCat1(){
            this.appData.cat1.value = this.appData.categories.value[0];

            return this.appData.cat1.value;
        },
        getCat2(){
            this.appData.cat2.value = this.appData.categories.value[1];

            return this.appData.cat2.value;
        },
        getCat3(){
            this.appData.cat3.value = this.appData.categories.value[2];

            return this.appData.cat3.value;
        },

        getTypeCategoryStatus(){
            if(this.typeCategoryStatus == this.typeCategoryApps) return this.getLang.app.applications;
            if(this.typeCategoryStatus == this.typeCategoryGames) return this.getLang.app.games;
            if(this.typeCategoryStatus == this.typeCategorySites) return this.getLang.app.sites;
        },

        // validation form
        isValidForm(){
            let result = false;

            result = (this.appData.title.value != '') && (this.appData.packageName.value != '') && this.appData.categories.value.length && this.appData.sex.value != '' && this.appData.age.value != '';

            result = result && (this.iconFileStatus ? this.appData.iconUrl.value != '' : this.appData.iconFile.value != '');

            // validation Playmarket PackageName and iTunesID
            result = result && (this.isVisiblePlaymarketPackageName == false || this.isVisibleITunesID == false);

            // validate package name
            result = result && this.validatePackageName == false && this.validateWrongSymbol == false && this.validateWrongSymbolG == false && this.validateWrongSymbolA == false;

            return result;
        }
    },
    methods: {
        // libs
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        setDataFiles(str){
            let data = str.replace('base64,', '').replace('data:image/', '').split(';');
            if(data[0] == 'jpeg') data[0] = 'jpg';

            return data;
        },

        isValidatePackageName(e){
            this.validatePackageName = this.appList.indexOf(e.target.value) != -1;
            this.validateWrongSymbol = e.target.value.indexOf("'") != -1;

            return this.validatePackageName;
        },

        backToPrev(){
            this.$router.back();
        },

        valuePlaymarketPackageName(e){
            if (this.appData.playmarketPackageName.value.length < this.osLength) {
                this.appData.playmarketPackageName.state = false;
                this.appData.playmarketPackageName.disabled = true;
            } else {
                this.appData.playmarketPackageName.disabled = false;
            }

            this.validateWrongSymbolG = e.target.value.indexOf("'") != -1;
        },

        changePlaymarketPackageName(){
            if(!this.appData.playmarketPackageName.disabled) this.appData.playmarketPackageName.state = !this.appData.playmarketPackageName.state;
        },

        valueITunesID(e){
            if (this.appData.iTunesID.value.length < this.osLength) {
                this.appData.iTunesID.state = false;
                this.appData.iTunesID.disabled = true;
            } else {
                this.appData.iTunesID.disabled = false;
            }

            this.validateWrongSymbolA = e.target.value.indexOf("'") != -1;
        },

        changeITunesID(){
            if(!this.appData.iTunesID.disabled) this.appData.iTunesID.state = !this.appData.iTunesID.state;
        },

        clearIcon(){
            this.appData.iconFile.value = '';
            this.appData.iconFile.typeInput = '';
            this.appData.iconFile.nameFile = '';
            this.appData.iconFile.viewFile = '';
            this.appData.iconFile.sizeFile = '';
            this.appData.iconUrl.value = '';
        },

        // files
        setIconFile(e){
            let _this = this;

            if (this.iconFileStatus) {
                if(this.appData.iconUrl.value == '') return
                if(this.appData.iconUrl.value.indexOf('http') != 0) this.errorIcon('url');

                fetch(this.appData.iconUrl.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createIcon(blob);
                    })
                    .catch(function(){
                        _this.errorIcon('blocked');
                    });
            } else {
                this.appData.iconFile.nameFile = e.target.files[0].name;

                this.createIcon(e.target.files[0]);
            }
        },
        createIcon(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        tmpWidth = _this.imgSize.bannerIcon[0];
                        tmpHeight = _this.imgSize.bannerIcon[1];

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.appData.iconFile.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.appData.iconFile.value = newData[1];
                        this.appData.iconFile.typeInput = newData[0];
                    },
                    error => {
                        this.errorIcon('size');
                    }
                );
            });
        },
        errorIcon(status){
            this.clearIcon();

            if (status == 'url') {
                this.mesIconStatusUrl = true;
            } else if (status == 'size') {
                this.mesIconStatus = true;
            } else if (status == 'blocked') {
                this.mesIconStatusBlocked = true;
            }

            setTimeout(() => {
                this.mesIconStatus = false;
                this.mesIconStatusUrl = false;
                this.mesIconStatusBlocked = false;
            }, 3000);
        },

        // sex
        selectSex(e){
            let option = e.target.selectedOptions[0];

            this.appData.sex.text = option.innerText;
        },

        // age
        selectAge(e){
            let option = e.target.selectedOptions[0];

            this.appData.age.text = option.innerText;
        },

        // type category
        setTypeCategory(type){
            this.appData.categories.value = [];

            //this.typeCategoryStatus = type.target.value;
        },

        // default form
        setFormDefault(){
            this.appData.title.value = '';
            this.appData.packageName.value = '';
            this.appData.playmarketPackageName.value = '';
            this.appData.playmarketPackageName.state = false;
            this.appData.playmarketPackageName.disabled = true;
            this.appData.iTunesID.value = '';
            this.appData.iTunesID.state = false;
            this.appData.iTunesID.disabled = true;
            this.appData.iconUrl.value = '';
            this.appData.iconFile.value = '';
            this.appData.iconFile.typeInput = '';
            this.appData.iconFile.nameFile = '';
            this.appData.iconFile.viewFile = '';

            this.appData.categories.value = [];
            this.appData.cat1.value = '';
            this.appData.cat2.value = '';
            this.appData.cat3.value = '';

            this.appData.rewardedOnly.value = false;
            this.appData.minimumPeriod.value = 0;
            this.appData.countPerDay.value = -1;

            this.appData.sex.value = ''; //this.getSexList[0].id;
            this.appData.sex.text = ''; //this.getSexList[0].name;

            this.appData.age.value = ''; //this.getAgeList[0].id;
            this.appData.age.text = ''; //this.getAgeList[0].name;
        },

        // close Application form
        closeAppForm(){
            this.$router.push({ name: 'applications' });
        },

        isDuplicateAdunits(){
            if (this.duplicate.isDuplicate) {

                let isSave = confirm('Are you sure you want to duplicate ad units?');

                if (!isSave) {
                    this.duplicate.id = 0;
                    this.duplicate.isDuplicate = false;
                }
            }
        },

        saveApplication(){
            this.isDuplicateAdunits();

            this.saveAppDiv.disabled = true;

            this.setApplicationDB('save');
        },

        reopenApplication(){
            this.isDuplicateAdunits();

            this.reopenAppDiv.disabled = true;

            this.setApplicationDB('reopen');
        },

        setAppDefault(){
            if (Object.keys(this.getAppDefault).length > 0) {
                this.duplicate.id = this.getAppDefault.id;
                this.duplicate.isDuplicate = true;

                this.iconFileStatus = true;

                this.appData.title.value = this.getAppDefault.title;
                this.appData.packageName.value = this.getAppDefault.packageName;
                this.appData.iconUrl.value = this.getAppDefault.iconUrl;
                this.appData.age.value = this.getAppDefault.age.value;
                this.appData.age.text = this.getAppDefault.age.text;
                this.appData.sex.value = this.getAppDefault.sex.value;
                this.appData.sex.text = this.getAppDefault.sex.text;

                this.appData.rewardedOnly.value = this.getAppDefault.rewardedOnly;
                this.appData.minimumPeriod.value = this.getAppDefault.minimumPeriod;
                this.appData.countPerDay.value = this.getAppDefault.countPerDay;

                this.isValidatePackageName({target:{value: this.appData.packageName.value}});
            }
        },

        // get list Applications
        getApplicationListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.appList = response.data.applications.map(val => val.package_name);
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // save form data and add new application to DB
        setApplicationDB(status){
            let data = {
                title: this.appData.title.value,
                package_name: this.appData.packageName.value,
                PlaymarketPackageName: this.appData.playmarketPackageName.value,
                usePlayMarket: Number(this.appData.playmarketPackageName.state),
                iTunesID: this.appData.iTunesID.value,
                useiTunes: Number(this.appData.iTunesID.state),
                gender: this.appData.sex.value,
                contentRating: this.appData.age.value,
                appType: this.typeCategoryStatus,

                rewardedMinimumPeriod: parseInt(this.appData.minimumPeriod.value),
                rewardedCountPerDay: parseInt(this.appData.countPerDay.value),

                duplicateId: 0
            };

            if (this.iconFileStatus) {
                data.iconurl = this.appData.iconUrl.value;
            } else {
                data.iconbase64 = this.appData.iconFile.value;
                data.iconext = this.appData.iconFile.typeInput;
            }

            if(this.getCat1 != undefined) data.cat1 = parseInt(this.getCat1);
            if(this.getCat2 != undefined) data.cat2 = parseInt(this.getCat2);
            if(this.getCat3 != undefined) data.cat3 = parseInt(this.getCat3);

            if (this.duplicate.isDuplicate) {
                data.duplicateId = this.duplicate.id;
                this.duplicate.id = 0;
                this.duplicate.isDuplicate = false;
            }

            data = JSON.stringify(data);

            //console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/application/add?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                if (status == 'save') {
                    this.saveAppDiv.disabled = false;

                    this.$router.push({ name: 'applications' });
                } else if (status == 'reopen') {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Application added!'});
                    this.setFormDefault();

                    this.reopenAppDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

            })
            .catch(e => {
                if (e.response != undefined && e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveAppDiv.disabled = false;
                    this.reopenAppDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error set application to DB");
            })
        },

        // set height for table form Application
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },
    components: {
        //AppColor,
        AppUploadFile,
        MultiSelect
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        height: 80px;
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 80px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        padding-bottom: 13px;
    }

    .add_app{
        padding: 0 52px;
    }

    .close_app, .reopen_app{
        margin-right: 65px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
