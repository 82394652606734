<template>
    <section>
        <div class="panel">
            <div>
                <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-group table-group-header" id="datatable-group">
                    <thead>
                        <tr>
                            <th class="col_img">

                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="panel-body">
                <div class="toc-tocify">
                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <div v-if="$store.getters.getLanguageActive == 1">
                            <h3 class="mrg20B">Как это работает?</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Наша система представляет из себя систему управление монетизацией. С ее помощью можно настраивать очереди показа баннеров.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Вы создаете очередь показа - для определенного приложения и определенной страны - показывать следующие правила баннеров.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Приложение или страну можно не указывать, то есть - эта очередь по умолчанию.
                                                                                    <br/>В инвентаре можно создавать баннеры для ваших приложений - для кросс переходов между вашими приложениями.
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Там же создаются правила - набор баннеров для добавления в сценарий.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">В сценариях правила можно менять местами, эти изменения вступят в силу через 15 минут.
                                                                                    <br/>Настройте первым показывать admob, после него правило с двумя баннерами лучшего вашего приложения, затем facebook баннер.
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">А потом передумайте, и поставьте facebook первым - наша система это позволяет.
                                                                                    <br/>Далее подробнее.
                            </p>
                            <h3 class="mrg20B">Инвентарь</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_menu_inventory.png"/></p>
                            <h3 class="mrg20B">AdUnit:</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Рекламный блок, то есть banner или interstitial
                                                                                    <br/>Здесь мы добавляем баннеры или интеры своих игр, приложений или ссылку на сайт.
                                                                                    <br/>Чтобы добавить новый баннер нажмите кнопку
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/btn1.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Откроется форма добавления.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_adunit_pack.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Введите ссылку на которую нужно перейти нажав на ваш баннер.
                                                                                    <br/>Или google play market package name например: com.yovoads.sdktest
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">К ссылке будут автоматически добавлены utm метки для точного отслеживания переходов и установок.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Далее Название и Описание баннера, которые покажутся на самом баннере, если выбран режим только изображение — OFF</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">По умолчанию язык текста Английский — USA. Вы можете выбрать любую другую страну из списка, и ввести текст на нужном языке, который будет отображен в выбранной стране.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_adunit_add_locale.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Выберите тип баннера. <strong>Только изображение</strong> или Баннер состоящий из изображения, иконки, названия, описания и пяти звезд.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_adunit_img_only.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Если вы выберете режим <strong>Только Изображение</strong>, Вам будет доступно к загрузке одно изображение, которое покажет система как полноценный баннер.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Если Вы выключите этот режим, то сможете загрузить два изображения: иконку и картинку которые выстроятся в полноценный баннер следующим образом</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/banner.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Затем выберите систему <strong>крекинга установок</strong>, если вы или ваш партнер используете систему </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><strong>AppsFlyer</strong> выберите соответствующий пункт меню. </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><strong>Yovoads</strong> система позволяет отслеживать установки приложения. Для этого выберите данный пункт в меню <strong>Трекинг</strong>.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Вы можете указать <strong>UTM_CONTENT</strong> метку которая будет автоматически добавлена к ссылке при переходе на <strong>Play Market</strong></p>

                            <h3 class="mrg20B">Rule:</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Правила banners, interstitials и rewarded video
                                                                                    <br/>Собираем баннеры в правила, чтоб позже добавить их в сценарии(очереди).
                                                                                    <br/>Нажмите на кнопку добавить правила
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/btn1.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Введите название, чтоб различать правила друг от друга,
                                                                                    <br/>Далее выберите какую сеть отображать в этом правиле. На данный момент доступны:
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;<strong>Admob</strong>
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Facebook</strong>
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Unity</strong>
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>ChartBoost</strong>
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Exchange</strong>
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Crosspromo</strong>
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Тип правила будет изменяться в зависимости от того, какую сеть Вы выберите.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Если вы выберите сеть отличную от Crosspromo, будут доступны следующие настройки правила:</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_rules_add_infinity.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><strong>Бесконечно</strong> — значит показывать баннеры выбранного правила, до тех пор, пока они доступны</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Если выключить этот параметр, введи количество показов для данной сети.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Выбирая <strong>Crosspromo</strong> — выберите тип показа
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Ordered</strong> — показывать баннеры этого правла последовательно
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Random</strong> — показывать баннеры этого правила в случайном порядке
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;<strong>Once</strong> — показывать ОДИН из баннеров этого правила (A/B Test)
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Выберите время показа баннера (бета-версия 10 — 120 секунд — будет изменено)
                                                                                    <br/>UTM_TERM метка, для точного отслеживания установок
                                                                                    <br/>Чтоб добавить баннер в правило Crosspromo нажмите кнопку Добавить в правиле
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_rules_block.png"/></p>

                            <p class="mrg20B font-gray-dark" style="line-height: 2em">В появившемся окне, в списке слева есть созданные Вами раннее рекламные блоки. Добавьте нужные в правило нажатием на кнопку </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/btn3.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Вы можете менять порядок рекламных блоков перетаскиванием. Или удалить не нужный блок нажатием на кнопку удалить блок</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/btn4.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Так же фильтруйте рекламные блоки кнопками «Скрыть добавленные в это правило» и «Скрыть добавленные в другие правила»</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_rules_btns_hide.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Когда Вы закончили редактирование правила, нажмите Сохранить чтоб сохранить изменения, и крестик слева чтоб закрыть текущее окно.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_rules_add_adunit_in_rules.png"/></p>

                            <h3 class="mrg20B">Applications</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Приложения добавляются автоматически при добавлении баннера, если его URL содержит уникальный идентификатор приложения для платформы google play market</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">(Система добавления приложений будет изменена в дальнейшем)</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">В приложении можно указать где именно показывать баннер — <strong>gravity</strong>, нужно ли включить сплошную плашку под баннером — <strong>need background</strong>, цвет плашки — <strong>background color</strong>, период между показами interstitials — <strong>Period in seconds</strong></p>

                            <h3 class="mrg20B">Scenario</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Сценарии показа баннеров</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Есть три типа сценария: <strong>banner, interstitial, rewarded</strong>. Для каждого типа есть сценарий по умолчанию — <strong>Country</strong>: - , <strong>Application</strong>: -</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Это значит, что такой сценарий будет запускаться при запуске любого приложения в любой стране.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_scenarios_block.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Можно сделать отдельный сценарий для нескольких приложения и одной страны. </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;<strong>Application: [ com.yovoads.test, com.yovoads.sdk ], Country: US</strong></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Данный сценарий запуститься если запрос на показ баннера придет от выбранного приложения и в выбранной стране.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Параметр <strong>Cycle</strong> говорит о том, что данный сценарий нужно зациклить в этой стране и этом приложении. А если нет, то после показа данного сценария перейти на сценарий по умолчанию.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Далее добавим в сценарий несколько созданных ранее правил. </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Выберите нужный сценарий слева, далее выберите правило справа и нажмите +</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/ru_scenarios_add_rules.png"/></p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Здесь же поменяем им порядок простым перетаскиванием.</p>

                            <h3 class="mrg20B">Exchange</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Биржа обмена баннерами и межэкранными баннерами (Interstitials)
                                                                                    <br/>Здесь можно обменяться показами своих баннеров с другими участниками проекта.
                                                                                    <br/>Сначала Вы показываете чужие баннеры в своих приложениях — зарабатываете баллы, которые тратите на показы своих баннеров в чужих приложениях.
                                                                                    <br/>Чтоб показать баннеры в своем приложении — добавьте правило exchange в сценарий.
                            </p>
                        </div>
                        <div v-else>
                            <h3 class="mrg20B">How does it work?</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">You create your own scenario of advertising with your own rules for every application.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">There’s a default scenario for every ad format (banner, interstitial and rewarded video). It can’t be deleted.</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">You create rules in every scenario for every ad network: number of ad impressions, duration etc.
                                                                                    <br/>In case of working with Admob, Facebook, Chartboost and UnityAds the rules are very simple. You specify the number of ad impressions for the chosen network and add UTM if you want.
                            </p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">There are very interesting rules and settings for cross-promo. Here you have an opportunity to choose the following settings:</p>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;- ONCE. The system selects one random  ad unit and displays it as many as you’ve specified in the setting. After that the next rule enters into force.
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- RANDOM. The system will display all ad units in random order until all of them will be displayed.
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- ORDER. All ad units will be displayed in order of priority until the new rule will enter into force.
                            </p>
                            <h3 class="mrg20B">Several scenarios</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">You always can create an additional scenario for your app or group of apps and run it in countries you want.
                                                                                    <br/>When requesting ad, an application gets the ad impressions in order of the priority due to the rules of an additional scenario.
                                                                                    <br/>In the settings of your scenario you can enable the CYCLE function. Your scenario wil go into repeat mode.
                            </p>
                            <h3 class="mrg20B">What happens when the scenario has finished?</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">After all ad units have been displayed the process starts from the very beginning. Scenario resets for a device every 24 hours.
                                                                                    <br/>AdUnit.
                            </p>
                            <h3 class="mrg20B">Inventory</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em"><img src="assets/images/how_it_works/en_menu_inventory.png"/></p>
                            <h3 class="mrg20B">Add an AdUnit:</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;- put a package name in
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose the language (English is default)
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- add the names
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- add a short description
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose the ad format:
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Banner:
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upload your own banner 320*50 or 640*100 or add an application icon 96*96 and  a screenshot 246*120
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Interstitial:
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;upload your own interstitial or add an icon 144x144 and a screenshot 610x298
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- use tracking from YovoAds or yours.
                            </p>
                            <h3 class="mrg20B">Rule:</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;- choose an ad network
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose an ad format
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- number of ad impressions (except cross-promo)
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose ONCE, RANDOM or ORDER (for cross-promo)
                            </p>
                            <h3 class="mrg20B">Scenario</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Here we add our rules and set them in priority order.
                                                                                    <br/>You can add new scenario:
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose an app you want to add
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- country or countries
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- choose an ad format
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- enable/disable “Cycle”
                            </p>
                            <h3 class="mrg20B">Applications (needed for exchange)</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">&nbsp;&nbsp;&nbsp;&nbsp;- Created automatically when we add a package name
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- Add a name
                                                                                    <br/>&nbsp;&nbsp;&nbsp;&nbsp;- Choose the categories
                            </p>
                            <h3 class="mrg20B">Exchange</h3>
                            <p class="mrg20B font-gray-dark" style="line-height: 2em">Coming soon…</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Lang from '../lang/message';

export default {
    data(){
        return {
            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 100,
        }
    },
    mounted(){
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        }
    },
    methods: {
        // set height for table list exchange
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },
    },
}
</script>

<style>
a.ui-accordion-header, a.ui-accordion-header-active{
    text-decoration: none;
}

a.ui-accordion-header:hover{
    text-decoration: none;
    color: #888;
}
a.ui-accordion-header-active:hover{
    color: #fff;
}

.table-group-header{
    margin-bottom: 0px;
    border-bottom: 0px none;
}

    .table-group-header th{
        padding: 6px 13px 5px;
        /* border-bottom: 0px none; */
        border-bottom-width: 1px;
        height: 56px;
    }

.scroll{
    overflow: auto;
}

/* animation */
.color-enter{
    border-color: #00a792;
    background: #00a792;
    color: #fff;
}
.color-enter-active{
    transition: all .5s;
}
.color-enter-to{
    border-color: #dfe8f1;
    background: #fff;
    color: #555a60;
}
.color-leave-active{
    transition: all .5s reverse;
}
.color-leave-to{
    border-color: #00a792;
    background: #00a792;
    color: #fff;

}

.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    transition: all .5s reverse;
}
.fade-leave-to{
    opacity: 0;
}
</style>
