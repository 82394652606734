<template>
    <div>
        <div id="page-wrapper">
            <div id="page-header" :style="getCloseSidebar ? 'padding-left: 0px;' : ''">
                <div id="mobile-navigation">
                    <button id="nav-toggle" class="collapsed" data-toggle="collapse" data-target="#page-sidebar"><span></span></button>
                    <a href="index.html" class="logo-content-small" title="YovoAds"></a>
                </div>
                <div id="header-logo" :style="getCloseSidebar ? 'width: 64px; margin-left: 0px;' : ''" class="logo-bg">
                    <router-link :to="{name: 'index'}"
                                class="logo-content-big"
                                :style="getCloseSidebar ? 'left:7px; font-size: 25px;' : ''"
                                title="YovoAds"
                                >
                        <div v-if="getCloseSidebar">Y<span>A</span></div>
                        <div v-else>Yovo<span>Ads</span></div>
                    </router-link>
                    <a id="close-sidebar" href="#" :title="getLang.closeSidebar" @click.prevent="setCloseSidebar()">
                        <i class="glyph-icon" :class="getCloseSidebar ? 'icon-angle-right' : 'icon-angle-left'"></i>
                    </a>
                </div>

                <div id="header-nav-right">

                    <div class="dropdown" :class="getModalsMobile ? 'show' : ''" id="lang-btn">
                        <a data-toggle="dropdown" class="headers-btn" style="margin-top: 4px!important; border: 1px solid #999; border-radius: 20px;" href="#" aria-expanded="false" @click.stop.prevent="setModalsMobile()">
                            <span class="small-badge" style="color: #999; top: 0px; right: 2px;">{{ getOsStatistic[0] }}</span>
                            <i class="glyph-icon icon-phone" style="color: #999;"></i>
                        </a>

                        <transition name="scale">
                            <ul class="dropdown-menu"
                                role="menu"
                                :style="getModalsMobile ? 'display: block;' : 'display: none;'"
                                v-show="getModalsMobile"
                                >
                                <li v-for="(item, index) in getOsListStatistic" :key="index" :class="getOsStatistic[0] === item.id[0] ? 'active' : ''">
                                    <a href="#" @click.prevent="setOSStatistic(item.id); setOSStatistics(item.id); setOS(item.id);">{{ item.name }}</a>
                                </li>
                            </ul>
                        </transition>
                    </div>

                    <div class="dropdown" :class="getModalsLanguage ? 'show' : ''" id="lang-btn">
                         <a data-toggle="dropdown" class="headers-btn" href="#" aria-expanded="false" @click.stop.prevent="setModalsLanguage()">
                            <img :src="getLangName == 'en' ? getLogosHeader.eng : getLogosHeader.rus" />
                        </a>

                        <transition name="scale">
                            <ul class="dropdown-menu"
                                role="menu"
                                :style="getModalsLanguage ? 'display: block;' : 'display: none;'"
                                v-show="getModalsLanguage"
                                >
                                <li v-for="(item, index) in languageList" :key="index" :class="(getLangId == index) ? 'active' : ''">
                                    <a href="#" @click.prevent="setLang($event, index)">{{ item.title }}</a>
                                </li>
                            </ul>
                        </transition>
                    </div>
                    <div class="dropdown" :class="getModalsNotification ? 'show' : ''" id="notifications-btn">
                        <a data-toggle="dropdown"
                            class="headers-btn"
                            href="#"
                            :title="getLang.follow"
                            @click.stop.prevent="setModalsNotification()"
                            >
                            <img :src="getLogosHeader.btnRing" />
                        </a>
                        <transition name="scale">
                            <div class="dropdown-menu box-md float-right"
                                @click.stop="''"
                                :style="getModalsNotification ? 'display: block' : ''"
                                v-show="getModalsNotification"
                                >

                                <div class="popover-title display-block clearfix pad10A">
                                    {{ getLang.notifications }}
                                </div>
                                <div class="scrollable-content scrollable-slim-box">
                                </div>
                            </div>setInfo
                        </transition>
                    </div>

                    <div class="dropdown" :class="getModalsExclamation ? 'show' : ''" id="notifications-btn">
                        <a data-toggle="dropdown"
                            class="headers-btn"
                            href="#"
                            @click.stop.prevent="setModalsExclamation()"
                            >
                            <img :src="getLogosHeader.btnExcl" />
                        </a>
                        <transition name="scale">
                            <div class="dropdown-menu box-md float-right"
                                @click.stop="''"
                                :style="getModalsExclamation ? 'display: block' : ''"
                                v-show="getModalsExclamation"
                                >

                                <div class="popover-title display-block clearfix pad10A">
                                    {{ getLang.notifications }}
                                </div>
                                <div class="scrollable-content scrollable-slim-box">
                                </div>
                            </div>
                        </transition>
                    </div>

                    <!-- <div class="dropdown" id="info-btn" :class="getModalsInfo ? 'show' : ''">
                        <router-link :to="{name: 'tutorial', query: {hash: 'android'}}" class="headers-btn" target="_blank">
                            <img :src="getLogosHeader.btnQues" />
                        </router-link>

                        <transition name="scale">
                            <div class="dropdown-menu box-md float-right"
                                @click.stop="''"
                                :style="getModalsInfo ? 'display: block;' : 'display: none;'"
                                v-show="getModalsInfo">

                                <div class="popover-title display-block clearfix pad10A">
                                    {{ getLang.information }}
                                </div>
                                <div class="scrollable-content scrollable-slim-box">
                                    <ul class="no-border notifications-box">
                                        <li>
                                            <span class="bg-danger icon-notification glyph-icon icon-bullhorn"></span>
                                            <span class="notification-text">This is an error notification</span>
                                            <div class="notification-time">
                                                a few seconds ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="bg-warning icon-notification glyph-icon icon-users"></span>
                                            <span class="notification-text font-blue">This is a warning notification</span>
                                            <div class="notification-time">
                                                <b>15</b> minutes ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="bg-green icon-notification glyph-icon icon-sitemap"></span>
                                            <span class="notification-text font-green">A success message example.</span>
                                            <div class="notification-time">
                                                <b>2 hours</b> ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="bg-azure icon-notification glyph-icon icon-random"></span>
                                            <span class="notification-text">This is an error notification</span>
                                            <div class="notification-time">
                                                a few seconds ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="bg-warning icon-notification glyph-icon icon-ticket"></span>
                                            <span class="notification-text">This is a warning notification</span>
                                            <div class="notification-time">
                                                <b>15</b> minutes ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="bg-blue icon-notification glyph-icon icon-user"></span>
                                            <span class="notification-text font-blue">Alternate notification styling.</span>
                                            <div class="notification-time">
                                                <b>2 hours</b> ago
                                                <span class="glyph-icon icon-clock-o"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div> -->

                    <user-icon></user-icon>

                </div><!-- #header-nav-right -->
                <div class="content">
                </div>

                <div class="msg float-right">
                    <transition-group name="list-complete" mode="out-in">
                        <div class="alert alert-success status_msg" v-if="getSendFormStatus.length > 0" v-for="(item, key) in getSendFormStatus" :key="'key'+key">
                            <div class="bg-green alert-icon">
                                <i class="glyph-icon icon-check"></i>
                            </div>
                            <div class="alert-content">
                                <h4 class="alert-title">{{ item.value }}</h4>
                            </div>
                        </div>
                        <div class="alert alert-danger status_msg" v-if="getErrorFormStatus.length > 0" v-for="(item, key) in getErrorFormStatus" :key="'key2'+key">
                            <div class="bg-red alert-icon">
                                <i class="glyph-icon icon-times"></i>
                            </div>
                            <div class="alert-content">
                                <h4 class="alert-title">{{ item.value }}</h4>
                            </div>
                        </div>

                    </transition-group>
                </div>
                <div class="linePreloader" v-if="getIsPreloader">&nbsp;</div>
            </div>
        </div>
    </div>
</template>

<script>
import UserIcon from './UserIcon';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';

export default {
    mixins: [Authentication],
    data(){
        return {
            languageList: this.$store.getters.getLanguageList,
            languageId: 0,
            languageName: this.$store.getters.getLanguageList[0].key,
            lang: Lang,

            modalsMobile: false,
            modalsLanguage: false,
            modalsNotification: false,
            modalsExclamation: false,
            modalsInfo: false,

            closeSidebar: false,

            logosHeader: null,

            sendFormStatus: [],
            errorFormStatus: [],

            routeName: null,
            routesEnabled: ['group', 'queue', 'exchange', 'queueAdd'],
            routesEnabledStatistics: ['statisticsShows'],
            routesEnabledCagorts: ['cagorts'],
            routesQueueAdd: 'queueAdd',

            osList: null,
            osListStat: [],
            osListStatAll: [],

            adUnitData: {
                os: {
                    value: 1,
                    text: ''
                },
                osStatistics: {
                    value: [],
                    text: ''
                },
                osStatistic: {
                    value: [],
                    text: ''
                }
            },

            isPreloader: false
        }
    },
    computed: {
        getLangId(){
            this.languageId = this.$store.getters.getLanguageActive;
            return this.languageId;
        },
        getLangName(){
            this.languageName = this.languageList[this.$store.getters.getLanguageActive].key;
            return this.languageName;
        },
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        /*--- modals ---*/
        getModalsMobile(){
            this.modalsMobile = this.$store.state.modals.mobile;
            return this.modalsMobile;
        },
        getModalsLanguage(){
            this.modalsLanguage = this.$store.state.modals.language;
            return this.modalsLanguage;
        },
        getModalsNotification(){
            this.modalsNotification = this.$store.state.modals.notification;
            return this.modalsNotification;
        },
        getModalsExclamation(){
            this.modalsExclamation = this.$store.state.modals.exclamation;
            return this.modalsExclamation;
        },
        getModalsInfo(){
            this.modalsInfo = this.$store.state.modals.info;
            return this.modalsInfo;
        },
        /*--- end modals ---*/

        getCloseSidebar(){
            this.closeSidebar = this.$store.getters.getCloseSidebar;

            return this.closeSidebar;
        },

        getRouteName(){
            this.routeName = this.$route.name;

            return this.routeName;
        },

        getLogosHeader(){
            this.logosHeader = this.$store.state.logos.headerIcons;

            return this.logosHeader;
        },

        /* os */
        getOsList(){
            this.osList = this.$store.getters.getOsList;

            return this.osList;
        },

        getOsStatistics(){
            this.adUnitData.osStatistics.value = this.$store.getters.getOsStatisticsActive;

            return this.adUnitData.osStatistics.value;
        },

        getOsStatistic(){
            this.adUnitData.osStatistic.value = this.$store.getters.getOsStatisticActive;

            return this.adUnitData.osStatistic.value;
        },

        getOsListStatistics(){
            let android = "android", ios = "ios";
            this.osListStatAll = [{id: [android, ios], name: "All"}];

            if (this.osListStatAll.length === 1) {
                this.getOsList.forEach(val => {
                    if(val.name.toLowerCase() === android) this.osListStatAll.push({name: val.name, id: [android]});
                    if(val.name.toLowerCase() === ios) this.osListStatAll.push({name: val.name, id: [ios]});
                });
            }

            return this.osListStatAll;
        },

        getOsListStatistic(){
            let android = "android", ios = "ios", huawei = "huawei";

            if (this.osListStat.length === 0) {
                this.getOsList.forEach(val => {
                    if(val.name.toLowerCase() === android) this.osListStat.push({name: val.name, id: [android]});
                    if(val.name.toLowerCase() === ios) this.osListStat.push({name: val.name, id: [ios]});
                    if(val.name.toLowerCase() === huawei) this.osListStat.push({name: val.name, id: [huawei]});
                });
            }

            return this.osListStat;
        },

        getIsPreloader(){
            this.isPreloader = this.$store.getters.getIsPreloader;

            return this.isPreloader;
        },

        getSendFormStatus(){
            this.sendFormStatus = this.$store.getters.getSendFormStatus;

            return this.sendFormStatus;
        },

        getErrorFormStatus(){
            this.errorFormStatus = this.$store.getters.getErrorFormStatus;

            return this.errorFormStatus;
        }
    },
    methods: {
        setLang(e, index){
            this.$store.dispatch('setLanguageActiveAction', index);
        },

        /*--- modals ---*/
        setModalsMobile(){
            this.modalsMobile = !this.modalsMobile;
            this.$store.commit('modals/setMobile', this.modalsMobile);

            if(this.$store.state.modals.userProfile == true) this.$store.commit('modals/setUserProfile', false);
            if(this.$store.state.modals.language == true) this.$store.commit('modals/setLanguage', false);
            if(this.$store.state.modals.notification == true) this.$store.commit('modals/setNotification', false);
            if(this.$store.state.modals.exclamation == true) this.$store.commit('modals/setExclamation', false);
            if(this.$store.state.modals.info == true) this.$store.commit('modals/setInfo', false);
        },
        setModalsLanguage(){
            this.modalsLanguage = !this.modalsLanguage;
            this.$store.commit('modals/setLanguage', this.modalsLanguage);

            if(this.$store.state.modals.mobile == true) this.$store.commit('modals/setMobile', false);
            if(this.$store.state.modals.userProfile == true) this.$store.commit('modals/setUserProfile', false);
            if(this.$store.state.modals.notification == true) this.$store.commit('modals/setNotification', false);
            if(this.$store.state.modals.exclamation == true) this.$store.commit('modals/setExclamation', false);
            if(this.$store.state.modals.info == true) this.$store.commit('modals/setInfo', false);
        },
        setModalsNotification(){
            this.modalsNotification = !this.modalsNotification;
            this.$store.commit('modals/setNotification', this.modalsNotification);

            if(this.$store.state.modals.mobile == true) this.$store.commit('modals/setMobile', false);
            if(this.$store.state.modals.userProfile == true) this.$store.commit('modals/setUserProfile', false);
            if(this.$store.state.modals.language == true) this.$store.commit('modals/setLanguage', false);
            if(this.$store.state.modals.info == true) this.$store.commit('modals/setInfo', false);
            if(this.$store.state.modals.exclamation == true) this.$store.commit('modals/setExclamation', false);
        },
        setModalsExclamation(){
            this.modalsExclamation = !this.modalsExclamation;
            this.$store.commit('modals/setExclamation', this.modalsExclamation);

            if(this.$store.state.modals.mobile == true) this.$store.commit('modals/setMobile', false);
            if(this.$store.state.modals.userProfile == true) this.$store.commit('modals/setUserProfile', false);
            if(this.$store.state.modals.language == true) this.$store.commit('modals/setLanguage', false);
            if(this.$store.state.modals.notification == true) this.$store.commit('modals/setNotification', false);
            if(this.$store.state.modals.info == true) this.$store.commit('modals/setInfo', false);
        },
        setModalsInfo(){
            this.modalsInfo = !this.modalsInfo;
            this.$store.commit('modals/setInfo', this.modalsInfo);

            if(this.$store.state.modals.mobile == true) this.$store.commit('modals/setMobile', false);
            if(this.$store.state.modals.userProfile == true) this.$store.commit('modals/setUserProfile', false);
            if(this.$store.state.modals.language == true) this.$store.commit('modals/setLanguage', false);
            if(this.$store.state.modals.notification == true) this.$store.commit('modals/setNotification', false);
            if(this.$store.state.modals.exclamation == true) this.$store.commit('modals/setExclamation', false);
        },
        /*--- end modals ---*/

        setCloseSidebar(){
            this.closeSidebar = !this.closeSidebar;

            this.$store.commit('setCloseSidebar', this.closeSidebar);
        },

        setOS(id){
            const os = {android: 1, ios: 2, huawei: 3};

            this.adUnitData.os.value = os[id];
            this.$store.commit('setOsActive', this.adUnitData.os.value);
            this.clearRemeberData();
        },

        setOSStatistics(id){
            if (this.adUnitData.osStatistics.value.length != id.length || this.adUnitData.osStatistics.value[0] != id[0]) {
                this.adUnitData.osStatistics.value = id;
                this.$store.commit('setOsStatisticsActive', this.adUnitData.osStatistics.value);
            }
        },

        setOSStatistic(id){
            this.adUnitData.osStatistic.value = id;
            this.$store.commit('setOsStatisticActive', this.adUnitData.osStatistic.value);
        },
    },
    components: {
        UserIcon
    }
}
</script>

<style scoped>
    /* Loading Spinner */
    .spinner{margin:0;width:70px;height:18px;margin:-35px 0 0 -9px;position:absolute;top:50%;left:50%;text-align:center}.spinner > div{width:18px;height:18px;background-color:#333;border-radius:100%;display:inline-block;-webkit-animation:bouncedelay 1.4s infinite ease-in-out;animation:bouncedelay 1.4s infinite ease-in-out;-webkit-animation-fill-mode:both;animation-fill-mode:both}.spinner .bounce1{-webkit-animation-delay:-.32s;animation-delay:-.32s}.spinner .bounce2{-webkit-animation-delay:-.16s;animation-delay:-.16s}@-webkit-keyframes bouncedelay{0%,80%,100%{-webkit-transform:scale(0.0)}40%{-webkit-transform:scale(1.0)}}@keyframes bouncedelay{0%,80%,100%{transform:scale(0.0);-webkit-transform:scale(0.0)}40%{transform:scale(1.0);-webkit-transform:scale(1.0)}}
    .dropdown-menu{
        min-width: auto!important;
        border: 1px solid #ccc;
    }
    .dropdown-menu a:hover{
        text-decoration: none;
    }
    #lang-btn .small-badge{
        padding: 0px 3px 0 0;
        font-size: 12px;
        line-height: 12px;
        color: #fff;
        width: 14px!important;
        height: 14px!important;
    }

    .icon-question-circle{
        top: 0px;
    }

    .headers-btn {
        float: left;
        height: 36px!important;
        line-height: 36px!important;
        display: block;
        width: 36px!important;
        text-align: center;
        border-radius: 25px;
        margin: 0px 20px 9px 0!important;
        color:rgba(255,255,255,0.7);
    }

    .msg{
        margin: 10px 20px 0 0;
    }

    div.radio > span {
        margin-top: 0px;
    }

    .alert .alert-icon{
        line-height: 34px;
        width: 34px;
        height: 34px;
        position: absolute;
        top: calc(50% - 17px);
        margin: 0px 10px 0 0;
    }

    .alert .alert-content{
        padding-left: 44px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        transition: all .5s reverse;
    }
    .fade-leave-to{
        opacity: 0;
    }

    .scale-enter{
        opacity: 0;
        height: 0px;
    }
    .scale-enter-active{
        transition: all .5s;
    }
    .scale-enter-to{
        opacity: 1;
        height: auth;
    }
    .scale-leave-active{
        opacity: 0;
        height: 0px;
        transition: all .5s reverse;
    }

    .list-complete-item {
        transition: all .5s;
        display: inline-block;
    }

    .list-complete-enter, .list-complete-leave-to {
        opacity: 0;
    }

    .list-complete-leave-active {
        position: absolute;
    }

    .list-complete-move {
        transition: transform .5s;
    }

    .linePreloader{
        background:linear-gradient(to right,green,green);
        background-color:#ccc;
        left:0;
        right:0;
        margin:auto;
        border-radius:4px;
        background-size:20%;
        background-repeat:repeat-y;
        background-position:-25% 0;
        animation:scroll 1.2s ease-in-out infinite;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0px;
    }

    @keyframes scroll{
        50%{background-size:80%}
        100%{background-position:125% 0;}
    }
</style>
