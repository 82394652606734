<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
                >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
                    >
            <div class="modal-dialog" @click.stop="''" style="width: 1190px!important; max-width: 1190px!important;">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader" style="padding: 5px 15px;">
                        <div class="w50 pt10" style="padding-top: 0px;">
                            <div ref="filterDiv" class="form-group header_filter no-border" style="padding-top: 0px; display: flex; justify-content: space-between;">
                                <div class="search" style="padding-top: 3px; width: 163px; padding-left: 10px; box-sizing: content-box;">
                                    <input class="form-control outlight"
                                            :placeholder="getLang.search.search"
                                            style="margin-left: 4px; padding-right: 20px;"
                                            type="text"
                                            v-model="searchText"
                                            @input="searchAdunit()"
                                            @blur="searchBlurAdunit($event)"
                                            required
                                    >
                                    <a class="btn search_clear" @click.prevent="searchText = ''; searchAdunit()" v-show="searchText.length > 0">
                                        <i class="glyph-icon icon-remove"></i>
                                    </a>
                                </div>
                                <div style="display: flex;">
                                    <div style="margin-left: 40px; position: relative;">
                                        <label
                                            class="control-label hidden_group label_on_input short_text"
                                            style="left: 0px;"
                                            @click="hideBlock.otherBlocks = !hideBlock.otherBlocks"
                                        >
                                            {{ getLang.group.hideAddedOtherBlock }}
                                        </label>
                                        <div :class="hideBlock.otherBlocks ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                            @click.prevent="hideBlock.otherBlocks = !hideBlock.otherBlocks"
                                            style="margin-top: 5px;"
                                            >
                                            <div class="bootstrap-switch-container">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                            </div>
                                        </div>
                                        <!-- <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.hideAddedOtherBlock" data-original-title=".icon-question"></i> -->
                                    </div>
                                    <div style="margin-left: 40px; position: relative; right: -4px;">
                                        <label
                                            class="control-label hidden_group label_on_input short_text"
                                            style="left: 0px;"
                                            @click="hideBlock.block = !hideBlock.block"
                                        >
                                            {{ getLang.group.hideAddedBlock }}
                                        </label>
                                        <div :class="hideBlock.block ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                            @click.prevent="hideBlock.block = !hideBlock.block"
                                            style="margin-top: 5px;"
                                            >
                                            <div class="bootstrap-switch-container">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                            </div>
                                        </div>
                                        <!-- <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.hideAddedBlock" data-original-title=".icon-question"></i> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w50 pt10" style="padding-top: 0px;">
                            <button type="button"
                                    class="btn btn-danger float-right"
                                    @click.prevent="setPopupAddHide()"
                                    v-if="updateData.adunitSort.length == 0 && updateData.adunitUpdate.length == 0 && updateData.adunitDelete.length == 0 && updateData.adunitAdd.length == 0"
                            >
                                {{ getLang.close }}
                            </button>
                            <button type="button"
                                    class="btn btn-primary float-right"
                                    @click.once="saveData()"
                                    :style="updateData.adunitSort.length == 0 && updateData.adunitUpdate.length == 0 && updateData.adunitDelete.length == 0 && updateData.adunitAdd.length == 0 ? 'opacity: 0.5; cursor: default;' : ''"
                                    v-else
                            >
                                {{ getLang.save }}
                            </button>
                            <h4 class="modal-title nowrap label_on_input" style="position: absolute; top: 1px; left: 28px;">{{ title }} <b v-if="popupTitle">({{ popupTitle }})</b></h4>
                        </div>
                    </div>
                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="adunit_in_group_wrapper">
                                    <div class="form_adunit scroll w50 pt10" :style="'max-height: ' + adunitListHeight + 'px; border-right: 1px dashed #dfe8f1;'">
                                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                                            <div v-for="item in getAdunitList"
                                                :key="item.id"
                                                class="flex-block-adunit-group list-complete-item"
                                                >
                                                <table class="adunit_in_group">
                                                    <!-- <tr> -->
                                                        <td :class="item.type != 0 ? 'icon_other_type' : 'icon'">
                                                            <div v-if="item.icon">
                                                                <img :src="item.icon" class="icon" alt="" v-if="item.image_only == false || item.type != 0">
                                                            </div>

                                                            <div v-if="item.image && item.type == 0" :class="item.image_only ? 'div_image_only' : ''">
                                                                <img :src="item.image" :class="item.image_only ? 'image_only' : 'image'" alt="">
                                                            </div>
                                                            <div v-else-if="item.image43 && item.type == 0">
                                                                <img :src="item.image43" class="image" alt="">
                                                            </div>
                                                            <div v-else-if="item.image11 && item.type == 0">
                                                                <img :src="item.image11" class="image" alt="">
                                                            </div>
                                                            <div v-else-if="item.image34 && item.type == 0">
                                                                <img :src="item.image34" class="image" alt="">
                                                            </div>
                                                            <div v-else-if="item.image916 && item.type == 0">
                                                                <img :src="item.image916" class="image" alt="">
                                                            </div>
                                                        </td>
                                                        <td :class="item.type != 0 ? 'info_other_type' : 'info'">
                                                            <div :class="item.type != 0 ? 'info__description_other_type' : 'info__description'">
                                                                <div class="info__name short_text" v-if="item.name">
                                                                    ({{ item.name }})
                                                                </div>
                                                                <div class="short_text" :title="item.title">
                                                                    <strong>{{ getShortTitle(item.title) }}</strong>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="action_add">
                                                            <div class="nowrap">
                                                                <button type="button"
                                                                        class="btn btn-success add_adunit_in_group"
                                                                        @click.prevent="addItem(item, item.appId);"
                                                                        >
                                                                    <span v-if="item.tag.length > 0" style="font-weight: bold;">M</span>&nbsp;<i class="glyph-icon icon-sign-in"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    <!-- </tr> -->
                                                </table>
                                            </div>

                                        </form>
                                    </div>
                                    <!-- Added adunit -->
                                    <div class="scroll w50 pt10" :style="'position: relative; max-height: ' + adunitListHeight + 'px;'"> <!--  v-if="adunit.length > 0" -->
                                        <div style="position: absolute; right: 0px; top: 0px; width: 100px; z-index: 2;" :style="'height:' + getSortHeight + 'px;'">&nbsp;</div>
                                        <div class="sort_block" ref="addedAdunitSort">
                                            <draggable v-model="adunit" :component-data="getComponentData()">
                                                <transition-group name="list-complete">
                                                    <div v-for="(item, idx) in getAdunitUpdated"
                                                        :key="item.order"
                                                        class="flex-block-adunit-group flex-block-adunit-group-added list-complete-item"
                                                        style="cursor: move;"
                                                        >
                                                        <div class="inner_block">
                                                            <div class="status">
                                                                <a href="#" @click.prevent="updateAdunitGroup($event, item, idx)" style="padding: 15px 5px; background-color: transparent;"><span class="bs-badge" :class="item.enabled ? 'badge-success' : 'badge-danger'">&nbsp;</span></a>
                                                            </div>
                                                            <table class="adunit_in_group_added">
                                                                <!-- <tr> -->
                                                                    <td :class="item.type != 0 ? 'icon_other_type' : 'icon'">
                                                                        <div v-if="item.icon && (!item.image_only || item.type != 0)">
                                                                            <img :src="item.icon" class="icon" alt="">
                                                                        </div>

                                                                        <div v-if="item.image && item.type == 0" :class="item.image_only ? 'div_image_only' : ''">
                                                                            <img :src="item.image" :class="item.image_only ? 'image_only_added' : 'image'" alt="">
                                                                        </div>
                                                                        <div v-else-if="item.image43 && item.type == 0">
                                                                            <img :src="item.image43" class="image" alt="">
                                                                        </div>
                                                                        <div v-else-if="item.image11 && item.type == 0">
                                                                            <img :src="item.image11" class="image" alt="">
                                                                        </div>
                                                                        <div v-else-if="item.image34 && item.type == 0">
                                                                            <img :src="item.image34" class="image" alt="">
                                                                        </div>
                                                                        <div v-else-if="item.image916 && item.type == 0">
                                                                            <img :src="item.image916" class="image" alt="">
                                                                        </div>
                                                                    </td>
                                                                    <td :class="item.type != 0 ? 'info_other_type' : 'info'">
                                                                        <div style="position: absolute; right: 0px; top: 0px; padding-right: 0px; height: 100%; display: flex; align-items: flex-end; cursor: default;">
                                                                            <span style="line-height: 26px;">{{ item.count }}</span>
                                                                            <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%; margin-left: 10px;">
                                                                                <a href="#" style="display: flex; position: relative; z-index:5;" @click.prevent="updateCount($event, item, idx, 'plus')">
                                                                                    <span class="icon square arrow up"></span>
                                                                                </a>
                                                                                <a href="#" style="display: flex; position: relative; z-index:5;" @click.prevent="updateCount($event, item, idx, 'minus')">
                                                                                    <span class="icon square arrow down"></span>
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                        <div :class="item.type != 0 ? 'info__description_other_type' : 'info__description'">
                                                                            <div class="info__name short_text" v-if="item.name">
                                                                                ({{ item.name }})
                                                                            </div>
                                                                            <div class="short_text" :title="item.title">
                                                                                <strong>{{ getShortTitle(item.title) }}</strong>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                <!-- </tr> -->
                                                            </table>
                                                            <div class="action">
                                                                <button type="button"
                                                                        class="btn btn-danger del_adunit_in_group"
                                                                        style="position: relative; z-index:5;"
                                                                        @click.prevent="deleteConfirm(item.uid)"
                                                                        >
                                                                    <i class="glyph-icon icon-close"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition-group>
                                            </draggable>
                                        </div>
                                    </div>
                                    <!-- End added adunit -->
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="form_adunit w50">
                                    {{ getAdunitList.length }} count
                                </div>
                                <div class="scroll w50" style="padding-right: 20px;">
                                    {{ adunit.length }} count
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelect from '../plugins/MultiSelect';
import Draggable from 'vuedraggable';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        popupTitle: String,
        editId: Number,
        urlAdded: String,
        urlRedirect: String,
        title: String,
        message: String,
        typeBanner: Number
    },

    data(){
        return {
            lang: Lang,

            adunitList: [],
            adunitView: [],
            adunitGroup: [], // список adunit в group
            otherAdunitGroup: [], // список other adunit в group
            adunitGroupId: [], // список id adunit в group
            adunitGroupMaxSort: 0, // максимальный sort adunit в group
            adunit: [],
            adUnutListForSearch: [], // список adunit для поиска

            updateData: {
                adunitSort: [],
                adunitUpdate: [],
                adunitDelete: [],
                adunitAdd: [],
                adunitAddTmp: null
            },

            searchText: '',

            otherHeight: 60,
            modalBodyHeight: 200,
            adunitListHeight: 200,
            sortHeight: 0,

            //timeout: 0,
            oldEditId: null,

            hideBlock:{
                block: true,
                otherBlocks: true,
            },

            adunitGroupData: {
                order: {
                    alt: '',
                    value: 1
                },
                count: {
                    alt: '',
                    value: 1
                },
                adunit: {
                    alt: '',
                    value: []
                },
                enabled: {
                    alt: '',
                    value: true
                }
            },

            // popup
            popupAdd: false,
            categoriesStatus: false,
            isUpdate: false,
            isEscClose: true,
        }
    },

    mounted(){
        let _this = this;
        this.setAdunitListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setAdunitListHeight();
        });
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getSortHeight(){
            this.$nextTick(() => {
                this.sortHeight = this.getAddedAdunitSort;
            });

            return this.sortHeight;
        },

        getAddedAdunitSort(){
            return this.$refs.addedAdunitSort.offsetHeight;
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        // get max order adunit for group from DB
        getAdunitGroupMaxOrder(){
            let maxValue = 0;

            this.adunitGroup.forEach(function(item){
                if(item.order > maxValue) maxValue = item.order;
            });

            maxValue = (maxValue === 0 ? 1 : ++maxValue);

            return maxValue;
        },

        // get updated adunit
        getAdunitUpdated(){
            this.adunit = this.adunit.filter(val => this.updateData.adunitDelete.indexOf(val.uid) == -1);

            // if (this.updateData.adunitAddTmp) {
            //     if(this.adunit.length == 0) this.$set(this.adunit, 0, this.updateData.adunitAddTmp);
            //     else this.$set(this.adunit, this.adunit.length, this.updateData.adunitAddTmp);
            // }

            // this.$set(this.updateData, 'adunitAddTmp', null);

            this.adunit.map((item) => {
                let tmpUpdate = {};

                if (tmpUpdate = this.updateData.adunitUpdate.find(val => val.uid == item.uid)) {
                    item.count = tmpUpdate.count;
                    item.enabled = tmpUpdate.enabled;
                }

                return item;
            });

            return this.adunit;
        },

        // get adunit list by id group for select
        getAdunitList(){
            let _this = this;

            this.adunitView = this.adunitList.filter(val => {
                let res = true;

                if (_this.hideBlock.block && _this.hideBlock.otherBlocks) {
                    res = (_this.otherAdunitGroup.indexOf(val.id) == -1);

                    if(res && (_this.adunitGroupId.indexOf(val.id) != -1)) res = false;
                    if(res && _this.updateData.adunitAdd.find(item => item.adblock == val.id)) res = false;
                } else if (_this.hideBlock.block) {
                    res = (_this.adunitGroupId.indexOf(val.id) == -1);

                    if(res && _this.updateData.adunitAdd.find(item => item.adblock == val.id)) res = false;
                } else if (_this.hideBlock.otherBlocks) {
                    res = (_this.otherAdunitGroup.indexOf(val.id) == -1);
                }

                if (this.searchText && res) res = val.title.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0;

                return (val.type == _this.typeBanner) && res;
            });

            this.adunitView.sort(function(f, s){
                if(f.title > s.title) return 1;
                if(f.title < s.title) return -1;
                return 0;
            });

            return this.adunitView;
        },

        // is valid form add Adunit
        // isValid(){
        //     return (this.adunitGroupData.adunit.value.length > 0);
        // },

        // isEdit(){
        //     let flag = false;

        //     flag = this.adunit.some(function(item){
        //         if(item.isEdit) return true;

        //         return false;
        //     });

        //     return flag;
        // },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupAdd;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                let prom = this.getAdunitListDB(); // получаем список adunit с DB

                prom.then(() => {
                    return this.getAdunitGroupDB(this.editId, false);
                })
                .then(() => {
                    this.getOtherAdunitGroupDB(this.typeBanner, this.editId);
                });

                let heightFilter = 0;
                if(this.adunit.length > 0) heightFilter = -20;

                this.$nextTick(function(){
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);

                    //this.modalBodyHeight = (this.$refs.modalHeader.offsetHeight + this.$refs.modalBody.offsetHeight);
                    this.adunitListHeight = mdlBodyHeight - (this.$refs.filterDiv.offsetHeight + heightFilter);
                });
            } else {
                this.updateData.adunitSort = [];
                this.updateData.adunitUpdate = [];
                this.updateData.adunitDelete = [];
                this.updateData.adunitAdd = [];
                this.oldEditId = 0;
            }

            return this.popupAdd;
        },
    },
    watch: {
        isUpdate(){
            if (this.isUpdate) {
                this.getAdunitGroupDB(this.editId, true); // обновляем adunit in group
                this.getOtherAdunitGroupDB(this.typeBanner, this.editId);
            }

            this.isUpdate = false;
        }
    },
    methods: {
        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        // draggable
        inputEnd() {
            let _this = this;
            this.updateData.adunitSort = [];

            this.adunit.forEach(function(item, i, arr){
                arr[i].order = i+1;

                _this.updateData.adunitSort[i] = {uid: Number(arr[i].uid), order: arr[i].order};
            });
        },
        getComponentData() {
            return {
                on: {
                    end: this.inputEnd
                }
            };
        },

        // clickModalDialog(e){
        //     this.adunit.map(val => val.isEdit = false);
        // },

        // clearCategory(){
        //     this.adunitGroupData.adunit.value = [];
        //     this.categoriesStatus = false;
        // },

        getShortTitle(title){
            if (title.length >= 30) {
                title = "..." + title.slice(-30);
            }

            return title;
        },

        /* search by table data */
        searchAdunit(){
            let res;

            //this.searchText = e.target.value;

            if(!this.adUnutListForSearch.length) this.adUnutListForSearch = this.adunitList;

            res = this.adUnutListForSearch.filter(item => {

                let isName = false;

                if(item.title != null) isName = (item.title.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0);

                return isName;

            });

            this.adunitList = res;
        },
        searchBlurAdunit(e){
            if(this.searchText == '' && this.adUnutListForSearch.length > 0) {
                this.adUnutListForSearch = [];
            }
        },
        /* end search table data */

        // get order for adunit by id
        getAdunitGroupSort(adunitId){
            let data = [];

            //let adunit = this.adunitGroup.find(val => val.adblock == adunitId);

            let adunit = [];
            let idx = 0;

            for(let i = 0; i < this.adunitGroup.length; i++){
                if (this.adunitGroup[i].adblock == adunitId) {
                    adunit[idx] = this.adunitGroup[i];
                    idx++;
                }
            }

            if (adunit.length > 0) {
                for(let i = 0; i < adunit.length; i++){
                    let tmp = {};

                    if(adunit[i].order != undefined) tmp.order = adunit[i].order;
                    if(adunit[i].uid != undefined) tmp.uid = adunit[i].uid;
                    if(adunit[i].count != undefined) tmp.count = adunit[i].count;
                    if(adunit[i].enabled != undefined) tmp.enabled = adunit[i].enabled;

                    data[data.length] = tmp;
                }
            }

            return data;
        },

        // set default form
        setFormDefault(){
            this.adunitGroupData.count.value = 1;
            this.adunitGroupData.enabled.value = true;
            this.adunitGroupData.adunit.value = [];
            this.adunitGroupData.order.value = 1;
        },

        // set height for adinut list in group
        setAdunitListHeight(){
            this.$nextTick(function(){
                let heightFilter = 0;
                let otherHeight = 124;
                let maxHeight = 650;
                let winHeight = window.innerHeight;
                let currentHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);

                if(this.adunit.length > 0) heightFilter = -20;
                if(currentHeight == 0) currentHeight = maxHeight;

                if (this.$refs.modalBody) {
                    if(winHeight < currentHeight + otherHeight) this.$refs.modalBody.style.height = (winHeight - otherHeight) + 'px';
                    else this.$refs.modalBody.style.height = maxHeight+'px';
                }

                this.adunitListHeight = currentHeight - (this.$refs.filterDiv.offsetHeight + heightFilter);

                if(this.adunitListHeight < 0) this.adunitListHeight = 0;
            });
        },

        /* popup */
        setPopupAddHide(){
            if (this.updateData.adunitSort.length > 0 || this.updateData.adunitUpdate.length > 0 || this.updateData.adunitDelete.length > 0 || this.updateData.adunitAdd.length > 0) {
                if(confirm('Are you sure you want to close the popup without saving the data?')){
                    this.setFormDefault();
                    this.$store.commit('modals/setPopupAdd', false);
                }
            } else {
                this.setFormDefault();
                this.$store.commit('modals/setPopupAdd', false);
            }
        },

        saveData(){
            let _this = this;

            if(this.updateData.adunitSort.length == 0 && this.updateData.adunitUpdate.length == 0 && this.updateData.adunitDelete.length == 0 && this.updateData.adunitAdd.length == 0) return;

            if(this.updateData.adunitSort.length > 0) this.updateAdunitSortDB();

            if(this.updateData.adunitUpdate.length > 0) {
                let isLast = false;

                this.updateData.adunitUpdate.forEach(function(item, i, arr){
                    if(arr.length-1 == i) isLast = true;
                    _this.updateAdunitGroupDB(JSON.stringify(item), isLast);
                });
            }

            if (this.updateData.adunitDelete.length > 0) {
                let isLast = false;

                for(let i = 0; i < this.updateData.adunitDelete.length; i++){
                    if(this.updateData.adunitDelete.length-1 == i) isLast = true;
                    this.deleteAdunitGroupDB(this.updateData.adunitDelete[i], isLast);
                }
            }

            if (this.updateData.adunitAdd.length > 0) {
                let isLast = false;

                this.updateData.adunitAdd.forEach(function(item, i, arr){
                    if(arr.length-1 == i) isLast = true;
                    _this.addItemDB(item, isLast);
                });
            }
        },

        // get adunit list have group
        getAdunit(groupId, isUpdate){
            if(this.oldEditId != groupId || isUpdate) {
                let _this = this;
                this.adunit = [];

                for(let i = 0; i < this.adunitList.length; i++){
                    let data = this.getAdunitGroupSort(this.adunitList[i].id);

                    if(data.length < 1) continue;

                    for(let j = 0; j < data.length; j++){
                        let tmp = this.setCloneObj(this.adunitList[i]);
                        let tmpSort = {};

                        if (tmpSort = this.updateData.adunitSort.find(val => val.uid == data[j].uid)) {
                            tmp.order = tmpSort.order;
                        } else {
                            tmp.order = data[j].order;
                        }

                        tmp.uid = data[j].uid;
                        tmp.count = data[j].count;
                        tmp.enabled = data[j].enabled;

                        if ( (tmp.type == this.typeBanner) && (this.adunitGroupId.indexOf(tmp.id) !== -1) ) {
                            this.adunit[tmp.order-1] = tmp;

                            this.$set(this.adunit[tmp.order-1], 'isEdit', false);
                        }
                    }
                }

                if(this.adunit != undefined) this.adunit = this.setUnique(this.adunit);

                this.oldEditId = groupId;
            }

            return this.adunit;
        },

        // get adunit in group
        getAdunitGroupDB(groupId, isUpdate){
            if(this.oldEditId != groupId || isUpdate) {
                this.adunitGroup = [];
                //this.adunitGroupId = [];

                return axios({
                    url: this.$store.getters.getBaseUrl+'/api/queue/group/adblocks/' + groupId + '?at='+this.getAccessToken,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    this.adunitGroup = response.data.Adblock;
                    this.updateData.adunitAdd = [];

                    this.adunitGroupId = this.adunitGroup.map(function(item){
                        return item.adblock;
                    });

                    this.getAdunit(groupId, isUpdate);
                })
                .catch(e => {
                    this.errorsMessage(e, 'error get adunit in group');
                });
            }
        },

        // get other adunit in group
        getOtherAdunitGroupDB(typeBanner, editId){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks/used/' + typeBanner + '/' + editId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.otherAdunitGroup = response.data.adblocks;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get other adunit in group');
            });
        },

        // get list Adunit
        getAdunitListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adunitList = response.data.Adblock.map(function(item){
                    let title = item.apptitle;
                    //if(item.name != '') title += ' (' + item.name + ')';

                    return {title: title, name: item.name, id: item.id, type: item.type, icon: item.icon, image: item.image, image43: item.image43, image11: item.image11, image34: item.image34, image916: item.image916, image_only: item.image_only, tag: item.tag, appId: item.appId };
                });

                //this.adunitView = this.adunitList;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get adunit');
            });
        },

        // delete adunit in group
        deleteConfirm(adunitId){
            if (confirm('Are you sure to delete adunnit?')) {
                let _this = this;

                if(this.updateData.adunitAdd.find(val => val.uid == adunitId)) {
                    this.updateData.adunitAdd = this.updateData.adunitAdd.filter(val => val.uid != adunitId);
                } else if(this.updateData.adunitDelete.indexOf(adunitId) == -1) this.updateData.adunitDelete.push(adunitId);

                this.adunit.forEach(function(item, i, arr){
                    if (item.uid == adunitId) {
                        _this.$delete(_this.adunitGroupId, _this.adunitGroupId.indexOf(item.id));
                        _this.$delete(_this.adunit, i);
                    }
                });
            }
        },
        // delete adunit in group
        deleteAdunitGroupDB(adunitId, lastUpdate){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/group/adblock/delete/' + this.editId + '/' + adunitId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (lastUpdate) {
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Adunit delete from Queue!'});
                    let _this = this;
                    this.updateData.adunitDelete = [];

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);

                    this.isUpdate = true;
                    this.$emit('setUpdata', true);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error delete adunit in group');
            });
        },

        updateCount(e, item, idx, state){
            if(state == 'plus') item.count = item.count + 1;
            else if(item.count > 1) item.count = item.count - 1;

            this.updateAdunitGroup(e, item, idx, true);
        },

        // update enabled adunit in group
        updateAdunitGroup(e, item, idx, delay){
            delay = delay || false;
            //let timer = 1000;
            let _this = this;
            let enabled = !item.enabled;

            if (delay) {
                enabled = item.enabled;
            } else {
                if (enabled) {
                    if(e.target.classList.contains('badge-danger')) e.target.classList.remove('badge-danger');
                    e.target.classList.add('badge-success');
                } else {
                    if(e.target.classList.contains('badge-success')) e.target.classList.remove('badge-success');
                    e.target.classList.add('badge-danger');
                }
            }

            if (this.updateData.adunitUpdate.some(function(val){ return val.uid == item.uid })) {
                this.updateData.adunitUpdate = this.updateData.adunitUpdate.map(function(val){
                    if (val.uid == item.uid) {
                        return {
                            uid: item.uid,
                            count: Number(item.count),
                            enabled: enabled
                        };
                    }
                    return val;
                });
            } else {
                this.updateData.adunitUpdate.push({
                    uid: item.uid,
                    count: Number(item.count),
                    enabled: enabled
                });
            }

            this.adunit[idx].enabled = enabled;
            this.$set(this.adunit, idx, this.adunit[idx]);

            // if(delay){
            //     clearTimeout(this.timeout);

            //     this.timeout = setTimeout(function(){
            //         _this.updateAdunitGroupDB(data, idx, enabled, true);
            //     }, timer);
            // } else {
                //this.updateAdunitGroupDB(data, true);
            //}
        },

        // update enabled adunit in group to DB
        updateAdunitGroupDB(data, lastUpdate){
            axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/group/adblock/edit?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (lastUpdate) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your adunit in group is update!'});
                    this.updateData.adunitUpdate = [];

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);

                    this.$emit('setUpdata', true);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error update adunit in group');
            });
        },

        // sorting adunit in group
        updateAdunitSortDB(){
            let data = JSON.stringify({
                group: this.editId,
                order_pairs: this.updateData.adunitSort
            });

            axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/group/adblock/sort?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your adunit is update sort!'});
                this.updateData.adunitSort = [];

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);

                this.$emit('setUpdata', true);
            })
            .catch(e => {
                this.errorsMessage(e, 'error update sorting adunit in group');
            });
        },

        addItem(item, appId){
            if(this.editId) {
                this.adunitGroupData.order.value = (this.adunitGroupData.order.value > this.getAdunitGroupMaxOrder ? this.adunitGroupData.order.value : this.getAdunitGroupMaxOrder);

                this.adunitGroupData.count.value = (parseInt(this.adunitGroupData.count.value) ? parseInt(this.adunitGroupData.count.value) : 1);

                let tmp = Object.assign({
                    order: this.adunitGroupData.order.value++,
                    uid: this.getUniqueId(),
                    count: this.adunitGroupData.count.value,
                    enabled: true,
                    isEdit: false,

                    group: this.editId,
                    adblock: item.id,
                    application: appId,
                }, item);

                //this.$set(this.updateData, 'adunitAddTmp', tmp);
                if(this.adunit.length == 0) this.$set(this.adunit, 0, tmp);
                else this.$set(this.adunit, this.adunit.length, tmp);

                if(this.updateData.adunitAdd.length == 0) this.$set(this.updateData.adunitAdd, 0, tmp);
                else this.$set(this.updateData.adunitAdd, this.updateData.adunitAdd.length, tmp);
            }
        },

        addItemDB(data, lastUpdate){
            data = JSON.stringify({
                order: data.order,
                count: data.count,
                enabled: data.enabled,
                isEdit: data.isEdit,
                group: data.group,
                adblock: data.adblock,
                application: data.application
            });

//console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl + '/api/queue/group/adblock/add?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (lastUpdate) {
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Adunit added to Queue!'});
                    let _this = this;

                    this.$emit('setUpdata', true);
                    this.setFormDefault();
                    this.isUpdate = true;
                    //this.updateData.adunitAdd = [];

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error added item');
            });
        }
        /* end popup */
    },
    components: {
        MultiSelect,
        Draggable
    }
}
</script>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: inherit;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.scroll{
    overflow: auto;
    /* padding-right: 10px; */
    margin-right: 10px;
    box-sizing: border-box;
}

.icon-question-circle{
    font-size: 16px;
    position: absolute;
    top: -3px;
    left: 74px;
    z-index: 100;
}

.padding_for_icon{
    padding-right: 0px;
}

.search_clear {
    position: absolute;
    right: 0px;
    z-index: 100;
    top: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0;
}

.label_on_input{
    font-size: 10px;
    color: #AFAFAF;
    font-weight: normal;
    position: absolute;
    top: -23px;
    left: 22px;
}

    h4.label_on_input{
        font-size: 13px;
    }

.input_snipper{
    width: 70px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.header_filter>div{
    position: relative;
    bottom: -13px;
}

.header_filter>div.search{
    bottom: -11px;
}

.modal-dialog{
    margin: 30px auto 0px;
}

.modal-body{
    height: 650px;
    /* max-height: 500px; */
}

.modal-body, .modal-footer{
    padding: 0px 15px;
}

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 0px;
    }

    .modal-body .panel-body{
        flex-direction: column;
    }

.w100{
    width: 100%;
}

.modal-body .w50:first-child{
    margin-left: 0px;
}

.modal-body .w50:last-child{
    width: 520px;
    padding-right: 10px;
}

.w50{
    /* width: 50%; */
    width: 600px;
    box-sizing: border-box;
}

.pt10{
    padding-top: 10px;
}

.close{
    margin-right: 0px;
}

.form_adunit{
    margin-left: 20px;
    margin-right: 20px;
}

.modal-title{
    position: relative;
    top: -15px;
    left: 0px;
}

#close_modal{
    width: 40px;
    height: 30px;
    top: -10px;
    left: -5px;
    position: relative;
    z-index: 1;
}

.modal-footer>div{
    width: 100%;
}

.adunit_in_group_wrapper{
    display: flex;
}

    .adunit_in_group{
        width: 570px;
        min-height: 54px;
        display: flex!important;
    }

        .status{
            width: 30px;
            display: inline-block;
        }

    .adunit_in_group_added{
        width: 450px;
        min-height: 54px;
        display: flex!important; /* inline-block */
    }

        .del_adunit_in_group{
            padding: 0 8px;
        }

    .hidden_group{
        line-height: 40px;
        margin-bottom: 0px;
    }

.flex-block-adunit-group-added td.icon{
    width: 170px!important;
}

.flex-block-adunit-group-added td.icon_other_type{
    /* width: 70px!important; */
}

    .flex-block-adunit-group-added img.icon{
        width: 51px;
    }

    .flex-block-adunit-group-added .image{
        width: 102px;
    }

.flex-block-adunit-group-added .info{
    width: 280px!important; /* 150-280 */
}

.flex-block-adunit-group-added .info_other_type{
    width: 400px!important; /* 150-380 */
}

        .flex-block-adunit-group-added .info__description{
            width: 210px; /* 150-210 */
        }

        .flex-block-adunit-group-added .info__description_other_type{
            width: 310; /* 150-310 */
        }

.adunit_in_group td.icon, .adunit_in_group td.icon_other_type, .adunit_in_group .info, .adunit_in_group .info_other_type, .flex-block-adunit-group-added .info_other_type,  .flex-block-adunit-group-added .info, .adunit_in_group .action_add,
.adunit_in_group_added td.icon{
    display: flex;
    align-items: center;
}

td.icon{
    width: 170px;
    display: flex;
}

 td.icon div.div_image_only{
    overflow: hidden;
    width: 163px;
 }

td.icon_other_type{
    width: 50px;
    display: flex;
}

    img.icon{
        width: 51px;
        height: auto;
        margin-right: 10px;
    }

    .image{
        width: 102px;
        height: auto;
    }

    .image_only{
        width: auto;
        height: 50px;
    }

    .image_only:hover{
        position: absolute;
        top: 2px;
        left: 15px;
        z-index: 1;
    }

    .image_only_added{
        /* width: 163px;
        height: auto; */
        width: auto;
        height: 50px;
    }

    .image_only_added:hover{
        position: absolute;
        top: 2px;
        left: 27px;
        z-index: 1;
    }

.info{
    width: 250px;
    box-sizing: border-box;
    padding: 0 10px 0 0;
    position: relative;
    margin: 0px!important;
    display: flex;
    align-items: flex-end!important;
}

.adunit_in_group .info{
    width: 338px;
}

.info_other_type{
    width: 458px; /*310*/
    box-sizing: border-box;
    padding: 0 10px 0 0;
    position: relative;
    display: flex;
    align-items: flex-end!important;
}

.adunit_in_group .info_other_type{
    width: 460px;
}

    .info>input{
        /* margin-top: 10px; */
    }

    .info__description{
        width: 210px;
    }

    .adunit_in_group .info__description{
        width: 360px;
    }

    .info__description_other_type{
        width: 270px;
    }

    .adunit_in_group .info__description_other_type{
        width: 420px;
    }

    .info__description, .info__description_other_type{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: -3px;
        margin-bottom: 2px;
    }

        .info__name{
            color: #888;
            font-size: 11px;
            line-height: 11px;
        }

        .info__description input{
            margin-top: 5px;
        }

        .info__description div input{
            margin-bottom: 10px;
        }

    .action{
        width: 30px;
        vertical-align: top;
    }

    .action_add{
        width: 60px;
        vertical-align: middle;
    }

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.add_adunit_in_group{
    line-height: 30px;
    height: 30px;
    min-width: 56px!important;
    width: 56px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .add_adunit_in_group i::before{
        font-size: 18px;
    }

.flex-block-adunit-group-added{
    padding: 0 0px 4px!important;
}

    .inner_block{
        display: flex;
        align-items: center;
    }

        .inner_block .action{
            align-self: end;
            display: flex;
            justify-content: flex-end;
        }

    .flex-block-adunit-group-added div.status{
        display: inline-block;
        vertical-align: middle;
    }

.flex-block-adunit-group{
    padding: 0 13px 4px; /* 10px */
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
}

    .adunit_in_group_added:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        padding: 10px 8px 0 15px;
        margin-right: 0px;
    }

        .edit_adunit i::before{
            font-size: 16px;
        }

    .flex-block-adunit-group table{
        border: 1px solid #ddd;
        border-radius: 5px;
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

/*--- arrows ---*/
span.square{
	background: #00bca4;
    background: -webkit-linear-gradient(#00bca4 0%, #00bba3 100%);
    background: -o-linear-gradient(#00bca4 0%, #00bba3 100%);
    background: -webkit-linear-gradient(#00bca4 0%, #00bba3 100%);
    background: -o-linear-gradient(#00bca4 0%, #00bba3 100%);
    background: linear-gradient(#00bca4 0%, #00bba3 100%);
	border-radius:5px;
    border-bottom:1px solid #FFF;
	display:inline-block;
    height:26px;
	width:26px;
}
.arrow{
    position:relative;
}
.arrow:hover{
    opacity: .9;
}

.arrow.up:after{
    position:absolute;
    top:8px;
    left:6px;
    width: 0;
	height: 0;
    content:'';
	border-style: solid;
	border-width: 0 7px 8px 7px;
	border-color: transparent transparent #FFFFFF transparent;
    border-radius:2px;
}

.arrow.up:before{
    position:absolute;
    top:10px;
    left:6px;
    width: 0;
	height: 0;
    content:'';
	border-style: solid;
	border-width: 0 7px 8px 7px;
	border-color: transparent transparent rgba(0,0,0,0.7) transparent;
    border-radius:2px;
    opacity: .5;
}

.arrow.down:after{
   position:absolute;
    top:9px;
    left:6px;
    width: 0;
	height: 0;
    content:'';
	border-style: solid;
	border-width: 8px 7px 0 7px;
	border-color: #FFFFFF transparent transparent transparent;
    border-radius:2px;
}

.arrow.down:before{
   position:absolute;
    top:11px;
    left:6px;
    width: 0;
	height: 0;
    content:'';
	border-style: solid;
	border-width: 8px 7px 0 7px;
	border-color: rgba(0,0,0,0.7) transparent transparent transparent;
    border-radius:2px;
    opacity: .5;
}
/*--- end arrows ---*/

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
