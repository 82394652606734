<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInDown"
                leave-active-class="animated bounceOutDown"
                >
        <div class="modal fade bs-example-modal-sm" 
                    :class="getPopupDelete ? 'show' : ''" 
                    :style="getPopupDelete ? 'display: block;' : ''" 
                    tabindex="-1" 
                    role="dialog" 
                    aria-labelledby="mySmallModalLabel" 
                    aria-hidden="true" 
                    @click="setPopupDeleteHide()" 
                    v-show="getPopupDelete" 
                    >
            <div class="modal-dialog modal-sm" @click.stop="''">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" 
                                class="close" 
                                id="close_modal" 
                                data-dismiss="modal" 
                                aria-hidden="true"
                                @click.prevent="setPopupDeleteHide()"      
                                >
                            ×
                        </button>
                        <h4 class="modal-title">{{ title }}</h4>
                    </div>
                    <div class="modal-body">
                        <p>{{ message }}</p>
                        <p class="short_text"><strong>{{ popupTitle }}</strong>?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-default" 
                                data-dismiss="modal"
                                @click.prevent="setPopupDeleteHide()"
                                >
                            {{ getLang.close }}
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="deleteItem()">{{ getLang.Delete }}</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';

export default {
    mixins: [Authentication],

    props: {
        popupTitle: String,
        editId: Number,
        urlDelete: String,
        urlRedirect: String,
        title: String,
        message: String
    },

    data(){
        return {
            lang: Lang,

            // popup
            popupDelete: false,
        }
    },
    
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        // visible popup delete
        getPopupDelete(){
            this.popupDelete = this.$store.state.modals.popupDelete;
            return this.popupDelete;
        },
    },

    methods: {
        /* popup */
        setPopupDeleteHide(){
            this.$store.commit('modals/setPopupDelete', false);
        },
        deleteItem(e){
            if(this.editId) {
                axios({
                    url: this.$store.getters.getBaseUrl + this.urlDelete + this.editId + '?at=' + this.getAccessToken, 
                    method: 'GET', 
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    console.log('record deleted');

                    this.$emit('setUpdata', true);

                    this.$router.push({name: this.urlRedirect});
                })
                .catch(e => {
                    this.errorsMessage(e, 'error delete item');
                });
            }

            this.setPopupDeleteHide();
        },
        /* end popup */
    }
}
</script>

<style>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
