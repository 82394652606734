<template>
    <div class="center-vertical">
        <div class="center-content row">
            <h3>{{ getLang.pageNotFound }}</h3>
        </div>
    </div>
</template>

<script>
import Lang from './lang/message';

export default {
    data(){
        return {
            lang: Lang,
        }
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },
    }
}
</script>

<style scoped>
h3{
    text-align: center;
    margin-top: 30px;
}
</style>