import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=b8f27282&scoped=true&"
import script from "./Pagination.vue?vue&type=script&lang=js&"
export * from "./Pagination.vue?vue&type=script&lang=js&"
import style0 from "./Pagination.vue?vue&type=style&index=0&id=b8f27282&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f27282",
  null
  
)

component.options.__file = "Pagination.vue"
export default component.exports