<template>
    <section style="text-align: left; display: flex; padding-top: 30px;">
        <a class="float-left update_captcha"
            style="margin: 10px 15px 0 0;"
            href="#"
            @click.prevent="createCaptcha()"
            tabindex="4"
        >
            <i class="glyph-icon icon-refresh" style="color: #555; font-size: 25px;"></i>
        </a>
        <div ref="captcha" style="line-height: 1;"></div>
        <div class="form-group" style="width: 100%;">
            <input class="form-control outlight"
                    type="text"
                    ref="inputCaptcha"
                    @keyup="validateCaptcha()"
                    tabindex="3"
            />
        </div>
        <input type="hidden" :value="getIsNew"/>
        <!-- <div class="alert alert-success" v-if="isValid">Captcha is correct</div> -->
    </section>
</template>

<script>
export default{
    props: {
        isNew: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            /*--- Captcha ---*/
            charsArray: "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
            lengthOtp: 6,
            code: '',
            captcha: [],
            isValid: false,
            /*--- End Captcha ---*/
        }
    },
    created(){
        this.$nextTick(function(){
            this.createCaptcha(); // Captcha
        });
    },
    updated(){
        this.createCaptcha();
    },
    computed: {
        getIsValid(){
            this.isValid = this.$refs.inputCaptcha.value == this.code;

            return this.isValid;
        },

        getIsNew(){
            return this.isNew;
        },
    },
    methods: {
        // Captcha
        createCaptcha(){
            this.code = '';
            this.captcha = [];
            this.$refs.captcha.innerHTML = "";

            for(let i = 0; i < this.lengthOtp; i++){
                let index = Math.floor(Math.random() * this.charsArray.length + 1);
                if(this.captcha.indexOf(this.charsArray[index]) == -1) this.captcha.push(this.charsArray[index]);
                else i--;
            }

            let canv = document.createElement('canvas');
            canv.id = "captcha";
            canv.width = 140;
            canv.height = 40;
            let ctx = canv.getContext("2d");
            ctx.font = "25px Georgia";
            ctx.strokeText(this.captcha.join(''), 0, 30);
            this.code = this.captcha.join('');

            this.$refs.captcha.appendChild(canv);
            this.$emit('setIsValid', this.getIsValid);
        },

        validateCaptcha(){
            this.isValid = this.$refs.inputCaptcha.value == this.code;

            this.$emit('setIsValid', this.isValid);
        },
    },
}
</script>

<style scoped>
.update_captcha:focus i{
    outline: 1px dotted #333;
}
</style>
