<template>
    <div class="center-vertical">
        <div class="center-content row">

            <form id="login-validation" class="col-md-3 col-sm-5 col-xs-11 col-lg-3 center-margin" v-if="!getAccessToken">
                <!-- <h3 class="text-center pad25B font-gray text-transform-upr font-size-23">Yovoads Admin <span class="opacity-80">v1.0</span></h3> -->
                <div class="row mar30T">
                    <div class="col-md-6">
                        <!-- <img class="center-margin radius-all-100 display-block" src="../../../assets/image-resources/gravatar.png" alt=""> -->
                        <div class="yovoads_logo text-right">Yovo<span>Ads</span></div>
                    </div>
                    <div class="col-md-6" style="display: flex; align-items: flex-end; justify-content: flex-end;">
                        <h3 class="text-right font-size-13">your monetization manager</h3>
                    </div>
                </div>

                <div id="login-forgot" class="content-box bg-default" v-if="forgotPassword">
                    <div class="content-box-wrapper pad20A">

                        <div class="form-group mar10T">
                            <label for="exampleInputEmail2">Email address:</label>
                            <div class="input-group">
                                <span class="input-group-addon addon-inside bg-gray">
                                    <i class="glyph-icon icon-envelope-o"></i>
                                </span>
                                <input type="email"
                                        class="form-control outlight"
                                        id="exampleInputEmail2"
                                        placeholder="Enter email"
                                        v-model="resetEmail"
                                        @blur="resetIsValidBlur()"
                                        @keyup="resetIsValidKeyup()"
                                        tabindex="1"
                                        >
                                <transition name="fade">
                                    <div class="alert alert-danger" v-if="errorMsg">{{ errorMsg }}</div>
                                    <div class="alert alert-success" v-else-if="successMsg">{{ successMsg }}</div>
                                </transition>
                            </div>
                        </div>

                        <captcha @setIsValid="val => captchaValid = val" :is-new="true"/>

                        <div class="text-center">
                            <button type="submit"
                                    class="btn btn-md btn-primary"
                                    @click.prevent="sendResetEmail()"
                                    :disabled="!resetIsValid"
                                    tabindex="5"
                            >
                                Recover Password
                            </button>
                            <a href="#"
                                class="btn btn-md btn-link switch-button"
                                title="Cancel"
                                @click.prevent="forgotPassword = false"
                                tabindex="6"
                            >
                                Cancel
                            </a>
                        </div>
                    </div>
                </div>

                <div id="login-form" class="content-box bg-default" v-else>
                    <div class="content-box-wrapper pad20A">
                        <div class="form-group mar10T">
                            <div class="alert alert-danger" v-if="auth.error">{{ auth.error }}</div>
                            <div class="input-group">
                                <span class="input-group-addon addon-inside bg-gray">
                                    <i class="glyph-icon icon-envelope-o"></i>
                                </span>
                                <input type="email"
                                        class="form-control outlight"
                                        id="exampleInputEmail1"
                                        placeholder="Enter email"
                                        v-model="user.login"
                                        tabindex="1"
                                >
                            </div>
                        </div>
                        <div class="form-group mar30B">
                            <div class="input-group">
                                <span class="input-group-addon addon-inside bg-gray">
                                    <i class="glyph-icon icon-unlock-alt"></i>
                                </span>
                                <input type="password"
                                        class="form-control outlight"
                                        id="exampleInputPassword1"
                                        placeholder="Password"
                                        v-model="user.password"
                                        tabindex="2"
                                >
                            </div>
                        </div>

                        <captcha @setIsValid="val => captchaValid = val" :is-new="false"/>

                        <div class="form-group mar30B">
                            <button type="submit"
                                    class="btn btn-block btn-primary"
                                    @click.prevent="login()"
                                    tabindex="5"
                            >
                                Login
                            </button>
                        </div>
                        <!-- <div class="row mar5B">
                            <div class="text-right col-md-12">
                                <a href="#"
                                    class="switch-button"
                                    switch-target="#register"
                                    switch-parent="#register-form"
                                    title="Register User"
                                    @click.prevent="$router.push({name: 'register'})"
                                    tabindex="6"
                                >
                                    Register User
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-right col-md-12">
                                <a href="#"
                                    class="switch-button"
                                    title="Recover password"
                                    @click.prevent="forgotPassword = !forgotPassword"
                                    tabindex="7"
                                >
                                    Forgot your password?
                                </a>
                            </div>
                        </div> -->
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SHA256 from 'crypto-js/sha256';
import MD5 from 'crypto-js/md5';
import Authentication from '../mixins/authentication';
import Captcha from '../plugins/Captcha';

export default {
    mixins: [Authentication],
    data() {
        return {
            forgotPassword: false,
            resetEmail: '',
            errorMsg: '',
            successMsg: '',

            roles: ['Moderator','Admin', 'SuperAdmin'],

            captchaValid: false,

            user: {
                login: '',
                password: ''
            }
        }
    },
    computed: {
        // validation button send form to email address
        resetIsValid(){
            if (
                (this.resetEmail == '') ||
                (/^(?:[a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(?:\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(this.resetEmail) == false)
            ) {
                return false;
            }

            return true;
        }
    },
    methods: {
        login(){
            if(!this.captchaValid) return;

            let hash = this.user.password ? SHA256(this.user.password) : this.user.password;

            let data = JSON.stringify({
                email: this.user.login,
                password: hash.toString()
            });

            axios({
                url: this.$store.getters.getBaseUrl+'/acc/login',
                method: 'POST',
                data: data,
                headers: {
                'Content-Type': 'application/json'
                }
            })
            .then(response => {

                if (response.data.access_token && response.data.id) {
                    if (this.roles.includes(response.data.role)) {
                        this.setUserData(response.data);
                    } else {
                        this.showErrors('ERROR_NO_SUCH_USER');
                    }
                } else {
                    this.showErrors(response.data.Error);
                }
            })
            .catch(e => {
                console.log("error");
                console.log(e);
                this.showErrors(e.response.data.Error);
            })
        },

        setUserData(data){
            let iconUrl = this.$store.getters.getGravatarUrl + MD5(data.email).toString();

            localStorage.setItem('user_id', data.id);
            localStorage.setItem('admin_token', data.access_token);
            localStorage.setItem('api_token', data.api_token);
            localStorage.setItem('email', data.email);
            localStorage.setItem('iconUrl', iconUrl);
            localStorage.setItem('role', data.role);

            this.clearRemeberData();
            this.setAuthData();

            window.location.replace('/');
        },

        showErrors(error) {
            this.auth.error = error;

            setTimeout(() => {
                this.auth.error = '';
            }, 3000);
        },

        // check validation on blur and set message error
        resetIsValidBlur(){
            if (this.resetEmail == '') {
                this.errorMsg = 'Field email is empty!';
            }else if (/^(?:[a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(?:\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(this.resetEmail) == false) {
                this.errorMsg = 'Please enter correct email to field input!';
            }else{
                this.errorMsg = '';
            }
        },

        // check validation on keyup and clear message error
        resetIsValidKeyup(){
            if(this.resetIsValid){
                this.errorMsg = '';
            }
        },

        // set Success message
        setSuccess(msg){
            let _this = this;
            this.successMsg = msg;

            setTimeout(function(){
                _this.successMsg = '';
            }, 3000);
        },

        // send reset password to email
        sendResetEmail(){
            if(this.forgotPassword && !this.captchaValid){
                axios({
                    url: this.$store.getters.getBaseUrl+'/acc/reset/'+this.resetEmail,
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    this.setSuccess('Your reset code send to your email!');
                })
                .catch(e => {
                    console.log("error");
                    console.log(e);
                    this.auth.error = e.response.data.Error;
                })
            }
        }
    },
    components: {
        Captcha
    }
}
</script>

<style scoped>
#login-forgot .alert{
    margin-top: 20px;
}

.yovoads_logo{
    font-family: 'Proxima Nova Rg', sans-serif;
    font-size: 35px;
    font-weight: 700;
    color: #2290e9;
    line-height: 1;
    text-align: center;
}

    .yovoads_logo span{
        color: #00bd22;
    }

.mar30B{
    margin-bottom: 30px;
}

.mar10B{
    margin-bottom: 10px;
}

.mar5B{
    margin-bottom: 5px;
}

.mar30T{
    margin-top: 30px;
}

.mar10T{
    margin-top: 10px;
}

/* animation */
.fade-enter-active{
    transition: all .5s;
}
.fade-enter{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}
/* .fade-leave{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
} */
</style>
