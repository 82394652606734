const lib = {
    data(){
        return {
            cloneArray: [],
            cloneArrayWithObj: [],
            cloneObj: {}
        }
    },
    
    methods: {
        setCloneArray(arr){
            this.cloneArray = [];

            if (arr != undefined) {
                for(let i = 0; i < arr.length; i++){
                    this.cloneArray[i] = arr[i];
                }
            }

            return this.cloneArray;
        },

        setCloneObj(obj){
            this.cloneObj = {};

            if (obj != undefined) {
                let tmp = {};
                
                for(let it in obj){
                    tmp[it] = obj[it];
                }
                    
                this.cloneObj = tmp;
            }

            return this.cloneObj;
        },
        
        setCloneArrayWithObj(arr){
            this.cloneArrayWithObj = [];

            if (arr != undefined) {
                for(let i = 0; i < arr.length; i++){
                    let obj = {};
                    
                    for(let it in arr[i]){
                        obj[it] = arr[i][it];
                    }
                    
                    this.cloneArrayWithObj[i] = obj;
                }
            }

            return this.cloneArrayWithObj;
        },

        setUnique(data, deep){
            deep = deep || false;

            for(let i = 0; i < data.length; i++){
                if (data[i] == undefined) data.splice(i--, 1);

                if (deep && data[i] != undefined) {
                    for(let j = 0; j < data[i].length; j++){
                        if (data[i][j] == undefined) data[i].splice(j--, 1);
                    }
                }
            }

            return data;
        },
        
        getUniqueId(){
            return Math.random().toString(36).substr(2, 9);
        },

        formatNumber(num, formRange = 3, split = ' '){
            let formNum = String(num);
            let formLength = formNum.length;

            if(formNum.match(/[.]+/g)) return num;

            if(formLength > formRange) {
                for(let i = formLength; i > 0; i -= formRange){
                    if(i != formLength) formNum = formNum.substring(0, i) + split + formNum.substring(i);
                }
            }

            return formNum;
        },

        clearHtmlTags(val, tags = []){
            let tag = tags.join('|');

            let flag = '<\/?[^(?:'+tag+')]{1}(?:.|\n)*?>';
            let pattern = new RegExp(flag, "gm");

            return val.replace(pattern, '');
        },

        arrayDiff(a, b){
            let arMerged = a.concat(b);

            return arMerged.filter(val => arMerged.indexOf(val) == arMerged.lastIndexOf(val));
        },

        isArrayEmpty(arr){
            return arr.length == 0;
        },
    }
};

export default lib;