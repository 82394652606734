const lang = [
    // en
    {
        menu: {
            reports: 'Reports',
            index: 'Admin Dashboard',
            online: 'Online',
            statistics: 'Statistics',
            showsClicks: 'Shows, clicks',
            installs: 'Installs',
            rateApp: 'Rate app',
            rateMessage: 'Rate Message',
            cagorts: 'Retention',
            users: 'Users graphics',
            graphics: 'Graphics',
            mainMenu: 'Main menu',
            inventory: 'Inventory',
            adUnit: 'AdUnits',
            group: 'Groups',
            rules: 'Rules',
            application: 'Applications',
            users: 'Users',
            moderationUsers: 'Moderation of users',
            scenario: 'Scenarios',
            exchange: 'Exchange',
            advertiser: 'Advertiser',
            admin: 'Admin',
            globalSettings: 'Global settings',
            type: 'Type',
            network: 'Network',
            relationTypeNetwork: 'Rel. Type Network',
            resetAccount: 'Reset the account',
            moderator: 'Moderator',
            reports: 'Reports',
            getsdk: 'Get SDK',
            tutorial: 'Tutorial',
            howItWorks: 'How it works',
            android: 'Android',
            ios: 'Ios',
            unity: 'Unity',

            onlinePage: 'Online',
            applicationPage: 'Applications',
            adUnitPage: 'AdUnits',
            installsPage: 'Installs',
            cagortsPage: 'Retention',
            usersGraphicsPage: 'Users graphics',
            graphicsPage: 'Graphics',
            rulesPage: 'Rules',
            scenarioPage: 'Scenarios',
            exchangePage: 'Exchange',
            advertiserPage: 'Advertiser',
            usersPage: 'Users',
            relationTypeNetworkPage: 'Rel. Type Network',
            resetAccountPage: 'Reset the account',
            moderatorPage: 'Moderator',

            userSettings: 'User settings',
            userInfo: 'User info',
            cardPay: 'Card pay',
            reportAnError: 'Report an error',
        },
        search: {
            search: 'Search',
            searchTerms: 'Search terms here ...'
        },
        adUnit: {
            listAdunit: 'List AdUnit',
            showing: 'Showing',
            to: 'to',
            of: 'of',
            entries: 'entries',
            id: 'ID',
            image: 'Image',
            identifier: 'Identifier',
            application: 'Identifier',
            locale: 'Locale',
            type: 'AdType',
            adLocale: 'Add Locale',
            title: 'Title',
            description: 'Description',
            action: 'Action',
            tagMark: 'Tag mark',

            addNewAdunit: 'Add new AdUnit',
            addAdunit: 'Add AdUnit',
            productsURLPackage: 'Products URL or package name',
            applications: 'Applications',
            trackSystems: 'Track systems',
            imageOnly: 'Image Only',
            chooseImage: 'Choose Images',
            chooseOS: 'Choose OS',
            chooseBothForms: 'Both Forms',
            chooseLeftForm: 'Left Form',
            chooseRightForm: 'Right Form',
            selectFile: 'OPEN FILE',
            UTMContent: 'UTM content',
            tracking: 'Tracking',
            iconUrl: 'Icon Url',
            iconFile: 'Icon',
            imageUrl: 'Image Url',
            imageUrl169: 'Image Url(16x9)',
            imageUrl43: 'Image Url(4x3)',
            imageUrl11: 'Image Url(1x1)',
            imageUrl34: 'Image Url(3x4)',
            imageUrl916: 'Image Url(9x16)',
            imageFile: 'Image',
            videoUrl: 'Video Url',
            videoFile: 'Video',
            portraitUrl: 'Portrait Url',
            portraitFile: 'Portrait File',
            landscapeUrl: 'Landscape Url',
            landscapeFile: 'Landscape File',
            videoUrl: 'Video Url',
            videoUrl169: 'Video Url(16x9)',
            videoUrl43: 'Video Url(4x3)',
            videoUrl11: 'Video Url(1x1)',
            videoUrl34: 'Video Url(3x4)',
            videoUrl916: 'Video Url(9x16)',

            deleteTitle: 'Delete AdUnit',
            deleteMessage: 'Are you sure you want to remove the AnUnit'
        },
        app: {
            title: 'Title',
            packageName: 'Identifier',
            iTunesID: 'iTunesID',
            playmarketPackageName: 'Playmarket PackageName',
            background: 'Background',
            appType: 'Type',
            category1: 'Age',
            category2: 'Gender',
            category3: 'Categories',
            iconUrl: 'Icon Url',
            iconFile: 'Icon File',
            selectFile: 'Select file',
            categories: 'Categories',
            needBackground: 'Need background',
            backgroundColor: 'Background сolor',
            gravity: 'Gravity',
            sex: 'Sex',
            age: 'Age',
            rewardedOnly: 'Settings for Rewarded Ad',
            needPeriod: 'Need period',
            minimumPeriod: 'Period',
            countPerDay: 'Count per day',
            none: 'NONE',
            applications: 'Applications',
            games: 'Games',
            sites: 'Sites',
            identifierAlreadyExists: 'Such identifier already exists',
            identifierWrongSymbol: 'Invalid character',

            limitation: "Limitation",
            added: "Added",
            typeResource: "Type resource",

            deleteTitle: 'Delete application',
            deleteMessage: 'Are you sure you want to remove the Application'
        },
        group: {
            name: 'Name',
            network: 'Network',
            networkCount: 'Network Count',
            showType: 'Show type',
            showClosed: 'Show closed (from 0 to 5)',
            showClosedInBox: 'Show closed',
            showTime: 'Show time (from 3 to 120)',
            showTimeInBox: 'Show time',
            showTimeScrollbar: 'Show time (from 0 to 3)',
            showTimeMin: 'Show time is less than the minimum',
            showTimeMax: 'Show time is longer than maximum',
            adUnitPriceScrollbar: 'Adunit price (from 0 to 2)',
            adUnitPriceInBox: 'Adunit price',
            adUnitPriceMin: 'Adunit price is less than the minimum',
            adUnitPriceMax: 'Adunit price is longer than maximum',
            UTMterm: 'UTM term',
            infinity: 'Infinity',
            countView: 'Count view',
            adunit: 'AdUnit',
            enabled: 'Enabled',
            countShow: 'Count show',
            type: 'Type',
            edit: 'edit',
            addAdunit: 'add',
            hideAddedBlock: 'Hide - added to this rule',
            hideAddedOtherBlock: 'Hide - added in other rules',

            delete: 'remove from scenario',
            deleteTitle: 'Delete group',
            deleteMessage: 'Are you sure you want to remove the Group',

            addAdunitTitle: 'Add AdUnit in rule',
            addAdunitMessage: 'Are you sure you want to add the Adunit to the Group'
        },
        queue: {
            idx: 'Idx',
            name: 'Name',
            queue: 'Queue',
            order: 'Order',
            application: 'Application',
            UTMterm: 'UTM Campaign',
            networkCount: 'Network count',
            country: 'Country',
            type: 'Type',
            enabled: 'Enabled',
            cycle: 'Cycle',
            addGroup: 'Your rules',
            errorAlredyExists: 'Such an application already exists (Application and Country)',
            adunit: 'Adunit',
            rulesQueue: 'Rules in Scenario',
            showNotExists: 'Show adunit not added in current Scenario',

            deleteTitle: 'Delete scenario',
            deleteMessage: 'Are you sure you want to remove the Scenario',
            deleteMessageGroup: 'Are you sure you want to remove the Group from Scenario',
        },
        statistics: {
            date: 'Date',
            banner: 'Banner',
            app: 'Apps',
            country: 'Country',
            calendar: 'Calendar',
            source: 'Source',
            campaign: 'Campaign',
            term: 'Term',
            content: 'Content',
            network: 'Network',
            productType: 'Product type',
            adtype: 'AdType',
            productid: 'Product ID',
            tagName: 'Tag name',
            version: 'Version',
            rate0: 'Rate0',
            rate1: 'Rate1',
            rate2: 'Rate2',
            rate3: 'Rate3',
            rate4: 'Rate4',
            rate5: 'Rate5',
            stars: 'Stars',
            adunit: 'Adunit',
            count: 'Count',
            today: 'Today',
            yesterday: 'Yesterday',
            lastWeek: 'Last 7 days',
            last14Days: 'Last 14 days',
            last30Days: 'Last 30 days',
            thisMonth: 'This month',
            lastMonth: 'Last month',
            fromStart: 'From start',
            ourRange: 'Our range',
            efficiency: 'Efficiency',
            shows: 'Shows',
            clicks: 'Clicks',
            ctrShows: 'CTR clicks',
            ctr: 'CTR clicks',
            failed: 'Failed',
            rewarded: 'Rewarded',
            revenuePerThousand: 'Revenue per thousand impressions',
            estimationOfIncome: 'Estimation of income',
            showGraphics: 'Show Graphics',
            hideGraphics: 'Hide Graphics',
            android: 'Android',
            ios: 'IOS',
            all: 'All',
            filter: 'Filter',
            params: 'Params',
            paramsTime: 'Params: Time',
            choose: 'Choose',
        },
        moderator: {
            blocked: 'Blocked',
            notConfirmed: 'Not confirmed',
            confirmed: 'Confirmed',
            title: 'Title',
            description: 'Description',
            url: 'Url',
            application: 'Application',
            adUnit: 'Adunit',
            UTMterm: 'UTM term',
            type: 'Type',
            linkDevice: 'Link device',
            linkApplication: 'Link application',
            packageName: 'Package name',
            period: 'Period',
            gravity: 'Gravity',
            category: 'Category',
        },
        type:{
            title: 'Title',
            deleteTitle: 'Delete type',
            deleteMessage: 'Are you sure you want to remove the Type banner',
        },
        network:{
            title: 'Title',
            deleteTitle: 'Delete network',
            deleteMessage: 'Are you sure you want to remove the Network',
        },
        relationTypeNet: {
            title: 'Title',
            network: 'Network',
        },
        exchange: {
            status: 'Status',
            state: 'State',
            countryCount: 'Countries',
            packageName: 'Identifier',
            title: 'Title',
            dataStart: 'Data Start',
            dataStop: 'Data Stop',
            addExchangeTitle: 'Add Adunit to exchange',
            editExchangeTitle: 'Edit Adunit in exchange',
            addExchangeMessage: 'Are you sure you want to add the Adunit to the Exchange',
            typeBanner: 'Type banner',
            osDeviceList: 'OS device list',
            amountViewInDays: 'Amount view in days',
            amountOfTimes: 'Show per day',
            unlimitedDuration: 'Unlimited Duration',
            priceper1000: 'Price for 1000 views per day',
            totalbudget: 'Total application budget',
            dailybudget: 'Daily application budget',
        },
        advertiser: {
            totalBudget: 'Budget',
            dailyBudget: 'Daily budget',
            dailyBudgetLost: 'Spent today',
            rating: 'Rating',
            comment: 'Comment',
            addPopupAdvertiserTitle: 'Add Adunit to advertiser',
            editPopupAdvertiserTitle: 'Edit Adunit in advertiser',
        },
        users: {
            roles: 'Roles',
            editRolesTitle: 'Edit Roles for user',
            editModerationChatTitle: 'Edit moderation for chat',
        },
        online: {
            application: 'Application',
            count: 'Count',
            total: 'Total',
            activeUsers: 'Users',
            bestGames: 'Best games',
            update: 'Update',
            rightNow: 'Right now',
            onMap: 'On the map',

        },
        account: {
            state: 'State',
            role: 'Role',
            email: 'Email',
            isChat: 'Chat moderation',
            lastChatAdmin: 'It is last chat administrator',
            usersChatWithoutAdmin: 'Users of chat do not have any administrator',
        },
        date: {
            day: 'Day',
            week: 'Week',
            mounth: 'Mounth',
        },
        cagorts: {
            version: 'Application version',
            longTimeGame: 'Average game duration',
        },
        sdk: {
            getSdk: 'Get SDK',
            download: 'Download'
        },
        messages: {
            imageSizeNotMatch: 'Image size does not match',
            imageUrlNotCorrect: 'Enter the correct image address',
            imageBlocked: 'Your image is not available',
            videoUrlNotCorrect: 'Enter the correct video address',
            videoBlocked: 'Your video is not available',
        },
        title: 'Title',
        action: 'Action',
        list: 'list',
        showAdunit: 'Show Adunit',
        add: 'add',
        edit: 'edit',
        delete: 'delete',
        visiblePage: 'Visible',
        addPage: 'Add',
        editPage: 'Edit',
        deletePage: 'Delete',
        titlePage: 'Page',
        Delete: 'Delete',
        remove: 'Remove',
        duplicate: 'Duplicate',
        default: 'Default',
        enable: 'Enable',
        disable: 'Disable',
        stateOn: 'New',
        stateOff: 'Added',
        on: 'On',
        off: 'Off',
        close: 'Close',
        CLOSE: 'CLOSE',
        APPLY: 'APPLY',
        save: 'Save',
        clear: 'Clear',
        reopen: 'Save and add more',
        previous: 'Previous',
        next: 'Next',
        required: 'required',
        ID: 'ID',
        token: 'Token',
        timeZone: 'Time zone',
        find: 'Find',
        other: 'Other',
        editProfile: 'Edit profile',
        updateData: 'Update data',
        logout: 'Logout',
        login: 'Login',
        follow: 'Follow',
        notifications: 'NOTIFICATIONS',
        info: 'Info',
        information: 'INFORMATION',
        changePhoto: 'Change photo',
        myAccount: 'My Account',
        closeSidebar: 'Close sidebar',
        notFoundItems: 'Not found items',
        btnStateLow: 'Low',
        btnStateMedium: 'Medium',
        btnStateHigh: 'High',
        btnStateCritical: 'Critical',

        count: 'Count',
        country: 'Country',
        category: 'Category',
        category1: 'Category1',
        category2: 'Category2',
        category3: 'Category3',

        maxChars: 'max 100 characters',
        max20Chars: 'max 20 characters',
        max45Chars: 'max 45 characters',
        max50Chars: 'max 50 characters',
        max64Chars: 'max 64 characters',
        max128Chars: 'max 128 characters',
        max200Chars: 'max 200 characters',
        max255Chars: 'max 255 characters',
        max500kb: 'max size 500 kb',
        max500kbSimple: '500kb',
        max3options: 'max select 3 options',
        size144x144: '144 x 144',
        size144x144Simple: '144x144',
        size610x298: '610 x 298',
        size610x298Simple: '610x298',

        // banner and imageonly
        size640x100: '640 x 100', // add img(320х50), url(_640, _320)
        size640x100Simple: '640x100',

        // banner and not imageonly
        // icon
        size96x96: '96 x 96', // url(_96)
        size96x96Simple: '96x96',
        // image
        size246x120: '246 x 120', // url(_246)
        size246x120Simple: '246x120',

        // interstitial & rewarded
        // icon
        size512х512: '512 х 512', // add img(192х192, 144х144), url(_192, _144)
        size512х512Simple: '512х512',
        // image 16x9
        size1024х500: '1024 х 500', // add img(610х298, 802x392), url(_610, _802)
        size1024х500Simple: '1024х500',
        // image 4x3
        size1280х720: '1280 x 720',
        size1280х720Simple: '1280x720',
        // rewarded
        // image 4x3
        size960х720: '960 x 720',
        size960х720Simple: '960x720',
        // image 1x1
        size1х1Simple: '1x1',
        size1022х1022Simple: '1022х1022',
        size1024х1024Simple: '1024х1024',
        // image 3x4
        size3х4Simple: '3x4',
        size720x960Simple: '720x960',
        // image 9x16
        size9х16Simple: '9x16',
        size500x1024Simple: '500x1024',
        size720x1280Simple: '720x1280',

        month: ['Jan', 'Feb', 'Marсh', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oсt', 'Nov', 'Dec'],

        pageNotFound: 'Page not Found',
        comingSoon: `Get coins for showing other developers ads in your apps. Then use your coins for advertising.<br/><br/>Coming soon... we are doing our best!`,
    },
    // ru
    {
        menu: {
            reports: 'Отчеты',
            index: 'Админ Панель',
            online: 'Онлайн',
            statistics: 'Статистика',
            showsClicks: 'Показы, клики',
            installs: 'Установки',
            rateApp: 'Rate app',
            rateMessage: 'Rate Message',
            cagorts: 'Кагортный',
            users: 'Пользователи',
            moderationUsers: 'Модерация пользователей',
            graphics: 'Графики',
            mainMenu: 'Главное меню',
            inventory: 'Инвентарь',
            adUnit: 'Рекламные блоки',
            group: 'Группы',
            rules: 'Правила',
            application: 'Приложения',
            scenario: 'Сценарии',
            exchange: 'Биржа',
            advertiser: 'Рекламодатель',
            admin: 'Админ',
            globalSettings: 'Глобальные настройки',
            type: 'Тип',
            network: 'Сеть',
            relationTypeNetwork: 'Связь типа и связи',
            resetAccount: 'Сбросить аккаунт',
            moderator: 'Модератор',
            reports: 'Отчеты',
            getsdk: 'Плагин',
            tutorial: 'Туториал',
            howItWorks: 'Как это работает',
            android: 'Android',
            ios: 'Ios',
            unity: 'Unity',

            onlinePage: 'Онлайн',
            applicationPage: 'Приложения',
            adUnitPage: 'Рекламные блоки',
            installsPage: 'Установки',
            cagortsPage: 'Кагортный',
            usersGraphicsPage: 'Пользователи',
            graphicsPage: 'Графики',
            rulesPage: 'Правила',
            scenarioPage: 'Сценарии',
            exchangePage: 'Биржа',
            advertiserPage: 'Рекламодатель',
            usersPage: 'Пользователи',
            relationTypeNetworkPage: 'Связь типа и связи',
            resetAccountPage: 'Сбросить аккаунт',
            moderatorPage: 'Модератор',

            userSettings: 'Настройки пользователя',
            userInfo: 'Информация пользователя',
            cardPay: 'Card pay',
            reportAnError: 'Сообщение об ошибке',
        },
        search: {
            search: 'Поиск',
            searchTerms: 'Поиск элементов здесь ...'
        },
        adUnit: {
            listAdunit: 'Список рекламных блоков',
            showing: 'Показаны',
            to: 'до',
            of: 'из',
            entries: 'записей',
            id: 'ИД',
            image: 'Изображение',
            identifier: 'Identifier',
            application: 'Приложение',
            locale: 'Локаль',
            type: 'Тип',
            adLocale: 'Добавить локаль',
            title: 'Название',
            description: 'Описание',
            action: 'Действия',
            tagMark: 'Метка тега',

            addNewAdunit: 'Добавить новый рекламный блок',
            addAdunit: 'Добавить рекламный блок',
            productsURLPackage: 'Ссылка продукта или название пакета',
            applications: 'Приложения',
            trackSystems: 'Трекинговая система',
            imageOnly: 'Только изображение',
            chooseImage: 'Выберите изображения',
            chooseOS: 'Выберите ОС',
            chooseBothForms: 'Обе формы',
            chooseLeftForm: 'Левая форма',
            chooseRightForm: 'Правая форма',
            selectFile: 'ВЫБРАТЬ ФАЙЛ',
            UTMContent: 'UTM текст',
            tracking: 'Трекинг',
            iconUrl: 'Ссылка иконки',
            iconFile: 'Иконка',
            imageUrl: 'Ссылка изображения',
            imageFile: 'Изображение',
            videoUrl: 'Ссылка видео',
            videoFile: 'Видео',
            portraitUrl: 'Ссылка портретного изображения',
            portraitFile: 'Файл портретного изображения',
            landscapeUrl: 'Ссылка пейзажного изображения',
            landscapeFile: 'Файл пейзажного изображения',
            videoUrl: 'Ссылка на видео',
            videoUrl169: 'Ссылка на видео(16x9)',
            videoUrl43: 'Ссылка на видео(4x3)',
            videoUrl11: 'Ссылка на видео(1x1)',
            videoUrl34: 'Ссылка на видео(3x4)',
            videoUrl916: 'Ссылка на видео(9x16)',

            deleteTitle: 'Удалить рекламный блок',
            deleteMessage: 'Вы уверены, что хотите удалить рекламный блок'
        },
        app: {
            title: 'Название',
            packageName: 'Имя пакета',
            iTunesID: 'iTunesID',
            playmarketPackageName: 'Playmarket PackageName',
            background: 'Цвет фона',
            appType: 'Тип',
            category1: 'Возраст',
            category2: 'Пол',
            category3: 'Категории',
            iconUrl: 'Ссылка иконки',
            iconFile: 'Файл иконки',
            selectFile: 'Выбрать файл',
            categories: 'Категории',
            needBackground: 'Нужен фон',
            backgroundColor: 'Цвет фона',
            gravity: 'Позиция',
            sex: 'Пол',
            age: 'Возраст',
            rewardedOnly: 'Настройки для вознагражденной рекламы',
            needPeriod: 'Нужна периодичность',
            minimumPeriod: 'Периодичность',
            countPerDay: 'Количество в день',
            none: 'НЕТ',
            applications: 'Приложения',
            games: 'Игры',
            sites: 'Сайты',
            identifierAlreadyExists: 'Такой индентификатор уже существует',
            identifierWrongSymbol: 'Не корректный символ',

            limitation: "Ограничение",
            added: "Добавленный",
            typeResource: "Тип ресурса",

            deleteTitle: 'Удалить приложение',
            deleteMessage: 'Вы уверены, что хотите удалить приложение'
        },
        group: {
            name: 'Название',
            network: 'Сеть',
            networkCount: 'Количество повторов',
            showType: 'Тип показа',
            showClosed: 'Показ закрытия (с 0 до 5)',
            showClosedInBox: 'Показ закрытия',
            showTime: 'Время показа (с 3 до 120)',
            showTimeInBox: 'Время показа',
            showTimeScrollbar: 'Время показа (с 0 до 3)',
            showTimeMin: 'Время показа меньше минимального',
            showTimeMax: 'Время показа больше максимального',
            adUnitPriceScrollbar: 'Цена рекламного блока (с 0 до 2)',
            adUnitPriceInBox: 'Цена adunit',
            adUnitPriceMin: 'Цена рекламного блока меньше минимального',
            adUnitPriceMax: 'Цена рекламного блока больше максимального',
            UTMterm: 'UTM данные',
            infinity: 'Бесконечно',
            countView: 'Количество показов',
            adunit: 'Рекламные блоки',
            enabled: 'Включено',
            countShow: 'Количество показов',
            type: 'Тип',
            edit: 'ред.',
            addAdunit: 'доб.',
            hideAddedBlock: 'Скрыть, добавленные в это правило',
            hideAddedOtherBlock: 'Скрыть, добавленные в другие правила',

            delete: 'удалить из сценария',
            deleteTitle: 'Удалить группу',
            deleteMessage: 'Вы уверены, что хотите удалить группу',

            addAdunitTitle: 'Добавить рекламный блок в правило',
            addAdunitMessage: 'Вы уверены, что хотите добавить рекламный блок в группу'
        },
        queue: {
            idx: 'Индекс',
            name: 'Название',
            queue: 'Очередь',
            order: 'Сортировка',
            application: 'Приложение',
            UTMterm: 'UTM Данные',
            networkCount: 'Количество сети',
            country: 'Страна',
            type: 'Тип',
            enabled: 'Включен',
            cycle: 'Зациклен',
            addGroup: 'Ваши правила',
            errorAlredyExists: 'Такое приложение уже существует (Приложение и Страна)',
            adunit: 'Рекламные блоки',
            rulesQueue: 'Правила в сценарии',
            showNotExists: 'Показать рекламные блоки, не добавленные в текущий сценарий',

            deleteTitle: 'Удалить сценарий',
            deleteMessage: 'Вы уверены, что хотите удалить сценарий',
            deleteMessageGroup: 'Вы уверены, что хотите удалить группу из сценария',
        },
        statistics: {
            date: 'Дата',
            banner: 'Баннер',
            app: 'Приложения',
            country: 'Страна',
            calendar: 'Календарь',
            source: 'Ресурс',
            campaign: 'Компания',
            term: 'Срок',
            content: 'Описание',
            network: 'Сеть',
            productType: 'Тип продукта',
            adtype: 'AdType',
            productid: 'ИД продукта',
            tagName: 'Имя тега',
            version: 'Версия',
            rate0: 'Рейтинг0',
            rate1: 'Рейтинг1',
            rate2: 'Рейтинг2',
            rate3: 'Рейтинг3',
            rate4: 'Рейтинг4',
            rate5: 'Рейтинг5',
            stars: 'Рейтинг',
            adunit: 'Рекламный блок',
            count: 'Количество',
            today: 'Сегодня',
            yesterday: 'Вчера',
            lastWeek: 'Последние 7 дней',
            last14Days: 'Последние 14 дней',
            last30Days: 'Последние 30 дней',
            thisMonth: 'Этот месяц',
            lastMonth: 'Последний месяц',
            fromStart: 'С самого начала',
            ourRange: 'Свой диапазон',
            efficiency: 'Эффективность',
            shows: 'Показы',
            clicks: 'Клики',
            ctrShows: 'CTR клики',
            ctr: 'CTR клики',
            failed: 'Неудачный',
            rewarded: 'Награжденный',
            revenuePerThousand: 'Доход на тысячу показов',
            estimationOfIncome: 'Оценка доходов',
            showGraphics: 'Показать график',
            hideGraphics: 'Скрыть график',
            android: 'Андроид',
            ios: 'IOS',
            all: 'Все',
            filter: 'Фильтр',
            params: 'Параметры',
            paramsTime: 'Параметры: Время',
            choose: 'Выбрать',
        },
        moderator: {
            blocked: 'Заблокировать',
            notConfirmed: 'Не подтвердить',
            confirmed: 'Подтвердить',
            title: 'Название',
            description: 'Описание',
            url: 'URL',
            application: 'Приложение',
            adUnit: 'Рекламный блок',
            UTMterm: 'UTM контент',
            type: 'Тип',
            linkDevice: 'Ссылка на устройство',
            linkApplication: 'Ссылка на приложение',
            packageName: 'Имя приложения',
            period: 'Период',
            gravity: 'Позиция',
            category: 'Категория',
        },
        type:{
            title: 'Название',
            deleteTitle: 'Удалить тип',
            deleteMessage: 'Вы уверены, что хотите удалить тип',
        },
        network: {
            title: 'Название',
            deleteTitle: 'Удалить сеть',
            deleteMessage: 'Вы уверены, что хотите удалить сеть',
        },
        relationTypeNet: {
            title: 'Название',
            network: 'Сеть',
        },
        exchange: {
            status: 'Статус',
            state: 'Состояние',
            countryCount: 'Страны',
            packageName: 'Имя пакета',
            title: 'Название',
            dataStart: 'Дата начала',
            dataStop: 'Дата окончания',
            addExchangeTitle: 'Добавить рекламный блок в биржу',
            editExchangeTitle: 'Редактировать рекламный блок в бирже',
            addExchangeMessage: 'Вы уверены, что хотите добавить рекламный блок в биржу',
            typeBanner: 'Тип рекламных блоков',
            osDeviceList: 'Спиок OS приложений',
            amountViewInDays: 'Количество показов в днях',
            amountOfTimes: 'Показов в день',
            unlimitedDuration: 'Неограниченная продолжительность',
            priceper1000: 'Цена за 1000 показов в день',
            totalbudget: 'Общий бюджет приложения',
            dailybudget: 'Дневной бюджет приложения',
        },
        advertiser: {
            totalBudget: 'Бюджет',
            dailyBudget: 'Дневной бюджет',
            dailyBudgetLost: 'Потрачено сегодня',
            rating: 'Рейтинг',
            comment: 'Комментарий',
            addPopupAdvertiserTitle: 'Добавить рекламный блок в рекламодателя',
            editPopupAdvertiserTitle: 'Редактировать рекламный блок в рекламодателе'
        },
        users: {
            roles: 'Роли',
            editRolesTitle: 'Изменить роль для пользователя',
            editModerationChatTitle: 'Редактирование модерации чата',
        },
        online: {
            application: 'Приложение',
            count: 'Количество',
            total: 'Общее количество',
            activeUsers: 'Пользователи',
            bestGames: 'Лучшие игры',
            update: 'Обновить',
            rightNow: 'Прямо сейчас',
            onMap: 'На карте',
        },
        account: {
            state: 'Состояние',
            role: 'Роль',
            email: 'Email',
            isChat: 'Модерация чата',
            lastChatAdmin: 'Это последний администратор чата',
            usersChatWithoutAdmin: 'Пользователям чата не назначен администратор',
        },
        date: {
            day: 'День',
            week: 'Неделя',
            mounth: 'Месяц',
        },
        cagorts: {
            version: 'Версия приложения',
            longTimeGame: 'Средняя продолжительность игры',
        },
        sdk: {
            getSdk: 'Получить SDK',
            download: 'Скачать'
        },
        messages: {
            imageSizeNotMatch: 'Размер изображения не совпадает',
            imageUrlNotCorrect: 'Введите корректный адрес изображения',
            imageBlocked: 'Ваше изображение не доступно',
            videoUrlNotCorrect: 'Введите корректный адрес видео',
            videoBlocked: 'Ваше видео не доступно',
        },
        title: 'Название',
        action: 'Действие',
        list: 'список',
        showAdunit: 'Крос. блоки',
        add: 'добавить',
        edit: 'ред.',
        delete: 'удалить',
        visiblePage: 'Видим.',
        addPage: 'Доб.',
        editPage: 'Ред.',
        deletePage: 'Удал.',
        titlePage: 'Страница',
        Delete: 'Удалить',
        remove: 'Удалить',
        duplicate: 'Дубликат',
        default: 'По-умолчанию',
        enable: 'Включить',
        disable: 'Выключить',
        stateOn: 'Новый',
        stateOff: 'Добавленный',
        on: 'Включен',
        off: 'Выключен',
        close: 'Закрыть',
        CLOSE: 'ЗАКРЫТЬ',
        APPLY: 'ПРИМЕНИТЬ',
        save: 'Сохранить',
        clear: 'Очистить',
        reopen: 'Сохранить и добавить',
        previous: 'Предыдущий',
        next: 'Следующий',
        required: 'обязательно',
        follow: 'Следить',
        notifications: 'УВЕДОМЛЕНИЯ',
        info: 'Информация',
        information: 'Информация',
        ID: 'ИДЕНТИФИКАТОР',
        token: 'Токен',
        timeZone: 'Временная зона',
        find: 'Найти',
        other: 'Остальные',
        editProfile: 'Редактировать профиль',
        updateData: 'Обновить данные',
        logout: 'Вытий',
        login: 'Войти',
        changePhoto: 'Изменить фото',
        myAccount: 'Мой аккаунт',
        closeSidebar: 'Закрыть сайдбар',
        notFoundItems: 'Элементов не найдено',
        btnStateLow: 'Маленький',
        btnStateMedium: 'Средний',
        btnStateHigh: 'Высокий',
        btnStateCritical: 'Критический',

        count: 'Количество',
        country: 'Страна',
        category: 'Категория',
        category1: 'Категория1',
        category2: 'Категория2',
        category3: 'Категория3',

        maxChars: 'максимум 100 символов',
        max20Chars: 'максимум 20 символов',
        max45Chars: 'максимум 45 символов',
        max50Chars: 'максимум 50 символов',
        max64Chars: 'максимум 64 символа',
        max128Chars: 'максимум 128 символов',
        max200Chars: 'максимум 200 символов',
        max255Chars: 'максимум 255 символа',
        max500kb: 'максимум 500 kb',
        max500kbSimple: '500kb',
        max3options: 'максимум выбрать 3 опции',
        size144x144: '144 x 144',
        size144x144Simple: '144x144',
        size610x298: '610 x 298',
        size610x298Simple: '610x298',

        // banner and imageonly
        size640x100: '640 x 100', // add img(320х50), url(_640, _320)
        size640x100Simple: '640x100',

        // banner and not imageonly
        // icon
        size96x96: '96 x 96', // url(_96)
        size96x96Simple: '96x96',
        // image
        size246x120: '246 x 120', // url(_246)
        size246x120Simple: '246x120',

        // interstitial & rewarded
        // icon
        size512х512: '512 х 512', // add img(192х192, 144х144), url(_192, _144)
        size512х512Simple: '512х512',
        // image 16x9
        size1024х500: '1024 х 500', // add img(610х298, 802x392), url(_610, _802)
        size1024х500Simple: '1024х500',
        // image 4x3
        size1280х720: '1280 x 720',
        size1280х720Simple: '1280x720',
        // rewarded
        // image 4x3
        size960х720: '960 x 720',
        size960х720Simple: '960x720',
        // image 1x1
        size1х1Simple: '1x1',
        size1022х1022Simple: '1022х1022',
        size1024х1024Simple: '1024х1024',
        // image 3x4
        size3х4Simple: '3x4',
        size720x960Simple: '720x960',
        // image 9x16
        size9х16Simple: '9x16',
        size500x1024Simple: '500x1024',
        size720x1280Simple: '720x1280',

        month: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],

        pageNotFound: 'Страница не найдена',
        comingSoon: `Получите монеты от показа рекламных блоков других разработчиков в ваших приложениях. Затем используйте ваши монеты для рекламы.<br/><br/>Скоро будет... мы делаем все возможное!`,
    }
];

export default lang;
