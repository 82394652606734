<template>
    <section>
        <div class="panel group-list">
            <div class="panel-body">
                <div class="group-box-wrapper">
                    {{ auth.error }}
                    <div ref="tableHeight">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-group table-group-header" id="datatable-group">
                            <thead>
                                <tr>
                                    <th class="col_img">
                                        <router-link :to="{name: 'groupAdd'}" tag="button" class="btn btn-success btn-add">
                                            <i class="glyph-icon icon-plus"></i>
                                        </router-link>
                                    </th>

                                    <th class="col_title">
                                        <div class="no_padding_right" style="display: flex; justify-content: flex-end;">
                                            <div style="margin-right: 20px; width: 250px;">
                                                <MultiSelectIcons categories-alt="network list"
                                                            :categories-title="''"
                                                            :get-category-list="getNetworkList"
                                                            :setDefaultCategory="getNetwork"
                                                            :countItem=-1
                                                            :placeholder="getLang.statistics.network"
                                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                                            styleBlockSelect="margin-bottom: 4px;"
                                                            :is-required="false"
                                                            :categoriesStatus="status.network"
                                                            @setCategoryStatus="val => updateStatusNetwork(val)"
                                                            @setCategory="val => setNetwork(val)"
                                                            @removeCategory="val => setNetwork(val)"
                                                            >
                                                </MultiSelectIcons>
                                            </div>
                                            <div style="margin-right: 20px; width: 130px;">
                                                <MultiSelectIcons categories-alt="type banner list"
                                                        :categories-title="''"
                                                        :get-category-list="getTypeBannerList"
                                                        :setDefaultCategory="getTypeBanner"
                                                        :countItem=-1
                                                        :placeholder="getLang.statistics.adtype"
                                                        styleInput="padding-right: 0px; padding-left: 0px;"
                                                        styleBlockSelect="margin-bottom: 4px;"
                                                        :is-required="false"
                                                        :categoriesStatus="status.adtype"
                                                        @setCategoryStatus="val => updateStatusAdtype(val)"
                                                        @setCategory="val => setTypeBanner(val)"
                                                        @removeCategory="val => setTypeBanner(val)"
                                                        >
                                                </MultiSelectIcons>
                                            </div>

                                            <div id="datatable_filter" class="dataTables_filter">
                                                <input class="form-control outlight"
                                                        style="padding-right: 30px;"
                                                        :placeholder="getLang.search.search+'...'"
                                                        aria-controls="datatable-example"
                                                        type="search"
                                                        ref="searchInput"
                                                        @input="searchGroup($event)"
                                                        @blur="searchBlurGroup($event)"
                                                        >
                                                <a class="btn search_clear"
                                                    v-if="searchValue.length > 0"
                                                    @click.prevent="searchClear()"
                                                    >
                                                    <i class="glyph-icon icon-remove"></i>
                                                </a>
                                            </div>

                                            <!-- <div class="flex-block" style="vertical-align: middle;">
                                                <a href="#"
                                                    class="btn btn-success btn-search"
                                                    @click.prevent="searchByGroups()"
                                                    >
                                                    <i class="glyph-icon icon-search"></i>
                                                </a>
                                            </div> -->
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <!-- <div style="background-color: #aaa;"><img src="assets/preloader/oval.svg"/></div> -->

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <div class="flex-container-start">
                            <div v-for="(item, inx) in groupView" :key="inx" class="flex-block-group">
                                <div>
                                    <div v-if="item.name" class="title short_text">
                                        {{ clearHtmlTags(item.name) }}
                                    </div>
                                    <div class="logo">
                                        <span class="bs-badge badge-success float-right" v-if="item.enabled">&nbsp;</span>
                                        <span class="bs-badge badge-danger float-right" v-else>&nbsp;</span>
                                        <img :src="getNetworkLogo(item.network)" />
                                    </div>
                                    <hr>
                                    <div class="flex-container-between description">
                                        <div>
                                            <div class="short_text" v-if="getNetworkName(item.network) != stateCrosspromo">
                                                {{ getLang.group.countShow }}:
                                                <span v-if="item.network_count == -1">&infin;</span>
                                                <span v-else>{{ item.network_count }} </span>
                                            </div>
                                            <div class="short_text" v-if="getNetworkName(item.network) == stateCrosspromo">{{ getLang.group.showType }}: {{ item.show_type }}</div>
                                            <div class="short_text" v-if="getNetworkName(item.network) == stateCrosspromo && getTypeBannerName(item.type) == 'banner'">{{ getLang.group.showTimeInBox }}: {{ item.show_time }}</div>
                                            <div class="short_text">{{ getLang.group.type }}: {{ getTypeBannerName(item.type) }}</div>
                                        </div>
                                        <div>
                                            <a href="#" class="btn btn-default" @click.prevent="setGroupEdit($event, item.idx)">
                                                {{ getLang.group.edit }}
                                            </a>
                                            <!-- <a href="#"
                                                class="btn btn-success add_adunit_group"
                                                @click.prevent="setAdunitGroupAdd($event, item.idx, item.name, getLang.group.addAdunitTitle, getLang.group.addAdunitMessage, '/api/queue/group/adblock/add', item.type)"
                                                v-if="getNetworkName(item.network) == stateCrosspromo"
                                                >
                                                {{ getLang.group.addAdunit }}
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <popup-adunit-group :popup-title="popupAddName"
                        :edit-id="popupAddId"
                        :url-delete="popupAddUrlDelete"
                        url-redirect="group"
                        :title="popupAddTitle"
                        :message="popupAddMsg"
                        :type-banner="popupAddTypeBanner"
                        @setUpdata="val => isUpdate = val"
                        >
                </popup-adunit-group>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import PopupAdunitGroup from '../plugins/PopupAdunitGroup';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            groupList: [],
            groupView: [],
            groupListForSearch: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 57,
            othersHeight: 89,

            isRemeber: false,
            isUpdate: false,

            tableHeightDiv: '',
            searchValue: '',

            searchBy: {
                network: [],
                typeBanner: []
            },

            stateCrosspromo: 'CrossPromotion',

            osActive: 1,
            popupAddId: 0,
            popupAddName: '',
            popupAddTypeBanner: 0,
            popupAddUrlDelete: '',
            popupAddTitle: '',
            popupAddMsg: '',
            popupAdunitGroup: {
                value: [],
                alt: ''
            },

            status: {
                network: false,
                adtype: false
            },

            networkList: [],
            typeBannerList: [],
            logos: {},
            logosNetworkAndType: null
        }
    },
    watch: {
        getGroupSearch(){
            this.$store.dispatch('setRemeberGroupSearchAction', this.searchValue);
        }
    },
    created(){
        this.searchValue = this.$store.getters.getRemeberGroupSearch;

        this.getGroups();
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        this.$nextTick(function(){
            this.$refs.searchInput.value = this.searchValue;
            this.tableHeightDiv = this.$refs.tableHeight;
        });

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getGroupSearch(){
            return this.searchValue;
        },

        getOsActive(){
            this.osActive = this.$store.getters.getOsActive;

            return this.osActive;
        },

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                this.typeBannerList = [...this.$store.getters.getTypeBannerList];

                this.typeBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogosNetworkAndType[val.name]};
                });
            }

            return this.typeBannerList;
        },

        getTypeBanner(){
            this.searchBy.typeBanner = this.$store.getters.getRemeberGroupTypeBanner;

            this.searchByGroups();
            this.setHeaderHeight();

            return this.searchBy.typeBanner;
        },

        getNetwork(){
            this.searchBy.network = this.$store.getters.getRemeberGroupNetwork;

            this.searchByGroups();
            this.setHeaderHeight();

            return this.searchBy.network;
        },

        getNetworkList(){
            if (this.networkList.length == 0) {
                this.networkList = this.setCloneArrayWithObj(this.$store.getters.getNetworkList);

                this.networkList.forEach((item, i , arr) => {
                    arr[i] = {id: item.id, name: item.name, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogosNetworkAndType[item.name]};
                });
            }

            return this.networkList;
        },
        getLogos(){
            this.logos = this.$store.state.logos.icons;

            return this.logos;
        },

        getLogosNetworkAndType() {
            this.logosNetworkAndType = this.$store.state.logos.networkAndTypeIcons;

            return this.logosNetworkAndType;
        }
    },
    methods: {
        setHeaderHeight(){
            this.$nextTick(function(){
                this.headerHeight = this.tableHeightDiv.offsetHeight;

                this.setTableListHeight();
            });
        },

        clearStatus(){
            this.status.adtype = false;
            this.status.network = false;
        },

        /*--- update status ---*/
        updateStatusNetwork(val){
            this.clearStatus();

            this.status.network = val;
        },

        updateStatusAdtype(val){
            this.clearStatus();

            this.status.adtype = val;
        },
        /*--- end update status ---*/

        // get networkById
        getNetworkLogo(inx){
            let name = '';
            let logo = '';

            if(this.getNetworkList.length){
                name = this.getNetworkName(inx);

                if(name != null) logo = this.getLogos[name];
            }

            return logo;
        },

        getNetworkName(id){
            let name = this.getNetworkList.find(item => item.id === id);

            name = ((name != undefined && name.name != undefined) ? name.name : null);

            return name;
        },

        // set network filter
        setNetwork(val){
            this.$store.dispatch('setRemeberGroupNetworkAction', val);
            this.searchBy.network = val;
        },

        // set type banner filter
        setTypeBanner(val){
            this.$store.dispatch('setRemeberGroupTypeBannerAction', val);
            this.searchBy.typeBanner = val;
        },

        // get name type banner by id
        getTypeBannerName(id){
            let data = this.getTypeBannerList.find(item => item.id == id);

            if(data == undefined || data.name == undefined) return '';

            return data.name;
        },

        // get list Groups
        getGroups(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/groups?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.groupList = response.data.groups;
                this.groupView = this.groupList.filter(item => {
                    return item.queueid == 0 && item.osid == this.getOsActive;
                });

                if(this.searchValue.length > 0) this.searchGroup({target: {value: this.searchValue}});
            })
            .catch(e => {
                this.errorsMessage(e, 'error get groups');
            });
        },

        // add id Group for edit
        setGroupEdit(e, id){
            this.$store.commit('setGroupEditId', id);
            this.$router.push({name: 'groupEdit'});
        },

        // set height for table list Group
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        searchGroup(e){
            let _this = this;
            let res;

            if(!this.groupListForSearch.length) this.groupListForSearch = this.groupList;

            res = this.groupListForSearch.filter(function(item){

                let isName = false;
                _this.searchValue = e.target.value || '';

                if(item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isName;
            });

            this.groupList = res;
        },

        searchBlurGroup(e){
            if(e.target.value == '' && this.groupListForSearch.length > 0) {
                this.groupListForSearch = [];
            }
        },

        searchClear(){
            this.$refs.searchInput.value = '';
            this.searchGroup({target: {value: ''}});
        },

        searchByGroups(){
            let _this = this;

            let tmpGroupList = this.groupList.filter(val => {
                return val.osid == this.getOsActive && val.queueid == 0;
            });

            if (this.searchBy.network.length < 1 && this.searchBy.typeBanner.length < 1) {
                this.groupView = tmpGroupList;
                return;
            } else if (this.searchBy.network.length < 1) {
                this.groupView = tmpGroupList.filter(function(item){
                    return _this.searchBy.typeBanner.find(val => val == item.type) != undefined;
                });

                return;
            } else if (this.searchBy.typeBanner.length < 1) {
                this.groupView = tmpGroupList.filter(function(item){
                    return _this.searchBy.network.find(val => val == item.network) != undefined;
                });

                return;
            }

            this.groupView = tmpGroupList.filter(function(item){
                return (_this.searchBy.network.find(val => val == item.network) != undefined) && (_this.searchBy.typeBanner.find(val => val == item.type) != undefined);
            });
        },

        setAdunitGroupAdd(e, id, name, title, msg, urlDelete, type){
            this.popupAddId = id;
            this.popupAddName = name;
            this.popupAddTypeBanner = type;
            this.popupAddTitle = title;
            this.popupAddMsg = msg;
            this.popupAddUrlDelete = urlDelete;

            this.$store.commit('modals/setPopupDelete', true);
        }
    },
    components: {
        Pagination,
        MultiSelectIcons,
        PopupAdunitGroup,
        MultiSelect
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    /*---*/
    .col_img{
        width: 13%;
        width: calc(164px);
        padding-right: 0px!important;
        padding-left: 20px!important;
    }

        .col_img .icon-question{
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0;
        }

    .col_title{
        width: 87%;
        padding-left: 0px!important;
        padding-top: 5px!important;
    }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    section section{
        width: 300px;
    }

    .group-list{
        border: 0px none;
    }

        .group-list .panel-body{
            padding: 0px;
        }

    .group-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        position: relative;
        z-index: 101;
        top: 0px;
        right: 0px;
        width: 170px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 170px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 170px;
        }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -16px;
        left: 33px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: 5px;
        z-index: 100;
    }

    .table-group th{
        vertical-align: top;
    }

    .btn-add, .btn-edit, .btn-search{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-group-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-group-header th{
            padding: 5px 13px 0px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 50px;
        }

            .table-group-header th input{
                width: 170px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 0px;
        }

            .table-head-content .form-group{
                /* width: 33%; */
                margin-bottom: 0px;
            }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .search_clear{
        position: absolute;
        right: 10px;
        top: 12px;
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        width: auto;
        min-width: auto;
        padding: 0px;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .add_adunit_group{
        margin-top: 10px;
    }

    .no_padding>div:first-child{
        padding-left: 0px;
    }

    .no_padding>div:last-child{
        padding-right: 0px;
    }

    .no_padding_right>div:last-child{
        padding-right: 0px;
    }

    /* flexbox */
    .flex-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    .flex-container-start{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 13px 0;
    }

        .flex-block-group{
            /* width: 20%; */
            width: 300px;
            height: 175px;
            padding: 0 10px 10px 0;
        }

            .flex-block-group>div{
                border: 1px solid #ddd;
                border-radius: 5px;
                height: 100%;
            }

            .flex-block-group .logo, .flex-block-group .title, .flex-block-group .description{
                box-sizing: border-box;
                padding: 0 8px;
            }

            .flex-block-group .title{
                margin-top: 5px;
            }

            .flex-block-group .logo{
                margin-top: 5px;
            }

            .flex-block-group hr{
                margin-top: 10px;
                margin-bottom: 10px;
            }

                .flex-block-group .logo img{
                    border: 1px solid #ddd;
                    border-radius: 10px;
                }

    .flex-container-between{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

        .description div{
            font-size: 14px;
            line-height: 19px;
        }

        .description>div:first-child{
            text-align: left;
            width: 75%;
            width: calc(100% - 60px);
            box-sizing: border-box;
            padding: 0 10px 0 0;
        }

        .description>div:last-child{
            text-align: right;
            width: 25%;
            width: calc(60px);
        }

            .description>div:last-child a{
                width: 60px;
                font-size: 14px;
            }
    /* end flexbox */

</style>
