<template>
    <div id="page-content-wrapper" :style="getPopupDelete || getPopupEdit || getPopupAdd || getPopupAdvertiserRating || getPopupAdvertiserComment || getPopupAdvertiserState ? 'z-index: inherit;' : ''">
        <div id="page-content" :style="getCloseSidebar ? 'margin-left: 64px!important;' : ''">
            <div class="container">
                <div class="clear"></div>
                <div id="an_slide">
                    <transition name="skide" mode="out-in">
                        <router-view></router-view>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            popupDelete: false,
            popupAdd: false,
            popupEdit: false,
            popupAdvertiserRating: false,
            popupAdvertiserComment: false,
            popupAdvertiserState: false,
            closeSidebar: this.$store.getters.getCloseSidebar
        }
    },
    computed: {
        getPopupDelete(){
            this.popupDelete = this.$store.state.modals.popupDelete;
            return this.popupDelete;
        },
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupAdd;
            return this.popupAdd;
        },
        getPopupEdit(){
            this.popupEdit = this.$store.state.modals.popupEdit;
            return this.popupEdit;
        },
        getPopupAdvertiserRating(){
            this.popupAdvertiserRating = this.$store.state.modals.popupAdvertiserRating;
            return this.popupAdvertiserRating;
        },
        getPopupAdvertiserComment(){
            this.popupAdvertiserComment = this.$store.state.modals.popupAdvertiserComment;
            return this.popupAdvertiserComment;
        },
        getPopupAdvertiserState(){
            this.popupAdvertiserState = this.$store.state.modals.popupAdvertiserState;
            return this.popupAdvertiserState;
        },
        getCloseSidebar(){
            this.closeSidebar = this.$store.getters.getCloseSidebar;

            return this.closeSidebar;
        }
    }
}
</script>

<style>
    #an_slide{
      position: relative;
    }
    #an_slide>section{
      position: absolute;
      width: 100%;
    }
    .clear{
      clear: both;
      height: 1px;
      float: none;
    }
    .skide-enter-active {
      animation: skide-in .5s;
    }
    .skide-leave-active {
      animation: skide-in .5s reverse;
    }
    @keyframes skide-in {
        0% {
            opacity: 0;
            /* left: 100vw; */
        }
        50% {
            /* left: -3vw; */
        }
        100% {
            opacity: 1;
            /* left: 0; */
        }
    }
</style>
