import { Line } from 'vue-chartjs'; // mixins
//const { reactiveProp } = mixins

export default {
    extends: Line,
    //mixins: [reactiveProp],
    props: ['options', 'chartData'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, {
                responsive: true,
                // tooltips: {
                //     mode: 'index',
                // },
                maintainAspectRatio: false,
                type: 'line',
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Data One'
                        },
                        stacked: true,
                        position: 'left',
                        id: 'One',
                        ticks: {
                            fontColor: '#f87979',
                            min: 0,
                            maxTicksLimit: 5,
                            callback: function(value, index, values) {
                                if(parseInt(value) >= 1000){
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                } else {
                                    return value;
                                }
                            }
                        }
                    },
                    {
                        display: true,
                        scaleLabel: {
                            display: true,
                            labelString: 'Data Two'
                        },
                        stacked: true,
                        position: 'right',
                        id: 'Two',
                        ticks: {
                            fontColor: '#ab47bc',
                            min: 0,
                            maxTicksLimit: 5,
                            callback: function(value, index, values) {
                                if(parseInt(value) >= 1000){
                                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                } else {
                                    return value;
                                }
                            }
                        }
                    }
                    ]
                }
            });
        }
    },
    mounted () {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            type: 'line',
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                    display: true,
                        labelString: 'Data One'
                    },
                    stacked: true,
                    position: 'left',
                    id: 'One',
                    ticks: {
                        fontColor: '#f87979',
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                },
                {
                    display: true,
                    scaleLabel: {
                    display: true,
                        labelString: 'Data Two'
                    },
                    stacked: true,
                    position: 'right',
                    id: 'Two',
                    ticks: {
                        fontColor: '#ab47bc',
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                }
                ]
            }
        }); // this.options
    }
}
