<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
    >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
        >
            <div class="modal-dialog" @click.stop="''">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader">
                        <div style="display: flex;">
                            <button type="button"
                                    class="close float-left"
                                    id="close_modal"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    @click.prevent="setPopupAddHide()"
                            >
                                ×
                            </button>
                            <h4 class="modal-title nowrap" style="font-size: 13px; color: #AFAFAF; position: relative; left: 28px; top: -8px;">
                                {{ title }} - <strong>{{ getAccountEmail }}</strong>:&nbsp;<a href="#" @click.prevent="copyData(getAccountEmail)"><i class="glyph-icon tooltip-button icon-copy"></i></a>
                            </h4>
                        </div>

                        <div class="filter">
                            <div class="filter__add row">
                                <button type="button"
                                        :class="'btn-danger'"
                                        class="btn btn-add"
                                        @click.prevent="setPopupAddHide()"
                                        v-if="isNotChangedAll"
                                >
                                    <i class="glyph-icon icon-close"></i>
                                </button>
                                <button type="button"
                                        :class="'btn-success'"
                                        class="btn btn-add"
                                        @click.prevent="addItemDB()"
                                        v-else
                                >
                                    <i class="glyph-icon icon-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">

                                <div class="form_adunit w50 pt10 pl10">
                                    <div class="h30" style="margin-right: 0px;">
                                        <div class="title_category_page"><h2>Chat of users visibility</h2></div>
                                    </div>

                                    <div class="scroll" :style="'max-height: ' + pagesListHeight + 'px; margin-right: 0px;'">
                                        <div class="roles_item" v-for="(item, key) in getChatUserList" :key="key">
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData[item.id])"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault[item.id])"
                                                    @update="val => {accountsRolesData[item.id] = val}"
                                                    :disabled="Boolean(anotherUsersChat.find(v => v.userId === item.id))"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4><strong>{{ item.email }}</strong> <span style="font-size: 12px;">{{ getAnotherUsersChatTitle(item.id) }}</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="col-md6" style="padding-right: 35px;">&nbsp;</div>
                        <div class="col-md6">&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import InputRoles from '../plugins/InputRoles';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        urlAdded: String,
        urlRedirect: String,
        title: String,
        opened: String,
        accId: Number,
        accEmail: String,
        accRole: {
            type: String,
            default: 'User'
        },
        accState: {
            type: String,
            default: 'New'
        }
    },

    data(){
        return {
            lang: Lang,

            rolesList: ['User', 'Moderator', 'Admin', 'SuperAdmin'],

            //pagesList: [],
            //pagesView: [],

            email: '',

            otherHeight: 60,
            differentHeight: 67,
            modalBodyHeight: 2000,
            pagesListHeight: 200,

            searchValue: '',

            timeout: 0,

            userId: null,

            anotherUsersChat: [],
            userList: [],

            accountsRolesData: {},

            accountsRolesDefault: {},

            exchangeList: [],

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    watch: {
        opened(){
            let prom = this.getUsersListDB();
            prom.then(() => this.getUsersChatDB());
            this.userId = this.accId;
        },
    },

    // created(){
    //     this.getPagesListDB();
    // },

    mounted(){
        let _this = this;
        this.setPagesListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setPagesListHeight();
        });
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getChatUserList(){
            let chatUsers = this.userList.filter(v => v.is_chat === 0);

            return chatUsers;
        },

        getAccountEmail(){
            this.email = this.accEmail;

            return this.email;
        },

        isNotChangedAll(){
            let notChanged = true;
            let keys = Object.keys(this.accountsRolesData);

            for(let i = 0; i < keys.length; i++){
                notChanged = Boolean(this.accountsRolesDefault[keys[i]]) === Boolean(this.accountsRolesData[keys[i]]) && notChanged;
            }

            return notChanged;
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        // is valid form add Adunit
        isValid(){
            return this.accountsRolesData.length > 0;
        },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupDelete;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                let heightFilter = 0;
                //if(this.pagesList.length > 0) heightFilter = -20;

                this.$nextTick(() => {
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.pagesListHeight = mdlBodyHeight - (this.differentHeight + heightFilter);
                });
            }

            return this.popupAdd;
        },
    },
    methods: {
        isChanged(key){
            return this.accountsRolesData[key] !== this.accountsRolesDefault[key];
        },

        copyData(link){
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
console.log('clipboard');
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
            } else if (document.queryCommandSupported('copy')) {
                let textArea = document.createElement("textarea");
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                let res = document.execCommand('copy');

                document.body.removeChild(textArea);
console.log('exec command copy');
            }
        },

        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        setIsEscClose(val){
            this.$store.commit('setIsEscClose', val);
        },

        searchClear(){
            this.$refs.searchInput.value = '';
        },

        // get list PagesList
        // getPagesListDB(){
        //     axios({
        //         url: this.$store.getters.getBaseUrl+'/admin/api/pages/get?at='+this.getAccessToken,
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     })
        //     .then(response => {
        //         this.pagesList = response.data.pages;
        //     })
        //     .catch(e => {
        //         this.errorsMessage(e, 'error get pages');
        //     });
        // },

        /* popup */
        setPopupAddHide(){
            if (this.isValid) {
                if (confirm('Are you sure you want to close the popup without saving the data?')) {
                    this.$emit('setOpenAdd', false);
                    this.$store.commit('modals/setPopupDelete', false);
                }
            } else {
                this.$emit('setOpenAdd', false);
                this.$store.commit('modals/setPopupDelete', false);
            }
        },

        getAnotherUsersChatTitle(accId){
            let u = this.anotherUsersChat.find(v => v.userId === accId);
            let title = (u && u.adminEmail && u.adminId ? `Chat moderator: ${u.adminEmail} (id ${u.adminId})` : '');

            return title;
        },

        getUsersListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/accounts?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.userList = response.data;
                let keys = Object.keys(this.userList);
                let anotherAdminChat = this.userList.filter(v => v.is_chat === 1 && v.id !== this.accId);

                this.anotherUsersChat = [];
                anotherAdminChat.forEach(v => this.getAnotherUsersChatDB(v.id, v.email));

                for(let i = 0; i < keys.length; i++){
                    this.$set(this.accountsRolesDefault, this.userList[keys[i]]['id'], false);
                    this.$set(this.accountsRolesData, this.userList[keys[i]]['id'], false);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error get users');
            });
        },

        getAnotherUsersChatDB(accId, email){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/chat/user/' + accId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let userChat = response.data.chat_users;

                userChat.forEach(v => {
                    if (v.visible === 1) {
                        this.$set(this.anotherUsersChat, this.anotherUsersChat.length, {adminId: accId, adminEmail: email, userId: v.id});
                    }
                });
            })
            .catch(e => {
                this.errorsMessage(e, 'error get users for admin chat');
            });
        },

        getUsersChatDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/chat/user/' + this.accId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let userChat = response.data.chat_users;
                let keys = Object.keys(userChat);

                for(let i = 0; i < keys.length; i++){
                    this.$set(this.accountsRolesDefault, userChat[keys[i]]['id'], userChat[keys[i]]['visible']);
                    this.$set(this.accountsRolesData, userChat[keys[i]]['id'], userChat[keys[i]]['visible']);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error get users');
            });
        },

        addItemDB(){
            if (confirm('Are you sure?')) {
                let tmpUsers = [];

                for (let tmp in this.accountsRolesData) {
                    if (this.accountsRolesData[tmp]) tmpUsers.push({managerId: Number(this.accId), userId: Number(tmp), visible: 1});
                    else if (this.isChanged(tmp)) tmpUsers.push({managerId: Number(this.accId), userId: Number(tmp), visible: 0});
                }

                let data = JSON.stringify({
                    roles: tmpUsers,
                    accId: this.accId
                });

//console.log(data);

                axios({
                    url: this.$store.getters.getBaseUrl + '/admin/api/chat/useredit?at=' + this.getAccessToken,
                    method: 'POST',
                    data: data,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your moderation chat changed!'});

                    this.$emit('setUpdata', true);
                    this.isUpdate = true;

                    setTimeout(() => { this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''}) }, 3000);

                    axios({
                        url: 'https://chat.yovoads.com/updateusers',
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(() => {
                        console.log(`your users of chat updated`);
                    })
                    .catch(e => {
                        console.log(e, 'error users of chat update');
                    });

                    this.setPopupAddHide();
                })
                .catch(e => {
                    this.errorsMessage(e, 'error moderation chat');
                    this.setPopupAddHide();
                });
            }
        },
        /* end popup */

        // set height for adinut list in group
        setPagesListHeight(){
            this.$nextTick(function(){
                let heightFilter = 0;
                let otherHeight = 124;
                let maxHeight = 650;
                let winHeight = window.innerHeight;
                let currentHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);

                //if(this.pagesList.length > 0) heightFilter = -20;
                if(currentHeight == 0) currentHeight = maxHeight;

                if (this.$refs.modalBody) {
                    if(winHeight < currentHeight + otherHeight) this.$refs.modalBody.style.height = (winHeight - otherHeight) + 'px';
                    else this.$refs.modalBody.style.height = maxHeight+'px';
                }

                this.pagesListHeight = currentHeight - (37 + heightFilter);

                if(this.pagesListHeight < 0) this.pagesListHeight = 0;
            });
        }
    },
    components: {
        MultiSelectIcons,
        MultiSelect,
        InputRoles,
    }
}
</script>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.yellow_outline{
    box-shadow: 1px 1px 5px 1px rgba(255,255,0,.9);
}

.search_clear{
    position: absolute;
    right: 20px;
    z-index: 100;
    top: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0px;
}

.scroll{
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 10px;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.modal-dialog{
    margin: 30px auto 0px;
    width: 590px!important;
    max-width: 590px!important;
}

    .modal-content{
        position: relative;
        background-color: #fff;
        z-index: 1000;
    }

        .modal-header{
            height: 63px;
            padding: 9px 20px 9px 0px;
            align-items: flex-start;
        }

        .modal-body{
            padding: 0 15px;
        }

        .modal-footer{
            padding: 0 15px;
        }

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 0px;
    }

.w100{
    width: 100%;
}

.w100px{
    width: 200px;
}

.w50{
    width: 500px;
    box-sizing: border-box;
}

.pl10{
    padding-left: 10px;
    margin-left: 0px!important;
}

.pt10{
    padding-top: 10px;
}

.h30{
    height: 30px;
    margin-right: 20px;
}

.title_category_page{
    float: left;
    width: 100%;
}

.title_page{
    float: left;
    width: 90%;
}

.title_moder{
    float: left;
    width: 10%;
}

/* .modal-body .w50:last-child{
    width: 420px;
    padding-right: 10px;
} */

.close{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
}

.action_popup{
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.filter{
    display: flex;
}

    .filter__item, .filter__add{
        margin: 0 5px;
    }

        .filter__add .btn-add{
            width: 42px;
            height: 42px;
            padding: 0 10px;
        }

            .filter__add .btn-add i{
                line-height: 42px;
                font-size: 24px;
            }

    .filter__item{
        width: 217px;
        position: absolute;
        left: 243px;
        top: 9px;
    }

        .form-group{
            margin-bottom: 0px;
        }

    .filter__search{
            margin-right: 8px;
            position: absolute;
            top: 19px;
            left: 14px;
            height: 34px;
            padding: 0px 10px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 34px;
                width: 100%;
                transition: all .5s;
            }

.form_adunit{
    margin-left: 6px;
    padding-right: 10px;
    width: 550px;
    box-sizing: border-box;
    /* margin-right: 10px; */
}

.roles_item{
    margin-left: 0px!important;
}

/*--- modal window ---*/
    .title_and_close{
        display: flex;
        align-items: flex-end;
        height: 44px;
    }

        .title_and_close h4{
            margin-left: 43px;
        }

.modal-footer>div{
    width: 100%;
}

.add_adunit_in_group{
    line-height: 30px;
    height: 30px;
    min-width: 56px!important;
    width: 56px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .add_adunit_in_group i::before{
        font-size: 18px;
    }

.adunit_in_group{
    width: 570px;
    min-height: 54px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex!important;
}

.form_adunit .adunit_in_group{
    width: 100%;
}

    .adunit_in_group tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    td.icon{
        width: 170px;
        overflow: hidden;
    }

        img.icon{
            height: 51px;
            width: auto;
            margin-right: 10px;
        }

        img.image{
            width: 102px;
            height: auto;
            vertical-align: middle;
        }

    .adunit_in_group .info{
        width: 338px;
        padding-right: 10px;
        display: flex;
        align-items: flex-end;
    }

    .form_adunit .adunit_in_group .info{
        width: 265px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 270px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: -4px;
            margin-bottom: 1px;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

    .form_adunit .action{
        justify-content: flex-end;
        align-items: flex-end;
    }

    .action{
        width: 60px;
        display: flex;
        align-items: center;
    }


        .action i.glyph-icon{
            font-size: 24px;
        }

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.form_adunit .flex-block-adunit-group{
    padding: 0 0 4px 13px;
}

.flex-block-adunit-group{
    padding: 0 13px 4px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;

}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

.roles_label h3{
    float: left;
    margin-right: 5px;
}

.roles_item{
    margin: 5px 0 5px 15px;
    display: flex;
}

    .roles_item h4 {
        margin-top: 5px;
    }

    .roles_item .checkbox {
        margin-top: 5px;
        margin-right: 20px;
    }

    .roles_item .checker {
        margin-right: 5px;
    }

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
