<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
                >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
                    >
            <div class="modal-dialog" @click.stop="''" style="width: 800px!important; max-width: 800px!important;">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader">
                        <button type="button"
                                class="close float-left"
                                id="close_modal"
                                data-dismiss="modal"
                                aria-hidden="true"
                                @click.prevent="setPopupAddHide()"
                                >
                            ×
                        </button>
                        <h4 class="modal-title nowrap">{{ title }}</h4>
                        <!-- <button class="btn btn-alt btn-hover btn-danger" @click.prevent="removeItem()">{{ getLang.remove }}</button> -->
                        <div class="action_popup">
                            <button type="button"
                                    class="btn btn-primary"
                                    @click.prevent="editItem()"
                                    :style="!isValid ? 'opacity: 0.5; cursor: default;' : ''"
                                    v-if="isValid"
                            >
                                {{ getLang.save }}
                            </button>
                            <button type="button"
                                    class="btn btn-danger"
                                    @click.prevent="setPopupAddHide()"
                                    v-else
                                    >
                                {{ getLang.close }}
                            </button>
                        </div>
                    </div>
                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="form_adunit">
                                    <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>

                                        <div class="form-group no-border form_group_wrap">
                                            <div class="col-md-12">
                                                <textarea class="form-control" v-model="adunitExchangeData.comment"></textarea>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">

                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelect from '../plugins/MultiSelect';
//import DatePicker from 'vue2-datepicker';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        urlAdded: String,
        urlRedirect: String,
        title: String,
        exchange: Object,
        opened: String
    },

    data(){
        return {
            lang: Lang,

            otherHeight: 60,
            modalBodyHeight: 200,
            adunitListHeight: 0,

            timeout: 0,

            // time: [new Date(), new Date()],
            // timeStart: '2019-01-01 00:00:00',
            // timeEnd: '2099-01-01 00:00:00',

            // stateList: [
            //     {name: 'NotConfirmed'},
            //     {name: 'Confirmed'},
            //     {name: 'Blocked'},
            // ],

            adunitExchangeData: {
                id: null,
                comment: ''
            },

            defaultExchangeData: {
                comment: ''
            },

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    mounted(){
        let _this = this;
        this.setAdunitListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setAdunitListHeight();
        });
    },

    watch: {
        exchange(){
            this.defaultExchangeData.comment = this.adunitExchangeData.comment = '';

            this.getAdunitExchange();
        },

        opened(){
            this.defaultExchangeData.comment = this.adunitExchangeData.comment = '';

            this.getAdunitExchange();
        }
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        // getStatDate(){
        //     if (!this.adunitExchangeData.unlimitedDuration.state && this.time[0]) {
        //         this.adunitExchangeData.startdate = this.getFormatDate(this.time[0]);
        //     }

        //     if (!this.adunitExchangeData.unlimitedDuration.state && this.time[1]) {
        //         this.adunitExchangeData.enddate = this.getFormatDate(this.time[1]);
        //     }

        //     return this.adunitExchangeData.startdate + ' ' + this.adunitExchangeData.enddate;
        // },

        // is valid form add Adunit
        isValid(){
            return (this.adunitExchangeData.comment !== this.defaultExchangeData.comment);
        },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupAdvertiserComment;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                this.$nextTick(function(){
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.modalBodyHeight = (this.$refs.modalHeader.offsetHeight + mdlBodyHeight);
                });
            }

            return this.popupAdd;
        },

        // validation form
        // isValidForm(){
        //     let result = true;

        //     result = (this.adunitExchangeData.totalbudget > 0 || this.adunitExchangeData.totalbudget === 0 || this.adunitExchangeData.totalbudget === '0') && (this.adunitExchangeData.dailybudget > 0 || this.adunitExchangeData.dailybudget === 0 || this.adunitExchangeData.dailybudget === '0') && (this.adunitExchangeData.priceper1000 > 0 || this.adunitExchangeData.priceper1000 === 0 || this.adunitExchangeData.priceper1000 === '0');

        //     return result;
        // }
    },
    methods: {
        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        // set default form
        // setFormDefault(){
        //     this.adunitExchangeData.enabled = this.defaultExchangeData.enabled;
        //     this.adunitExchangeData.state = this.defaultExchangeData.state;
        //     this.adunitExchangeData.per_days = this.defaultExchangeData.per_days;
        //     this.adunitExchangeData.shows_count = this.defaultExchangeData.shows_count;
        //     this.adunitExchangeData.totalbudget = this.defaultExchangeData.totalbudget;
        //     this.adunitExchangeData.dailybudget = this.defaultExchangeData.dailybudget;
        //     this.adunitExchangeData.priceper1000 = this.defaultExchangeData.priceper1000;
        //     this.adunitExchangeData.startdate = this.defaultExchangeData.startdate;
        //     this.adunitExchangeData.enddate = this.defaultExchangeData.enddate;
        // },

        getAdunitExchange(){
            this.adunitExchangeData.id = this.exchange.adblock;
            // this.adunitExchangeData.title = this.exchange.title;
            // this.adunitExchangeData.name = this.exchange.name;
            // this.adunitExchangeData.icon = this.exchange.icon;
            // this.adunitExchangeData.image = this.exchange.image;

            if(this.exchange.country_cats != undefined) {
                this.defaultExchangeData.comment = this.adunitExchangeData.comment = this.exchange.country_cats[0].comment;
            //     this.defaultExchangeData.enabled = this.adunitExchangeData.enabled = this.exchange.country_cats[0].enabled;
            //     this.defaultExchangeData.state = this.adunitExchangeData.state = this.exchange.country_cats[0].state;
            //     this.defaultExchangeData.per_days = this.adunitExchangeData.per_days = this.exchange.country_cats[0].per_days;
            //     this.defaultExchangeData.shows_count = this.adunitExchangeData.shows_count = this.exchange.country_cats[0].shows_count;

            //     this.defaultExchangeData.totalbudget = this.adunitExchangeData.totalbudget = this.exchange.country_cats[0].totalbudget;
            //     this.defaultExchangeData.dailybudget = this.adunitExchangeData.dailybudget = this.exchange.country_cats[0].dailybudget;
            //     this.defaultExchangeData.priceper1000 = this.adunitExchangeData.priceper1000 = this.exchange.country_cats[0].priceper1000;

            //     this.defaultExchangeData.startdate = this.adunitExchangeData.startdate = this.exchange.country_cats[0].startdate;
            //     this.defaultExchangeData.enddate = this.adunitExchangeData.enddate = this.exchange.country_cats[0].enddate;
            //     this.time[0] = new Date(this.exchange.country_cats[0].startdate);
            //     this.time[1] = new Date(this.exchange.country_cats[0].enddate);

            //     if(this.adunitExchangeData.startdate == this.timeStart && this.adunitExchangeData.enddate == this.timeEnd) this.adunitExchangeData.unlimitedDuration.state = true;
            //     else this.adunitExchangeData.unlimitedDuration.state = false;
            }

            return this.adunitExchangeData;
        },

        // changeUnlimitedDuration(){
        //     this.adunitExchangeData.unlimitedDuration.state = !this.adunitExchangeData.unlimitedDuration.state;

        //     if (this.adunitExchangeData.unlimitedDuration.state) {
        //         this.adunitExchangeData.startdate = this.timeStart;
        //         this.adunitExchangeData.enddate = this.timeEnd;
        //     }
        // },

        // get formatted date (YYYY-mm-dd)
        // getFormatDate(time, flag = false){
        //     let month = (time.getMonth() + 1);
        //     let date = time.getDate();
        //     let hours = time.getHours();
        //     let minutes = time.getMinutes();
        //     let seconds = time.getSeconds();

        //     if(month < 10) month = '0' + month;
        //     if(date < 10) date = '0' + date;

        //     if(hours < 10) hours = '0' + hours;
        //     if(minutes < 10) minutes = '0' + minutes;
        //     if(seconds < 10) seconds = '0' + seconds;

        //     if(flag === true) return time.getFullYear() + '-' + month + '-' + date;
        //     return time.getFullYear() + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
        // },

        /* popup */
        setPopupAddHide(){
            if (this.isValid) {
                if (confirm('Are you sure you want to close the popup without saving the data?')) {
                    this.$store.commit('modals/setPopupAdvertiserComment', false);
                }
            } else {
                this.$store.commit('modals/setPopupAdvertiserComment', false);
            }
        },

        editItem(e){
            if(!this.isValid) return;

            let data = JSON.stringify({
                adblock: Number(this.adunitExchangeData.id),
                comment: this.adunitExchangeData.comment,
                // shows_count: Number(this.adunitExchangeData.shows_count),
                // per_days: Number(this.adunitExchangeData.per_days),
                // enabled: this.adunitExchangeData.enabled,
                // state: this.adunitExchangeData.state,
                // totalbudget: this.adunitExchangeData.totalbudget,
                // dailybudget: this.adunitExchangeData.dailybudget,
                // priceper1000: this.adunitExchangeData.priceper1000,
                // startdate: (this.adunitExchangeData.unlimitedDuration.state ? this.timeStart : this.getFormatDate(this.time[0])),
                // enddate: (this.adunitExchangeData.unlimitedDuration.state ? this.timeEnd : this.getFormatDate(this.time[1])),
            });

            this.editItemDB(data);

            this.setPopupAddHide();
        },

        // addItemDB(data, done){
        //     axios({
        //         url: this.$store.getters.getBaseUrl + '/api/exblock/add?at=' + this.getAccessToken,
        //         method: 'POST',
        //         data: data,
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     })
        //     .then(response => {
        //         if (done) {
        //             let uniqueId = this.getUniqueId();
        //             this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your country added to Exchange!'});
        //             let _this = this;

        //             this.$emit('setUpdata', true);
        //             this.isUpdate = true;

        //             setTimeout(function(){
        //                 _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
        //             }, 3000);
        //         }
        //     })
        //     .catch(e => {
        //         this.errorsMessage(e, 'error added adunit to exchange');
        //     });
        // },

        editItemDB(data){
            axios({
                url: this.$store.getters.getBaseUrl + '/api/advblocks/comment/edit?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Adunit comment edited in Advertiser!'});
                let _this = this;

                this.$emit('setUpdata', true);
                this.isUpdate = true;

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error edited adunit comment in advertiser');
            });
        },

        // removeItem(){
        //     let isRemove = confirm('Are you sure?');

        //     if(isRemove) this.removeItemDB(this.$store.getters.getBaseUrl + '/api/exblock/delete/'+this.adunitExchangeData.id+'?at=' + this.getAccessToken);
        // },

        // removeItemDB(url){
        //     axios({
        //         url: url,
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     })
        //     .then(response => {
        //         let uniqueId = this.getUniqueId();
        //         this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Exchange is removed!'});
        //         let _this = this;

        //         this.$emit('setUpdata', true);
        //         this.isUpdate = true;

        //         setTimeout(function(){
        //             _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
        //         }, 3000);

        //         this.setPopupAddHide();
        //     })
        //     .catch(e => {
        //         this.errorsMessage(e, 'error remove exchange');
        //     });
        // },

        // set height for adinut list in group
        setAdunitListHeight(){
            this.adunitListHeight = window.innerHeight - (this.otherHeight + this.modalBodyHeight);

            if(this.adunitListHeight < 0) this.adunitListHeight = 0;
            if(this.adunitListHeight >500) this.adunitListHeight = 450;

            return this.adunitListHeight;
        }
    },
    components: {
        MultiSelect,
        //DatePicker
    }
}
</script>

<style>
.mx-datepicker-popup{
    height: 365px!important;
}
</style>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.scroll{
    overflow: auto;
    margin-right: 10px;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 65px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

.modal-dialog{
    margin: 30px auto 0px;
}

.modal-body{
    max-height: 560px;
}

.modal-content{
    position: relative;
    background-color: #fff;
    z-index: 1000;
}

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 15px 20px 10px;
    }

.w100{
    width: 100%;
}

.w50{
    width: 450px;
    box-sizing: border-box;
}

.close{
    margin-right: 20px;
}

.action_popup{
    text-align: right;
    position: relative;
}

.filter{
    display: flex;
}

    .filter div{
        width: 220px;
        margin: 0 5px;
    }

    .filter__search{
            margin-right: 8px;
            position: relative;
            top: 0px;
            right: 0px;
            height: 44px;
            width: 144px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 44px;
                width: 144px;
                position: absolute;
                right: 0px;
                transition: all .5s;
            }

            .filter__search input:focus{
                width: 233px;
            }

.form_adunit{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}

.modal-header{
    display: flex;
    align-items: flex-end;
}

.modal-footer>div{
    width: 100%;
}

.adunit_in_group{
    width: 410px;
}

    td.icon{
        width: 140px;
    }

        img.icon{
            height: 40px;
            width: auto;
        }

    .info{
        width: 260px;
        box-sizing: border-box;
        padding: 0 10px;
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 190px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

.modal-title{
    margin-left: 28px;
    position: relative;
    bottom: -5px;
}

.exchange_enabled{
    width: 100%;
}

#close_modal{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.flex-block-adunit-group{
    padding: 0 13px 10px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

.bordered-row > .form-group{
    padding: 0 0 13px;
}

.adunit_item{
    padding: 0px!important;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 44px;
    display: flex;
    align-items: center;
}

.bordered-row > .form-group:last-child{
    padding: 0 0 0;
}

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
