<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_group"
                                                ref="saveGroup"
                                                @click.prevent="setGroupDB()"
                                                :disabled="!isValidForm"
                                                >
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <!-- <button class="btn btn-alt btn-hover btn-warning float-right close_group"
                                                @click.prevent="closeGroupForm()"
                                                v-if="$route.query.isqueue == undefined"
                                                >
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button> -->
                                        <button class="btn btn-alt btn-hover btn-danger delete_group float-right close_group"
                                                @click.prevent="setPopupDelete($event, getLang.group.deleteTitle, getLang.queue.deleteMessageGroup, '/api/queue/group/delete/' + $route.query.queueActive + '/', 'queue')"
                                                v-if="$route.query.isqueue == 'queue'"
                                                >
                                            <span>{{ getLang.group.delete }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-danger float-right close_group"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-sm"
                                                @click.prevent="setPopupDelete($event, getLang.group.deleteTitle, getLang.group.deleteMessage, '/api/group/delete/', 'group')"
                                                v-if="$route.query.isqueue == undefined"
                                                >
                                            <span>{{ getLang.delete }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9" style="text-align: right;">
                                    <label class="control-label label_on_input"
                                            style="top: -18px;"
                                            :style="$store.getters.getLanguageActive == 1 ? 'right: 61px;' : 'right: 70px;'"
                                            :title="groupData.enabled.alt"
                                    >
                                        {{ getLang.group.enabled }}
                                    </label>
                                    <div :class="groupData.enabled.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        @click.prevent="groupData.enabled.value = !groupData.enabled.value"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.group.name + ' (' + getLang.maxChars + ')'">
                                        {{ getLang.group.name }}
                                    </label>
                                    <input class="form-control outlight" id="" :placeholder="''" type="text" v-model="groupData.name.value">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.name + ' (' + getLang.maxChars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <label class="control-label label_on_input" :title="groupData.typeBanner.alt">
                                        {{ getLang.group.type }}
                                    </label>
                                    <!-- <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ groupData.typeBanner.value }}</span>
                                        <select class="custom-select" v-model="groupData.typeBanner.value" disabled>
                                            <option :value="el.name" v-for="(el, ind) in getTypeBannerList" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div> -->
                                    <MultiSelectIcons categories-alt="type banner list"
                                            :categories-title="''"
                                            :get-category-list="getTypeBannerList"
                                            :setDefaultCategory="groupData.typeBanner.value"
                                            :countItem="1"
                                            :placeholder="getLang.statistics.adtype"
                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                            styleBlockSelect="margin-bottom: 0px;"
                                            :is-update-category-list="true"
                                            :is-default-required="false"
                                            :is-disabled="true"
                                            @setCategory="val => groupData.typeBanner.value = val"
                                            @removeCategory="val => groupData.typeBanner.value = val"
                                            >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.type" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <img :src="getNetworkLogo(groupData.network.value)" />
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <label class="control-label label_on_input">
                                        {{ getLang.adUnit.chooseOS }}
                                    </label>
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getOsActive }}</span>
                                        <select class="custom-select" disabled="disabled"></select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.chooseOS" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="!getCheckCrosspromo">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <label class="control-label label_on_input"
                                                style="top: -18px;"
                                                :style="$store.getters.getLanguageActive == 1 ? 'right: 52px;' : 'right: 76px;'"
                                                :title="groupData.infinity.alt"
                                        >
                                            {{ getLang.group.infinity }}
                                        </label>
                                        <div :class="groupData.infinity.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                            @click.prevent="groupData.infinity.value = !groupData.infinity.value"
                                            style="margin-top: 5px;"
                                            >
                                            <div class="bootstrap-switch-container">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="groupData.networkCount.alt"> {{ getLang.group.networkCount }}</label>
                                        <input class="form-control outlight" type="number" step="1" min="1" :disabled="groupData.infinity.value" @input="setNetworkCount($event)" :value="getNetworkCount" required>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.networkCount" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>

                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="getCheckCrosspromo">
                                    <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                    <div class="col-sm-9">
                                        <label class="control-label label_on_input" :title="groupData.showType.alt">
                                            {{ getLang.group.showType }}
                                        </label>
                                        <div class="selector" style="width: 82px;">
                                            <span style="width: 60px; -moz-user-select: none;">{{ getShowTypeText }}</span>
                                            <select class="custom-select" @change="selectShowType($event)" v-model="groupData.showType.value">
                                                <option :value="el.value" v-for="(el, ind) in getShowTypeList" :key="ind">{{ el.name }}</option>
                                            </select>
                                            <i class="glyph-icon icon-caret-down"></i>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showType" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>

                            <!-- AdUnit price for Admob, Facebook + Banner, Inter, Rewarded -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="(getCheckAdmob || getCheckFacebook) && (getTypeBanner == 'interstitial' || getTypeBanner == 'banner' || getTypeBanner == 'rewarded')">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <div class="alert alert-danger" style="margin-bottom: 0px;" v-if="groupData.adUnitPrice.value < 0">{{ getLang.group.adUnitPriceMin }}</div>
                                        <div class="alert alert-danger" style="margin-bottom: 0px;" v-if="groupData.adUnitPrice.value > 2">{{ getLang.group.adUnitPriceMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <label class="control-label label_on_input" :title="groupData.adUnitPrice.alt"> {{ getLang.group.adUnitPriceScrollbar }}</label>
                                                <input class="form-control" type="text" v-model="groupData.adUnitPrice.value" :disabled="true"/>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 0"
                                                >
                                                    <span>{{ getLang.btnStateLow }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 1 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 1"
                                                >
                                                    <span>{{ getLang.btnStateMedium }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 2 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 2"
                                                >
                                                    <span>{{ getLang.btnStateHigh }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.adUnitPriceScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End AdUnit price for Admob, Facebook + Banner, Inter, Rewarded -->

                            <!-- Show time for Banner -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="(getCheckCrosspromo || getCheckOtherNetwork || getCheckFacebook || getCheckAdmob) && getTypeBanner == 'banner'">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <div class="alert alert-danger margin-top-20" v-if="groupData.showTime.value < 3">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-20" v-if="groupData.showTime.value > 120">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <label class="control-label label_on_input" :title="groupData.showTime.alt">{{ getLang.group.showTime }}</label>
                                        <input class="form-control outlight" :placeholder="getLang.group.showTime+'...'" type="number" step="1" min="3" max="120" v-model="groupData.showTime.value" required>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTime" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show time for Banner -->

                            <!-- Show closed for Inter -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="(getCheckCrosspromo || getCheckOtherNetwork) && getTypeBanner == 'interstitial'">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value < 0">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value > 5">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label label_on_input" :title="groupData.showTime.alt"> {{ getLang.group.showClosed }}</label>
                                                <input class="form-control" type="text" :disabled="true" v-model="groupData.showTime.value">
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 0"
                                                >
                                                    <span>{{ getLang.btnStateLow }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 1 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 1"
                                                >
                                                    <span>{{ getLang.btnStateMedium }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 3 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 3"
                                                >
                                                    <span>{{ getLang.btnStateHigh }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 5 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 5"
                                                >
                                                    <span>{{ getLang.btnStateCritical }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTimeScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show closed for Inter -->

                            <!-- Show closed for Rewarded -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="(getCheckCrosspromo || getCheckOtherNetwork) && getTypeBanner == 'rewarded'">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value < 0">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value > 10">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label label_on_input" :title="groupData.showTime.alt"> {{ getLang.group.showClosed }}</label>
                                                <input class="form-control" type="text" :disabled="true" v-model="groupData.showTime.value">
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 0"
                                                >
                                                    <span>0</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 5 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 5"
                                                >
                                                    <span>5</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 8 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 8"
                                                >
                                                    <span>8</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 10 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 10"
                                                >
                                                    <span>10</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTimeScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show closed for Rewarded -->
                        </form>
                    </div>
                </div>

                <popup :popup-title="groupData.name.value"
                        :edit-id="getGroupEditId"
                        :url-delete="popupUrlDelete"
                        :url-redirect="popupUrlRedirect"
                        :title="popupTitle"
                        :message="popupMsg"
                        >
                </popup>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import Popup from '../plugins/Popup';
import Lib from '../mixins/lib';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            logos: null,
            networkAndTypeLogos: null,

            sendFormStatus: '',
            checkCrosspromo: false,
            checkAdmob: false,
            checkFacebook: false,
            checkOtherNetwork: false,

            default: {
                showTime: 30,
                adUnitPrice: 0
            },

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 84,

            backPrevLink: '',

            saveGroupDiv: '',

            logos: {},
            networkList: [],
            osList: [],
            typeBannerList: [],

            groupEditId: 0,

            stateCrosspromo: 'CrossPromotion',
            stateExchange: 'Exchange',
            stateAdvertisement: 'Advertisement',
            stateAdmob: 'Admob',
            stateFacebook: 'Facebook',

            popupUrlDelete: '',
            popupTitle: '',
            popupMsg: '',
            popupUrlRedirect: '',

            groupData: {
                id: {
                    value: 0
                },
                name: {
                    value: '',
                    alt: ''
                },
                typeBanner: {
                    id: 0,
                    value: [],
                    alt: 'Type banner',
                },
                network: {
                    value: '0',
                    alt: 'Network',
                    text: ''
                },
                networkCount: {
                    value: 1,
                    alt: ''
                },
                os: {
                    value: 1
                },
                showType: {
                    value: '',
                    alt: '',
                    text: ''
                },
                showTime: {
                    value: 30,
                    alt: ''
                },
                adUnitPrice: {
                    value: 0,
                    alt: ''
                },
                enabled: {
                    value: true,
                    alt: ''
                },
                infinity: {
                    value: false,
                    alt: ''
                }
            }
        }
    },
    created(){
        this.getGroupByID();

        this.$store.commit('setBackPrevLink', 'group');
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы group

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(function(){
            this.saveGroupDiv = this.$refs.saveGroup;
        });
    },
    destroyed(){
        this.$store.commit('setBackPrevLink', '');
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        // get id application
        getGroupEditId(){
            this.groupEditId = this.$store.getters.getGroupEditId;

            if(this.groupEditId == 0) this.$router.push({name: 'group'});

            return this.groupEditId;
        },

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                let tBannerList = [...this.$store.getters.getTypeBannerList];

                tBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getNetworkAndTypeLogos[val.name]};
                });
            }
            return this.typeBannerList;
        },

        getNetworkAndTypeLogos(){
            this.networkAndTypeLogos = this.$store.state.logos.networkAndTypeIcons; // Local

            return this.networkAndTypeLogos;
        },

        // get value type banner (default first option from list)
        getTypeBanner(){
            return this.groupData.typeBanner.value[0];
        },

        getLogos(){
            this.logos = this.$store.state.logos.icons;

            return this.logos;
        },

        // network list
        getNetworkList(){
            this.networkList = this.$store.getters.getNetworkList;

            return this.networkList;
        },

        getOsList(){
            this.osList = this.$store.getters.getOsList;

            return this.osList;
        },

        getOsActive(){
            let os = this.getOsList.find(val => val.id == this.groupData.os.value);

            return (os != undefined && os.name != undefined ? os.name : '');
        },

        // get show type list
        getShowTypeList(){
            return [
                {name: 'Ordered', value: 'ordered'},
                {name: 'Random', value: 'random'},
                {name: 'Once', value: 'once'},
            ];
        },

        // check active show type and Time
        getCheckCrosspromo(){
            if(this.groupData.network.text == this.stateCrosspromo) this.checkCrosspromo = true;
            else  this.checkCrosspromo = false;

            return this.checkCrosspromo;
        },

        // check active Admob
        getCheckAdmob(){
            if(this.groupData.network.text == this.stateAdmob) this.checkAdmob = true;
            else  this.checkAdmob = false;

            return this.checkAdmob;
        },

        // check active Facebook
        getCheckFacebook(){
            if(this.groupData.network.text == this.stateFacebook) this.checkFacebook = true;
            else  this.checkFacebook = false;

            return this.checkFacebook;
        },

        // check active show Time
        getCheckOtherNetwork(){
            if(this.groupData.network.text == this.stateExchange || this.groupData.network.text == this.stateAdvertisement) this.checkOtherNetwork = true;
            else this.checkOtherNetwork = false;

            return this.checkOtherNetwork;
        },

        // show type value
        getShowTypeText(){
            if (this.getShowTypeList.length && this.groupData.showType.value == '' && this.groupData.showType.text == '') {
                this.groupData.showType.value = this.getShowTypeList[0].value;
                this.groupData.showType.text = this.getShowTypeList[0].name;
            }

            return this.groupData.showType.text;
        },

        // network count
        getNetworkCount(){
            if(this.groupData.infinity.value) this.groupData.networkCount.value = -1;
            else if(this.groupData.networkCount.value == -1) this.groupData.networkCount.value = 1;

            return this.groupData.networkCount.value;
        },

        // validation form
        isValidForm(){
            let result = false;

            result = (this.groupData.name.value != '');

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork || this.getCheckFacebook || this.getCheckAdmob) && this.getTypeBanner == 'banner')) {
                result = this.groupData.showTime.value >= 3 && this.groupData.showTime.value <= 120;
            }

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner == 'interstitial')) {
                result = this.groupData.showTime.value >= 0 && this.groupData.showTime.value <= 5;
            }

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner == 'rewarded')) {
                result = this.groupData.showTime.value >= 0 && this.groupData.showTime.value <= 10;
            }

            if (result && (this.getCheckAdmob || this.getCheckFacebook) && (this.getTypeBanner == 'interstitial' || this.getTypeBanner == 'banner' || this.getTypeBanner == 'rewarded')) {
                result = this.groupData.adUnitPrice.value >= 0 && this.groupData.adUnitPrice.value <= 2;
            }

            return result;
        }
    },
    methods: {
        backToPrev(){
            //this.$router.push({name: this.getBackPrevLink});

            this.$router.back();
        },

        // Network
        selectNetworkById(id){
            let data = {
                id: this.getNetworkList[0].id,
                name: this.getNetworkList[0].name
            };

            let tmp = this.getNetworkList.find(item => item.id == id);

            if(tmp.id != undefined) data.id = tmp.id;
            if(tmp.name != undefined) data.name = tmp.name;

            return data;
        },

        // get network by Id
        getNetworkLogo(inx){
            let name = '';
            let logo = '';

            if(this.getNetworkList.length){
                name = this.getNetworkList.find(item => item.id === inx);
                if(name != undefined && name.name != undefined) logo = this.getLogos[name.name];
            }

            return logo;
        },

        // show type
        selectShowType(e){
            let option = e.target.selectedOptions[0];

            this.groupData.showType.text = option.innerText;
        },

        // set network count
        setNetworkCount(e){
            this.groupData.networkCount.value = e.target.value;
        },

        // close Group form
        closeGroupForm(){
            this.$router.push({ name: 'group' });
        },

        // save form data and add new application to DB
        setGroupDB(){
            let data = {
                id: this.groupData.id.value,
                name: this.groupData.name.value,
                type: Number(this.groupData.typeBanner.id),
                network: Number(this.groupData.network.value),
                enabled: Number(this.groupData.enabled.value)
            };
            this.saveGroupDiv.disabled = true;

            if ((this.getCheckAdmob || this.getCheckFacebook) && (this.getTypeBanner == 'interstitial' || this.getTypeBanner == 'banner' || this.getTypeBanner == 'rewarded')) {
                data.adunitPrice = Number(this.groupData.adUnitPrice.value);
            } else {
                data.adunitPrice = 0;
            }

            if (this.checkCrosspromo) {
                data.show_time = parseInt(this.groupData.showTime.value) ? parseInt(this.groupData.showTime.value) : (parseInt(this.groupData.showTime.value) === 0 ? 0 : this.default.showTime);
                data.show_type = this.groupData.showType.value;
                data.network_count = -1;
            } else if (this.checkOtherNetwork || this.getCheckFacebook || this.getCheckAdmob) {
                data.show_time = parseInt(this.groupData.showTime.value) ? parseInt(this.groupData.showTime.value) : (parseInt(this.groupData.showTime.value) === 0 ? 0 : this.default.showTime);
                data.show_type = "";
                data.network_count = parseInt(this.groupData.networkCount.value) ? parseInt(this.groupData.networkCount.value) : -1;
            } else {
                data.show_time = this.default.showTime;
                data.show_type = ""; //this.getShowTypeList[0].value;
                data.network_count = parseInt(this.groupData.networkCount.value) ? parseInt(this.groupData.networkCount.value) : -1;
            }

            data = JSON.stringify(data);

//console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/group/edit?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.saveGroupDiv.disabled = false;

                this.$router.back();
            })
            .catch(e => {
                if (e.response != undefined && e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveGroupDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error edit group to BD");
            });
        },

        // get group by ID
        getGroupByID(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/group/id/' + this.getGroupEditId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let groupTmp = response.data.group;
                let tmpNetworkList = this.selectNetworkById(groupTmp.network);

                this.groupData.id.value = groupTmp.idx;
                this.groupData.name.value = groupTmp.name;
                this.groupData.typeBanner.id = groupTmp.type;
                //this.groupData.typeBanner.value = this.getTypeBannerList.find(val => val.id == groupTmp.type)['name'];
                this.$set(this.groupData.typeBanner.value, 0, groupTmp.type);

                this.groupData.network.value = tmpNetworkList.id;
                this.groupData.network.text = tmpNetworkList.name;
                this.groupData.infinity.value = (groupTmp.network_count == -1 ? true : false);
                this.groupData.networkCount.value = groupTmp.network_count;
                this.groupData.showType.value = groupTmp.show_type;
                this.groupData.showType.text = groupTmp.show_type;
                this.groupData.showTime.value = groupTmp.show_time;
                this.groupData.enabled.value = groupTmp.enabled;
                this.groupData.os.value = groupTmp.osid;
                this.groupData.adUnitPrice.value = groupTmp.adunitPrice;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get group by id');
            });
        },

        // popup
        setPopupDelete(e, title, msg, urlDel, urlRedirect){
            this.popupTitle = title;
            this.popupMsg = msg;
            this.popupUrlDelete = urlDel;
            this.popupUrlRedirect = urlRedirect;

            this.$store.commit('modals/setPopupDelete', true);
        },

        // set height for table form Adunit
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },

    components: {
        Popup,
        VueSlider,
        MultiSelectIcons
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        height: 80px;
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 80px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        border-bottom: 0px none;
        padding-bottom: 13px;
    }

    .add_group{
        padding: 0 52px;
    }

    .close_group{
        margin-right: 65px;
    }

    .state_group{
        overflow: hidden;
        background-color: #53eec9;
    }

    .state_group.active{
        background-color: #20bb96;
    }


    .margin-top-20{
        margin-top: 20px;
        margin-bottom: 0px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
