import { Radar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins

export default {
    extends: Radar,
    mixins: [reactiveProp],
    props: ['options', 'chartData'],
    watch: {
        chartData() {
            this.renderChart(this.chartData, {
                responsive: true,
            });
        }
    },
    mounted () {
        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        this.renderChart(this.chartData, {
            responsive: true,
            maintainAspectRatio: false,
            type: 'line',
            legend: {
                display: false
            }
        }); // this.options
    }
}
