import { render, staticRenderFns } from "./RateappInventory.vue?vue&type=template&id=21a6484a&scoped=true&"
import script from "./RateappInventory.vue?vue&type=script&lang=js&"
export * from "./RateappInventory.vue?vue&type=script&lang=js&"
import style0 from "./RateappInventory.vue?vue&type=style&index=0&id=21a6484a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a6484a",
  null
  
)

component.options.__file = "RateappInventory.vue"
export default component.exports