<template>
    <section>
        <div class="panel group-list">
            <div class="panel-body">
                <div class="group-box-wrapper">
                    <div>
                        <div class="flex-container-start">
                            <div class="flex-block-group">
                                <div v-if="getTypeAdblock">
                                    <div class="title short_text">
                                        {{ getLang.moderator.adUnit }}
                                    </div>
                                    <div class="logo">
                                        <img :src="moderatorBlockDB.adblock.image" v-if="moderatorBlockDB.adblock.image" />
                                        <img :src="moderatorBlockDB.adblock.image_portrait" v-else />
                                    </div>
                                    <hr>
                                    <div class="flex-container-between description">
                                        <div>
                                            <div class="short_text">{{ getLang.moderator.title }}: {{ moderatorBlockDB.adblock.name }} </div>
                                            <div class="short_text">{{ getLang.moderator.description }}: {{ moderatorBlockDB.adblock.description }}</div>
                                            <div class="short_text">{{ getLang.moderator.application }}: {{ moderatorBlockDB.adblock.application }}</div>
                                            <div class="short_text">{{ getLang.moderator.UTMterm }}: {{ moderatorBlockDB.adblock.utm_content }}</div>
                                            <div class="short_text">{{ getLang.moderator.type }}: {{ getTypeBanner(moderatorBlockDB.adblock.type) }}</div>

                                            <div class="short_text">
                                                {{ getLang.moderator.linkDevice }}:
                                                <a target="_blank" :href="getLinkDevice">{{ getLinkDevice }}</a>
                                            </div>
                                            <div class="short_text">
                                                {{ getLang.moderator.linkApplication }}:
                                                <a target="_blank" :href="getLinkApplication">{{ getLinkApplication }}</a>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="#" class="btn btn-danger btn_moderator" @click.prevent="updateModeratorBlockDB('adblock',  moderatorBlockDB.adblock.id, 'Blocked')">
                                                {{ getLang.moderator.blocked }}
                                            </a>
                                            <a href="#" class="btn btn-warning btn_moderator" @click.prevent="updateModeratorBlockDB('adblock',  moderatorBlockDB.adblock.id, 'NotConfirmed')">
                                                {{ getLang.moderator.notConfirmed }}
                                            </a>
                                            <a href="#"
                                                class="btn btn-success btn_moderator"
                                                @click.prevent="updateModeratorBlockDB('adblock',  moderatorBlockDB.adblock.id, 'Confirmed')"
                                                >
                                                {{ getLang.moderator.confirmed }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="getTypeApplication">
                                    <div class="title short_text">
                                        {{ getLang.moderator.application }}
                                    </div>
                                    <div class="logo">
                                        <img :src="moderatorBlockDB.application.icon" v-if="moderatorBlockDB.application.icon" />
                                    </div>
                                    <hr>
                                    <div class="flex-container-between description">
                                        <div>
                                            <div class="short_text">{{ getLang.moderator.title }}: {{ moderatorBlockDB.application.title }}</div>
                                            <div class="short_text">{{ getLang.moderator.packageName }}: {{ moderatorBlockDB.application.package_name }}</div>
                                            <div class="short_text">{{ getLang.moderator.period }}: {{ moderatorBlockDB.application.period }}</div>
                                            <div class="short_text">{{ getLang.moderator.gravity }}: {{ getGravity(moderatorBlockDB.application.gravity) }}</div>

                                            <div class="short_text">
                                                {{ getLang.moderator.category }}:
                                                {{ getCategory(moderatorBlockDB.application.cat1) }}
                                                <span v-if="moderatorBlockDB.application.cat2">, {{ getCategory(moderatorBlockDB.application.cat2) }}</span>
                                                <span v-if="moderatorBlockDB.application.cat3">, {{ getCategory(moderatorBlockDB.application.cat3) }}</span>
                                            </div>

                                            <div class="short_text">
                                                {{ getLang.moderator.linkDevice }}:
                                                <a target="_blank" :href="getLinkDevice">{{ getLinkDevice }}</a>
                                            </div>
                                            <div class="short_text">
                                                {{ getLang.moderator.linkApplication }}:
                                                <a target="_blank" :href="getLinkApplication">{{ getLinkApplication }}</a>
                                            </div>
                                        </div>
                                        <div>
                                            <a href="#" class="btn btn-danger btn_moderator" @click.prevent="updateModeratorBlockDB('application',  moderatorBlockDB.application.id, 'Blocked')">
                                                {{ getLang.moderator.blocked }}
                                            </a>
                                            <a href="#" class="btn btn-warning btn_moderator" @click.prevent="updateModeratorBlockDB('application',  moderatorBlockDB.application.id, 'NotConfirmed')">
                                                {{ getLang.moderator.notConfirmed }}
                                            </a>
                                            <a href="#"
                                                class="btn btn-success btn_moderator"
                                                @click.prevent="updateModeratorBlockDB('application',  moderatorBlockDB.application.id, 'Confirmed')"
                                                >
                                                {{ getLang.moderator.confirmed }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="not_found" v-if="moderatorBlockDB.adblock == undefined && moderatorBlockDB.application == undefined">
                                    {{ getLang.notFoundItems }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    data(){
        return {
            lang: Lang,

            moderatorBlockDB: {},

            gravityList: [],
            categoryList: [],
            typeBannerList: [],

            linkDevice: 'https://play.google.com/store/apps/dev?id=',
            linkApplication: 'https://play.google.com/store/apps/details?id='
        };
    },

    created(){
        if(!this.isAdmin()) this.$router.push({name: 'index'});
        else this.getModeratorBlockDB();
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getGravityList(){
            this.gravityList = this.$store.getters.getGravityList;

            return this.gravityList;
        },

        getCategoryList(){
            this.categoryList = this.$store.getters.getCategoryList;

            return this.categoryList;
        },

        getTypeBannerList(){
            this.typeBannerList = this.$store.getters.getTypeBannerList;

            return this.typeBannerList;
        },

        getTypeAdblock(){
            let fl = false;

            if (this.moderatorBlockDB.moderation_item != undefined) {
                fl = this.moderatorBlockDB.moderation_item.item == 'Adblock';
            }

            return fl;
        },

        getTypeApplication(){
            let fl = false;

            if (this.moderatorBlockDB.moderation_item != undefined) {
                fl = this.moderatorBlockDB.moderation_item.item == 'Application';
            }

            return fl;
        },

        getLinkDevice(){
            let link = '';

            if(this.moderatorBlockDB.play_account[0] != undefined) link = this.linkDevice + this.moderatorBlockDB.play_account[0].play_account_id;

            return link;
        },

        getLinkApplication(){
            let link = '';

            if (this.getTypeApplication) {
                link = this.linkApplication + this.moderatorBlockDB.application.package_name;
            } else {
                let res = /^http[s]?:\/\//i.test(this.moderatorBlockDB.adblock.url);

                if (res) {
                    link = this.moderatorBlockDB.adblock.url;
                } else {
                    link = this.linkApplication + this.moderatorBlockDB.adblock.url;
                }
            }

            return link;
        },
    },

    methods: {
        isAdmin(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() === this.$store.getters.getRoleSuperAdmin);
        },

        getGravity(val){
            let res = this.getGravityList.find(item => item.id == val);

            return res != undefined ? res.name : '';
        },

        getCategory(val){
            let res = this.getCategoryList.find(item => item.id == val);;

            return res != undefined ? res.name : '';
        },

        getTypeBanner(val){
            let res = this.getTypeBannerList.find(item => item.id == val);

            return res != undefined ? res.name : '';
        },

        // get moderator adunits or applications
        getModeratorBlockDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/moderation/get?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.moderatorBlockDB = response.data;
            })
            .catch(e => {
                this.moderatorBlockDB = {};
                this.errorsMessage(e, 'error get moderator items');
            });
        },

        // update application or adblock
        updateModeratorBlockDB(item, itemId, state){
            let data = JSON.stringify({
                item: item,
                item_id: Number(itemId),
                state: state
            });

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/moderation/changestate?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Moderate updated! ' + item + ' ' + state});

                this.getModeratorBlockDB();

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error set moderator items');
            });
        }
    }
}
</script>

<style scoped>
    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }

    .not_found{
        text-align: center;
        padding: 20px;
        width: 30%;
        margin: 0 auto;
        color: red;
        background-color: #eee;
    }

    .group-list{
        border: 0px none;
    }

        .group-list .panel-body{
            padding: 0px;
        }

    .group-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .table-group th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-group-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-group-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-group-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn_moderator{
        margin-bottom: 10px;
    }

    /* flexbox */
    .flex-container{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    .flex-container-start{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        margin: 10px 13px 0;
    }

        .flex-block-group{
            width: 60%;
            padding: 0 10px 10px 0;
        }

            .flex-block-group>div{
                border: 1px solid #ddd;
                border-radius: 5px;
                height: 100%;
            }

            .flex-block-group .logo, .flex-block-group .title, .flex-block-group .description{
                box-sizing: border-box;
                padding: 0 8px;
            }

            .flex-block-group .title{
                margin-top: 5px;
            }

            .flex-block-group .logo{
                margin-top: 5px;
            }

            .flex-block-group hr{
                margin-top: 10px;
                margin-bottom: 10px;
            }

                .flex-block-group .logo img{
                    border: 1px solid #ddd;
                    border-radius: 10px;
                }

    .flex-container-between{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
    }

        .description div{
            font-size: 14px;
            line-height: 19px;
        }

        .description>div:first-child{
            text-align: left;
            box-sizing: border-box;
            width: 100%;
            padding: 0 10px 0 0;
            margin-bottom: 10px;
        }

        .description>div:last-child{
            text-align: center;
        }

            .description>div:last-child a{
                font-size: 14px;
            }
    /* end flexbox */
</style>
