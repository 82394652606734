<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_adunit" ref="saveAdunit" @click.prevent="saveAdunit($event)" :disabled="!isValidForm">
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_adunit reopen_adunit" ref="reopenAdunit" @click.prevent="reopenAdunit()" :disabled="!isValidForm">
                                            <span>{{ getLang.reopen }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <!-- <button class="btn btn-alt btn-hover btn-warning float-right close_adunit" @click.prevent="closeAdUnitForm">
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button> -->
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <!-- App, Type, Tracking -->
                            <div class="form-group form_group_wrap" style="height: 100px">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.adUnit.applications">
                                        {{ getLang.adUnit.applications }}
                                    </label>
                                    <MultiSelectIcons categories-alt="applications list"
                                                :categories-title="''"
                                                :get-category-list="getAppList"
                                                :setDefaultCategory="adUnitData.packageName.value"
                                                :count-item="1"
                                                :placeholder="getLang.statistics.app"
                                                styleInput="padding-left: 0px; padding-right: 0px;"
                                                styleBlockSelect="margin-bottom: 0px;"
                                                :isSearchShow="true"
                                                :is-default-required="false"
                                                :is-border="true"
                                                :is-icon="true"
                                                :view-count="true"
                                                @setCategory="val => adUnitData.packageName.value = val"
                                                @removeCategory="val => adUnitData.packageName.value = val"
                                                >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.applications" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <label class="control-label label_on_input" :title="' (' + getLang.max128Chars + ')'">{{ getLang.adUnit.tagMark }}</label>
                                    <input class="form-control outlight" id="" type="text" v-model="adUnitData.tagMark.value">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.tagMark + ' (' + getLang.max128Chars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.adUnit.type">{{ getLang.adUnit.type }}</label>

                                    <!-- <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTypeBannerValue }}</span>
                                        <select name="" class="custom-select" @change="selectAdtypes($event)" v-model="adUnitData.typeBanner.value">
                                            <option :value="el.id" v-for="(el, ind) in getTypeBannerList" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div> -->
                                    <MultiSelectIcons :categories-alt="''"
                                                :categories-title="''"
                                                :get-category-list="getTypeBannerList"
                                                :setDefaultCategory="adUnitData.typeBanner.value"
                                                :count-item="1"
                                                :placeholder="getLang.statistics.adtype"
                                                styleInput="padding-right: 0px; padding-left: 0px;"
                                                styleBlockSelect="margin-bottom: 0px;"
                                                :is-required="false"
                                                @setCategory="val => adUnitData.typeBanner.value = val"
                                                @removeCategory="val => adUnitData.typeBanner.value = val"
                                                >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.type" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <label class="control-label label_on_input" :title="getLang.adUnit.tracking">{{ getLang.adUnit.tracking }}</label>
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTrackSystemTextInput }}</span>
                                        <select name="" class="custom-select" @change="selectTrackSystem($event)" v-model="adUnitData.trackSystem.value" :disabled="true">
                                            <option :value="el.id" v-for="(el, ind) in getTrackSystemList" :key="ind">{{ el.system }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.tracking" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>
                            <!-- End App, Type, Tracking -->

                            <!-- Locales -->
                            <!-- <div class="form-group form_group_wrap no-border">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <button class="btn btn-success btn-add" @click.prevent="addCountryLocale()" :disabled="getLocaleList.length < 1">
                                                <i class="glyph-icon icon-plus"></i>
                                            </button>
                                        </div>

                                        <div class="col-sm-10">
                                            <label class="control-label label_on_input">
                                                {{ getLang.adUnit.adLocale }}
                                            </label>
                                            <div class="selector" style="width:100%!important;">
                                                <span style="-moz-user-select: none;">{{ getCountryText }}</span>
                                                <select class="custom-select" @change="selectCountry($event)" v-model="adUnitData.country.value">
                                                    <option :value="el.langIso" v-for="(el, idx) in getLocaleList" :key="idx">{{ getLangId == 1 ? el.description_ru : el.description }}</option>
                                                </select>
                                                <i class="glyph-icon icon-caret-down"></i>
                                            </div>
                                            <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.adLocale" data-original-title=".icon-question"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div> -->

                            <div class="form-group no-border no-padding-bottom">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <ul class="nav-responsive nav nav-tabs locale_list">
                                        <label class="control-label label_on_input" style="left: 32px">
                                            {{ getLang.adUnit.adLocale }}
                                        </label>
                                        <li class="active">
                                            <button
                                                class="btn btn-add"
                                                :class="isShowLocales ? 'btn-danger' : 'btn-success'"
                                                @click.prevent="isShowLocales = !isShowLocales"
                                                :disabled="getLocaleList.length < 1"
                                                style="padding: 0px 15px; height: 40px;"
                                            >
                                                <i class="glyph-icon icon-plus-locales icon-close" v-if="isShowLocales"></i>
                                                <i class="glyph-icon icon-plus-locales icon-plus" v-else></i>
                                            </button>

                                            <div v-show="isShowLocales" class="selector" style="height: 40px; width: 155px!important; position: absolute; top: 0px; left: 48px; z-index: 10; background: #fff;">
                                                <span style="-moz-user-select: none;">{{ getCountryText }}</span>
                                                <select class="custom-select" @change="selectCountry($event)">
                                                    <option>{{ getCountryPlaceholder }}</option>
                                                    <option :value="el.langIso" v-for="(el, idx) in getLocaleList" :key="idx">{{ getLangId == 1 ? el.description_ru : el.description }}</option>
                                                </select>
                                                <i class="glyph-icon icon-caret-down"></i>
                                            </div>
                                        </li>
                                        <li :class="val.active ? 'active' : ''" v-for="(val, idx) in countryData" :key="idx">
                                            <a href="#" data-toggle="tab" @click.prevent="setActiveCountryLocale($event, val.value)">{{ val.text }} <span class="bs-badge badge-absolute float-right badge-danger" @click.stop.prevent="deleteCountryLocale(idx)" v-if="idx!=0"><i class="glyph-icon icon-close"></i></span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.adUnit.title + ' (' + getLang.max50Chars + ')'">{{ getLang.adUnit.title }}</label>
                                    <input class="form-control outlight" id="" type="text" v-model="countryData[countryActive].title">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.title + ' (' + getLang.max50Chars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-3">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.adUnit.description + ' (' + getLang.maxChars + ')'">{{ getLang.adUnit.description }}</label>
                                    <input class="form-control outlight" id="" type="text" v-model="countryData[countryActive].description">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.description + ' (' + getLang.maxChars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>
                            <!-- End Locales -->

                            <!-- Images -->
                            <transition name="bounce" mode="out-in">
                                <!-- Icon Banner -->
                                <div class="form-group form_group_wrap_big no-border" :class="this.adUnitData.imgOnly.value ? '' : 'form_group_border_content'" v-if="checkBanner">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <div class="form-group no-border">
                                            <label :title="adUnitData.imgOnly.alt">
                                                <label class="control-label label_on_input" style="top: -18px;" :style="$store.getters.getLanguageActive == 1 ? 'right: -7px;' : 'right: 44px;'">{{ getLang.adUnit.imageOnly }}</label>
                                                <div :class="adUnitData.imgOnly.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                                    @click.prevent="changeImageOnly()"
                                                    style="margin-top: 5px;"
                                                    >
                                                    <div class="bootstrap-switch-container">
                                                        <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                        <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <label class="control-label col-sm-2 col-md-2" style="text-align: left;" v-if="checkIconFile">
                                        <span class="bs-badge badge-absolute badge-danger"
                                                style="cursor: pointer; left: 60px; top: 4px;"
                                                @click.prevent="clearIcon()"
                                                v-if="(adUnitData.iconFile.viewFile && !iconFileStatus) || (adUnitData.iconUrl.value && iconFileStatus)"
                                        >
                                            <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                        </span>
                                        <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.iconFile }}</label>
                                        <img :src="adUnitData.iconUrl.value" style="width: 50px; max-height: 50px;" v-if="adUnitData.iconUrl.value && iconFileStatus"/>
                                        <img :src="adUnitData.iconFile.viewFile" style="width: 50px; max-height: 50px;" v-else-if="adUnitData.iconFile.viewFile && !iconFileStatus"/>
                                        <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatus">{{ getLang.messages.imageSizeNotMatch }}</span>
                                        </transition>
                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                        </transition>
                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                        </transition>
                                    </label>
                                    <div class="col-sm-7 col-md-7" v-if="checkIconFile">
                                        <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                            <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                            <input type="file" name="..." @change="setIconFile">
                                        </span>

                                        <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                            <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                            <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size96x96 }}&nbsp;{{ getLang.max500kb }}</label>

                                            <a href="#"
                                                class="control-label label_on_input copy_link_text"
                                                style="right: 12px;"
                                                @click.prevent="copyLink('icon_banner')"
                                            >
                                                {{ getCopyLinkIconBanner }}
                                            </a>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.iconFile.nameFile }}</span>
                                                </div>
                                            </div>
                                            <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.iconFile.alt" data-original-title=".icon-question"></i>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                                <!-- End Icon Banner -->
                            </transition>

                            <transition name="bounce" mode="out-in">
                                <!-- Icon & (Inter || Rewarded) -->
                                <div class="form-group form_group_wrap_big form_group_border_content" v-if="checkInter || checkRewarded">
                                    <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                    <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                        <span class="bs-badge badge-absolute badge-danger"
                                                style="cursor: pointer; left: 60px; top: 4px;"
                                                @click.prevent="clearIcon()"
                                                v-if="(adUnitData.iconFile.viewFile && !iconFileStatus) || (adUnitData.iconUrl.value && iconFileStatus)"
                                        >
                                            <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                        </span>
                                        <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.iconFile }}</label>
                                        <img :src="adUnitData.iconUrl.value" style="width: 50px; max-height: 50px;" v-if="adUnitData.iconUrl.value && iconFileStatus"/>
                                        <img :src="adUnitData.iconFile.viewFile" style="width: 50px; max-height: 50px;" v-else-if="adUnitData.iconFile.viewFile && !iconFileStatus"/>
                                        <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatus">{{ getLang.messages.imageSizeNotMatch }}</span>
                                        </transition>
                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                        </transition>
                                        <transition name="fade">
                                            <span class="bs-label label-danger" v-show="mesIconStatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                        </transition>
                                    </label>
                                    <div class="col-sm-7 col-md-7">
                                        <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                            <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                            <input type="file" name="..." @change="setIconFile">
                                        </span>

                                        <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                            <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                            <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size512х512Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                            <a href="#"
                                                class="control-label label_on_input copy_link_text"
                                                style="right: 12px;"
                                                @click.prevent="copyLink('icon')"
                                            >
                                                {{ getCopyLinkIcon }}
                                            </a>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.iconFile.nameFile }}</span>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.iconFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                                <!-- End Icon & Inter -->
                            </transition>

                            <!-- Image & Inter && Rewarded -->
                                <transition name="bounce" mode="out-in">
                                    <!-- image 169 -->
                                    <div class="form-group form_group_wrap_big no-border" v-if="checkInter || checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; top: 4px;"
                                                    :style="checkInter ? 'left: 112px;' : checkRewarded ? 'left: 98px;' : 'left: 112px;'"
                                                    @click.prevent="clearImg()"
                                                    v-if="(adUnitData.imageFile.viewFile && !imageFileStatus) || (adUnitData.imageUrl.value && imageFileStatus)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl.value" v-if="adUnitData.imageUrl.value && imageFileStatus"/>
                                            <img :src="adUnitData.imageFile.viewFile" v-else-if="adUnitData.imageFile.viewFile && !imageFileStatus"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImageStatus">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImageStatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImageStatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideoStatusUrl">{{ getLang.messages.videoUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideoStatusBlocked">{{ getLang.messages.videoBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <!-- <label class="control-label label_on_input" style="left: 12px;" v-if="checkRewarded">{{ getLang.size1280х720Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>
                                                <label class="control-label label_on_input" style="left: 12px;" v-else-if="checkInter">{{ getLang.size1024х500Simple }}&nbsp;{{ getLang.max500kbSimple }}</label> -->
                                                <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size1280х720Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image')"
                                                >
                                                    {{ getCopyLinkImage }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition>
                                <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap form_group_border_content" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <div class="col-sm-2" style="text-align: left;">
                                            <label class="control-label label_on_input" style="top: -20px;">{{ getLang.adUnit.videoFile }}</label>
                                            <i class="glyph-icon tooltip-button demo-icon icon-camera" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>
                                        </div>

                                        <!-- Video169 -->
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="validateVideo">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('video')"
                                                >
                                                    {{ getCopyLinkVideo }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename">{{ adUnitData.videoFile.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="getLang.adUnit.videoFile" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <!-- end Video169 -->
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition>

                                <!-- <transition name="bounce" mode="out-in"> -->
                                    <!-- image 43 -->
                                    <!-- <div class="form-group form_group_wrap_big" :class="checkRewarded ? 'no-border' : ''" v-if="checkInter || checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; top: 4px;"
                                                    :style="checkInter ? 'left: 98px;' : checkRewarded ? 'left: 76px;' : 'left: 98px;'"
                                                    @click.prevent="clearImg43()"
                                                    v-if="(adUnitData.imageFile43.viewFile && !imageFile43Status) || (adUnitData.imageUrl43.value && imageFile43Status)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl43.value" v-if="adUnitData.imageUrl43.value && imageFile43Status"/>
                                            <img :src="adUnitData.imageFile43.viewFile" v-else-if="adUnitData.imageFile43.viewFile && !imageFile43Status"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage43Status">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage43StatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage43StatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo43StatusUrl">{{ getLang.messages.videoUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo43StatusBlocked">{{ getLang.messages.videoBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile43">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <label class="control-label label_on_input" style="left: 12px;" v-if="checkRewarded">{{ getLang.size960х720Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>
                                                <label class="control-label label_on_input" style="left: 12px;" v-else-if="checkInter">{{ getLang.size1280х720Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image43')"
                                                >
                                                    {{ getCopyLinkImage43 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile43.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->
                                <!-- <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap form_group_border_content" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>

                                        <div class="col-sm-2" style="text-align: left;">
                                            <label class="control-label label_on_input" style="top: -20px;">{{ getLang.adUnit.videoFile }}</label>
                                            <i class="glyph-icon tooltip-button demo-icon icon-camera" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>
                                        </div> -->

                                        <!-- Video43 -->
                                        <!-- <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="validateVideo43">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('video43')"
                                                >
                                                    {{ getCopyLinkVideo43 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename">{{ adUnitData.videoFile43.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="getLang.adUnit.videoFile" data-original-title=".icon-question"></i>
                                            </div>
                                        </div> -->
                                        <!-- End Video43 -->
                                        <!-- <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->

                                <!-- <transition name="bounce" mode="out-in"> -->
                                    <!-- image rewarded -->
                                    <!-- image 11 -->
                                    <!-- <div class="form-group form_group_wrap_big no-border" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; left: 60px; top: 4px;"
                                                    @click.prevent="clearImg11()"
                                                    v-if="(adUnitData.imageFile11.viewFile && !imageFile11Status) || (adUnitData.imageUrl11.value && imageFile11Status)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl11.value" v-if="adUnitData.imageUrl11.value && imageFile11Status"/>
                                            <img :src="adUnitData.imageFile11.viewFile" v-else-if="adUnitData.imageFile11.viewFile && !imageFile11Status"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage11Status">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage11StatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage11StatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo11StatusUrl">{{ getLang.messages.videoUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo11StatusBlocked">{{ getLang.messages.videoBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile11">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size1024х1024Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image11')"
                                                >
                                                    {{ getCopyLinkImage11 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile11.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->
                                <!-- <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap form_group_border_content" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <div class="col-sm-2" style="text-align: left;">
                                            <label class="control-label label_on_input" style="top: -20px;">{{ getLang.adUnit.videoFile }}</label>
                                            <i class="glyph-icon tooltip-button demo-icon icon-camera" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>
                                        </div> -->

                                        <!-- Video11 -->
                                        <!-- <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="validateVideo11">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('video11')"
                                                >
                                                    {{ getCopyLinkVideo11 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename">{{ adUnitData.videoFile11.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="getLang.adUnit.videoFile" data-original-title=".icon-question"></i>
                                            </div>
                                        </div> -->
                                        <!-- End Video11 -->
                                    <!-- </div>
                                </transition> -->

                                <!-- <transition name="bounce" mode="out-in"> -->
                                    <!-- image 34 -->
                                    <!-- <div class="form-group form_group_wrap_big no-border" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; left: 47px; top: 4px;"
                                                    @click.prevent="clearImg34()"
                                                    v-if="(adUnitData.imageFile34.viewFile && !imageFile34Status) || (adUnitData.imageUrl34.value && imageFile34Status)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl34.value" v-if="adUnitData.imageUrl34.value && imageFile34Status"/>
                                            <img :src="adUnitData.imageFile34.viewFile" v-else-if="adUnitData.imageFile34.viewFile && !imageFile34Status"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage34Status">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage34StatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage34StatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo34StatusUrl">{{ getLang.messages.videoUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo34StatusBlocked">{{ getLang.messages.videoBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile34">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size720x960Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image34')"
                                                >
                                                    {{ getCopyLinkImage34 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile34.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->
                                <!-- <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap form_group_border_content" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <div class="col-sm-2" style="text-align: left;">
                                            <label class="control-label label_on_input" style="top: -20px;">{{ getLang.adUnit.videoFile }}</label>
                                            <i class="glyph-icon tooltip-button demo-icon icon-camera" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>
                                        </div> -->

                                        <!-- Video34 -->
                                        <!-- <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="validateVideo34">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('video34')"
                                                >
                                                    {{ getCopyLinkVideo34 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename">{{ adUnitData.videoFile34.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="getLang.adUnit.videoFile" data-original-title=".icon-question"></i>
                                            </div>
                                        </div> -->
                                        <!-- End Video34 -->
                                        <!-- <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->

                                <!-- <transition name="bounce" mode="out-in"> -->
                                    <!-- image 916 -->
                                    <!-- <div class="form-group form_group_wrap_big no-border" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; left: 38px; top: 4px;"
                                                    @click.prevent="clearImg916()"
                                                    v-if="(adUnitData.imageFile916.viewFile && !imageFile916Status) || (adUnitData.imageUrl916.value && imageFile916Status)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" :style="'top: -15px;'">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl916.value" v-if="adUnitData.imageUrl916.value && imageFile916Status"/>
                                            <img :src="adUnitData.imageFile916.viewFile" v-else-if="adUnitData.imageFile916.viewFile && !imageFile916Status"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage916Status">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage916StatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger label_image" v-show="mesImage916StatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo916StatusUrl">{{ getLang.messages.videoUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesVideo916StatusBlocked">{{ getLang.messages.videoBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile916">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <label class="control-label label_on_input" style="left: 12px;">{{ getLang.size720x1280Simple }}&nbsp;{{ getLang.max500kbSimple }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image916')"
                                                >
                                                    {{ getCopyLinkImage916 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile916.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->
                                <!-- <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap" v-if="checkRewarded">
                                        <div class="col-sm-2" style="text-align: right;">&nbsp;</div>
                                        <div class="col-sm-2" style="text-align: left;">
                                            <label class="control-label label_on_input" style="top: -20px;">{{ getLang.adUnit.videoFile }}</label>
                                            <i class="glyph-icon tooltip-button demo-icon icon-camera" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>
                                        </div> -->

                                        <!-- Video916 -->
                                        <!-- <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="validateVideo916">
                                            </span>

                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('video916')"
                                                >
                                                    {{ getCopyLinkVideo916 }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename">{{ adUnitData.videoFile916.nameFile }}</span>
                                                    </div>
                                                </div>
                                                <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="getLang.adUnit.videoFile" data-original-title=".icon-question"></i>
                                            </div>
                                        </div> -->
                                        <!-- End Video916 -->
                                        <!-- <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition> -->
                                <!--
                                End Image & Inter && Rewarded
                                Image Other
                                -->
                                <transition name="bounce" mode="out-in">
                                    <div class="form-group form_group_wrap_big" v-if="!checkInter && !checkRewarded && checkImageFile">
                                        <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                        <label class="control-label col-sm-2 col-md-2" style="text-align: left;">
                                            <span class="bs-badge badge-absolute badge-danger"
                                                    style="cursor: pointer; top: 4px;"
                                                    :style="checkBanner && adUnitData.imgOnly.value ? 'left: 275px;' : 'left: 112px;'"
                                                    @click.prevent="clearImg()"
                                                    v-if="(adUnitData.imageFile.viewFile && !imageFileStatus) || (adUnitData.imageUrl.value && imageFileStatus)"
                                            >
                                                <i class="glyph-icon icon-close" title="" data-original-title=".icon-close"></i>
                                            </span>
                                            <label class="control-label label_on_input" style="top: -15px;">{{ getLang.adUnit.imageFile }}</label>
                                            <img :src="adUnitData.imageUrl.value" v-if="adUnitData.imageUrl.value && imageFileStatus"/>
                                            <img :src="adUnitData.imageFile.viewFile" v-if="adUnitData.imageFile.viewFile && !imageFileStatus"/>
                                            <i v-else class="glyph-icon tooltip-button demo-icon icon-question" style="margin: 0px; width: 50px; height: 50px; font-size: 40px; line-height: 50px;"></i>

                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesImageStatus">{{ getLang.messages.imageSizeNotMatch }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesImageStatusUrl">{{ getLang.messages.imageUrlNotCorrect }}</span>
                                            </transition>
                                            <transition name="fade">
                                                <span class="bs-label label-danger" v-show="mesImageStatusBlocked">{{ getLang.messages.imageBlocked }}</span>
                                            </transition>
                                        </label>
                                        <div class="col-sm-7 col-md-7">
                                            <span class="input-group-addon btn btn-primary btn-file" style="width: 95px; height: 34px; line-height: 20px; display:inline; border-radius: 3px; float: left;">
                                                <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                <input type="file" name="..." @change="setImageFile">
                                            </span>
                                            <div class="padding_for_icon" style="position: relative; margin-left: 115px;">
                                                <span class="icon_required" style="left: -10px;" :title="getLang.required">*</span>
                                                <label class="control-label label_on_input" style="left: 12px;" v-if="checkBanner && adUnitData.imgOnly.value">{{ getLang.size640x100 }}&nbsp;{{ getLang.max500kb }}</label>
                                                <label class="control-label label_on_input" style="left: 12px;" v-else-if="checkBanner">{{ getLang.size246x120 }}&nbsp;{{ getLang.max500kb }}</label>

                                                <a href="#"
                                                    class="control-label label_on_input copy_link_text"
                                                    style="right: 12px;"
                                                    @click.prevent="copyLink('image')"
                                                >
                                                    {{ getCopyLinkImage }}
                                                </a>
                                                <div class="fileinput fileinput-new input-group" data-provides="fileinput" style="display: inline;">
                                                    <div class="form-control" style="overflow: hidden;" data-trigger="fileinput">
                                                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                        <span class="fileinput-filename" style="word-break: keep-all; white-space: nowrap;">{{ adUnitData.imageFile.nameFile }}</span>
                                                    </div>
                                                    <i class="glyph-icon font-gray icon-question-circle" style="right: -15px;" :title="adUnitData.imageFile.alt" data-original-title=".icon-question"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-1">&nbsp;</div>
                                    </div>
                                </transition>
                                <!-- End Image Other -->
                            <!-- End Images -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import AppColor from '../plugins/Color';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            logos: null,

            iconFileStatus: false,
            imageFileStatus: false,
            imageFile43Status: false,
            imageFile11Status: false,
            imageFile34Status: false,
            imageFile916Status: false,

            videoFileStatus: false,
            videoFile43Status: false,
            videoFile11Status: false,
            videoFile34Status: false,
            videoFile916Status: false,

            videoW: 0,
            videoH: 0,
            videoDuration: 0,

            iconFileClass: ['file', 'file_active active'],
            imageFileClass: ['file', 'file_active active'],

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 84,

            backPrevLink: '',

            saveAdunitDiv: '',
            reopenAdunitDiv: '',

            countryList: [],
            localeList: [],
            typeBannerList: [],
            trackSystemList: {},
            appList:[],

            countryActive: 0,
            countryData: [
                {
                    value: 'US',
                    text: 'English',
                    active: true,
                    title: '',
                    description: ''
                },
            ],

            countryPlaceholder: '',

            stateBanner: 'banner',
            stateInter: 'interstitial',
            stateRewarded: 'rewarded',

            isBanner: false,
            isInter: false,
            isRewarded: false,

            isShowLocales: false,

            stateChooseBoth: 'both',
            stateChooseLeft: 'left',
            stateChooseRight: 'right',

            copyLinkIcon: null,
            copyLinkIconBanner: null,
            copyLinkImage: null,
            copyLinkImage43: null,
            copyLinkImage11: null,
            copyLinkImage34: null,
            copyLinkImage916: null,
            copyLinkVideo: null,
            copyLinkVideo43: null,
            copyLinkVideo11: null,
            copyLinkVideo34: null,
            copyLinkVideo916: null,
            copyLinkText: ['copy link', 'скопировать ссылку'],
            copiedLinkText: ['link copied', 'ссылка скопирована'],

            imgSize: {
                bannerIcon: ['96', '96'],
                bannerImage: ['246', '120'],
                bannerImageOnly: ['640', '100'],
                bannerImageOnlyD: ['320', '50'],
                // icon
                interIcon: ['512', '512'],
                interIconD: [['192', '192'], ['144', '144']],
                // image 16x9
                interImage: ['1280', '720'],
                interImageD: [['804', '452'], ['612', '344']],
                // image 4x3
                interImage43: ['1024', '500'],
                interImage43D: [['802', '392'], ['610', '298']],

                // image 16x9
                rewardedImage: ['1280', '720'],
                rewardedImageD: [['804', '452'], ['612', '344']],
                //rewardedImageD: [['802', '392'], ['610', '298']],
                // image 4x3
                rewardedImage43: ['960', '720'],
                rewardedImage43D: [['804', '452'], ['612', '344']],
                // image 1x1
                rewardedImage11: ['1024', '1024'],
                rewardedImage11D: [['801', '801'], ['609', '609']],
                // image 3x4
                rewardedImage34: ['720', '960'],
                rewardedImage34D: [['452', '804'], ['344', '612']],
                // image 9x16
                rewardedImage916: ['720', '1280'],
                rewardedImage916D: [['392', '802'], ['298', '610']],
            },

            mesIconStatus: false,
            mesIconStatusUrl: false,
            mesIconStatusBlocked: false,
            // image 16x9
            mesImageStatus: false,
            mesImageStatusUrl: false,
            mesImageStatusBlocked: false,
            // video 16x9
            mesVideoStatusUrl: false,
            mesVideoStatusBlocked: false,
            // image 4x3
            mesImage43Status: false,
            mesImage43StatusUrl: false,
            mesImage43StatusBlocked: false,
            // video 4x3
            mesVideo43StatusUrl: false,
            mesVideo43StatusBlocked: false,
            // image 1x1
            mesImage11Status: false,
            mesImage11StatusUrl: false,
            mesImage11StatusBlocked: false,
            // video 1x1
            mesVideo11StatusUrl: false,
            mesVideo11StatusBlocked: false,
            // image 3x4
            mesImage34Status: false,
            mesImage34StatusUrl: false,
            mesImage34StatusBlocked: false,
            // video 3x4
            mesVideo34StatusUrl: false,
            mesVideo34StatusBlocked: false,
            // image 9x16
            mesImage916Status: false,
            mesImage916StatusUrl: false,
            mesImage916StatusBlocked: false,
            // video 9x16
            mesVideo916StatusUrl: false,
            mesVideo916StatusBlocked: false,

            adUnitData: {
                packageName: {
                    value: [],
                    alt: 'applications'
                },

                trackSystem: {
                    value: 0,
                    textInput: 'yovoads',
                    alt: 'track system'
                },

                country: {
                    text: '',
                    value: ''
                },

                typeBanner: {
                    value: [],
                    textInput: '',
                    alt: 'type banner'
                },

                tagMark: {
                    value: ''
                },

                needBackground: {
                    value: false,
                    alt: 'need background'
                },
                background: {
                    value: ''
                },

                imgOnly: {
                    value: false,
                    alt: 'image only'
                },
                chooseImg: {
                    value: 'both',
                    alt: 'choose images'
                },
                // icon
                iconUrl: {
                    value: '',
                    alt: ''
                },
                iconFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: 'Icon file',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },
                // image
                imageUrl: {
                    value: '',
                    alt: ''
                },
                imageUrl43: {
                    value: '',
                    alt: ''
                },
                imageUrl11: {
                    value: '',
                    alt: ''
                },
                imageUrl34: {
                    value: '',
                    alt: ''
                },
                imageUrl916: {
                    value: '',
                    alt: ''
                },

                imageFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: 'Image file',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },
                imageFile43: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: '',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },
                imageFile11: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: '',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },
                imageFile34: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: '',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },
                imageFile916: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    sizeFile: '',
                    alt: '',
                    check: true,

                    value1: '',
                    typeInput1: '',
                    value2: '',
                    typeInput2: '',
                },

                videoUrl: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false,
                },
                videoFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video File',
                    check: false,
                },
                videoUrl43: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoFile43: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoUrl11: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoFile11: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoUrl34: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoFile34: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoUrl916: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },
                videoFile916: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: 'Video Url',
                    check: false
                },

                utmContent: {
                    value: '',
                    alt: 'UTM content'
                }
            },
            adUnit: this.$store.getters.getAdUnit
        }
    },
    created(){
        this.$store.commit('setBackPrevLink', 'adunit');

        this.getApplicationsDB();
    },
    destroyed(){
        this.$store.commit('setIsPreloader', false);
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы adunit

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(() => {
            this.saveAdunitDiv = this.$refs.saveAdunit;
            this.reopenAdunitDiv = this.$refs.reopenAdunit;
        });
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getLangId(){
            this.langId = this.$store.getters.getLanguageActive;

            return this.langId;
        },

        getCountryPlaceholder(){
            if (this.getLangId == 1) this.countryPlaceholder = 'Выбрать страну';
            else this.countryPlaceholder = 'Choose country';

            return this.countryPlaceholder;
        },

        getCopyLinkIcon(){
            if(this.copyLinkIcon != this.copiedLinkText[this.getLangId]) this.copyLinkIcon = this.copyLinkText[this.getLangId];

            return this.copyLinkIcon;
        },

        getCopyLinkIconBanner(){
            if(this.copyLinkIconBanner != this.copiedLinkText[this.getLangId]) this.copyLinkIconBanner = this.copyLinkText[this.getLangId];

            return this.copyLinkIconBanner;
        },

        getCopyLinkImage(){
            if(this.copyLinkImage != this.copiedLinkText[this.getLangId]) this.copyLinkImage = this.copyLinkText[this.getLangId];

            return this.copyLinkImage;
        },

        getCopyLinkImage43(){
            if(this.copyLinkImage43 != this.copiedLinkText[this.getLangId]) this.copyLinkImage43 = this.copyLinkText[this.getLangId];

            return this.copyLinkImage43;
        },

        getCopyLinkImage11(){
            if(this.copyLinkImage11 != this.copiedLinkText[this.getLangId]) this.copyLinkImage11 = this.copyLinkText[this.getLangId];

            return this.copyLinkImage11;
        },

        getCopyLinkImage34(){
            if(this.copyLinkImage34 != this.copiedLinkText[this.getLangId]) this.copyLinkImage34 = this.copyLinkText[this.getLangId];

            return this.copyLinkImage34;
        },

        getCopyLinkImage916(){
            if(this.copyLinkImage916 != this.copiedLinkText[this.getLangId]) this.copyLinkImage916 = this.copyLinkText[this.getLangId];

            return this.copyLinkImage916;
        },

        getCopyLinkVideo(){
            if(this.copyLinkVideo != this.copiedLinkText[this.getLangId]) this.copyLinkVideo = this.copyLinkText[this.getLangId];

            return this.copyLinkVideo;
        },

        getCopyLinkVideo43(){
            if(this.copyLinkVideo43 != this.copiedLinkText[this.getLangId]) this.copyLinkVideo43 = this.copyLinkText[this.getLangId];

            return this.copyLinkVideo43;
        },

        getCopyLinkVideo11(){
            if(this.copyLinkVideo11 != this.copiedLinkText[this.getLangId]) this.copyLinkVideo11 = this.copyLinkText[this.getLangId];

            return this.copyLinkVideo11;
        },

        getCopyLinkVideo34(){
            if(this.copyLinkVideo34 != this.copiedLinkText[this.getLangId]) this.copyLinkVideo34 = this.copyLinkText[this.getLangId];

            return this.copyLinkVideo34;
        },

        getCopyLinkVideo916(){
            if(this.copyLinkVideo916 != this.copiedLinkText[this.getLangId]) this.copyLinkVideo916 = this.copyLinkText[this.getLangId];

            return this.copyLinkVideo916;
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        getAppList(){
            let res = [];

            res = this.appList.map(function(val){
                return {id: val.id, name: '<p style="text-align:left; font-size: 11px; line-height: 11px; margin-top: 4px;">' + val.title + '</p>' + (val.package_name ? '<p style="text-align:left;"><strong>' + val.package_name + '</strong></p>' : ''), icon: val.icon};
            });

            return res;
        },

        // locale list
        getLocaleList(){
            let _this = this;
            this.localeList = this.setCloneArrayWithObj(this.$store.getters.getLocaleList);

            // remove english from list locales
            let locInd = this.localeList.findIndex(val => val.description.toLowerCase() == 'english');
            if(locInd != -1) this.localeList.splice(locInd, 1);

            this.localeList = this.localeList.filter(function(item){

                let fl = true;

                _this.countryData.forEach(function(el, idx, arr){
                    if(el.value == item.langIso) fl = false;
                });

                return fl;
            });

            this.localeList.sort((a, b) => {
                if (this.getLangId == 1) {
                    if (a.description_ru > b.description_ru) {
                        return 1;
                    }
                    if (a.description_ru < b.description_ru) {
                        return -1;
                    }
                } else {
                    if (a.description > b.description) {
                        return 1;
                    }
                    if (a.description < b.description) {
                        return -1;
                    }
                }

                return 0;
            });

            return this.localeList;
        },

        // country value
        getCountryText(){
            if ((this.getLocaleList.length && this.adUnitData.country.value == '' && this.adUnitData.country.text == '')) {
                // this.adUnitData.country.value = this.getLocaleList[0].langIso;
                // this.adUnitData.country.text = this.getLocaleList[0].description;
                // if(this.getLangId == 1) this.adUnitData.country.text = this.getLocaleList[0].description_ru;
                return this.getCountryPlaceholder;
            } else {
                let find = this.getLocaleList.find(val => val.description == this.adUnitData.country.text || val.description_ru == this.adUnitData.country.text);

                this.adUnitData.country.text = '';
                if(find != undefined) this.adUnitData.country.text = (this.getLangId == 1 ? find.description_ru : find.description);
            }

            return this.adUnitData.country.text;
        },

        /* type banner lists */
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                let tBannerList = [...this.$store.getters.getTypeBannerList];

                tBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.name]};
                });
            }
            return this.typeBannerList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons; // Local

            return this.logos;
        },
        // getTypeBannerValue(){
        //     return this.adUnitData.typeBanner.textInput;
        // },
        /* end type banner lists */

        /* tracking systems */
        getTrackSystemList(){
            this.trackSystemList = this.$store.getters.getTrackingList;

            return this.trackSystemList;
        },
        getTrackSystemTextInput(){
            return this.adUnitData.trackSystem.textInput;
        },
        /* end tracking systems */

        /* get image and type files */
        getIconSize(){
            if (this.checkBanner && !this.adUnitData.imgOnly.value) this.adUnitData.iconFile.sizeFile = this.imgSize.bannerIcon[0] + 'x' + this.imgSize.bannerIcon[1];
            else if(this.checkInter || this.checkRewarded) this.adUnitData.iconFile.sizeFile = this.imgSize.interIcon[0] + 'x' + this.imgSize.interIcon[1];
            else this.adUnitData.iconFile.sizeFile = '';

            return this.adUnitData.iconFile.sizeFile;
        },
        getImageSize(){
            if (this.checkBanner && this.adUnitData.imgOnly.value) this.adUnitData.imageFile.sizeFile = this.imgSize.bannerImageOnly[0] + 'x' + this.imgSize.bannerImageOnly[1];
            else if(this.checkBanner) this.adUnitData.imageFile.sizeFile = this.imgSize.bannerImage[0] + 'x' + this.imgSize.bannerImage[1];
            else if(this.checkInter) this.adUnitData.imageFile.sizeFile = this.imgSize.interImage[0] + 'x' + this.imgSize.interImage[1];
            else if(this.checkRewarded) this.adUnitData.imageFile.sizeFile = this.imgSize.rewardedImage[0] + 'x' + this.imgSize.rewardedImage[1];
            else this.adUnitData.imageFile.sizeFile = '';

            return this.adUnitData.imageFile.sizeFile;
        },
        getImage43Size(){
            if(this.checkRewarded) this.adUnitData.imageFile43.sizeFile = this.imgSize.rewardedImage43[0] + 'x' + this.imgSize.rewardedImage43[1];
            //else if(this.checkInter) this.adUnitData.imageFile43.sizeFile = this.imgSize.interImage43[0] + 'x' + this.imgSize.interImage43[1];
            else this.adUnitData.imageFile43.sizeFile = '';

            return this.adUnitData.imageFile43.sizeFile;
        },
        getImage11Size(){
            this.adUnitData.imageFile11.sizeFile = this.imgSize.rewardedImage11[0] + 'x' + this.imgSize.rewardedImage11[1];

            return this.adUnitData.imageFile11.sizeFile;
        },
        getImage34Size(){
            this.adUnitData.imageFile34.sizeFile = this.imgSize.rewardedImage34[0] + 'x' + this.imgSize.rewardedImage34[1];

            return this.adUnitData.imageFile34.sizeFile;
        },
        getImage916Size(){
            this.adUnitData.imageFile916.sizeFile = this.imgSize.rewardedImage916[0] + 'x' + this.imgSize.rewardedImage916[1];

            return this.adUnitData.imageFile916.sizeFile;
        },
        getIconFile(){
            return 'value = '+this.adUnitData.iconFile.value + '; type = ' + this.adUnitData.iconFile.typeInput;
        },
        getImageFile(){
            return 'value = '+this.adUnitData.imageFile.value + '; type = ' + this.adUnitData.imageFile.typeInput;
        },
        /* end get image and type files */

        /* check visible input fields */
        checkIconFile(){
            this.adUnitData.iconFile.check = (this.checkBanner && !this.adUnitData.imgOnly.value);
            return this.adUnitData.iconFile.check;
        },
        checkImageFile(){
            this.adUnitData.imageFile.check = this.checkBanner || this.checkInter;
            return this.adUnitData.imageFile.check;
        },
        checkBanner(){
            //this.isBanner = (this.adUnitData.typeBanner.textInput == this.stateBanner);
            this.isBanner = this.adUnitData.typeBanner.value.length > 0 ? (this.adUnitData.typeBanner.value[0] == 0) : false;
            return this.isBanner;
        },
        checkInter(){
            // this.isInter = (this.adUnitData.typeBanner.textInput == this.stateInter);
            this.isInter = this.adUnitData.typeBanner.value.length > 0 ? (this.adUnitData.typeBanner.value[0] == 1) : false;
            return this.isInter;
        },
        checkRewarded(){
            //this.isRewarded = (this.adUnitData.typeBanner.textInput == this.stateRewarded);
            this.isRewarded = this.adUnitData.typeBanner.value.length > 0 ? (this.adUnitData.typeBanner.value[0] == 2) : false;
            return this.isRewarded;
        },
        /* end check visible input fields */

        isChooseImgLeft(){
            return this.adUnitData.chooseImg.value == this.stateChooseLeft || this.adUnitData.chooseImg.value == this.stateChooseBoth;
        },

        isChooseImgRight(){
            return this.adUnitData.chooseImg.value == this.stateChooseRight || this.adUnitData.chooseImg.value == this.stateChooseBoth;
        },

        isTypeBanner(){
            return this.adUnitData.typeBanner.value.length > 0 ? this.adUnitData.typeBanner.value[0] == 0 : false;
        },

        isTypeInter(){
            return this.adUnitData.typeBanner.value.length > 0 ? this.adUnitData.typeBanner.value[0] == 1 : false;
        },

        isTypeRewarded(){
            return this.adUnitData.typeBanner.value.length > 0 ? this.adUnitData.typeBanner.value[0] == 2 : false;
        },

        isTypeBannerImgonly(){
            return this.adUnitData.typeBanner.value.length > 0 ? (this.adUnitData.typeBanner.value[0] == 0 && this.adUnitData.imgOnly.value) : false;
        },

        // rewarded check sizes
        checkSize(){
            let result = false;

            if(this.imageFileStatus) result = (this.adUnitData.imageUrl.value != '');
            else result = (this.adUnitData.imageFile.value != '');

            if(this.videoFileStatus) result = (this.adUnitData.videoUrl.nameFile != '') && result;
            else result = (this.adUnitData.videoFile.nameFile != '') && result;

            this.adUnitData.videoUrl.check = result;

            return this.adUnitData.videoUrl.check;
        },

        checkSize43(){
            let result = false;

            if(this.imageFile43Status) result = (this.adUnitData.imageUrl43.value != '');
            else result = (this.adUnitData.imageFile43.value != '');

            if(this.videoFile43Status) result = (this.adUnitData.videoUrl43.nameFile != '') && result;
            else result = (this.adUnitData.videoFile43.nameFile != '') && result;

            this.adUnitData.videoUrl43.check = result;

            return this.adUnitData.videoUrl43.check;
        },

        checkSize11(){
            let result = false;

            if(this.imageFile11Status) result = (this.adUnitData.imageUrl11.value != '');
            else result = (this.adUnitData.imageFile11.value != '');

            if(this.videoFile11Status) result = (this.adUnitData.videoUrl11.nameFile != '') && result;
            else result = (this.adUnitData.videoFile11.nameFile != '') && result;

            this.adUnitData.videoUrl11.check = result;

            return this.adUnitData.videoUrl11.check;
        },

        checkSize34(){
            let result = false;

            if(this.imageFile34Status) result = (this.adUnitData.imageUrl34.value != '');
            else result = (this.adUnitData.imageFile34.value != '');

            if(this.videoFile34Status) result = (this.adUnitData.videoUrl34.nameFile != '') && result;
            else result = (this.adUnitData.videoFile34.nameFile != '') && result;

            this.adUnitData.videoUrl34.check = result;

            return this.adUnitData.videoUrl34.check;
        },

        checkSize916(){
            let result = false;

            if(this.imageFile916Status) result = (this.adUnitData.imageUrl916.value != '');
            else result = (this.adUnitData.imageFile916.value != '');

            if(this.videoFile916Status) result = (this.adUnitData.videoUrl916.nameFile != '') && result;
            else result = (this.adUnitData.videoFile916.nameFile != '') && result;

            this.adUnitData.videoUrl916.check = result;

            return this.adUnitData.videoUrl916.check;
        },
        // end rewarded check sizes

        // validation form
        isValidForm(){
            let result = false;

            if (this.checkInter) {
                if(this.imageFileStatus) result = (this.adUnitData.imageUrl.value != '');
                else result = (this.adUnitData.imageFile.value != '');

                /*if (result == false) {
                    if(this.imageFile43Status) result = (this.adUnitData.imageUrl43.value != '');
                    else result = (this.adUnitData.imageFile43.value != '');
                }*/
            } else if (this.checkRewarded) {
                // 16x9
                if(this.imageFileStatus) result = (this.adUnitData.imageUrl.value != '');
                else result = (this.adUnitData.imageFile.value != '');

                if(this.videoFileStatus) result = (this.adUnitData.videoUrl.nameFile != '') && result;
                else result = (this.adUnitData.videoFile.nameFile != '') && result;

                /*if (result == false) {
                    // 4x3
                    if(this.imageFile43Status) result = (this.adUnitData.imageUrl43.value != '');
                    else result = (this.adUnitData.imageFile43.value != '');

                    if(this.videoFile43Status) result = (this.adUnitData.videoUrl43.nameFile != '') && result;
                    else result = (this.adUnitData.videoFile43.nameFile != '') && result;
                }

                if (result == false) {
                    // 1x1
                    if(this.imageFile11Status) result = (this.adUnitData.imageUrl11.value != '');
                    else result = (this.adUnitData.imageFile11.value != '');

                    if(this.videoFile11Status) result = (this.adUnitData.videoUrl11.nameFile != '') && result;
                    else result = (this.adUnitData.videoFile11.nameFile != '') && result;
                }

                if (result == false) {
                    // 3x4
                    if(this.imageFile34Status) result = (this.adUnitData.imageUrl34.value != '');
                    else result = (this.adUnitData.imageFile34.value != '');

                    if(this.videoFile34Status) result = (this.adUnitData.videoUrl34.nameFile != '') && result;
                    else result = (this.adUnitData.videoFile34.nameFile != '') && result;
                }

                if (result == false) {
                    // 9x16
                    if(this.imageFile916Status) result = (this.adUnitData.imageUrl916.value != '');
                    else result = (this.adUnitData.imageFile916.value != '');

                    if(this.videoFile916Status) result = (this.adUnitData.videoUrl916.nameFile != '') && result;
                    else result = (this.adUnitData.videoFile916.nameFile != '') && result;
                }*/
            } else {
                if(this.imageFileStatus) result = (this.adUnitData.imageUrl.value != '');
                else result = (this.adUnitData.imageFile.value != '');
            }

            if((this.checkIconFile || this.checkInter || this.checkRewarded) && result){
                if(this.iconFileStatus) result = (this.adUnitData.iconUrl.value != '');
                else result = (this.adUnitData.iconFile.value != '');
            }

            if(this.adUnitData.packageName.value.length == 0) result = false;

            for(let i = 0; i < this.countryData.length; i++){
                if(this.countryData[i].title == '' || this.countryData[i].description == '') result = false;
            }

            return result;
        }
    },
    methods: {
        // libs
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                /*--- Get width and height size video ---*/
                // const videoEl = document.createElement('video');
                // let that = this;
                // videoEl.addEventListener('loadedmetadata', function(e) {
                //     that.videoW = this.videoWidth;
                //     that.videoH = this.videoHeight;
                //     that.videoDuration = Math.round(this.duration);

                //     console.log('loadedmetadata = ', that.videoW, that.videoH, that.videoDuration, this.width, this.height, this.offsetWidth, this.offsetHeight);
                // }, false);
                /*--- End get width and height size video ---*/

                reader.readAsDataURL(file);
                reader.onload = () => {
                    //videoEl.src = reader.result // Get width and height size video

                    return resolve(reader.result);
                }
                reader.onerror = error => reject(error);
            });
        },
        setDataFiles(str){
            let data = str.replace('base64,', '').replace('data:image/', '').split(';');
            if(data[0] == 'jpeg') data[0] = 'jpg';

            return data;
        },
        setDataVideos(str){
            let data = str.replace('base64,', '').replace('data:video/', '').split(';');

            return data;
        },

        backToPrev(){
            this.$router.back();
        },

        copyLink(type){
            let link = null;

            if (type == 'icon') link = this.adUnitData.iconFile.nameFile;
            if (type == 'icon_banner') link = this.adUnitData.iconFile.nameFile;
            if (type == 'image') link = this.adUnitData.imageFile.nameFile;
            if (type == 'image43') link = this.adUnitData.imageFile43.nameFile;
            if (type == 'image11') link = this.adUnitData.imageFile11.nameFile;
            if (type == 'image34') link = this.adUnitData.imageFile34.nameFile;
            if (type == 'image916') link = this.adUnitData.imageFile916.nameFile;
            if (type == 'video') link = this.adUnitData.videoFile.nameFile;
            if (type == 'video43') link = this.adUnitData.videoFile43.nameFile;
            if (type == 'video11') link = this.adUnitData.videoFile11.nameFile;
            if (type == 'video34') link = this.adUnitData.videoFile34.nameFile;
            if (type == 'video916') link = this.adUnitData.videoFile916.nameFile;

            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
                        this.copyLinkDescribe(type);
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
console.log('clipboard');
            } else if (document.queryCommandSupported('copy')) {
                let textArea = document.createElement("textarea");
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                let res = document.execCommand('copy');

                document.body.removeChild(textArea);
console.log('exec command copy');
                this.copyLinkDescribe(type);
            }
        },

        copyLinkDescribe(type) {
            if(type == 'icon') this.copyLinkIcon = this.copiedLinkText[this.getLangId];
                if(type == 'icon_banner') this.copyLinkIconBanner = this.copiedLinkText[this.getLangId];
                if(type == 'image') this.copyLinkImage = this.copiedLinkText[this.getLangId];
                if(type == 'image43') this.copyLinkImage43 = this.copiedLinkText[this.getLangId];
                if(type == 'image11') this.copyLinkImage11 = this.copiedLinkText[this.getLangId];
                if(type == 'image34') this.copyLinkImage34 = this.copiedLinkText[this.getLangId];
                if(type == 'image916') this.copyLinkImage916 = this.copiedLinkText[this.getLangId];
                if(type == 'video') this.copyLinkVideo = this.copiedLinkText[this.getLangId];
                if(type == 'video43') this.copyLinkVideo43 = this.copiedLinkText[this.getLangId];
                if(type == 'video11') this.copyLinkVideo11 = this.copiedLinkText[this.getLangId];
                if(type == 'video34') this.copyLinkVideo34 = this.copiedLinkText[this.getLangId];
                if(type == 'video916') this.copyLinkVideo916 = this.copiedLinkText[this.getLangId];

                setTimeout(() => {
                    if(type == 'icon') this.copyLinkIcon = this.copyLinkText[this.getLangId];
                    if(type == 'icon_banner') this.copyLinkIconBanner = this.copyLinkText[this.getLangId];
                    if(type == 'image') this.copyLinkImage = this.copyLinkText[this.getLangId];
                    if(type == 'image43') this.copyLinkImage43 = this.copyLinkText[this.getLangId];
                    if(type == 'image11') this.copyLinkImage11 = this.copyLinkText[this.getLangId];
                    if(type == 'image34') this.copyLinkImage34 = this.copyLinkText[this.getLangId];
                    if(type == 'image916') this.copyLinkImage916 = this.copyLinkText[this.getLangId];
                    if(type == 'video') this.copyLinkVideo = this.copyLinkText[this.getLangId];
                    if(type == 'video43') this.copyLinkVideo43 = this.copyLinkText[this.getLangId];
                    if(type == 'video11') this.copyLinkVideo11 = this.copyLinkText[this.getLangId];
                    if(type == 'video34') this.copyLinkVideo34 = this.copyLinkText[this.getLangId];
                    if(type == 'video916') this.copyLinkVideo916 = this.copyLinkText[this.getLangId];
                }, 1000);
        },

        changeImageOnly(){
            this.adUnitData.imgOnly.value = !this.adUnitData.imgOnly.value;

            this.clearImages();
        },

        clearImages(){
            this.clearIcon();
            this.clearImg();
            this.clearImg43();
        },

        clearRewardedImages(){
            this.clearImages();
            this.clearImg11();
            this.clearImg34();
            this.clearImg916();
        },

        clearIcon(){
            this.adUnitData.iconFile.value = '';
            this.adUnitData.iconFile.typeInput = '';
            this.adUnitData.iconFile.nameFile = '';
            this.adUnitData.iconFile.viewFile = '';
            this.adUnitData.iconFile.sizeFile = '';
            this.adUnitData.iconFile.value1 = '';
            this.adUnitData.iconFile.typeInput1 = '';
            this.adUnitData.iconFile.value2 = '';
            this.adUnitData.iconFile.typeInput2 = '';
            this.adUnitData.iconUrl.value = '';
        },

        clearImg(){
            this.adUnitData.imageFile.value = '';
            this.adUnitData.imageFile.typeInput = '';
            this.adUnitData.imageFile.nameFile = '';
            this.adUnitData.imageFile.viewFile = '';
            this.adUnitData.imageFile.sizeFile = '';
            this.adUnitData.imageFile.value1 = '';
            this.adUnitData.imageFile.typeInput1 = '';
            this.adUnitData.imageFile.value2 = '';
            this.adUnitData.imageFile.typeInput2 = '';
            this.adUnitData.imageUrl.value = '';
        },

        clearImg43(){
            this.adUnitData.imageFile43.value = '';
            this.adUnitData.imageFile43.typeInput = '';
            this.adUnitData.imageFile43.nameFile = '';
            this.adUnitData.imageFile43.viewFile = '';
            this.adUnitData.imageFile43.sizeFile = '';
            this.adUnitData.imageFile43.value1 = '';
            this.adUnitData.imageFile43.typeInput1 = '';
            this.adUnitData.imageFile43.value2 = '';
            this.adUnitData.imageFile43.typeInput2 = '';
            this.adUnitData.imageUrl43.value = '';
        },

        clearImg11(){
            this.adUnitData.imageFile11.value = '';
            this.adUnitData.imageFile11.typeInput = '';
            this.adUnitData.imageFile11.nameFile = '';
            this.adUnitData.imageFile11.viewFile = '';
            this.adUnitData.imageFile11.sizeFile = '';
            this.adUnitData.imageFile11.value1 = '';
            this.adUnitData.imageFile11.typeInput1 = '';
            this.adUnitData.imageFile11.value2 = '';
            this.adUnitData.imageFile11.typeInput2 = '';
            this.adUnitData.imageUrl11.value = '';
        },

        clearImg34(){
            this.adUnitData.imageFile34.value = '';
            this.adUnitData.imageFile34.typeInput = '';
            this.adUnitData.imageFile34.nameFile = '';
            this.adUnitData.imageFile34.viewFile = '';
            this.adUnitData.imageFile34.sizeFile = '';
            this.adUnitData.imageFile34.value1 = '';
            this.adUnitData.imageFile34.typeInput1 = '';
            this.adUnitData.imageFile34.value2 = '';
            this.adUnitData.imageFile34.typeInput2 = '';
            this.adUnitData.imageUrl34.value = '';
        },

        clearImg916(){
            this.adUnitData.imageFile916.value = '';
            this.adUnitData.imageFile916.typeInput = '';
            this.adUnitData.imageFile916.nameFile = '';
            this.adUnitData.imageFile916.viewFile = '';
            this.adUnitData.imageFile916.sizeFile = '';
            this.adUnitData.imageFile916.value1 = '';
            this.adUnitData.imageFile916.typeInput1 = '';
            this.adUnitData.imageFile916.value2 = '';
            this.adUnitData.imageFile916.typeInput2 = '';
            this.adUnitData.imageUrl916.value = '';
        },

        clearVideos(){
            this.clearVideo();
            this.clearVideo43();
            this.clearVideo11();
            this.clearVideo34();
            this.clearVideo916();
        },

        clearVideo(){
            this.adUnitData.videoUrl.value = '';
            this.adUnitData.videoUrl.typeInput = '',
            this.adUnitData.videoUrl.nameFile = '';
            this.adUnitData.videoUrl.viewFile = '';
            this.adUnitData.videoUrl.check = false;

            this.adUnitData.videoFile.value = '';
            this.adUnitData.videoFile.typeInput = '',
            this.adUnitData.videoFile.nameFile = '';
            this.adUnitData.videoFile.viewFile = '';
            this.adUnitData.videoFile.check = false;
        },

        clearVideo43(){
            this.adUnitData.videoUrl43.value = '';
            this.adUnitData.videoUrl43.typeInput = '',
            this.adUnitData.videoUrl43.nameFile = '';
            this.adUnitData.videoUrl43.viewFile = '';
            this.adUnitData.videoUrl43.check = false;

            this.adUnitData.videoFile43.value = '';
            this.adUnitData.videoFile43.typeInput = '',
            this.adUnitData.videoFile43.nameFile = '';
            this.adUnitData.videoFile43.viewFile = '';
            this.adUnitData.videoFile43.check = false;
        },

        clearVideo11(){
            this.adUnitData.videoUrl11.value = '';
            this.adUnitData.videoUrl11.typeInput = '',
            this.adUnitData.videoUrl11.nameFile = '';
            this.adUnitData.videoUrl11.viewFile = '';
            this.adUnitData.videoUrl11.check = false;

            this.adUnitData.videoFile11.value = '';
            this.adUnitData.videoFile11.typeInput = '',
            this.adUnitData.videoFile11.nameFile = '';
            this.adUnitData.videoFile11.viewFile = '';
            this.adUnitData.videoFile11.check = false;
        },

        clearVideo34(){
            this.adUnitData.videoUrl34.value = '';
            this.adUnitData.videoUrl34.typeInput = '',
            this.adUnitData.videoUrl34.nameFile = '';
            this.adUnitData.videoUrl34.viewFile = '';
            this.adUnitData.videoUrl34.check = false;

            this.adUnitData.videoFile34.value = '';
            this.adUnitData.videoFile34.typeInput = '',
            this.adUnitData.videoFile34.nameFile = '';
            this.adUnitData.videoFile34.viewFile = '';
            this.adUnitData.videoFile34.check = false;
        },

        clearVideo916(){
            this.adUnitData.videoUrl916.value = '';
            this.adUnitData.videoUrl916.typeInput = '',
            this.adUnitData.videoUrl916.nameFile = '';
            this.adUnitData.videoUrl916.viewFile = '';
            this.adUnitData.videoUrl916.check = false;

            this.adUnitData.videoFile916.value = '';
            this.adUnitData.videoFile916.typeInput = '',
            this.adUnitData.videoFile916.nameFile = '';
            this.adUnitData.videoFile916.viewFile = '';
            this.adUnitData.videoFile916.check = false;
        },

        // files
        setIconFile(e){
            let _this = this;

            if (this.iconFileStatus) {
                if(this.adUnitData.iconUrl.value == '') return
                if(this.adUnitData.iconUrl.value.indexOf('http') != 0) this.errorIcon('url');

                fetch(this.adUnitData.iconUrl.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createIcon(blob);
                    })
                    .catch(function(){
                        _this.errorIcon('blocked');
                    });
            } else {
                this.adUnitData.iconFile.nameFile = e.target.files[0].name;

                this.createIcon(e.target.files[0]);
            }
        },
        createIcon(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        if (_this.isTypeBanner && !_this.isTypeBannerImgonly) {
                            tmpWidth = _this.imgSize.bannerIcon[0];
                            tmpHeight = _this.imgSize.bannerIcon[1];
                        } else if (_this.isTypeInter || _this.isTypeRewarded) {
                            tmpWidth = _this.imgSize.interIcon[0];
                            tmpHeight = _this.imgSize.interIcon[1];
                        }

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.iconFile.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.iconFile.value = newData[1];
                        this.adUnitData.iconFile.typeInput = newData[0];

                        if (this.isTypeInter || _this.isTypeRewarded) {
                            this.setResizeFile(data, this.imgSize.interIconD[0][0], this.imgSize.interIconD[0][1], newData[0], {"type": 'icon', "step": 1});
                            this.setResizeFile(data, this.imgSize.interIconD[1][0], this.imgSize.interIconD[1][1], newData[0], {"type": 'icon', "step": 2});
                        }
                    },
                    error => {
                        this.errorIcon('size');
                    }
                );
            });
        },
        errorIcon(status){
            this.clearIcon();

            if (status == 'url') {
                this.mesIconStatusUrl = true;
            } else if (status == 'size') {
                this.mesIconStatus = true;
            } else if (status == 'blocked') {
                this.mesIconStatusBlocked = true;
            }

            setTimeout(() => {
                this.mesIconStatus = false;
                this.mesIconStatusUrl = false;
                this.mesIconStatusBlocked = false;
            }, 3000);
        },

        setImageFile(e){
            let _this = this;

            if (this.imageFileStatus) {
                if(this.adUnitData.imageUrl.value == '') return
                if(this.adUnitData.imageUrl.value.indexOf('http') != 0) this.errorImage('url', 'image');

                fetch(this.adUnitData.imageUrl.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createImage(blob);
                    })
                    .catch(function(){
                        _this.errorImage('blocked', 'image');
                    });
            } else {
                this.adUnitData.imageFile.nameFile = e.target.files[0].name;

                this.createImage(e.target.files[0]);
            }
        },
        createImage(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        if (_this.isTypeBanner && _this.isTypeBannerImgonly) {
                            tmpWidth = _this.imgSize.bannerImageOnly[0];
                            tmpHeight = _this.imgSize.bannerImageOnly[1];
                        } else if (_this.isTypeBanner) {
                            tmpWidth = _this.imgSize.bannerImage[0];
                            tmpHeight = _this.imgSize.bannerImage[1];
                        } else if (_this.isTypeInter) {
                            tmpWidth = _this.imgSize.interImage[0];
                            tmpHeight = _this.imgSize.interImage[1];
                        } else if (_this.isTypeRewarded) {
                            tmpWidth = _this.imgSize.rewardedImage[0];
                            tmpHeight = _this.imgSize.rewardedImage[1];
                        }

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.imageFile.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.imageFile.value = newData[1];
                        this.adUnitData.imageFile.typeInput = newData[0];

                        if (this.isTypeBannerImgonly) {
                            this.setResizeFile(data, this.imgSize.bannerImageOnlyD[0], this.imgSize.bannerImageOnlyD[1], newData[0], {"type": 'image', "step": 1});
                        }

                        if (this.isTypeInter) {
                            this.setResizeFile(data, this.imgSize.interImageD[0][0], this.imgSize.interImageD[0][1], newData[0], {"type": 'image', "step": 1});
                            this.setResizeFile(data, this.imgSize.interImageD[1][0], this.imgSize.interImageD[1][1], newData[0], {"type": 'image', "step": 2});
                        } else if (this.isTypeRewarded) {
                            this.setResizeFile(data, this.imgSize.rewardedImageD[0][0], this.imgSize.rewardedImageD[0][1], newData[0], {"type": 'image', "step": 1});
                            this.setResizeFile(data, this.imgSize.rewardedImageD[1][0], this.imgSize.rewardedImageD[1][1], newData[0], {"type": 'image', "step": 2});
                        }
                    },
                    error => {
                        _this.errorImage('size', 'image');
                    }
                );
            });
        },

        // image 4x3
        setImageFile43(e){
            let _this = this;

            if (this.imageFile43Status) {
                if(this.adUnitData.imageUrl43.value == '') return
                if(this.adUnitData.imageUrl43.value.indexOf('http') != 0) this.errorImage('url', 'image43');

                fetch(this.adUnitData.imageUrl43.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createImage43(blob);
                    })
                    .catch(function(){
                        _this.errorImage('blocked', 'image43');
                    });
            } else {
                this.adUnitData.imageFile43.nameFile = e.target.files[0].name;

                this.createImage43(e.target.files[0]);
            }
        },
        createImage43(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        if (_this.isTypeRewarded) {
                            tmpWidth = _this.imgSize.rewardedImage43[0];
                            tmpHeight = _this.imgSize.rewardedImage43[1];
                        } else if (_this.isTypeInter) {
                            tmpWidth = _this.imgSize.interImage43[0];
                            tmpHeight = _this.imgSize.interImage43[1];
                        }

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.imageFile43.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.imageFile43.value = newData[1];
                        this.adUnitData.imageFile43.typeInput = newData[0];

                        if (this.isTypeInter) {
                            this.setResizeFile(data, this.imgSize.interImage43D[0][0], this.imgSize.interImage43D[0][1], newData[0], {"type": 'image43', "step": 1});
                            this.setResizeFile(data, this.imgSize.interImage43D[1][0], this.imgSize.interImage43D[1][1], newData[0], {"type": 'image43', "step": 2});
                        } else if (this.isTypeRewarded) {
                            this.setResizeFile(data, this.imgSize.rewardedImage43D[0][0], this.imgSize.rewardedImage43D[0][1], newData[0], {"type": 'image43', "step": 1});
                            this.setResizeFile(data, this.imgSize.rewardedImage43D[1][0], this.imgSize.rewardedImage43D[1][1], newData[0], {"type": 'image43', "step": 2});
                        }
                    },
                    error => {
                        _this.errorImage('size', 'image43');
                    }
                );
            });
        },

        // image 1x1
        setImageFile11(e){
            let _this = this;

            if (this.imageFile11Status) {
                if(this.adUnitData.imageUrl11.value == '') return
                if(this.adUnitData.imageUrl11.value.indexOf('http') != 0) this.errorImage('url', 'image11');

                fetch(this.adUnitData.imageUrl11.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createImage11(blob);
                    })
                    .catch(function(){
                        _this.errorImage('blocked', 'image11');
                    });
            } else {
                this.adUnitData.imageFile11.nameFile = e.target.files[0].name;

                this.createImage11(e.target.files[0]);
            }
        },
        createImage11(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        tmpWidth = _this.imgSize.rewardedImage11[0];
                        tmpHeight = _this.imgSize.rewardedImage11[1];

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.imageFile11.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.imageFile11.value = newData[1];
                        this.adUnitData.imageFile11.typeInput = newData[0];

                        this.setResizeFile(data, this.imgSize.rewardedImage11D[0][0], this.imgSize.rewardedImage11D[0][1], newData[0], {"type": 'image11', "step": 1});
                        this.setResizeFile(data, this.imgSize.rewardedImage11D[1][0], this.imgSize.rewardedImage11D[1][1], newData[0], {"type": 'image11', "step": 2});
                    },
                    error => {
                        _this.errorImage('size', 'image11');
                    }
                );
            });
        },

        // image 3x4
        setImageFile34(e){
            let _this = this;

            if (this.imageFile34Status) {
                if(this.adUnitData.imageUrl34.value == '') return
                if(this.adUnitData.imageUrl34.value.indexOf('http') != 0) this.errorImage('url', 'image34');

                fetch(this.adUnitData.imageUrl34.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createImage34(blob);
                    })
                    .catch(function(){
                        _this.errorImage('blocked', 'image34');
                    });
            } else {
                this.adUnitData.imageFile34.nameFile = e.target.files[0].name;

                this.createImage34(e.target.files[0]);
            }
        },
        createImage34(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        tmpWidth = _this.imgSize.rewardedImage34[0];
                        tmpHeight = _this.imgSize.rewardedImage34[1];

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.imageFile34.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.imageFile34.value = newData[1];
                        this.adUnitData.imageFile34.typeInput = newData[0];

                        this.setResizeFile(data, this.imgSize.rewardedImage34D[0][0], this.imgSize.rewardedImage34D[0][1], newData[0], {"type": 'image34', "step": 1});
                        this.setResizeFile(data, this.imgSize.rewardedImage34D[1][0], this.imgSize.rewardedImage34D[1][1], newData[0], {"type": 'image34', "step": 2});
                    },
                    error => {
                        _this.errorImage('size', 'image34');
                    }
                );
            });
        },

        // image 9x16
        setImageFile916(e){
            let _this = this;

            if (this.imageFile916Status) {
                if(this.adUnitData.imageUrl916.value == '') return
                if(this.adUnitData.imageUrl916.value.indexOf('http') != 0) this.errorImage('url', 'image916');

                fetch(this.adUnitData.imageUrl916.value)
                    .then(res => res.blob())
                    .then(blob => {
                        _this.createImage916(blob);
                    })
                    .catch(function(){
                        _this.errorImage('blocked', 'image916');
                    });
            } else {
                this.adUnitData.imageFile916.nameFile = e.target.files[0].name;

                this.createImage916(e.target.files[0]);
            }
        },
        createImage916(file){
            let _this = this;

            this.getBase64(file).then(data => {
                let tmpImage = new Image();
                tmpImage.src = data;

                let prom = new Promise((resolve, reject) => {
                    tmpImage.onload = function(imageEvent){
                        let tmpWidth, tmpHeight;

                        tmpWidth = _this.imgSize.rewardedImage916[0];
                        tmpHeight = _this.imgSize.rewardedImage916[1];

                        if (tmpImage.width == tmpWidth && tmpImage.height == tmpHeight) resolve();
                        else reject();
                    }
                });

                prom.then(
                    result => {
                        this.adUnitData.imageFile916.viewFile = data;
                        let newData = this.setDataFiles(data);

                        this.adUnitData.imageFile916.value = newData[1];
                        this.adUnitData.imageFile916.typeInput = newData[0];

                        this.setResizeFile(data, this.imgSize.rewardedImage916D[0][0], this.imgSize.rewardedImage916D[0][1], newData[0], {"type": 'image916', "step": 1});
                        this.setResizeFile(data, this.imgSize.rewardedImage916D[1][0], this.imgSize.rewardedImage916D[1][1], newData[0], {"type": 'image916', "step": 2});
                    },
                    error => {
                        _this.errorImage('size', 'image916');
                    }
                );
            });
        },

        errorImage(status, typeImage){
            if(typeImage == 'image') this.clearImg();
            if(typeImage == 'image43') this.clearImg43();
            if(typeImage == 'image11') this.clearImg11();
            if(typeImage == 'image34') this.clearImg34();
            if(typeImage == 'image916') this.clearImg916();

            if (status == 'url') {
                if(typeImage == 'image') this.mesImageStatusUrl = true;
                if(typeImage == 'image43') this.mesImage43StatusUrl = true;
                if(typeImage == 'image11') this.mesImage11StatusUrl = true;
                if(typeImage == 'image34') this.mesImage34StatusUrl = true;
                if(typeImage == 'image916') this.mesImage916StatusUrl = true;
            } else if (status == 'size') {
                if(typeImage == 'image') this.mesImageStatus = true;
                if(typeImage == 'image43') this.mesImage43Status = true;
                if(typeImage == 'image11') this.mesImage11Status = true;
                if(typeImage == 'image34') this.mesImage34Status = true;
                if(typeImage == 'image916') this.mesImage916Status = true;
            } else if (status == 'blocked') {
                if(typeImage == 'image') this.mesImageStatusBlocked = true;
                if(typeImage == 'image43') this.mesImage43StatusBlocked = true;
                if(typeImage == 'image11') this.mesImage11StatusBlocked = true;
                if(typeImage == 'image34') this.mesImage34StatusBlocked = true;
                if(typeImage == 'image916') this.mesImage916StatusBlocked = true;
            }

            setTimeout(() => {
                this.mesImageStatus = false;
                this.mesImageStatusUrl = false;
                this.mesImageStatusBlocked = false;

                this.mesImage43Status = false;
                this.mesImage43StatusUrl = false;
                this.mesImage43StatusBlocked = false;
                this.mesImage11Status = false;
                this.mesImage11StatusUrl = false;
                this.mesImage11StatusBlocked = false;
                this.mesImage34Status = false;
                this.mesImage34StatusUrl = false;
                this.mesImage34StatusBlocked = false;
                this.mesImage916Status = false;
                this.mesImage916StatusUrl = false;
                this.mesImage916StatusBlocked = false;
            }, 3000);
        },

        setResizeFile(data, width, height, ext, options){
            let _this = this;
            let image = new Image();

            if(ext == 'jpg') ext = 'jpeg';

            image.onload = function (imageEvent) {
                // Resize the image
                let canvas = document.createElement('canvas');

                canvas.width = width;
                canvas.height = height;

                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                let resizedImage = canvas.toDataURL('image/' + ext);
                let tmpData = _this.setDataFiles(resizedImage);

                if (options['type'] == 'icon') {
                    if (options['step'] == 1) {
                        _this.adUnitData.iconFile.value1 = tmpData[1];
                        _this.adUnitData.iconFile.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.iconFile.value2 = tmpData[1];
                        _this.adUnitData.iconFile.typeInput2 = tmpData[0];
                    }
                } else if(options['type'] == 'image43') {
                    if (options['step'] == 1) {
                        _this.adUnitData.imageFile43.value1 = tmpData[1];
                        _this.adUnitData.imageFile43.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.imageFile43.value2 = tmpData[1];
                        _this.adUnitData.imageFile43.typeInput2 = tmpData[0];
                    }
                } else if(options['type'] == 'image11') {
                    if (options['step'] == 1) {
                        _this.adUnitData.imageFile11.value1 = tmpData[1];
                        _this.adUnitData.imageFile11.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.imageFile11.value2 = tmpData[1];
                        _this.adUnitData.imageFile11.typeInput2 = tmpData[0];
                    }
                } else if(options['type'] == 'image34') {
                    if (options['step'] == 1) {
                        _this.adUnitData.imageFile34.value1 = tmpData[1];
                        _this.adUnitData.imageFile34.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.imageFile34.value2 = tmpData[1];
                        _this.adUnitData.imageFile34.typeInput2 = tmpData[0];
                    }
                } else if(options['type'] == 'image916') {
                    if (options['step'] == 1) {
                        _this.adUnitData.imageFile916.value1 = tmpData[1];
                        _this.adUnitData.imageFile916.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.imageFile916.value2 = tmpData[1];
                        _this.adUnitData.imageFile916.typeInput2 = tmpData[0];
                    }
                } else {
                    if (options['step'] == 1) {
                        _this.adUnitData.imageFile.value1 = tmpData[1];
                        _this.adUnitData.imageFile.typeInput1 = tmpData[0];
                    } else {
                        _this.adUnitData.imageFile.value2 = tmpData[1];
                        _this.adUnitData.imageFile.typeInput2 = tmpData[0];
                    }
                }

            }
            image.src = data;
        },

        // video 16x9
        validateVideo(e){
            let _this = this;
            const proxyurl = "https://cors-anywhere.herokuapp.com/";

            if (this.videoFileStatus) {
                if(this.adUnitData.videoUrl.nameFile == '') {
                    this.clearVideo();
                    return;
                }
                if(this.adUnitData.videoUrl.nameFile.indexOf('http') != 0) this.errorVideo('url', 'video');

                fetch(proxyurl + this.adUnitData.videoUrl.nameFile)
                        .then(res => {
                            return res.blob()
                        })
                        .then(blob => {
                            if(blob.type == undefined || blob.type == "") throw Error();
                            _this.saveVideo(blob);
                        })
                        .catch(function(){
                            _this.errorVideo('blocked', 'video');
                        });
            } else {
                this.adUnitData.videoFile.nameFile = e.target.files[0].name;

                this.saveVideo(e.target.files[0]);
            }
        },

        saveVideo(file){
            let _this = this;

            /*--- Get width and height ---*/
            // this.$nextTick(() => {
            //     const url = URL.createObjectURL(file)
            //     const video = document.createElement('video')

            //     video.src = url
            //     video.load() // fetches metadata

            //     video.onloadedmetadata = evt => {
            //         // Revoke when you don't need the url any more to release any reference
            //         URL.revokeObjectURL(url)
            //         console.log(video, video.videoWidth, video.videoHeight)
            //     }
            // });
            /*--- End get width and height ---*/

            this.getBase64(file).then(data => {
                this.adUnitData.videoFile.viewFile = data;
                let newData = this.setDataVideos(data);

                this.adUnitData.videoFile.value = newData[1];

                if(this.videoFileStatus) this.adUnitData.videoUrl.typeInput = newData[0];
                else this.adUnitData.videoFile.typeInput = newData[0];
            });
        },

        // video 4x3
        validateVideo43(e){
            let _this = this;
            const proxyurl = "https://cors-anywhere.herokuapp.com/";

            if (this.videoFile43Status) {
                if(this.adUnitData.videoUrl43.nameFile == '') {
                    this.clearVideo43();
                    return;
                }
                if(this.adUnitData.videoUrl43.nameFile.indexOf('http') != 0) this.errorVideo('url', 'video43');

                fetch(proxyurl + this.adUnitData.videoUrl43.nameFile)
                        .then(res => {
                            return res.blob()
                        })
                        .then(blob => {
                            if(blob.type == undefined || blob.type == "") throw Error();
                            _this.saveVideo43(blob);
                        })
                        .catch(function(){
                            _this.errorVideo('blocked', 'video43');
                        });
            } else {
                this.adUnitData.videoFile43.nameFile = e.target.files[0].name;

                this.saveVideo43(e.target.files[0]);
            }
        },

        saveVideo43(file){
            let _this = this;

            this.getBase64(file).then(data => {
                this.adUnitData.videoFile43.viewFile = data;
                let newData = this.setDataVideos(data);

                this.adUnitData.videoFile43.value = newData[1];

                if(this.videoFile43Status) this.adUnitData.videoUrl43.typeInput = newData[0];
                else this.adUnitData.videoFile43.typeInput = newData[0];
            });
        },

        // video 1x1
        validateVideo11(e){
            let _this = this;
            const proxyurl = "https://cors-anywhere.herokuapp.com/";

            if (this.videoFile11Status) {
                if(this.adUnitData.videoUrl11.nameFile == '') {
                    this.clearVideo11();
                    return;
                }
                if(this.adUnitData.videoUrl11.nameFile.indexOf('http') != 0) this.errorVideo('url', 'video11');

                fetch(proxyurl + this.adUnitData.videoUrl11.nameFile)
                        .then(res => {
                            return res.blob()
                        })
                        .then(blob => {
                            if(blob.type == undefined || blob.type == "") throw Error();
                            _this.saveVideo11(blob);
                        })
                        .catch(function(){
                            _this.errorVideo('blocked', 'video11');
                        });
            } else {
                this.adUnitData.videoFile11.nameFile = e.target.files[0].name;

                this.saveVideo11(e.target.files[0]);
            }
        },

        saveVideo11(file){
            let _this = this;

            this.getBase64(file).then(data => {
                this.adUnitData.videoFile11.viewFile = data;
                let newData = this.setDataVideos(data);

                this.adUnitData.videoFile11.value = newData[1];

                if(this.videoFile11Status) this.adUnitData.videoUrl11.typeInput = newData[0];
                else this.adUnitData.videoFile11.typeInput = newData[0];
            });
        },

        // video 3x4
        validateVideo34(e){
            let _this = this;
            const proxyurl = "https://cors-anywhere.herokuapp.com/";

            if (this.videoFile34Status) {
                if(this.adUnitData.videoUrl34.nameFile == '') {
                    this.clearVideo34();
                    return;
                }
                if(this.adUnitData.videoUrl34.nameFile.indexOf('http') != 0) this.errorVideo('url', 'video34');

                fetch(proxyurl + this.adUnitData.videoUrl34.nameFile)
                        .then(res => {
                            return res.blob()
                        })
                        .then(blob => {
                            if(blob.type == undefined || blob.type == "") throw Error();
                            _this.saveVideo34(blob);
                        })
                        .catch(function(){
                            _this.errorVideo('blocked', 'video34');
                        });
            } else {
                this.adUnitData.videoFile34.nameFile = e.target.files[0].name;

                this.saveVideo34(e.target.files[0]);
            }
        },

        saveVideo34(file){
            let _this = this;

            this.getBase64(file).then(data => {
                this.adUnitData.videoFile34.viewFile = data;
                let newData = this.setDataVideos(data);

                this.adUnitData.videoFile34.value = newData[1];

                if(this.videoFile34Status) this.adUnitData.videoUrl34.typeInput = newData[0];
                else this.adUnitData.videoFile34.typeInput = newData[0];
            });
        },

        // video 9x16
        validateVideo916(e){
            let _this = this;
            const proxyurl = "https://cors-anywhere.herokuapp.com/";

            if (this.videoFile916Status) {
                if(this.adUnitData.videoUrl916.nameFile == '') {
                    this.clearVideo916();
                    return;
                }
                if(this.adUnitData.videoUrl916.nameFile.indexOf('http') != 0) this.errorVideo('url', 'video916');

                fetch(proxyurl + this.adUnitData.videoUrl916.nameFile)
                        .then(res => {
                            return res.blob()
                        })
                        .then(blob => {
                            if(blob.type == undefined || blob.type == "") throw Error();
                            _this.saveVideo916(blob);
                        })
                        .catch(function(){
                            _this.errorVideo('blocked', 'video916');
                        });
            } else {
                this.adUnitData.videoFile916.nameFile = e.target.files[0].name;

                this.saveVideo916(e.target.files[0]);
            }
        },

        saveVideo916(file){
            let _this = this;

            this.getBase64(file).then(data => {
                this.adUnitData.videoFile916.viewFile = data;
                let newData = this.setDataVideos(data);

                this.adUnitData.videoFile916.value = newData[1];

                if(this.videoFile916Status) this.adUnitData.videoUrl916.typeInput = newData[0];
                else this.adUnitData.videoFile916.typeInput = newData[0];
            });
        },

        errorVideo(status, typeVideo){
            if(typeVideo == 'video') this.clearVideo();
            if(typeVideo == 'video43') this.clearVideo43();
            if(typeVideo == 'video11') this.clearVideo11();
            if(typeVideo == 'video34') this.clearVideo34();
            if(typeVideo == 'video916') this.clearVideo916();

            if (status == 'url') {
                if(typeVideo == 'video') this.mesVideoStatusUrl = true;
                if(typeVideo == 'video43') this.mesVideo43StatusUrl = true;
                if(typeVideo == 'video11') this.mesVideo11StatusUrl = true;
                if(typeVideo == 'video34') this.mesVideo34StatusUrl = true;
                if(typeVideo == 'video916') this.mesVideo916StatusUrl = true;
            } else if (status == 'blocked') {
                if(typeVideo == 'video') this.mesVideoStatusBlocked = true;
                if(typeVideo == 'video43') this.mesVideo43StatusBlocked = true;
                if(typeVideo == 'video11') this.mesVideo11StatusBlocked = true;
                if(typeVideo == 'video34') this.mesVideo34StatusBlocked = true;
                if(typeVideo == 'video916') this.mesVideo916StatusBlocked = true;
            }

            setTimeout(() => {
                this.mesVideoStatusUrl = false;
                this.mesVideo43StatusUrl = false;
                this.mesVideo11StatusUrl = false;
                this.mesVideo34StatusUrl = false;
                this.mesVideo916StatusUrl = false;

                this.mesVideoStatusBlocked = false;
                this.mesVideo43StatusBlocked = false;
                this.mesVideo11StatusBlocked = false;
                this.mesVideo34StatusBlocked = false;
                this.mesVideo916StatusBlocked = false;
            }, 3000);
        },

        // Country
        selectCountry(e){
            this.adUnitData.country.text = e.target.selectedOptions[0].innerText;
            this.adUnitData.country.value = e.target.value;

            e.target.selectedIndex = 0;

            this.addCountryLocale();
        },

        // add country to locale
        addCountryLocale(){
            this.countryData.map(function(item){
                return item.active = false;
            });

            this.countryData.push({active: true, text: this.adUnitData.country.text, value: this.adUnitData.country.value, title: '', description: ''});
            this.countryActive = this.countryData.length-1;

            this.adUnitData.country.text = '';
            this.adUnitData.country.value = '';

            this.isShowLocales = false;
        },

        // delete country locale
        deleteCountryLocale(idx){

            let fl = true;
            this.countryData.splice(idx, 1);

            for(let i = 0; i < this.countryData.length; i++){
                if (this.countryData[i].active == true) {
                    fl = false;
                    this.countryActive = i;
                }
            }

            if (fl) {
                this.countryActive = 0;
                this.countryData[this.countryActive].active = true;
            }
        },

        // set active country item
        setActiveCountryLocale(e, value){
            let _this = this;

            this.countryData.map(function(item, idx){
                if (item.value == value) {
                    _this.countryActive = idx;
                    return item.active = true;
                }

                return item.active = false;
            });

        },

        // select tracking text
        selectTrackSystem(e){
            let option = e.target.selectedOptions[0];

            this.adUnitData.trackSystem.textInput = option.innerText;
        },

        // selectAdtypes(e){
        //     let option = e.target.selectedOptions[0];

        //     this.adUnitData.typeBanner.textInput = option.innerText;

        //     this.clearImages();
        // },

        // default form
        setFormDefault(){
            this.adUnitData.packageName.value = [];
            this.adUnitData.trackSystem.value = this.trackSystemList[0].id;
            this.adUnitData.trackSystem.textInput = this.trackSystemList[0].system;
            this.adUnitData.typeBanner.value = []; //this.getTypeBannerList[0].id;
            //this.adUnitData.typeBanner.textInput = ''; //this.getTypeBannerList[0].name;
            this.adUnitData.tagMark.value = '';
            this.adUnitData.imgOnly.value = false;
            // icon
            this.clearIcon();
            // image
            this.clearImg();
            this.clearImg43();
            this.clearImg11();
            this.clearImg34();
            this.clearImg916();
            // video url
            this.clearVideo();
            this.clearVideo43();
            this.clearVideo11();
            this.clearVideo34();
            this.clearVideo916();
            this.adUnitData.utmContent.value = '';

            this.adUnitData.country.text = '';
            this.adUnitData.country.value = '';
            this.countryActive = 0;
            this.countryData = [
                {
                    value: 'US',
                    text: 'English',
                    active: true,
                    title: '',
                    description: ''
                },
            ];
        },

        // close adUnit form
        closeAdUnitForm(){
            this.$router.push({ name: 'adunit' });
        },

        saveAdunit(e){
            this.saveAdunitDiv.disabled = true;

            this.setAdUnitDB('save');
        },

        reopenAdunit(){
            this.reopenAdunitDiv.disabled = true;

            this.setAdUnitDB('reopen');
        },

        // get list Applications
        getApplicationsDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.appList = response.data.applications;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // save form data and add new adUnit to DB
        setAdUnitDB(status){
            this.$store.commit('setIsPreloader', true);

            let data = {
                name: this.countryData[0].title,
                description: this.countryData[0].description,
                appId: this.adUnitData.packageName.value[0],
                type: this.adUnitData.typeBanner.value[0],
                image_only: this.adUnitData.imgOnly.value,
                tag: this.adUnitData.tagMark.value,
                utm_content: this.adUnitData.utmContent.value,
                text_color: '',
            };

            data.tracking = (this.adUnitData.trackSystem.value == null ? -1 : parseInt(this.adUnitData.trackSystem.value));

            if (this.countryData.length > 1) {
                data.locales = [];

                for(let i = 1; i < this.countryData.length; i++){
                    data.locales.push({locale: this.countryData[i].value, name: this.countryData[i].title, description: this.countryData[i].description});
                }
            }

            if (!this.adUnitData.imgOnly.value || this.checkInter || this.checkRewarded) {
                // icon
                data.iconsize = this.getIconSize;
                data.iconbase64 = this.adUnitData.iconFile.value;
                data.iconext = this.adUnitData.iconFile.typeInput;

                data.iconbase641 = this.adUnitData.iconFile.value1;
                data.iconext1 = this.adUnitData.iconFile.typeInput1;

                data.iconbase642 = this.adUnitData.iconFile.value2;
                data.iconext2 = this.adUnitData.iconFile.typeInput2;
            }

            if (this.checkBanner || this.checkInter) {
                // image
                data.imagesize = this.getImageSize;
                data.imagebase64 = this.adUnitData.imageFile.value;
                data.imageext = this.adUnitData.imageFile.typeInput;

                data.imagebase641 = this.adUnitData.imageFile.value1;
                data.imageext1 = this.adUnitData.imageFile.typeInput1;

                data.imagebase642 = this.adUnitData.imageFile.value2;
                data.imageext2 = this.adUnitData.imageFile.typeInput2;

                /*if (this.checkInter) {
                    // image43
                    data.image43size = this.getImage43Size;
                    data.image43base64 = this.adUnitData.imageFile43.value;
                    data.image43ext = this.adUnitData.imageFile43.typeInput;

                    data.image43base641 = this.adUnitData.imageFile43.value1;
                    data.image43ext1 = this.adUnitData.imageFile43.typeInput1;

                    data.image43base642 = this.adUnitData.imageFile43.value2;
                    data.image43ext2 = this.adUnitData.imageFile43.typeInput2;
                }*/
            } else if (this.checkRewarded) {
                if (this.checkSize) {
                    // image 16x9
                    data.imagesize = this.getImageSize;
                    data.imagebase64 = this.adUnitData.imageFile.value;
                    data.imageext = this.adUnitData.imageFile.typeInput;

                    data.imagebase641 = this.adUnitData.imageFile.value1;
                    data.imageext1 = this.adUnitData.imageFile.typeInput1;

                    data.imagebase642 = this.adUnitData.imageFile.value2;
                    data.imageext2 = this.adUnitData.imageFile.typeInput2;

                    // video 16x9
                    if (this.videoFileStatus) {
                        data.video = this.adUnitData.videoUrl.nameFile;
                        data.videobase64 = "";
                        data.videoext = this.adUnitData.videoUrl.typeInput;
                    } else {
                        data.video = this.adUnitData.videoFile.nameFile;
                        data.videobase64 = this.adUnitData.videoFile.value;
                        data.videoext = this.adUnitData.videoFile.typeInput;
                    }
                }

                /*if (this.checkSize43) {
                    // image 4x3
                    data.image43size = this.getImage43Size;
                    data.image43base64 = this.adUnitData.imageFile43.value;
                    data.image43ext = this.adUnitData.imageFile43.typeInput;

                    data.image43base641 = this.adUnitData.imageFile43.value1;
                    data.image43ext1 = this.adUnitData.imageFile43.typeInput1;

                    data.image43base642 = this.adUnitData.imageFile43.value2;
                    data.image43ext2 = this.adUnitData.imageFile43.typeInput2;

                    // video 4x3
                    if(this.videoFile43Status) {
                        data.video43 = this.adUnitData.videoUrl43.nameFile;
                        data.video43base64 = "";
                        data.video43ext = this.adUnitData.videoUrl43.typeInput;
                    } else {
                        data.video43 = this.adUnitData.videoFile43.nameFile;
                        data.video43base64 = this.adUnitData.videoFile43.value;
                        data.video43ext = this.adUnitData.videoFile43.typeInput;
                    }
                }

                if (this.checkSize11) {
                    // image11
                    data.image11size = this.getImage11Size;
                    data.image11base64 = this.adUnitData.imageFile11.value;
                    data.image11ext = this.adUnitData.imageFile11.typeInput;

                    data.image11base641 = this.adUnitData.imageFile11.value1;
                    data.image11ext1 = this.adUnitData.imageFile11.typeInput1;

                    data.image11base642 = this.adUnitData.imageFile11.value2;
                    data.image11ext2 = this.adUnitData.imageFile11.typeInput2;

                    // video 1x1
                    if (this.videoFile11Status) {
                        data.video11 = this.adUnitData.videoUrl11.nameFile;
                        data.video11base64 = "";
                        data.video11ext = this.adUnitData.videoUrl11.typeInput;
                    } else {
                        data.video11 = this.adUnitData.videoFile11.nameFile;
                        data.video11base64 = this.adUnitData.videoFile11.value;
                        data.video11ext = this.adUnitData.videoFile11.typeInput;
                    }
                }

                if (this.checkSize34) {
                    // image34
                    data.image34size = this.getImage34Size;
                    data.image34base64 = this.adUnitData.imageFile34.value;
                    data.image34ext = this.adUnitData.imageFile34.typeInput;

                    data.image34base641 = this.adUnitData.imageFile34.value1;
                    data.image34ext1 = this.adUnitData.imageFile34.typeInput1;

                    data.image34base642 = this.adUnitData.imageFile34.value2;
                    data.image34ext2 = this.adUnitData.imageFile34.typeInput2;

                    // video 3x4
                    if (this.videoFile34Status) {
                        data.video34 = this.adUnitData.videoUrl34.nameFile;
                        data.video34base64 = "";
                        data.video34ext = this.adUnitData.videoUrl34.typeInput;
                    } else {
                        data.video34 = this.adUnitData.videoFile34.nameFile;
                        data.video34base64 = this.adUnitData.videoFile34.value;
                        data.video34ext = this.adUnitData.videoFile34.typeInput;
                    }
                }

                if (this.checkSize916) {
                    // image916
                    data.image916size = this.getImage916Size;
                    data.image916base64 = this.adUnitData.imageFile916.value;
                    data.image916ext = this.adUnitData.imageFile916.typeInput;

                    data.image916base641 = this.adUnitData.imageFile916.value1;
                    data.image916ext1 = this.adUnitData.imageFile916.typeInput1;

                    data.image916base642 = this.adUnitData.imageFile916.value2;
                    data.image916ext2 = this.adUnitData.imageFile916.typeInput2;

                    // video 9x16
                    if (this.videoFile916Status) {
                        data.video916 = this.adUnitData.videoUrl916.nameFile;
                        data.video916base64 = "";
                        data.video916ext = this.adUnitData.videoUrl916.typeInput;
                    } else {
                        data.video916 = this.adUnitData.videoFile916.nameFile;
                        data.video916base64 = this.adUnitData.videoFile916.value;
                        data.video916ext = this.adUnitData.videoFile916.typeInput;
                    }
                }*/
            }

            data = JSON.stringify(data);

//console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblock/add?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setIsPreloader', false);

                if (status == 'save') {
                    this.saveAdunitDiv.disabled = false;

                    this.$router.push({ name: 'adunit' });
                } else if (status == 'reopen') {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your AdUnit added!'});
                    this.setFormDefault();

                    this.reopenAdunitDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

            })
            .catch(e => {
                this.$store.commit('setIsPreloader', false);

                if (e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveAdunitDiv.disabled = false;
                    this.reopenAdunitDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error add adunit to DB = ", e.response.data);
            });
        },

        // set height for table form Adunit
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },
    components: {
        AppColor,
        MultiSelectIcons,
        MultiSelect
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .btn-add{
        width: 100%;
        height: 38px;
        line-height: 38px;
    }

    .copy_link_text{
        color: #00aaff!important;
    }

        .copy_link_text:hover{
            text-decoration: underline;
        }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .example-box-wrapper .nav{
        margin-bottom: 10px;
    }

    .nav>li>span.disabled, .nav>span.disabled{
        padding: 10px 15px;
        position: relative;
        display: block;
    }

    .no-padding-bottom{
        padding-bottom: 0px;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .icon-plus-locales{
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        text-transform: none;
    }

        .icon-plus-locales::before{
            font-weight: bold;
            font-size: 19px;
            font-family: 'FontAwesome';
        }

        .icon-plus::before{
            content: "\f067";
        }

        .icon-close::before{
            content: "\f00d";
        }

    .icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        height: 80px;
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 80px;
    }

    .form_group_wrap_big{
        height: 100px;
    }

    .form_group_border_content{
        border-bottom: 0px none!important;
        position: relative;
    }

    .form_group_border_content:before{
        border-bottom: 1px dashed #dfe8f1;
        position: absolute;
        bottom: 0px;
        left: 17.5%;
        right: 9%;
        width: 73.5%;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .rewarded_item{
        border: 1px solid #ccc;
        border-radius: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .label_image{
        position: relative;
        top: 10px;
    }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .fileinput{
        margin-bottom: 0px;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        padding-bottom: 13px;
    }

        .form-group .control-label img{
            max-height: 50px;
            max-width: 265px;
        }

    .locale_list li{
        margin-right: 20px;
    }

        .locale_list li a{
            border: 1px solid #eee;
            border-radius: 5px;
        }

    .add_adunit{
        padding: 0 52px;
    }

    .close_adunit, .reopen_adunit{
        margin-right: 65px;
    }

    /* flex */
    .space_around{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .space_around button{
        margin-right: 15px;
    }

    .adunit_title{
        text-align: left;
        word-break: keep-all;
        white-space: nowrap;
        margin-right: 20px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
