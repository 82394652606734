import { render, staticRenderFns } from "./Moderator.vue?vue&type=template&id=dc229e40&scoped=true&"
import script from "./Moderator.vue?vue&type=script&lang=js&"
export * from "./Moderator.vue?vue&type=script&lang=js&"
import style0 from "./Moderator.vue?vue&type=style&index=0&id=dc229e40&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc229e40",
  null
  
)

component.options.__file = "Moderator.vue"
export default component.exports