const Modals = {
    namespaced: true,

    state: {
        userProfile: false,
        popupDelete: false,
        popupAdd: false,
        popupEdit: false,
        popupAdvertiserRating: false,
        popupAdvertiserComment: false,
        popupAdvertiserState: false,
        mobile: false,
        language: false,
        notification: false,
        exclamation: false,
        info: false
    },
    mutations: {
        setUserProfile(state, status){
            state.userProfile = status;
        },
        setPopupDelete(state, status){
            state.popupDelete = status;
        },
        setPopupAdd(state, status){
            state.popupAdd = status;
        },
        setPopupEdit(state, status){
            state.popupEdit = status;
        },
        setPopupAdvertiserRating(state, status){
            state.popupAdvertiserRating = status;
        },
        setPopupAdvertiserComment(state, status){
            state.popupAdvertiserComment = status;
        },
        setPopupAdvertiserState(state, status){
            state.popupAdvertiserState = status;
        },
        setMobile(state, status){
            state.mobile = status;
        },
        setLanguage(state, status){
            state.language = status;
        },
        setNotification(state, status){
            state.notification = status;
        },
        setExclamation(state, status){
            state.exclamation = status;
        },
        setInfo(state, status){
            state.info = status;
        }
    },
    actions: {

    }
};

export default Modals;
