<template>
    <section>
        <div class="panel app-list">
            <div class="panel-body">
                <div class="app-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-header" id="datatable-app">
                            <thead>

                                <tr>
                                    <th class="col_title">
                                        <div class="table-head-content">
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>

                                    <th class="col_action">
                                        <router-link :to="{name: 'typeAdd'}"
                                                    tag="button"
                                                    class="btn btn-success btn-add"
                                                    >
                                            <i class="glyph-icon icon-plus"></i>
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-content" id="datatable-app2">
                            <tbody id="app_list">

                                <tr class="odd gradeX" v-for="(item, index) in getTypeView" :key="index">
                                    <td class="v-bottom col_title">
                                        <div class="short_text" v-html="item.name"></div>
                                    </td>

                                    <td class="right v-top col_action">
                                        <button class="btn btn-alt btn-hover btn-danger btn-edit"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-sm"
                                                @click.prevent="setPopupDelete($event, item.id, item.name)"
                                                >
                                            <span>{{ getLang.delete }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.typeStart = val"
                                @setEnd="val => pagination.typeEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                                >
                    </pagination>

                </div>

                <popup :popup-title="popupName"
                        :edit-id="popupId"
                        :url-delete="'/admin/superadmin/sysmanage/adtype/delete/'"
                        url-redirect="type"
                        :title="getLang.type.deleteTitle"
                        :message="getLang.type.deleteMessage"
                        @setUpdata="val => isUpdate = val"
                        >
                </popup>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import Popup from '../plugins/Popup';

export default {
    mixins: [Authentication],
    data(){
        return {
            typeList: [],
            typeView: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 79,

            isUpdate: false,

            popupId: 0,
            popupName: '',

            // pagination
            pagination: {
                typeStart: 0,
                typeEnd: 0,
                countLength: 0,
                countList: [5,10,25,50,100],
                activeCount: 0,
                activePage: 0
            }
        }
    },
    watch:{
        isUpdate(){
            this.getTypes();

            this.isUpdate = false;
        },

        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationTypeAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationTypePageAction', parseInt(this.pagination.activePage));
        }
    },
    created(){
        if(!this.isAdmin()) this.$router.push({name: 'index'});

        this.pagination.activeCount = this.$store.getters.getRemeberPaginationType;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationTypePage;
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        /* types */
        getTypeList(){
            this.typeList = this.$store.getters.getTypeBannerList;
            this.pagination.countLength = this.typeList.length; // paginations

            return this.typeList;
        },
        getTypeView(){
            this.typeView = this.getTypeList.slice(this.pagination.typeStart, this.pagination.typeEnd);

            return this.typeView;
        },
        /* end types */
    },
    methods: {
        isAdmin(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() === this.$store.getters.getRoleSuperAdmin);
        },

        // set height for table list Types
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        backToPrev(){
            this.$router.back();
        },

        getTypes(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/alltypes?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.typeList = response.data.types;

                this.$store.commit('setTypeBannerList', this.typeList);
            })
            .catch(e => {
                this.errorsMessage(e, 'error get types');
            });
        },

        // set popup enable and set data
        setPopupDelete(e, id, name){
            this.popupId = id;
            this.popupName = name;

            this.$store.commit('modals/setPopupDelete', true);
        }
    },
    components: {
        Pagination,
        Popup
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    /*---*/
    .col_action{
        width: 11%;
        width: calc(180px);
    }

    .col_title{
        width: 89%;
    }
    /* end sizes */

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .app-list{
        border: 0px none;
    }

        .app-list .panel-body{
            padding: 0px;
        }

    .app-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 144px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 144px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 233px;
        }

    .table-app th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-app-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-app-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-app-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-app-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-app-content tr:first-child td{
        border-top: 0px none;
    }

    .table-app-content td.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-app-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-app-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
            background-color: #ffff00!important;
        }

    /* animation */

    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter-active{
        transition: all .3s;
    }
    .width-leave-active{
        transition: all .3s reverse;
    }

</style>
