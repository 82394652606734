<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_queue" ref="saveQueue" @click.prevent="saveQueue()" :disabled="!isValidForm">
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right reopen_queue" ref="reopenQueue" @click.prevent="reopenQueue()" :disabled="!isValidForm">
                                            <span>{{ getLang.reopen }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <!-- <button class="btn btn-alt btn-hover btn-warning float-right close_queue" @click.prevent="closeQueueForm()">
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button> -->
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9" style="text-align: right;">
                                    <label class="control-label label_on_input"
                                            style="top: -18px;"
                                            :style="$store.getters.getLanguageActive == 1 ? 'right: 67px;' : 'right: 70px;'"
                                    >
                                        {{ getLang.queue.enabled }}
                                    </label>
                                    <div :class="queueData.enabled.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        @click.prevent="queueData.enabled.value = !queueData.enabled.value"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.queue.name + ' (' + getLang.maxChars + ')'">
                                        {{ getLang.queue.name }}
                                    </label>
                                    <input class="form-control outlight" id="" type="text" v-model="queueData.name.value">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.queue.name + ' (' + getLang.maxChars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.queue.type">
                                        {{ getLang.queue.type }}
                                    </label>
                                    <!-- <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTypeBanner }}</span>
                                        <select class="custom-select" @change="selectTypeBanner($event)" v-model="queueData.typeBanner.value">
                                            <option :value="el.id" v-for="(el, ind) in getTypeBannerList" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div> -->
                                    <MultiSelectIcons categories-alt="type banner list"
                                            :categories-title="''"
                                            :get-category-list="getTypeBannerList"
                                            :setDefaultCategory="queueData.typeBanner.value"
                                            :countItem="1"
                                            :placeholder="getLang.statistics.adtype"
                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                            styleBlockSelect="margin-bottom: 4px;"
                                            :is-required="false"
                                            @setCategory="val => queueData.typeBanner.value = val"
                                            @removeCategory="val => queueData.typeBanner.value = val"
                                            >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.queue.type" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.queue.application">
                                        {{ getLang.queue.application }}
                                    </label>
                                    <MultiSelectIcons :categories-alt="queueData.application.alt"
                                                :categories-title="''"
                                                :get-category-list="getApplicationList"
                                                :setDefaultCategory="queueData.application.value"
                                                :count-item="-1"
                                                :placeholder="getLang.statistics.app"
                                                styleInput="padding-left: 0px; padding-right: 0px;"
                                                styleBlockSelect="margin-bottom: 4px;"
                                                :isSearchShow="true"
                                                :isCategoriesShowTitle="true"
                                                :is-required="false"
                                                :is-border="true"
                                                :is-icon="true"
                                                :is-update-category-list="true"
                                                :is-default-required="false"
                                                @setCategory="val => queueData.application.value = val"
                                                @removeCategory="val => queueData.application.value = val"
                                                >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.queue.application" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <label class="control-label label_on_input" :title="getLang.queue.country">
                                        {{ getLang.queue.country }}
                                    </label>
                                    <multi-select :categories-alt="queueData.country.alt"
                                                :categories-title="''"
                                                :get-category-list="getCountryList"
                                                :setDefaultCategory="queueData.country.value"
                                                :is-required="false"
                                                style-input="padding-left: 0px; padding-right: 0px;"
                                                :count-item="-1"
                                                :placeholder="'ALL'"
                                                @setCategory="val => queueData.country.value = val"
                                                @removeCategory="val => queueData.country.value = val"
                                                >
                                    </multi-select>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.queue.country" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <label class="control-label label_on_input" :title="getLang.adUnit.chooseOS">
                                        {{ getLang.adUnit.chooseOS }}
                                    </label>
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getOsActive }}</span>
                                        <select class="custom-select" disabled="disabled"></select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.chooseOS" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9" style="text-align: right;">
                                    <label class="control-label label_on_input"
                                            style="top: -18px;"
                                            :style="$store.getters.getLanguageActive == 1 ? 'right: 62px;' : 'right: 84px;'"
                                    >
                                        {{ getLang.queue.cycle }}
                                    </label>
                                    <div :class="queueData.cycle.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        @click.prevent="queueData.cycle.value = !queueData.cycle.value"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            logos: null,

            sendFormStatus: '',

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 84,

            backPrevLink: '',

            saveQueueDiv: '',
            reopenQueueDiv: '',

            countryList: [],
            osList: [],
            typeBannerList: [],
            applicationList: [],
            usedApplicationList: [],

            queueData: {
                name: {
                    value: '',
                    alt: ''
                },
                application: {
                    value: [],
                    alt: ''
                },
                os: {
                    value: null
                },
                country: {
                    value: [],
                    alt: '',
                },
                typeBanner: {
                    value: [],
                    text: '',
                    alt: '',
                },
                enabled: {
                    value: true,
                    alt: ''
                },
                cycle: {
                    value: false,
                    alt: ''
                },
            }
        }
    },
    created(){
        this.getApplications();
        this.getUsedApplications();

        this.$store.commit('setBackPrevLink', 'queue');
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы adunit

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(function(){
            this.saveQueueDiv = this.$refs.saveQueue;
            this.reopenQueueDiv = this.$refs.reopenQueue;
            this.queueData.os.value = this.$store.getters.getOsActive;
        });
    },
    destroyed(){
        this.$store.commit('setBackPrevLink', '');
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        // get application list (id and title)
        getApplicationList(){
            let tmp;
            let usedApps = this.usedApplicationList.find(val => val.adtypeid === this.queueData.typeBanner.value[0]);

            if(usedApps) {
                tmp = this.applicationList.filter(val => usedApps.applications.indexOf(val.id) == -1);
            } else {
                tmp = this.applicationList;
            }

            return tmp;
        },

        // country list
        getCountryList(){
            this.countryList = this.setCloneArrayWithObj(this.$store.getters.getCountryList);

            this.countryList.map(val => {
                val.id = val.code;
                val.name = val.country;
            });

            return this.countryList;
        },

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                let tBannerList = [...this.$store.getters.getTypeBannerList];

                tBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.name]};
                });
            }
            return this.typeBannerList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons; // Local

            return this.logos;
        },

        // get value type banner (default first option from list)
        // getTypeBanner(){
        //     return this.queueData.typeBanner.text;
        // },

        getOsList(){
            this.osList = this.$store.getters.getOsList;

            return this.osList;
        },

        getOsActive(){
            let os = this.getOsList.find(val => val.id == this.queueData.os.value);

            return (os != undefined && os.name != undefined ? os.name : '');
        },

        // validation form
        isValidForm(){
            let result = false;

            result = (this.queueData.name.value != '') && (this.queueData.application.value.length > 0) && (this.queueData.typeBanner.value.length > 0);

            return result;
        }
    },
    methods: {
        backToPrev(){
            this.$router.back();
        },

        // get list Applications
        getApplications(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.applicationList = response.data.applications.map(function(item){
                    let res = {id: item.package_name, icon: item.icon}; // item.id
                    res.name = item.title ? '<p style="text-align:left; font-size: 11px; line-height: 11px; margin-top: 4px;">' + item.title + '</p>' : '';
                    res.name += '<p style="text-align:left;"><strong>' + item.package_name+ '</strong></p>';

                    return res;
                });
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // get list used Applications in queues
        getUsedApplications(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/apps/get?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                for (let i = 0; i < response.data.applications.length; i++) {
                    let tmp = response.data.applications[i];
                    let apps = JSON.parse(tmp.applications);

                    if (this.usedApplicationList.find(val => val.adtypeid === tmp.adtypeid)) {
                        this.usedApplicationList.map(val => {
                            if(val.adtypeid == tmp.adtypeid) val.applications.push(...apps);
                            return val;
                        });
                    } else {
                        this.usedApplicationList.push({adtypeid: tmp.adtypeid, applications: apps});
                    }

                    /*let tmp = JSON.parse(response.data.applications[i]);
                    tmp.forEach(val => this.usedApplicationList.push(val.applications));*/
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error get list used applications in queue');
            });
        },

        // TypeBanner
        // selectTypeBanner(e){
        //     let option = e.target.selectedOptions[0].innerText;

        //     this.queueData.typeBanner.text = option;
        // },

        // default form
        setFormDefault(){
            this.queueData.name.value = '';
            this.queueData.application.value = [];
            this.queueData.country.value = []; //this.getCountryList[0].code;
            this.queueData.typeBanner.value = []; //this.getTypeBannerList[0].id;
            this.queueData.enabled.value = true;
            this.queueData.cycle.value = false;
        },

        // close Queue form
        closeQueueForm(){
            this.$router.push({ name: 'queue' });
        },

        saveQueue(){
            this.saveQueueDiv.disabled = true;

            this.setQueueDB('save');
        },

        reopenQueue(){
            this.reopenQueueDiv.disabled = true;

            this.setQueueDB('reopen');
        },

        // save form data and add new queue to DB
        setQueueDB(status){
            let data = {
                name: this.queueData.name.value,
                adtypeid: this.queueData.typeBanner.value[0],
                targetingbundle: this.queueData.application.value,
                targetingcountry: this.queueData.country.value,
                isactive: Number(this.queueData.enabled.value),
                cycle: Number(this.queueData.cycle.value),
                osid: Number(this.queueData.os.value),
            };

            data = JSON.stringify(data);

//console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/add?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                if (status == 'save') {
                    this.saveQueueDiv.disabled = false;

                    this.$router.push({ name: 'queue' });
                } else if(status == 'reopen') {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Queue added!'});
                    this.setFormDefault();

                    this.reopenQueueDiv.disabled = false;

                    this.getUsedApplications();

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

            })
            .catch(e => {
                if (e.response != undefined && e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();

                    if(this.$store.state.error.queue.alredyExists == e.response.data.Error) this.$store.commit('setErrorFormStatus', {key: uniqueId, value: this.getLang.queue.errorAlredyExists});
                    else this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveQueueDiv.disabled = false;
                    this.reopenQueueDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error add queue to DB");
            });
        },

        // set height for table form Adunit
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },
    components: {
        MultiSelectIcons,
        MultiSelect
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        height: 80px;
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 80px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        border-bottom: 0px none;
        padding-bottom: 13px;
    }

    .add_queue{
        padding: 0 52px;
    }

    .close_queue, .reopen_queue{
        margin-right: 65px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
</style>
