<template>
    <section>
        <div class="panel adunit-list">
            <div class="panel-body">
                <div class="adunit-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th :class="appList.length > 0 ? 'col_img' : 'col_img_without_img'">
                                        <router-link :to="{name: 'adunitAdd'}"
                                                    tag="button"
                                                    class="btn btn-success btn-add"
                                                    v-if="appList.length > 0"
                                        >
                                            <i class="glyph-icon icon-plus"></i>
                                        </router-link>
                                    </th>

                                    <th class="col_identifier_small">
                                        <div class="table-head-content">
                                            <i class="glyph-icon font-gray icon-sort"></i>
                                            <a href="#" @click.prevent="sortByIdentifier()">
                                                {{ getLang.adUnit.application }}
                                            </a>
                                        </div>
                                    </th>

                                    <transition name="list-complete">
                                        <th class="col_tracking">
                                            <div class="table-head-content">
                                                <i class="glyph-icon font-gray icon-sort"></i>
                                                <a href="#" @click.prevent="sortByTracking()">
                                                    {{ getLang.adUnit.tracking }}
                                                </a>
                                            </div>
                                        </th>
                                    </transition>

                                    <th class="col_title_small">
                                        <div class="table-head-content">
                                            {{ getLang.adUnit.title }}
                                        </div>
                                    </th>
                                    <transition name="width">
                                        <th class="col_description1_body">
                                            <div class="block_select_search">
                                                <div class="col-sm-5" style="padding-left: 0px; padding-right: 0px;">
                                                    <MultiSelectIcons categories-alt="categoriy type list"
                                                                :categories-title="''"
                                                                :get-category-list="getTypeBannerList"
                                                                :setDefaultCategory="getTypeCategoryStatus"
                                                                :is-update-category-list="true"
                                                                :is-default-required="false"
                                                                :count-item="1"
                                                                :placeholder="getLang.statistics.adtype"
                                                                styleInput="padding-right: 0px; padding-left: 0px;"
                                                                :style-block-select="'margin-bottom: 0px;'"
                                                                :is-disabled-close="true"
                                                                @setCategory="val => setTypeCategoryStatus(val)"
                                                                @removeCategory="val => setTypeCategoryStatus(val)"
                                                    >
                                                    </MultiSelectIcons>
                                                </div>
                                                <div id="datatable_filter" class="dataTables_filter col-sm-6">
                                                    <input class="form-control outlight"
                                                            style="padding-right: 30px; z-index: 100;"
                                                            :placeholder="getLang.search.search+'...'"
                                                            aria-controls="datatable-example"
                                                            type="search"
                                                            ref="searchInput"
                                                            @input="searchAdunit($event)"
                                                            @blur="searchBlurAdunit($event)"
                                                            >
                                                    <a class="btn search_clear"
                                                        v-if="searchValue.length > 0"
                                                        @click.prevent="searchClear()"
                                                        >
                                                        <i class="glyph-icon icon-remove"></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="table-head-content">
                                                {{ getLang.adUnit.description }}
                                            </div>
                                        </th>
                                    </transition>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-content" id="datatable-adunit">
                            <tbody id="adunit_list">
                                <tr class="odd gradeX" v-for="(item, index) in getAdUnintView" :key="index" :class="item.state == stateNotConfirmed || item.state == stateBlocked ? 'row_disabled' : ''">
                                    <td class="v-top col_img">
                                        <img :src="item.icon" class="icon" v-if="item.icon" />
                                        <img :src="item.image" v-if="item.image"/>
                                        <img :src="item.image_portrait" class="icon" v-if="item.image_portrait" />
                                        <img :src="item.image_landscape" v-if="item.image_landscape" />
                                        <i class="glyph-icon tooltip-button demo-icon icon-question" v-if="!item.icon && !item.image && !item.image_portrait && !item.image_landscape"></i>
                                    </td>

                                    <td class="v-bottom col_identifier_small">
                                        <div class="short_text" v-html="item.apptitle"></div>
                                    </td>

                                    <transition name="list-complete">
                                        <td class="v-bottom col_tracking">{{ item.tracking }}</td>
                                    </transition>

                                    <td class="v-bottom adunit_title_content_wrap col_title_small">
                                        <div class="short_text adunit_title_content" v-html="item.name"></div>
                                    </td>

                                    <transition name="width">
                                        <td class="v-bottom col_description1_body">
                                            <button class="btn btn-alt btn-hover btn-default btn-edit"
                                                    @click="setAdUnitEdit($event, item.id)"
                                                    :disabled="item.state == stateNotConfirmed || item.state == 'Blocked'"
                                            >
                                                <span>{{ getLang.edit }}</span>
                                                <i class="glyph-icon icon-arrow-right"></i>
                                            </button>
                                            <div class="short_text" style="margin-right: 120px;" v-html="item.description"></div>
                                        </td>
                                    </transition>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.adUnitStart = val"
                                @setEnd="val => pagination.adUnitEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                                >
                    </pagination>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            appList: [],

            adUnutList: [],
            adUnintView: [],
            addUnitStart: 0,
            addUnitEnd: 0,
            adUnutListForSearch: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 75,
            //showColumns: false,

            logos: null,
            logosHeader: null,

            typeBannerList: [],
            trackingList: [],

            typeCategoryStatus: ['banner'],
            //typeCategories: [{id: 'banner', name: 'banner'}, {id: 'interstitial', name: 'interstitial'}, {id: 'rewarded', name: 'rewarded'}],

            stateConfirmed: 'Confirmed',
            stateNotConfirmed: 'NotConfirmed',
            stateBlocked: 'Blocked',

            searchValue: '',

            // sorting
            sortingDatatables: {
                idFlag: true,
                titleFlag: true,
                identifierFlag: true,
                typeFlag: true,
                descriptionFlag: true,
                trackingFlag: true
            },

            // pagination
            pagination: {
                adUnitStart: 0,
                adUnitEnd: 0,
                countLength: 0,
                countList: [10,15,25,50,100],
                activeCount: 1,
                activePage: 0
            }
        }
    },
    watch: {
        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationAdunitAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationAdunitPageAction', parseInt(this.pagination.activePage));
        },

        getAdunitSearch(){
            this.$store.dispatch('setRemeberAdunitSearchAction', this.searchValue);
        }
    },
    created(){
        this.pagination.activeCount = this.$store.getters.getRemeberPaginationAdunit;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationAdunitPage;
        this.searchValue = this.$store.getters.getRemeberAdunitSearch;

        this.getAdunitList();
        this.getApplicationListDB();
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        this.$nextTick(function(){
            this.$refs.searchInput.value = this.searchValue;
        });

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons; // Local

            return this.logos;
        },

        getLogosHeader(){
            this.logosHeader = this.$store.state.logos.headerIcons;

            return this.logosHeader;
        },

        getAdunitSearch(){
            return this.searchValue;
        },

        /* adunit */
        getAdUnutList(){
            return this.adUnutList;
        },
        getAdUnintView(){
            if (this.typeCategoryStatus.length > 0) this.adUnintView = this.getAdUnutList.filter(val => this.getTypeBannerName(val.type) == this.typeCategoryStatus[0]);
            else this.adUnintView = this.getAdUnutList;

            this.pagination.countLength = this.adUnintView.length; // pagination

            this.adUnintView = this.adUnintView.slice(this.pagination.adUnitStart, this.pagination.adUnitEnd);

            return this.adUnintView;
        },
        /* end adUnit */

        getTypeCategoryStatus() {
            this.typeCategoryStatus = this.$store.getters.getRemeberAdunitTypeBanner;

            return this.typeCategoryStatus;
        },

        // get type banner list with integer id
        getTypeBannerWithIdList(){
            let typeBanner = this.setCloneArrayWithObj(this.$store.getters.getTypeBannerList);

            return typeBanner;
        },

        getTypeBannerList(){
            //this.typeBannerList = this.setCloneArrayWithObj(this.$store.getters.getTypeBannerList);
            if (this.typeBannerList.length == 0) {
                this.typeBannerList = [...this.$store.getters.getTypeBannerListAssoc];

                this.typeBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.id]};
                });
            }

            return this.typeBannerList;
        },
        // get tracking lists
        getTrackingList(){
            this.trackingList = this.$store.getters.getTrackingList;

            return this.trackingList;
        },
    },
    methods: {
        // get list Applications
        getApplicationListDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.appList = response.data.applications;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        // get list AdUnit
        getAdunitList(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adUnutList = response.data.Adblock;

                this.replaceTracking();

                if(this.searchValue.length > 0) this.searchAdunit({target: {value: this.searchValue}});

                this.pagination.countLength = this.adUnutList.length; // paginations
            })
            .catch(e => {
                this.errorsMessage(e, "error list adblock");
            });
        },

        // set type banner filter
        setTypeCategoryStatus(val){
            this.$store.dispatch('setRemeberAdunitTypeBannerAction', val);
            this.typeCategoryStatus = val;
        },

        // add id AdUnit for edit
        setAdUnitEdit(e, id){
            this.$store.commit('setAdUnitEditId', id);
            this.$router.push({name: 'adunitEdit'});
        },

        // set height for table list adUnit
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        // get locale name by id
        getLocales(index){
            let locales = '';
            let split = '';

            if (this.getAdUnintView[index].locales != undefined) {
                this.getAdUnintView[index].locales.forEach(function(item){
                    locales += split + item.locale;
                    split = ', ';
                });
            }

            return locales;
        },

        // get name banner by id
        getTypeBannerName(inx){
            let item = '';

            if (this.getTypeBannerWithIdList.length) {
                let tmp = this.getTypeBannerWithIdList.find(x => x.id === inx);
                item = tmp ? tmp.name : '';
            }

            return item;
        },

        /* Tracking */
        // get name tracking by id
        replaceTracking(){
            let _this = this;

            this.adUnutList.filter(function(item){
                item.tracking = _this.getTracking(item.tracking);
            });
        },

        // get name tracking by id
        getTracking(inx){
            let item = '';

            if (this.getTrackingList.length) {
                item = this.getTrackingList.find(x => x.id === inx);

                if(item != undefined && item.system != undefined) item = item.system;
            }

            return item;
        },
        /* end Tracking */

        /* search by table data */
        searchAdunit(e){
            let _this = this;
            let res;

            if(!this.adUnutListForSearch.length) this.adUnutListForSearch = this.adUnutList;

            res = this.adUnutListForSearch.filter(function(item){

                let isDescription = false;
                let isUrl = false;
                let isName = false;
                _this.searchValue = e.target.value || '';

                if(item.description != null) isDescription = (item.description.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);
                if(item.url != null) isUrl = (item.url.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);
                if(item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isName || isUrl || isDescription;
            });

            this.adUnutList = res;
            this.pagination.countLength = this.adUnutList.length; // paginations
        },

        searchBlurAdunit(e){
            if(e.target.value == '' && this.adUnutListForSearch.length > 0) {
                this.adUnutListForSearch = [];
            }
        },

        searchClear(){
            this.$refs.searchInput.value = '';
            this.searchAdunit({target: {value: ''}});
        },
        /* end search table data */

        /* sorting */
        sortByIdentifier(){
            let _this = this;
            this.adUnutList = this.getAdUnutList.sort(function(a, b) {
                let frst = a.apptitle.toLowerCase().trim();
                let sec = b.apptitle.toLowerCase().trim();

                if(_this.sortingDatatables.identifierFlag) {
                    return (frst > sec) ? 1 : (frst < sec) ? -1 : 0;
                } else {
                    return (frst < sec) ? 1 : (frst > sec) ? -1 : 0;
                }
            });

            this.sortingDatatables.identifierFlag = !this.sortingDatatables.identifierFlag;
        },

        sortByType(){
            let _this = this;
            this.adUnutList = this.getAdUnutList.sort(function(a, b) {
                if(_this.sortingDatatables.typeFlag) return a.type - b.type;
                else return b.type - a.type;
            });

            this.sortingDatatables.typeFlag = !this.sortingDatatables.typeFlag;
        },

        sortByTracking(){
            let _this = this;
            this.adUnutList = this.getAdUnutList.sort(function(a, b) {
                let frst = a.tracking ? a.tracking.toLowerCase().trim() : '';
                let sec = b.tracking ? b.tracking.toLowerCase().trim() : '';

                if(_this.sortingDatatables.trackingFlag) return (frst > sec) ? 1 : (frst < sec) ? -1 : 0;
                else return (frst < sec) ? 1 : (frst > sec) ? -1 : 0;
            });

            this.sortingDatatables.trackingFlag = !this.sortingDatatables.trackingFlag;
        },
        /* end sorting */
    },
    components: {
        Pagination,
        MultiSelectIcons,
        MultiSelect
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    .block_select_search{
        width: 335px;
        position: absolute;
        top: 6px;
        right: 13px;
    }

    /*---*/
    .col_img{
        width: 14%;
        width: calc(170px); /* 225 */
        padding-right: 2px!important;
    }

        .col_img .icon-question{
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0;
        }

    .col_img_without_img{
        width: 4%;
        width: calc(25px);
    }

    /* .col_utm_content{
        width: 13%;
    } */

    .col_tracking{
        width: 11%;
    }
    /*---*/

    .col_identifier{
        width: 19%;
    }

    .col_identifier_small{
        width: 23%;
    }

    .col_title{
        width: 18%;
    }

    .col_title_small{
        width: 12%;
    }

    .col_locale{
        width: 10%;
    }

    .col_type{
        width: 13%;
    }

    .col_description1_body{
        width: 50%;
        position: relative;
    }

    .col_description2_body{
        width: 49%;
        position: relative;
    }

    .row_disabled td{
        border-top-width: 0px;
        color: #beb4bb;
    }

    .row_disabled .col_status, .row_disabled .col_action, .row_disabled .col_img{
        opacity: .5;
    }

    /* Custom */
    @media only screen and (max-width: 1200px) {
        .col_tracking{
            width: 10%;
        }

        .col_identifier{
            width: 9%;
        }

        .col_type{
            width: 10%;
        }

        .col_title{
            width: 10%;
        }
    }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .adunit-list{
        border: 0px none;
    }

        .adunit-list .panel-body{
            padding: 0px;
        }

    .adunit-box-wrapper img{
        width: auto;
        max-width: 170px;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 170px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 170px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 170px;
        }

    .table-adunit th{
        vertical-align: bottom;
    }

    .dataTables_info, .selector{
        display: inline-block;
        vertical-align: middle!important;
    }
    .dataTables_info{
        margin-left: 3px;
        margin-right: 20px;
    }

    .dataTables_paginate{
        float: right;
        margin-right: 11px;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        padding: 0px;
    }

    .btn-add{
        width: 144px;
    }

    .btn-edit{
        margin-right: 8px;
        width: 100px;
        position: absolute;
        top: 3px;
        right: 13px;
    }

    .btn-add{
        margin-right: 30px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 170px; /*144px;*/
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-adunit-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-adunit-content tr:first-child td{
        border-top: 0px none;
    }

    .table-adunit-content td.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-adunit-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-adunit-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .search_clear{
        position: absolute;
        z-index: 100;
        right: 10px;
        top: 12px;
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        width: auto;
        min-width: auto;
        padding: 0px;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
            background-color: #ffff00!important;
        }

    /* animation */
    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter-active{
        transition: all .3s;
    }
    .width-leave-active{
        transition: all .3s reverse;
    }

</style>
