<template>
    <section>
        <div class="panel">
            <div class="statistic-box-wrapper" ref="filter_graphic">
                <table
                    style="width: 100%; background-color: rgb(249, 250, 254); border-bottom: 1px solid rgb(223, 232, 241); margin-bottom: 5px;"
                    cellpadding="0"
                    cellspacing="0"
                    class="table table-group table-group-header"
                    id="datatable-group"
                >
                    <thead>
                        <tr>
                            <div class="filters">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="no_padding_right" style="display: flex; justify-content: space-between;">
                                            <div class="col-md-3" style="padding-left: 0px;">
                                                <MultiSelectIcons :categories-alt="statistics.filters.app.alt"
                                                            :categories-title="''"
                                                            :get-category-list="applicationListDB"
                                                            :setDefaultCategory="getStatisticApp"
                                                            :count-item="1"
                                                            :placeholder="getLang.statistics.app"
                                                            styleInput="padding-right: 0px;"
                                                            styleBlockSelect="margin-bottom: 4px;"
                                                            :isSearchShow="true"
                                                            :is-required="false"
                                                            :is-border="true"
                                                            :is-icon="true"
                                                            :view-count="true"
                                                            :categoriesStatus="status.app"
                                                            @setCategoryStatus="val => updateStatusApp(val)"
                                                            @setCategory="val => setApplication(val)"
                                                            @removeCategory="val => statistics.filters.app.value = val"
                                                            >
                                                </MultiSelectIcons>
                                                <input type="hidden" :value="getOsActive" />
                                            </div>
                                            <div class="col-md-3">&nbsp;</div>
                                            <div class="col-md-3" style="word-break: break-word; height: 43px; display: flex; align-items: center;">
                                                {{ getLang.cagorts.longTimeGame }}: {{ filterMinutes(cagortDataDB.sessionavg) }}&nbsp;&nbsp;&nbsp;{{ cagortDataDB.dailygamedurationsec ? filterMinutes(cagortDataDB.dailygamedurationsec) : '' }}&nbsp;&nbsp;&nbsp;{{ cagortDataDB.dailyentranceavg ? cagortDataDB.dailyentranceavg.toFixed(2) : '' }}
                                            </div>
                                            <div class="col-md-3" style="margin-right: -12px;">
                                                <date-picker lang="en"
                                                            v-model="time"
                                                            format="dd-MM-yyyy"
                                                            confirm
                                                            :first-day-of-week="1"
                                                            :not-after="getYesterdayDate"
                                                            :not-before="getBeforeDate"
                                                            class="float-right stat_datepicker"
                                                            id="sidebarInstall"
                                                >
                                                </date-picker>
                                                <input type="hidden" :value="getStatisticDate" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="panel-body">
                <div class="toc-tocify">
                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <div class="flexbox">
                            <div v-if="cagortDataDB.length === 0">
                                <div class="cagort_table_tr" style="padding-top: 10px; justify-content: center;">No data</div>
                            </div>
                            <div v-else>
                                <div class="small">
                                    <div class="width7perc">
                                        <div style="position: absolute; right: 0px; top: -4px;">100%</div>
                                        <div style="position: absolute; right: 0px; top: 40px;">80%</div>
                                        <div style="position: absolute; right: 0px; top: 86px;">60%</div>
                                        <div style="position: absolute; right: 0px; top: 132px;">40%</div>
                                        <div style="position: absolute; right: 0px; top: 178px;">20%</div>
                                    </div>
                                    <div class="width93perc">
                                        <chartBar :chart-data="getDatacollectionBar" style="height: 260px;"></chartBar>
                                    </div>
                                </div>
                                <div class="cagort_table_tr">
                                    <div class="cagort_table_tr_td title_col">&nbsp;</div>
                                    <div v-for="(perc, ind) in getCagortTitle.perc" :key="ind" class="cagort_table_tr_td data_col">{{ (ind === 0 ? 100 : ((perc ^ 0) === perc  ? perc : perc.toFixed(2))) }}</div>
                                </div>
                                <div v-for="(cagort, idx) in getCagortTable" :key="idx">
                                    <div v-if="/\d{4}-\d{2}-\d{2}/.test(cagort.date)" class="cagort_table_tr">
                                        <div class="cagort_table_tr_td title_col">{{ getFormatDate(cagort.date) }}</div>
                                        <div
                                            v-for="(perc, ind) in cagort.perc"
                                            v-if="perc > 0"
                                            :key="ind"
                                            :style="(ind === 0 || perc === 100)
                                                    ? 'background-color: #fff;'
                                                    : (perc === 0)
                                                        ? 'background-color: #bebebe;'
                                                        : (perc < 3)
                                                            ? 'background-color: #fff;'
                                                            : (perc < 8)
                                                                ? 'background-color: #c9ffc9;'
                                                                : (perc < 13)
                                                                    ? 'background-color: #a1ffa1;'
                                                                    : (perc < 21)
                                                                        ? 'background-color: #63ff63;'
                                                                        : (perc < 30)
                                                                        ? 'background-color: #00d100;'
                                                                        : (perc < 50)
                                                                            ? 'background-color: #00b000;'
                                                                            : 'background-color: #008c00;'
                                                    "
                                            class="cagort_table_tr_td data_col"
                                        >
                                            {{ getFormatPerc(perc, ind, null) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Lib from '../mixins/lib';
import MultiSelect from '../plugins/MultiSelect';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import DatePicker from 'vue2-datepicker';
import ChartBar from '../plugins/ChartBar.js';

export default {
    mixins: [Authentication, Lib],

    data() {
        return {
            lang: Lang,

            tableListHeight: 0,
            headerHeight: 61,
            othersHeight: 23,
            filterHeight: 97,

            datacollectionBar: null,

            osActive: ['android'],

            applicationListDB: [],
            cagortDataDB: [],
            //bundleverList: [],

            // calendar
            mxDatepickerPopup: null,
            mxDatepickerTop: null,
            mxDatepickerBtnConfirm: null,
            mxCalendars: null,
            styleWidthSm: 'width: 190px;',
            styleDisplayNone: 'display: none;',

            statistics: {
                filters: {
                    app: {
                        value: [],
                        alt: ''
                    }
                },

                //bundleVer: null,

                dateFrom: "",
                dateTo: "",
            },

            time: null,

            status: {
                app: false
            },
        };
    },
    destroyed(){
        this.$store.commit('setIsPreloader', false);
    },
    mounted() {
        this.$nextTick(() => {
            let prom = this.getApplicationsDB();
            // prom.then(() => {
            //     this.getCagortDataDB();
            // });

            this.setTableListHeight();

            window.addEventListener('resize', () => {
                this.setTableListHeight();
            });

            // custom datepicker range
            this.custDatePick();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getNowDate(){
            let date = new Date();

            date.year = date.getFullYear();
            date.month = date.getMonth();
            date.date = date.getDate();
            date.hour = date.getHours();
            date.minute = date.getMinutes();
            date.second = date.getSeconds();

            return date;
        },

        getYesterdayDate(){
            let yesterDate = new Date(this.getNowDate.year, this.getNowDate.month, this.getNowDate.date - 2, this.getNowDate.hour, this.getNowDate.minute, this.getNowDate.second);

            return yesterDate;
        },

        getBeforeDate(){
            let beforeDate = new Date(2019, 10, 1, this.getNowDate.hour, this.getNowDate.minute, this.getNowDate.second);

            return beforeDate;
        },

        getLast32Date(){
            let lastDate = new Date(this.getNowDate.year, this.getNowDate.month, this.getNowDate.date - 31, this.getNowDate.hour, this.getNowDate.minute, this.getNowDate.second);

            return lastDate;
        },

        getStatisticDate(){
            if(!this.time) this.time = this.getLast32Date;

            if (this.time) {
                let oldDateFrom = this.statistics.dateFrom;
                this.statistics.dateFrom = this.getFormatStandartDate(this.time);
                this.statistics.dateTo = this.getFormatStandartDate(this.getLastRangeDate(this.time));

                if (oldDateFrom != this.getFormatStandartDate(this.time) && oldDateFrom != '' && this.statistics.filters.app.value.length > 0) this.getCagortDataDB();
            }

            return this.statistics.dateFrom;
        },

        getDatacollectionBar(){
            /*this.datacollectionBar.datasets[0].data[0] = 20;
            this.datacollectionBar.datasets[0].data[1] = 40;
            this.datacollectionBar.datasets[0].data[2] = 60;
            this.datacollectionBar.datasets[0].data[3] = 80;
            this.datacollectionBar.datasets[0].data[4] = 100;*/

            return this.datacollectionBar;
        },

        /*getBundleVerList(){
            if (this.applicationListDB.length > 0) {
                let tmp = this.applicationListDB.filter(val => val.id === this.statistics.filters.app.value[0]);
                this.bundleverList = tmp[0].bundlever;
            }

            return this.bundleverList;
        },

        getBundleverActive(){
            if(this.statistics.bundleVer) this.getCagortDataDB();

            return this.statistics.bundleVer;
        },*/

        getCagortTable(){
            return this.cagortDataDB.data !== undefined ? this.cagortDataDB.data.slice(0, this.cagortDataDB.data.length - 1) : [];
        },

        getCagortTitle(){
            let tmp = [{perc: []}];

            if (this.cagortDataDB.data !== undefined) tmp = this.cagortDataDB.data.slice(this.cagortDataDB.data.length - 1)

            return tmp[0];
        },

        getStatisticApp(){
            // if (this.applicationListDB !== undefined && this.applicationListDB[0] !== undefined && this.statistics.filters.app.value.length === 0) {
            //     this.statistics.filters.app.value.push(this.applicationListDB[0].id);
            // }

            return this.statistics.filters.app.value;
        },

        getOsActive(){
            if (this.osActive[0] !== this.$store.getters.getOsStatisticActive[0]) {
                this.osActive = this.$store.getters.getOsStatisticActive;

                if (this.statistics.filters.app.value.length > 0) this.getCagortDataDB();
            }
            return this.osActive;
        }
    },
    methods: {
        clearStatus(){
            this.status.app = false;
        },

        custDatePick(){
            this.mxDatepickerPopup = document.querySelector('.mx-datepicker-popup');
            let mxCalendarHeader = document.querySelector('.mx-calendar-header');
            this.mxDatepickerBtnConfirm = document.querySelector('.mx-datepicker-btn-confirm');
            this.mxCalendars = document.querySelector('.mx-calendar');

            this.mxDatepickerPopup.style = this.styleDisplayNone + 'width: 400px;';
            mxCalendarHeader.style = 'width: 100%;';

            this.mxDatepickerBtnConfirm.innerHTML = this.getLang.APPLY;
            this.mxCalendars.style = 'display: block!important;';
        },

        getLastRangeDate(time){
            let lastDate = new Date(time.getFullYear(), time.getMonth(), time.getDate() + 30, time.getHours(), time.getMinutes(), time.getSeconds());

            return lastDate;
        },

        getFormatDate(val){
            let d = new Date(val);

            return d.getDate() + ' ' + this.getLang.month[d.getMonth()];
        },

        // get formatted date (dd-mm-YYYY)
        getFormatStandartDate(time){
            if(!time) return '';

            let month = (time.getMonth() + 1);
            let date = time.getDate();

            if(month < 10) month = '0' + month;
            if(date < 10) date = '0' + date;

            return date + '-' + month + '-' + time.getFullYear();
        },

        // set height for table list Statistics
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.filterHeight + this.headerHeight + this.othersHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        filterMinutes(val){
            const MINUTE = 60
            // const minExt = ' minute'
            // const secExt = ' second'
            const minExt = ':'
            let min = 0
            let sec = 0
            let res = ''

            if (val > 0) {
                min = Math.floor(val / MINUTE)
                sec = (val % MINUTE)

                if (min < 10) min = '0' + min
                if (sec < 10) sec = '0' + sec

                // if (min !== 0) res += min + minExt + (min > 1 ? 's ' : ' ')
                // if (sec !== 0) res += sec + secExt + (sec > 1 ? 's' : '')

                res += min + minExt
                res += sec

                return res
            }

            return val
        },

        getFormatPerc(val, ind, ext = '%'){
            let res;

            if(ind === 0) return 100;
            if(val === 0) return null;

            if ((val ^ 0) === val) {
                res = val;
            }  else {
                res = val.toFixed(2);
            }

            if(ext) res += ext

            return res;
        },

        /*--- update status ---*/
        updateStatusApp(val){
            this.clearStatus();

            this.status.app = val;
        },
        /*--- end update status ---*/

        setApplication(val) {
            this.statistics.filters.app.value = val;
            this.status.app = false;
            this.getCagortDataDB();
        },

        fillDataGraphicBar() {
            let grapLabels = [];
            let grapData = [];

            if (this.cagortDataDB.data.length > 0) {
                this.cagortDataDB.data[this.cagortDataDB.data.length - 1].perc.forEach((val, ind) => {
                    grapLabels.push(ind)
                    grapData.push(this.getFormatPerc(val, ind, null))
                })
            }

            this.datacollectionBar = {
                labels: grapLabels,
                datasets: [
                {
                    label: this.getLang.menu.cagorts,
                    backgroundColor: 'rgba(0, 0, 255, .8)',
                    data: grapData
                }]
            };

            this.$store.commit('setIsPreloader', false);
        },

        // get list Applications from DB
        getApplicationsDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken, // this.$store.getters.getBaseStatUrl+'/api/stats/inapp/allbundlelist?at='+this.getApiToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.applicationListDB = response.data.applications.map(function(item){
                    return {name: '<p style="text-align:left; font-size: 11px; line-height: 11px; margin-top: 4px;">' + item.title + '</p>' + (item.package_name ? '<p style="text-align:left;"><strong>' + item.package_name + '</strong></p>' : ''), id: item.package_name, icon: item.icon};
                });
            })
            .catch(e => {
                this.errorsMessage(e, 'error get applications');
            });
        },

        /*getBundleverDB(){
            this.$store.commit('setIsPreloader', true);

            let res = JSON.stringify({
                bundle: this.statistics.filters.app.value.join(','),
                date_from: this.statistics.dateFrom,
                date_to: this.statistics.dateTo
            })

            axios({
                url: 'https:' + this.$store.getters.getBaseStatUrl + '/api/stats/inapp/bundleverlist?at=' + this.getApiToken,
                method: 'POST',
                data: res,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.bundleverDB = response.data.bundlever;
                if (this.bundleverDB.length > 0) this.statistics.bundleVer = this.bundleverDB[0];
                else this.statistics.bundleVer = null;
                this.getCagortDataDB();
            })
            .catch(e => {
                this.$store.commit('setIsPreloader', false);
                this.statistics.bundleVer = null;
                this.errorsMessage(e, 'error get bundle version');
            });
        },*/

        getCagortDataDB(){
            this.$store.commit('setIsPreloader', true);

            let dateToData = this.getFormatStandartDate(this.getYesterdayDate);

            if (dateToData > this.statistics.dateTo) dateToData = this.statistics.dateTo;

            let data = JSON.stringify({
                bundle: this.statistics.filters.app.value.join(','),
                os: this.getOsActive[0],
                //bundlever: String(this.statistics.bundleVer),
                date_from: this.statistics.dateFrom,
                date_to: dateToData
            });

//console.log('cagort data = ', data);

            axios({
                url: 'https:' + this.$store.getters.getBaseStatUrl+'/api/stats/inapp/engage?at='+this.getApiToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let cagortTmp = response.data;
                let cagortCount = 32; // our range in table plus AVG

                if (cagortTmp.data.length < cagortCount) {
                    let cagortD = [];
                    let tmp; // one row for table
                    let cagortDate = new Date(cagortTmp.data[cagortTmp.data.length - 2].date); // last date in response
                    let diffCount = (cagortCount - 1) - ((cagortTmp.data[cagortTmp.data.length - 1].perc === null) ? 0 : cagortTmp.data[cagortTmp.data.length - 1].perc.length); // different count isset data and empty
                    let diffPerc = Array.from({length: diffCount}, v => 0);

                    // format isset data
                    cagortTmp.data.map((val, ind, arr) => {
                        val.perc = [...val.perc, ...diffPerc];

                        return val;
                    });

                    // build new data in empty values
                    for(let i = cagortCount - 1; i > cagortTmp.data.length - 1; i--) {
                        cagortDate.setDate(cagortDate.getDate() + 1);
                        tmp = {date: this.getFormatStandartDate(cagortDate), perc: [...diffPerc]};

                        cagortD.push(tmp);
                        if(diffPerc.length > 0) diffPerc.pop();
                    }

                    cagortTmp.data.splice(cagortTmp.data.length - 1, 0, ...cagortD);
                }

                this.cagortDataDB = cagortTmp;

                this.fillDataGraphicBar();
//console.log('cagorts = ', response.data);
            })
            .catch(e => {
                this.$store.commit('setIsPreloader', false);
                this.errorsMessage(e, 'error get cagorts graphics data');
            });
        }
    },
    components: {
        MultiSelect,
        MultiSelectIcons,
        DatePicker,
        ChartBar
    }
}
</script>

<style scoped>
    .panel-body{
        padding-top: 0px;
        padding-left: 13px;
    }

    .small {
        /* max-width: 400px; */
        height: 260px;
        max-height: 260px;
        margin:  20px 0px 0px;
        display: flex;
    }

    .scroll{
        overflow: auto;
    }

    .statistic-box-wrapper img{
        width: auto;
        height: 40px;
        margin-right: 5px;
    }

    .filters{
        margin: 5px 22px 0px 10px;
    }

    .no_padding_right>div:last-child{
        padding-right: 0px;
    }

    /*.flexbox{
        display: flex;
        flex-wrap: wrap;
    }

    .flexbox>div{
        width: 400px;
        padding-bottom: 30px;
        margin-right: 30px;
    }*/

    .table td{
        border-top: 0px none;
    }

    .font-gray{
        color: #c2c2c2 !important;
    }

    .col_img{
        padding: 14px 13px 5px!important;
        border-bottom-width: 1px;
        height: 55px;
    }

    .cagort_table_tr{
        width: 100%;
        display: flex;
    }

        .cagort_table_tr_td{
            padding: 5px 10px;
            border-top: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-top: -1px;
        }

        /* .cagort_table_tr_td:last-child {
            border-bottom: 1px solid #ccc;
        } */

        .width7perc{
            width: 7%;
            position: relative;
        }

        .width93perc{
            width: 93%;
            height: 260px;
            max-height: 260px;
        }

        .title_col {
            font-size: 11px;
            width: 7%;
            min-width: 7%;
            border: 1px solid #ccc;
            background-color: #eee;
            word-break: keep-all;
            white-space: nowrap;
        }

        /* .cagort_table_tr:last-child .title_col {
            border-bottom: 1px solid #ccc;
        } */

        .data_col {
            font-size: 11px;
            padding: 5px 0px;
            width: 3%;
            min-width: 3%;
            text-align: center;
            color: #505050;
        }

    /* patepicker */
    .mx-datepicker-popup{
        right: 0px!important;
    }

    /* .mx-datepicker-popup.range{
        width: 830px!important;
    }

        .mx-datepicker-top{
            width: 20%!important;
        }

        .mx-calendar{
            width: 40%!important;
        } */

        .mx-datepicker-popup>div{
            display: flex;
        }

    .mx-datepicker-top{
        word-break: break-all;
        text-align: center!important;
        order: 2;
        line-height: 23px!important;
    }

        .mx-datepicker-top > span{
            padding: 5px 15px;
            background-color: #eee;
            border-radius: 10px;
            margin: 3px 5px;
            word-break: keep-all;
            white-space: nowrap!important;
            display: inline-block;
            width: 100%;
        }

        .mx-datepicker-top > span::after{
            content: ''!important;
            margin: 0px!important;
        }

    .mx-datepicker-btn{
        width: 200px;
        position: absolute;
        bottom: 30px;
        color: #fff!important;
        height: 40px;
    }

    .mx-datepicker-btn-close{
        left: 230px;
        width: 100px;
        background-color: #e67e22!important;
    }

    .mx-datepicker-btn-confirm{
        left: 10px;
        background: #eee!important;
        color: #777!important;
    }

    .mx-calendar{
        order: 1;
    }

        .mx-calendar-header .mx-calendar__prev-icon:first-child{
            display: none;
        }

        .mx-calendar-header .mx-calendar__next-icon:first-child, .mx-calendar-header .mx-calendar__next-icon:nth-child(3){
            display: none;
        }

            .mx-calendar-header .mx-calendar__prev-icon, .mx-calendar-header .mx-calendar__next-icon{
                width: 30px;
                /*background: rgba(41,183,101,0.8) !important;*/
                border-radius: 3px;
                margin-right: 3px;
            }

            .mx-calendar-header .mx-calendar__prev-icon{
                background: #eee!important; /*rgba(41,183,101,0.8) !important;*/
            }

            .mx-calendar-header .mx-calendar__next-icon{
                background: #ddd!important; /*rgba(41,183,101,0.8) !important;*/
            }

    span.active{
        background: #ccc;
        /* color: #fff; */
    }

    tr .info__column.not_active{
        background-color: #eee;
        color: #333;
    }

    .mx-calendar-table td.current{
        color:#fff!important;
        background-color:#1284e7!important;
    }

    .table-striped tr:nth-child(2n) > td{
        background: transparent!important;
    }
    /* end patepicker */
</style>
