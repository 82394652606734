<template>
    <div id="app">
        <div class="fon" style="position: absolute; top: 0px; left: 0px; width: 100%;
                    height: 100%; background: rgba(0, 0, 0, .5); z-index: 1000;" v-if="getIsPreloader"></div>
        <div id="sb-site" v-if="getAccessToken" @click="appStatus">
            <app-header></app-header>
            <app-sidebar></app-sidebar>
            <app-body></app-body>
            <app-footer></app-footer>
        </div>
        <div v-else>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AppHeader from './components/template/Header';
import AppSidebar from './components/template/Sidebar';
import AppBody from './components/template/Body';
import AppFooter from './components/template/Footer';
import Login from './components/auth/Login';
import Authentication from './components/mixins/authentication';

export default {
    name: 'app',
    data(){
        return {
            checkToken: 300000,
            modalsMobile: false,
            modalsUserProfile: false,
            modalsLanguage: false,
            modalsNotification: false,
            modalsExclamation: false,
            modalsInfo: false,
            isPreloader: false
        }
    },
    computed: {
        getModalsMobile(){
            if(this.$store.state.modals.mobile != undefined) this.modalsMobile = this.$store.state.modals.mobile;

            return this.modalsMobile;
        },
        getModalsUserProfile(){
            if(this.$store.state.modals.userProfile != undefined) this.modalsUserProfile = this.$store.state.modals.userProfile;

            return this.modalsUserProfile;
        },
        getModalsLanguage(){
            if(this.$store.state.modals.language != undefined) this.modalsLanguage = this.$store.state.modals.language;

            return this.modalsLanguage;
        },
        getModalsNotification(){
            if(this.$store.state.modals.notification != undefined) this.modalsNotification = this.$store.state.modals.notification;

            return this.modalsNotification;
        },
        getModalsExclamation(){
            if(this.$store.state.modals.exclamation != undefined) this.modalsExclamation = this.$store.state.modals.exclamation;

            return this.modalsExclamation;
        },
        getModalsInfo(){
            if(this.$store.state.modals.info != undefined) this.modalsInfo = this.$store.state.modals.info;

            return this.modalsInfo;
        },

        getIsPreloader(){
            this.isPreloader = this.$store.getters.getIsPreloader;

            return this.isPreloader;
        },
    },
    methods: {
        appStatus(){
            if(this.getModalsMobile) this.$store.commit('modals/setMobile', false);
            if(this.getModalsUserProfile) this.$store.commit('modals/setUserProfile', false);
            if(this.getModalsLanguage) this.$store.commit('modals/setLanguage', false);
            if(this.getModalsNotification) this.$store.commit('modals/setNotification', false);
            if(this.getModalsExclamation) this.$store.commit('modals/setExclamation', false);
            if(this.getModalsInfo) this.$store.commit('modals/setInfo', false);
        }
    },
    mixins: [Authentication],
    created(){
        let _this = this;

        if(this.getAccessToken){
            if(localStorage.getItem('languageActive')) this.$store.commit('setLanguageActive', localStorage.getItem('languageActive'));
            if(localStorage.getItem('remeberQueueTypeBannerId')) this.$store.commit('setRemeberQueueTypeBannerId', localStorage.getItem('remeberQueueTypeBannerId'));
            if(localStorage.getItem('remeberQueueTypeBannerName')) this.$store.commit('setRemeberQueueTypeBannerName', localStorage.getItem('remeberQueueTypeBannerName'));
            if(localStorage.getItem('remeberQueueQueueId')) this.$store.commit('setRemeberQueueQueueId', localStorage.getItem('remeberQueueQueueId'));
            if(localStorage.getItem('remeberQueueQueueName')) this.$store.commit('setRemeberQueueQueueName', localStorage.getItem('remeberQueueQueueName'));
            if(localStorage.getItem('remeberQueueGroupId')) this.$store.commit('setRemeberQueueGroupId', localStorage.getItem('remeberQueueGroupId'));
            if(localStorage.getItem('remeberQueueGroupNetwork')) this.$store.commit('setRemeberQueueGroupNetwork', localStorage.getItem('remeberQueueGroupNetwork'));

            if (localStorage.getItem('remeberAnaliticsTypeGraphicsShows')) {
                let tmpType = localStorage.getItem('remeberAnaliticsTypeGraphicsShows').split(',');

                this.$store.commit('setRemeberAnaliticsTypeGraphicsShows', tmpType);
            }
            if (localStorage.getItem('remeberAnaliticsTypeGraphicsAdunit')) {
                let tmpType = localStorage.getItem('remeberAnaliticsTypeGraphicsAdunit').split(',');

                this.$store.commit('setRemeberAnaliticsTypeGraphicsAdunit', tmpType);
            }
            if (localStorage.getItem('remeberAnaliticsTypeResources')) {
                let tmpType = localStorage.getItem('remeberAnaliticsTypeResources').split(',');

                this.$store.commit('setRemeberAnaliticsTypeResources', tmpType);
            }

            if (localStorage.getItem('remeberApplicationsTypeBanner')) {
                let tmpType = localStorage.getItem('remeberApplicationsTypeBanner').split(',');

                this.$store.commit('setRemeberApplicationsTypeBanner', tmpType);
            }
            if (localStorage.getItem('remeberAdunitTypeBanner')) {
                let tmpType = localStorage.getItem('remeberAdunitTypeBanner').split(',');

                this.$store.commit('setRemeberAdunitTypeBanner', tmpType);
            }
            if (localStorage.getItem('remeberExchangeTypeBanner')) {
                let tmpType = localStorage.getItem('remeberExchangeTypeBanner').split(',');

                for(let i = 0; i < tmpType.length; i++ ){ tmpType[i] = parseInt(tmpType[i]); }

                this.$store.commit('setRemeberExchangeTypeBanner', tmpType);
            }
            if (localStorage.getItem('remeberGroupTypeBanner')) {
                let tmpType = localStorage.getItem('remeberGroupTypeBanner').split(',');

                for(let i = 0; i < tmpType.length; i++ ){ tmpType[i] = parseInt(tmpType[i]); }

                this.$store.commit('setRemeberGroupTypeBanner', tmpType);
            }
            if (localStorage.getItem('remeberGroupNetwork')) {
                let tmpNet = localStorage.getItem('remeberGroupNetwork').split(',');

                for(let i = 0; i < tmpNet.length; i++){ tmpNet[i] = parseInt(tmpNet[i]); }

                this.$store.commit('setRemeberGroupNetwork', tmpNet);
            }
            if(localStorage.getItem('remeberGroupSearch')) this.$store.commit('setRemeberGroupSearch', localStorage.getItem('remeberGroupSearch'));
            if(localStorage.getItem('remeberAdunitSearch')) this.$store.commit('setRemeberAdunitSearch', localStorage.getItem('remeberAdunitSearch'));
            if(localStorage.getItem('remeberApplicationsSearch')) this.$store.commit('setRemeberApplicationsSearch', localStorage.getItem('remeberApplicationsSearch'));

            if(localStorage.getItem('remeberPaginationType')) this.$store.commit('setRemeberPaginationType', localStorage.getItem('remeberPaginationType'));
            if(localStorage.getItem('remeberPaginationTypePage')) this.$store.commit('setRemeberPaginationTypePage', localStorage.getItem('remeberPaginationTypePage'));

            if(localStorage.getItem('remeberPaginationNetwork')) this.$store.commit('setRemeberPaginationNetwork', localStorage.getItem('remeberPaginationNetwork'));
            if(localStorage.getItem('remeberPaginationNetworkPage')) this.$store.commit('setRemeberPaginationNetworkPage', localStorage.getItem('remeberPaginationNetworkPage'));

            if(localStorage.getItem('remeberPaginationRelationTypeNet')) this.$store.commit('setRemeberPaginationRelationTypeNet', localStorage.getItem('remeberPaginationRelationTypeNet'));
            if(localStorage.getItem('remeberPaginationRelationTypeNetPage')) this.$store.commit('setRemeberPaginationRelationTypeNetPage', localStorage.getItem('remeberPaginationRelationTypeNetPage'));

            if(localStorage.getItem('remeberPaginationStatClicks')) this.$store.commit('setRemeberPaginationStatClicks', localStorage.getItem('remeberPaginationStatClicks'));
            if(localStorage.getItem('remeberPaginationStatClicksPage')) this.$store.commit('setRemeberPaginationStatClicksPage', localStorage.getItem('remeberPaginationStatClicksPage'));

            if(localStorage.getItem('remeberPaginationStatAdunits')) this.$store.commit('setRemeberPaginationStatAdunits', localStorage.getItem('remeberPaginationStatAdunits'));
            if(localStorage.getItem('remeberPaginationStatAdunitsPage')) this.$store.commit('setRemeberPaginationStatAdunitsPage', localStorage.getItem('remeberPaginationStatAdunitsPage'));


            if(localStorage.getItem('remeberPaginationStatInstalls')) this.$store.commit('setRemeberPaginationStatInstalls', localStorage.getItem('remeberPaginationStatInstalls'));
            if(localStorage.getItem('remeberPaginationStatInstallsPage')) this.$store.commit('setRemeberPaginationStatInstallsPage', localStorage.getItem('remeberPaginationStatInstallsPage'));

            if(localStorage.getItem('remeberPaginationExchange')) this.$store.commit('setRemeberPaginationExchange', localStorage.getItem('remeberPaginationExchange'));
            if(localStorage.getItem('remeberPaginationExchangePage')) this.$store.commit('setRemeberPaginationExchangePage', localStorage.getItem('remeberPaginationExchangePage'));

            if(localStorage.getItem('remeberPaginationAdunit')) this.$store.commit('setRemeberPaginationAdunit', localStorage.getItem('remeberPaginationAdunit'));
            if(localStorage.getItem('remeberPaginationAdunitPage')) this.$store.commit('setRemeberPaginationAdunitPage', localStorage.getItem('remeberPaginationAdunitPage'));

            if(localStorage.getItem('remeberPaginationResetAccount')) this.$store.commit('setRemeberPaginationResetAccount', localStorage.getItem('remeberPaginationResetAccount'));
            if(localStorage.getItem('remeberPaginationResetAccountPage')) this.$store.commit('setRemeberPaginationResetAccountPage', localStorage.getItem('remeberPaginationResetAccountPage'));

            let timer = setInterval(function(){
                axios({
                    url: _this.$store.getters.getBaseUrl+'/acc/login/check?at='+_this.getAccessToken,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {
                    console.log('access token continue!'+response.data.Error);
                })
                .catch(e => {
                    console.log("error check");

                    if(e.response != undefined && e.response.data.Error != undefined) _this.auth.error = e.response.data.Error;

                    if(_this.auth.error == _this.$store.getters.getError.needToLogin){
                        _this.logout();
                    }
                });

            }, this.checkToken);

            // get type banner list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/alltypes?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let types = response.data.types;
                let typeAssoc = [];

                for(let i = 0; i < types.length; i++){
                    typeAssoc[i] = {id: types[i].name, name: types[i].name};
                }

                this.$store.commit('setTypeBannerList', types);
                this.$store.commit('setTypeBannerListAssoc', typeAssoc);
            })
            .catch(e => {
                this.errorsMessage(e, 'error all types');
            });

            // get tracking list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/tracksystems?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setTrackingList', response.data.tracking_systems);
            })
            .catch(e => {
                this.errorsMessage(e, 'error tracksystems');
            });

            // get os list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/os?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setOsList', response.data.os);
            })
            .catch(e => {
                this.errorsMessage(e, 'error os');
            });

            // get category list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/cats?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setCategoryList', response.data.categories);
            })
            .catch(e => {
                this.errorsMessage(e, 'error categories');
            });

            // get network list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/group/types/?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setNetworkList', response.data.types);
            })
            .catch(e => {
                this.errorsMessage(e, "error queue group types");
            });

            // get country list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/countries?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setCountryList', response.data.countries);
            })
            .catch(e => {
                this.errorsMessage(e, "error country list");
            });

            // get locale list
            axios({
                url: this.$store.getters.getBaseUrl+'/api/locales?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setLocaleList', response.data.locales);
            })
            .catch(e => {
                this.errorsMessage(e, "error locale list");
            });

            // get application list
            // axios({
            //     url: this.$store.getters.getBaseUrl+'/api/applications?at='+this.getAccessToken,
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // })
            // .then(response => {
            //     this.$store.commit('setApplicationList', response.data.applications);
            // })
            // .catch(e => {
            //     this.errorsMessage(e, "error application list");
            // });

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/roles/get/' + this.getUserId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setRolesList', response.data.accounts_roles);
            })
            .catch(e => {
                this.errorsMessage(e, "error roles list");
            });

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/pages/get?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.$store.commit('setPagesList', response.data.pages);
            })
            .catch(e => {
                this.errorsMessage(e, "error pages list");
            });
        }

    },
    mounted(){
        this.$nextTick(function(){
            if(window.innerWidth < 1200) this.$store.commit('setCloseSidebar', true);
        });
    },
    components: {
        AppHeader,
        AppSidebar,
        AppBody,
        AppFooter,
        Login
    }
}
</script>

<style>
    .outlight:focus, .outlight select:focus, .outlight_active {
        box-shadow: 0 0 2px rgba(68, 139, 235, 1)!important;
        outline: none!important;
        border-color: #448beb!important;
    }
</style>
