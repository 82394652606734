<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
                >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
                    >
            <div class="modal-dialog" @click.stop="clearStatus()">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader">
                        <div style="display: flex;">
                            <h4 class="modal-title nowrap" style="font-size: 13px; color: #AFAFAF; position: relative; left: 28px; top: -8px;">{{ title }}</h4>

                            <div class="filter__item filter__search">
                                <input class="form-control outlight"
                                        :placeholder="getLang.search.search+'...'"
                                        aria-controls="datatable-example"
                                        type="search"
                                        ref="searchInput"
                                        @click="clearStatus()"
                                        @input="searchAdvertiser($event)"
                                        @focus="setIsEscClose(false)"
                                        @blur="searchBlurAdvertiser($event); setIsEscClose(true);"
                                >
                                <a class="btn search_clear"
                                    v-if="searchValue.length > 0"
                                    @click.prevent="searchClear()"
                                    >
                                    <i class="glyph-icon icon-remove"></i>
                                </a>
                            </div>

                            <div class="filter__item">
                                <MultiSelectIcons categories-alt="Type banner"
                                            :categories-title="''"
                                            :get-category-list="getTypeBannerList"
                                            :setDefaultCategory="advertiserData.typeBanner.value"
                                            :count-item="1"
                                            :placeholder="getLang.exchange.typeBanner"
                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                            :style-block-select="'margin-bottom: 0px;'"
                                            :is-disabled-close="true"
                                            :categoriesStatus="status.adtype"
                                            @setCategoryStatus="val => updateStatusAdtype(val)"
                                            @setCategory="val => advertiserData.typeBanner.value = val"
                                            @removeCategory="val => advertiserData.typeBanner.value = val"
                                            >
                                </MultiSelectIcons>
                            </div>
                        </div>

                        <div class="filter">
                            <div class="filter__add row"><!-- setPopupAddHide() -->
                                <button type="button"
                                        :class="isValid ? 'btn-success' : 'btn-danger'"
                                        class="btn btn-add"
                                        @click.prevent="addItem()"
                                >
                                    <i class="glyph-icon icon-plus" v-if="isValid"></i>
                                    <i class="glyph-icon icon-close" v-else></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="scroll w50 pt10" :style="'max-height: ' + adunitListHeight + 'px; border-right: 1px dashed rgb(223, 232, 241);'">

                                    <div v-for="(item, idx) in getAdunitList"
                                        :key="idx"
                                        class="flex-block-adunit-group list-complete-item"
                                        >
                                        <table class="adunit_in_group" :style="adunitAdvertiserData.find(val => val.id == item.id) ? 'opacity: .5;' : ''">

                                            <td class="icon" style="display: flex;">
                                                <div v-if="item.icon">
                                                    <img :src="item.icon" class="icon" alt="">
                                                </div>
                                                <div v-if="item.image">
                                                    <img :src="item.image" class="image" alt="">
                                                </div>
                                            </td>
                                            <td class="info">

                                                <div class="info__description">

                                                    <div style="width: 100%;">
                                                        <div class="short_text" v-if="item.name" style="color: #999; font-size: 11px; line-height: 11px;">
                                                            ({{ item.name }})
                                                        </div>
                                                        <div class="short_text">
                                                            <strong>{{ item.title }}</strong>
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                            <td class="action">
                                                <div class="nowrap">
                                                    <button type="button"
                                                            class="btn btn-alt btn-success float-right add_adunit_in_group"
                                                            @click.prevent="addAdunitAdvertiser(item)"
                                                            >
                                                        <i class="glyph-icon icon-sign-in"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </table>
                                    </div>
                                </div>
                                <div class="form_adunit scroll w50 pt10" :style="'max-height: ' + adunitListHeight + 'px;'">

                                    <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate v-if="adunitAdvertiserData.length > 0">

                                        <div class="flex-block-adunit-group list-complete-item"
                                            style="width: 100%;"
                                            v-for="(item, idx) in adunitAdvertiserData"
                                            :key="idx"
                                            >
                                            <table class="adunit_in_group" style="justify-content: flex-start;">

                                                <td class="icon" style="display: flex;">
                                                    <div v-if="item.icon">
                                                        <img :src="item.icon" class="icon" alt="">
                                                    </div>
                                                    <div v-if="item.image">
                                                        <img :src="item.image" class="image" alt="">
                                                    </div>
                                                </td>
                                                <td class="info">

                                                    <div class="info__description">

                                                        <div style="width: 100%;">
                                                            <div class="short_text" v-if="item.name" style="color: #999; font-size: 11px; line-height: 11px;">
                                                                ({{ item.name }})
                                                            </div>
                                                            <div class="short_text">
                                                                <strong>{{ item.title }}</strong>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </td>
                                                <td class="action" style="position: absolute; right: 1px; bottom: 5px;">
                                                    <div class="nowrap">
                                                        <button type="button"
                                                                class="btn btn-alt btn-danger float-right"
                                                                @click.prevent="removeAdunitAdvertiser(item.id)"
                                                                >
                                                            <i class="glyph-icon icon-close"></i>
                                                        </button>
                                                    </div>
                                                </td>

                                            </table>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="col-md6" style="padding-right: 35px;">{{ getAdunitList.length }} {{ getLang.count }}</div>
                        <div class="col-md6">&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        urlAdded: String,
        urlRedirect: String,
        title: String,
        opened: String
    },

    data(){
        return {
            pageId: 22,

            lang: Lang,

            adunitList: [],
            adunitView: [],
            adunitListForSearch: [],

            otherHeight: 60,
            modalBodyHeight: 2000,
            adunitListHeight: 200,

            logos: null,

            searchValue: '',

            timeout: 0,

            // default data
            showsCountDefault: 55,
            perDaysDefault: 1,

            advertiserData: {
                typeBanner: {
                    value: [0],
                },
                osDev: {
                    value: [],
                },
            },

            adunitAdvertiserData: [],

            advertiserList: [],
            typeBannerList: [],
            osDev: [],

            status: {
                os: false,
                adtype: false,
            },

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    created(){
        let isVisibleP = this.getPageVisiblePromise(this.pageId);

        isVisibleP.then(res => {
            if (res) {
                this.getAdunitListDB(); // получаем список adunit с DB
            } else {
                this.$router.push({name: '404'});
            }
        });
    },
    mounted(){
        let _this = this;
        this.setAdunitListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setAdunitListHeight();
        });
    },

    watch: {
        isUpdate(){
            if (this.isUpdate) {
                this.updateAdvertiser(); // обновляем adunit in advertiser
            }

            this.isUpdate = false;
        },

        opened(){
            this.updateAdvertiser(); // обновляем adunit in advertiser
        },

        getTypeBanner(){
            this.removeAdunitAdvertiser('all');
        }
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        getOsDev(){
            this.osDev = this.$store.getters.getOsDev;

            return this.osDev;
        },

        // get adunit list by id group for select
        getAdunitList(){
            let _this = this;

            this.adunitView = this.adunitList.filter(val => {
                let res = true;

                if(_this.advertiserData.typeBanner.value.length > 0 && _this.advertiserData.typeBanner.value.indexOf(val.type) == -1) res = false;

                return res && !_this.advertiserList.find(v => v.adblock == val.id);
            });

            return this.adunitView;
        },

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                this.typeBannerList = [...this.$store.getters.getTypeBannerList];

                this.typeBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.name]};
                });
            }

            return this.typeBannerList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons;

            return this.logos;
        },

        getTypeBanner(){
            return this.advertiserData.typeBanner.value;
        },

        // is valid form add Adunit
        isValid(){
            return this.adunitAdvertiserData.length > 0;
        },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupDelete;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                let heightFilter = 0;
                if(this.adunitList.length > 0) heightFilter = -20;

                this.$nextTick(function(){
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.adunitListHeight = mdlBodyHeight - (37 + heightFilter);
                });
            }

            return this.popupAdd;
        },
    },
    methods: {
        clearStatus(){
            this.status.adtype = false;
            this.status.os = false;
        },

        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        setIsEscClose(val){
            this.$store.commit('setIsEscClose', val);
        },

        /*--- update status ---*/
        updateStatusOs(val){
            this.clearStatus();

            this.status.os = val;
        },

        updateStatusAdtype(val){
            this.clearStatus();

            this.status.adtype = val;
        },
        /*--- end update status ---*/

        addAdunitAdvertiser(item){
            let tmp = {
                id: item.id,
                title: item.title,
                name: item.name,
                icon: item.icon,
                image: item.image
            };

            this.adunitAdvertiserData.push(tmp);
        },

        removeAdunitAdvertiser(id){
            if(id == 'all') this.adunitAdvertiserData = [];

            this.adunitAdvertiserData = this.adunitAdvertiserData.filter(val => val.id != id);
        },

        /* search by table data */
        searchAdvertiser(e){
            let _this = this;
            let res;

            if(!this.adunitListForSearch.length) this.adunitListForSearch = this.adunitList;

            res = this.adunitListForSearch.filter(function(item){
                let isTitle = false;
                let isName = false;
                _this.searchValue = e.target.value || '';

                if(item.title != null) isTitle = (item.title.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);
                if(item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isTitle || isName;
            });

            this.adunitList = res;
        },
        searchBlurAdvertiser(e){
            if(e.target.value == '' && this.adunitListForSearch.length > 0) {
                this.adunitListForSearch = [];
            }
        },
        /* end search table data */

        searchClear(){
            this.$refs.searchInput.value = '';
            this.searchAdvertiser({target: {value: ''}});
        },

        updateAdvertiser(){
            this.removeAdunitAdvertiser('all');
            this.getAdunitListDB();
        },

        // get list Adunit
        getAdunitListDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adunitList = response.data.Adblock.map(function(item){
                    let title = item.apptitle;

                    return {title: title, name: item.name, id: item.id, type: item.type, icon: item.icon, image: item.image};
                });

                this.adunitView = this.adunitList;

                return this.getAdvertiserListDB();
            })
            .then(response => {
                this.setAdvertiserListDB(response.data.adv_blocks);
            })
            .catch(e => {
                this.errorsMessage(e, 'error get adunit');
            });
        },

        // get and set list Advertiser
        getAdvertiserListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/advblocks/my?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        },
        setAdvertiserListDB(data){
            this.advertiserList = data;
        },

        /* popup */
        setPopupAddHide(){
            if (this.isValid) {
                if (confirm('Are you sure you want to close the popup without saving the data?')) {
                    this.$emit('setOpenAdd', false);
                    this.$store.commit('modals/setPopupDelete', false);
                }
            } else {
                this.$emit('setOpenAdd', false);
                this.$store.commit('modals/setPopupDelete', false);
            }
        },

        addItem(){
            if (!this.isValid) {
                this.setPopupAddHide();
                return;
            }

            let flag = false;

            for(let i = 0; i < this.adunitAdvertiserData.length; i++){
                let data = JSON.stringify({
                    adblock: this.adunitAdvertiserData[i].id,
                    adtype: this.advertiserData.typeBanner.value[0],
                    osid: this.$store.getters.getOsActive,
                    shows_count: this.showsCountDefault,
                    per_days: this.perDaysDefault,
                    countries: ['-'],
                    enabled: false,
                    status: 'Disabled',
                    state: "Confirmed"
                });

                if(i == this.adunitAdvertiserData.length-1) flag = true;

                this.addItemDB(data, flag);
            }
        },

        addItemDB(data, isLast){
            if(isLast) this.$store.commit('setIsEscClose', true);
            //console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl + '/api/advblocks/add?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Adunit added to Advertiser!'});

                if (isLast) {
                    this.$emit('setUpdata', true);
                    this.isUpdate = true;
                }

                setTimeout(() => { this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''}) }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error added adunit in advertiser');
            });
        },
        /* end popup */

        // set height for adinut list in group
        setAdunitListHeight(){
            this.$nextTick(function(){
                let heightFilter = 0;
                let otherHeight = 124;
                let maxHeight = 650;
                let winHeight = window.innerHeight;
                let currentHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);

                if(this.adunitList.length > 0) heightFilter = -20;
                if(currentHeight == 0) currentHeight = maxHeight;

                if (this.$refs.modalBody) {
                    if(winHeight < currentHeight + otherHeight) this.$refs.modalBody.style.height = (winHeight - otherHeight) + 'px';
                    else this.$refs.modalBody.style.height = maxHeight+'px';
                }

                this.adunitListHeight = currentHeight - (37 + heightFilter);

                if(this.adunitListHeight < 0) this.adunitListHeight = 0;
            });
        }
    },
    components: {
        MultiSelectIcons,
        MultiSelect,
    }
}
</script>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.search_clear{
    position: absolute;
    right: 20px;
    z-index: 100;
    top: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0px;
}

.scroll{
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 10px;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.modal-dialog{
    margin: 30px auto 0px;
    width: 1190px!important;
    max-width: 1190px!important;
    /* height: 680px; */
}

    .modal-content{
        /* height: 680px; */
        position: relative;
        background-color: #fff;
        z-index: 1000;
    }

        .modal-header{
            height: 63px;
            padding: 9px 20px 9px 0px;
            align-items: flex-start;
        }

        .modal-body{
            /* height: 650px; */
            padding: 0 15px;
        }

        .modal-footer{
            /* height: 29px; */
            padding: 0 15px;
        }

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 0px;
    }

.w100{
    width: 100%;
}

.w50{
    width: 600px;
    box-sizing: border-box;
}

.pt10{
    padding-top: 10px;
}

.modal-body .w50:last-child{
    width: 520px;
    padding-right: 10px;
}

.close{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
}

.action_popup{
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.filter{
    display: flex;
}

    .filter__item, .filter__add{
        margin: 0 5px;
    }

        .filter__add .btn-add{
            width: 42px;
            height: 42px;
            padding: 0 10px;
        }

            .filter__add .btn-add i{
                line-height: 42px;
                font-size: 24px;
            }

    .filter__item{
        width: 217px;
        position: absolute;
        left: 243px;
        top: 9px;
    }

        .form-group{
            margin-bottom: 0px;
        }

    .filter__search{
            margin-right: 8px;
            position: absolute;
            top: 19px;
            left: 14px;
            height: 34px;
            padding: 0px 10px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 34px;
                width: 100%;
                transition: all .5s;
            }

.form_adunit{
    margin-left: 6px;
    margin-right: 10px;
}

/*--- modal window ---*/
    .title_and_close{
        display: flex;
        align-items: flex-end;
        height: 44px;
    }

        .title_and_close h4{
            margin-left: 43px;
        }

.modal-footer>div{
    width: 100%;
}

.add_adunit_in_group{
    line-height: 30px;
    height: 30px;
    min-width: 56px!important;
    width: 56px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .add_adunit_in_group i::before{
        font-size: 18px;
    }

.adunit_in_group{
    width: 570px;
    min-height: 54px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex!important;
}

.form_adunit .adunit_in_group{
    width: 100%;
}

    .adunit_in_group tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    td.icon{
        width: 170px;
        /* height: 42px; */
        overflow: hidden;
    }

        img.icon{
            height: 51px;
            width: auto;
            margin-right: 10px;
        }

        img.image{
            width: 102px;
            height: auto;
            vertical-align: middle;
        }

    .adunit_in_group .info{
        width: 338px;
        padding-right: 10px;
        display: flex;
        align-items: flex-end;
    }

    .form_adunit .adunit_in_group .info{
        width: 265px;
        box-sizing: border-box;
        /* margin: 0 10px 0 5px; */
        display: flex;
        align-items: flex-end;
        /* padding: 0 10px; */
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 270px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: -4px;
            margin-bottom: 1px;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

    .form_adunit .action{
        justify-content: flex-end;
        align-items: flex-end;
    }

    .action{
        width: 60px;
        display: flex;
        align-items: center;
        /* justify-content: flex-end; */
    }


        .action i.glyph-icon{
            font-size: 24px;
        }

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.form_adunit .flex-block-adunit-group{
    padding: 0 0 4px 13px;
}

.flex-block-adunit-group{
    padding: 0 13px 4px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    /* height: 44px; */
    /* margin-bottom: 3px; */

}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
