import Vue from 'vue';
import Vuex from 'vuex';
import VueHotkey from 'v-hotkey';

import Modals from './modules/Modals';
import Logos from './modules/Logos';

Vue.use(Vuex);
Vue.use(VueHotkey);

export const store = new Vuex.Store({
    state: {
        //baseUrl: '//so.yovoads.com',
        //baseUrl: '//api4.yovoads.com',
        //baseUrl: '//testapi.yovoads.com',
        dashUrl: '//dash.yovoads.com',
        //baseUrl: '//localhost:8081',

        baseUrl: process.env.NODE_ENV === 'production' ? '//so.yovoads.com' : '//localhost:8081',

        baseStatUrl: '//stat.yovoads.com',
        gravatarUrl: 'https://www.gravatar.com/avatar/',

        role: {
            superAdmin: 'superadmin',
            admin: 'admin',
            moderator: 'moderator'
        },
        auth: {
            userId: '',
            accessToken: '',
            apiToken: '',
            email: '',
            iconUrl: '',
            role: '',
            error: ''
        },
        error: {
            needToLogin: 'ERROR_NEED_TO_LOGIN',
            queue: {
                alredyExists: 'ERROR_WRONG_APP_AND_COUNTRY'
            },
        },

        remeber: {
            analitics: {
                typeGraphicsInapps: [],
                typeGraphicsShows: [],
                typeGraphicsAdunit: [],
                typeResources: [],
            },
            queue: {
                typeBannerId: null,
                typeBannerName: null,
                queueId: null,
                queueName: null,
                groupId: null,
                groupNetwork: null
            },
            group: {
                network: [],
                typeBanner: [],
                search: ''
            },
            adunit: {
                search: '',
                typeBanner: [],
            },
            applications: {
                search: '',
                typeBanner: [],
            },
            exchange: {
                typeBanner: [],
            },
            pagination: {
                type: 0,
                typePage: 0,
                network: 0,
                networkPage: 0,
                relationTypeNet: 0,
                relationTypeNetPage: 0,
                statInapps: 0,
                statInappsPage: 0,
                statClicks: 0,
                statClicksPage: 0,
                statAdunits: 0,
                statAdunitsPage: 0,
                statInstalls: 0,
                statInstallsPage: 0,
                exchange: 1,
                exchangePage: 0,
                adunit: 1,
                adunitPage: 0,
                applications: 1,
                applicationsPage: 0,
                resetAccount: 0,
                resetAccountPage: 0,
            },
        },

        languageActive: 0,
        languageList: [
            {title: 'English', key: 'en'},
            {title: 'Русский', key: 'ru'}
        ],

        osActive: 1,
        osStatisticsActive: ["android", "ios"],
        osStatisticActive: ["android"],

        appDefault: {},

        typeBannerList: [],
        typeBannerListAssoc: [],
        trackingList: [],
        osList: [],
        categoryList: [],
        networkList: [],
        applicationList: [],
        rolesList: [],
        pagesList: [],
        gravityList: [
            {id: 0, name: 'None'},
            {id: 51, name: 'top_left'},
            {id: 49, name: 'top_center'},
            {id: 53, name: 'top_right'},
            {id: 83, name: 'bottom_left'},
            {id: 81, name: 'bottom_center'},
            {id: 85, name: 'bottom_right'},
        ],
        countryList: [],
        localeList: [],
        sexList: [
            {id: 'ANY', name: 'Any'},
            {id: 'M', name: 'Male'},
            {id: 'F', name: 'Female'},
        ],
        ageList: [
            {id: '0-5', name: '0-5'},
            {id: '5-9', name: '5-9'},
            {id: '9-13', name: '9-13'},
            {id: '13+', name: '13+'},
            {id: '18+', name: '18+'},
        ],

        exchangeEditId: 0,
        adUnitEditId: 0,
        appEditId: 0,
        queueEditId: 0,
        groupEditId: 0,
        typeEditId: 0,
        networkEditId: 0,

        closeSidebar: false,

        sendFormStatus: [],
        errorFormStatus: [],
        backPrevLink: '',

        osDev: [{id: 1, name: 'IOS'}, {id: 2, name: 'Android'}],
        adUnit: [],
        queue: [],
        group: [],
        application: [],
        exchange: [],

        isPreloader: false,
        isEscClose: true
    },
    getters: {
        getBaseUrl(state){
            return state.baseUrl;
        },

        getBaseStatUrl(state){
            return state.baseStatUrl;
        },

        /*--- auth data ---*/
        getDashUrl(state){
            return state.dashUrl;
        },

        getGravatarUrl(state){
            return state.gravatarUrl;
        },

        getAuthUserId(state){
            return state.auth.userId;
        },
        getAuthEmail(state){
            return state.auth.email;
        },
        getAuthIconUrl(state){
            return state.auth.iconUrl;
        },
        getAuthAccessToken(state){
            return state.auth.accessToken;
        },
        getAuthApiToken(state){
            return state.auth.apiToken;
        },
        getAuthRole(state){
            return state.auth.role;
        },
        getRoleSuperAdmin(state){
            return state.role.superAdmin;
        },
        getRoleAdmin(state){
            return state.role.admin;
        },
        getRoleModerator(state){
            return state.role.moderator;
        },
        /*--- end auth data ---*/

        /*--- remeber settings ---*/
        //analitics
        getRemeberAnaliticsTypeGraphicsInapps(state){
            return state.remeber.analitics.typeGraphicsInapps;
        },

        getRemeberAnaliticsTypeGraphicsShows(state){
            return state.remeber.analitics.typeGraphicsShows;
        },

        getRemeberAnaliticsTypeGraphicsAdunit(state){
            return state.remeber.analitics.typeGraphicsAdunit;
        },

        getRemeberAnaliticsTypeResources(state){
            return state.remeber.analitics.typeResources;
        },

        // queue
        getRemeberQueueTypeBannerId(state){
            return state.remeber.queue.typeBannerId;
        },

        getRemeberQueueTypeBannerName(state){
            return state.remeber.queue.typeBannerName;
        },

        getRemeberQueueQueueId(state){
            return state.remeber.queue.queueId;
        },

        getRemeberQueueQueueName(state){
            return state.remeber.queue.queueName;
        },

        getRemeberQueueGroupId(state){
            return state.remeber.queue.groupId;
        },

        getRemeberQueueGroupNetwork(state){
            return state.remeber.queue.groupNetwork;
        },

        getRemeberApplicationsTypeBanner(state){
            return state.remeber.applications.typeBanner;
        },

        getRemeberAdunitTypeBanner(state){
            return state.remeber.adunit.typeBanner;
        },

        getRemeberExchangeTypeBanner(state){
            return state.remeber.exchange.typeBanner;
        },

        // group
        getRemeberGroupTypeBanner(state){
            return state.remeber.group.typeBanner;
        },

        getRemeberGroupNetwork(state){
            return state.remeber.group.network;
        },

        getRemeberGroupSearch(state){
            return state.remeber.group.search;
        },

        getRemeberAdunitSearch(state){
            return state.remeber.adunit.search;
        },

        getRemeberApplicationsSearch(state){
            return state.remeber.applications.search;
        },

        // pagination
        getRemeberPaginationType(state){
            return state.remeber.pagination.type;
        },

        getRemeberPaginationTypePage(state){
            return state.remeber.pagination.typePage;
        },

        getRemeberPaginationNetwork(state){
            return state.remeber.pagination.network;
        },

        getRemeberPaginationNetworkPage(state){
            return state.remeber.pagination.networkPage;
        },

        getRemeberPaginationRelationTypeNet(state){
            return state.remeber.pagination.relationTypeNet;
        },

        getRemeberPaginationRelationTypeNetPage(state){
            return state.remeber.pagination.relationTypeNetPage;
        },

        getRemeberPaginationStatInapps(state){
            return state.remeber.pagination.statInapps;
        },

        getRemeberPaginationStatInappsPage(state){
            return state.remeber.pagination.statInappsPage;
        },

        getRemeberPaginationStatClicks(state){
            return state.remeber.pagination.statClicks;
        },

        getRemeberPaginationStatClicksPage(state){
            return state.remeber.pagination.statClicksPage;
        },

        getRemeberPaginationStatAdunits(state){
            return state.remeber.pagination.statAdunits;
        },

        getRemeberPaginationStatAdunitsPage(state){
            return state.remeber.pagination.statAdunitsPage;
        },

        getRemeberPaginationStatInstalls(state){
            return state.remeber.pagination.statInstalls;
        },

        getRemeberPaginationStatInstallsPage(state){
            return state.remeber.pagination.statInstallsPage;
        },

        getRemeberPaginationExchange(state){
            return state.remeber.pagination.exchange;
        },

        getRemeberPaginationExchangePage(state){
            return state.remeber.pagination.exchangePage;
        },

        getRemeberPaginationAdunit(state){
            return state.remeber.pagination.adunit;
        },

        getRemeberPaginationAdunitPage(state){
            return state.remeber.pagination.adunitPage;
        },

        getRemeberPaginationApplications(state){
            return state.remeber.pagination.applications;
        },

        getRemeberPaginationApplicationsPage(state){
            return state.remeber.pagination.applicationsPage;
        },


        getRemeberPaginationResetAccount(state){
            return state.remeber.pagination.resetAccount;
        },

        getRemeberPaginationResetAccountPage(state){
            return state.remeber.pagination.resetAccountPage;
        },
        /*--- end remeber settings ---*/

        getLanguageActive(state){
            return state.languageActive;
        },

        getOsDev(state){
            return state.osDev;
        },

        getExchange(state){
            return state.exchange;
        },

        getAdUnit(state){
            return state.adUnit;
        },

        /*--- edit id ---*/
        getExchangeEditId(state){
            return state.exchangeEditId;
        },

        getAdUnitEditId(state){
            return state.adUnitEditId;
        },

        getAppEditId(state){
            return state.appEditId;
        },

        getQueueEditId(state){
            return state.queueEditId;
        },

        getGroupEditId(state){
            return state.groupEditId;
        },

        getTypeEditId(state){
            return state.typeEditId;
        },

        getNetworkEditId(state){
            return state.networkEditId;
        },
        /*--- end edit id ---*/

        getAppDefault(state){
            return state.appDefault;
        },

        /*--- list ---*/
        getLanguageList(state){
            return state.languageList;
        },

        getTypeBannerList(state){
            return state.typeBannerList;
        },

        getTypeBannerListAssoc(state){
            return state.typeBannerListAssoc;
        },

        getTrackingList(state){
            return state.trackingList;
        },

        getOsList(state){
            return state.osList;
        },

        getOsActive(state){
            return state.osActive;
        },

        getOsStatisticsActive(state){
            return state.osStatisticsActive;
        },

        getOsStatisticActive(state){
            return state.osStatisticActive;
        },

        getCategoryList(state){
            return state.categoryList;
        },

        getNetworkList(state){
            return state.networkList;
        },

        getApplicationList(state){
            return state.applicationList;
        },

        getRolesList(state){
            return state.rolesList;
        },

        getPagesList(state){
            return state.pagesList;
        },

        getGravityList(state){
            return state.gravityList;
        },

        getSexList(state){
            return state.sexList;
        },

        getAgeList(state){
            return state.ageList;
        },

        getCountryList(state){
            return state.countryList;
        },

        getLocaleList(state){
            return state.localeList;
        },
        /*--- end list ---*/

        getCloseSidebar(state){
            return state.closeSidebar;
        },

        getSendFormStatus(state){
            return state.sendFormStatus;
        },

        getErrorFormStatus(state){
            return state.errorFormStatus;
        },

        getError(state){
            return state.error;
        },

        getBackPrevLink(state){
            return state.backPrevLink;
        },

        getIsPreloader(state){
            return state.isPreloader;
        },

        getIsEscClose(state){
            return state.isEscClose;
        },

    },
    mutations: {
        setLanguageActive(state, playLoad){
            state.languageActive = playLoad;
        },

        /*--- remeber settings ---*/
        //analitics
        setRemeberAnaliticsTypeGraphicsInapps(state, playLoad){
            state.remeber.analitics.typeGraphicsInapps = playLoad;
        },

        setRemeberAnaliticsTypeGraphicsShows(state, playLoad){
            state.remeber.analitics.typeGraphicsShows = playLoad;
        },

        setRemeberAnaliticsTypeGraphicsAdunit(state, playLoad){
            state.remeber.analitics.typeGraphicsAdunit = playLoad;
        },

        setRemeberAnaliticsTypeResources(state, playLoad){
            state.remeber.analitics.typeResources = playLoad;
        },

        // queue
        setRemeberQueueTypeBannerId(state, playLoad){
            state.remeber.queue.typeBannerId = playLoad;
        },

        setRemeberQueueTypeBannerName(state, playLoad){
            state.remeber.queue.typeBannerName = playLoad;
        },

        setRemeberQueueQueueId(state, playLoad){
            state.remeber.queue.queueId = playLoad;
        },

        setRemeberQueueQueueName(state, playLoad){
            state.remeber.queue.queueName = playLoad;
        },

        setRemeberQueueGroupId(state, playLoad){
            state.remeber.queue.groupId = playLoad;
        },

        setRemeberQueueGroupNetwork(state, playLoad){
            state.remeber.queue.groupNetwork = playLoad;
        },

        setRemeberApplicationsTypeBanner(state, playLoad){
            state.remeber.applications.typeBanner = playLoad;
        },

        setRemeberAdunitTypeBanner(state, playLoad){
            state.remeber.adunit.typeBanner = playLoad;
        },

        setRemeberExchangeTypeBanner(state, playLoad){
            state.remeber.exchange.typeBanner = playLoad;
        },

        // group
        setRemeberGroupTypeBanner(state, playLoad){
            state.remeber.group.typeBanner = playLoad;
        },

        setRemeberGroupNetwork(state, playLoad){
            state.remeber.group.network = playLoad;
        },

        setRemeberGroupSearch(state, playLoad){
            state.remeber.group.search = playLoad;
        },

        setRemeberAdunitSearch(state, playLoad){
            state.remeber.adunit.search = playLoad;
        },

        setRemeberApplicationsSearch(state, playLoad){
            state.remeber.applications.search = playLoad;
        },

        // pagination
        setRemeberPaginationType(state, playLoad){
            state.remeber.pagination.type = playLoad;
        },

        setRemeberPaginationTypePage(state, playLoad){
            state.remeber.pagination.typePage = playLoad;
        },

        setRemeberPaginationNetwork(state, playLoad){
            state.remeber.pagination.network = playLoad;
        },

        setRemeberPaginationNetworkPage(state, playLoad){
            state.remeber.pagination.networkPage = playLoad;
        },

        setRemeberPaginationRelationTypeNet(state, playLoad){
            state.remeber.pagination.relationTypeNet = playLoad;
        },

        setRemeberPaginationRelationTypeNetPage(state, playLoad){
            state.remeber.pagination.relationTypeNetPage = playLoad;
        },

        setRemeberPaginationStatInapps(state, playLoad){
            state.remeber.pagination.statInapps = playLoad;
        },

        setRemeberPaginationStatInappsPage(state, playLoad){
            state.remeber.pagination.statInappsPage = playLoad;
        },

        setRemeberPaginationStatClicks(state, playLoad){
            state.remeber.pagination.statClicks = playLoad;
        },

        setRemeberPaginationStatClicksPage(state, playLoad){
            state.remeber.pagination.statClicksPage = playLoad;
        },

        setRemeberPaginationStatAdunits(state, playLoad){
            state.remeber.pagination.statAdunits = playLoad;
        },

        setRemeberPaginationStatAdunitsPage(state, playLoad){
            state.remeber.pagination.statAdunitsPage = playLoad;
        },

        setRemeberPaginationStatInstalls(state, playLoad){
            state.remeber.pagination.statInstalls = playLoad;
        },

        setRemeberPaginationStatInstallsPage(state, playLoad){
            state.remeber.pagination.statInstallsPage = playLoad;
        },

        setRemeberPaginationExchange(state, playLoad){
            state.remeber.pagination.exchange = playLoad;
        },

        setRemeberPaginationExchangePage(state, playLoad){
            state.remeber.pagination.exchangePage = playLoad;
        },

        setRemeberPaginationAdunit(state, playLoad){
            state.remeber.pagination.adunit = playLoad;
        },

        setRemeberPaginationAdunitPage(state, playLoad){
            state.remeber.pagination.adunitPage = playLoad;
        },

        setRemeberPaginationApplications(state, playLoad){
            state.remeber.pagination.applications = playLoad;
        },

        setRemeberPaginationApplicationsPage(state, playLoad){
            state.remeber.pagination.applicationsPage = playLoad;
        },

        setRemeberPaginationResetAccount(state, playLoad){
            state.remeber.pagination.resetAccount = playLoad;
        },

        setRemeberPaginationResetAccountPage(state, playLoad){
            state.remeber.pagination.resetAccountPage = playLoad;
        },
        /*--- end remeber settings ---*/

        // adUnit
        setAuthData(state, playload){
            state.auth.userId = playload.userId;
            state.auth.accessToken = playload.accessToken;
            state.auth.apiToken = playload.apiToken;
            state.auth.email = playload.email;
            state.auth.iconUrl = playload.iconUrl;
            state.auth.role = playload.role;
        },
        setAdUnit(state, playLoad){
            state.adUnit.push(playLoad);
        },

        // osDev
        setOsDev(state, playLoad){
            state.osDev = playLoad;
        },

        /*--- edit id ---*/
        // exchange edit by id
        setExchangeEditId(state, playLoad){
            state.exchangeEditId = playLoad;
        },

        // adunit edit by id
        setAdUnitEditId(state, playLoad){
            state.adUnitEditId = playLoad;
        },

        // application edit by id
        setAppEditId(state, playLoad){
            state.appEditId = playLoad;
        },

        // queue edit by id
        setQueueEditId(state, playLoad){
            state.queueEditId = playLoad;
        },

        // group edit by id
        setGroupEditId(state, playLoad){
            state.groupEditId = playLoad;
        },

        // type edit by id
        setTypeEditId(state, playLoad){
            state.typeEditId = playLoad;
        },

        // network edit by id
        setNetworkEditId(state, playLoad){
            state.networkEditId = playLoad;
        },
        /*--- end edit id ---*/

        setAppDefault(state, playLoad){
            state.appDefault = playLoad;
        },

        /*--- list ---*/
        setTypeBannerList(state, playLoad){
            state.typeBannerList = playLoad;
        },
        setTypeBannerListAssoc(state, playLoad){
            state.typeBannerListAssoc = playLoad;
        },
        setTrackingList(state, playLoad){
            state.trackingList = playLoad;
        },
        setOsList(state, playLoad){
            state.osList = playLoad;
        },
        setOsActive(state, playLoad){
            state.osActive = playLoad;
        },
        setOsStatisticsActive(state, playLoad){
            state.osStatisticsActive = playLoad;
        },
        setOsStatisticActive(state, playLoad){
            state.osStatisticActive = playLoad;
        },
        setCategoryList(state, playLoad){
            state.categoryList = playLoad;
        },
        setNetworkList(state, playLoad){
            state.networkList = playLoad;
        },
        setApplicationList(state, playLoad){
            state.applicationList = playLoad;
        },
        setRolesList(state, playLoad){
            state.rolesList = playLoad;
        },
        setPagesList(state, playLoad){
            state.pagesList = playLoad;
        },
        setGravityList(state, playLoad){
            state.gravityList = playLoad;
        },
        setSexList(state, playLoad){
            state.sexList = playLoad;
        },
        setAgeList(state, playLoad){
            state.ageList = playLoad;
        },
        setCountryList(state, playLoad){
            state.countryList = playLoad;
        },
        setLocaleList(state, playLoad){
            state.localeList = playLoad;
        },
        /*--- end list ---*/

        setCloseSidebar(state, playLoad){
            state.closeSidebar = playLoad;
        },

        setSendFormStatus(state, playLoad){

            if (playLoad.value == '') {
                state.sendFormStatus = state.sendFormStatus.filter(function(val){
                    return val.key != playLoad.key;
                });
            } else {
                state.sendFormStatus.unshift(playLoad);
            }

        },

        setErrorFormStatus(state, playLoad){

            if (playLoad.value == '') {
                state.errorFormStatus = state.errorFormStatus.filter(function(val){
                    return val.key != playLoad.key;
                });
            } else {
                state.errorFormStatus.unshift(playLoad);
            }

        },

        setBackPrevLink(state, playLoad){
            state.backPrevLink = playLoad;
        },

        setIsPreloader(state, playLoad){
            state.isPreloader = playLoad;
        },

        setIsEscClose(state, playLoad){
            state.isEscClose = playLoad;
        },
    },
    actions: {
        setLanguageActiveAction(store, playLoad){
            store.commit('setLanguageActive', playLoad);
            localStorage.setItem('languageActive', playLoad);
        },

        setAdUnitAction(store, playLoad){
            store.commit('setAdUnit', playLoad);
        },

        /*--- remeber settings ---*/
        //analitics
        setRemeberAnaliticsTypeGraphicsInappsAction(store, playLoad){
            store.commit('setRemeberAnaliticsTypeGraphicsInapps', playLoad);
            localStorage.setItem('remeberAnaliticsTypeGraphicsInapps', playLoad);
        },

        setRemeberAnaliticsTypeGraphicsShowsAction(store, playLoad){
            store.commit('setRemeberAnaliticsTypeGraphicsShows', playLoad);
            localStorage.setItem('remeberAnaliticsTypeGraphicsShows', playLoad);
        },

        setRemeberAnaliticsTypeGraphicsAdunitAction(store, playLoad){
            store.commit('setRemeberAnaliticsTypeGraphicsAdunit', playLoad);
            localStorage.setItem('remeberAnaliticsTypeGraphicsAdunit', playLoad);
        },

        setRemeberAnaliticsTypeResourcesAction(store, playLoad){
            store.commit('setRemeberAnaliticsTypeResources', playLoad);
            localStorage.setItem('remeberAnaliticsTypeResources', playLoad);
        },

        // queue
        setRemeberQueueTypeBannerIdAction(store, playLoad){
            store.commit('setRemeberQueueTypeBannerId', playLoad);
            localStorage.setItem('remeberQueueTypeBannerId', playLoad);
        },

        setRemeberQueueTypeBannerNameAction(store, playLoad){
            store.commit('setRemeberQueueTypeBannerName', playLoad);
            localStorage.setItem('remeberQueueTypeBannerName', playLoad);
        },

        setRemeberQueueQueueIdAction(store, playLoad){
            store.commit('setRemeberQueueQueueId', playLoad);
            localStorage.setItem('remeberQueueQueueId', playLoad);
        },

        setRemeberQueueQueueNameAction(store, playLoad){
            store.commit('setRemeberQueueQueueName', playLoad);
            localStorage.setItem('remeberQueueQueueName', playLoad);
        },

        setRemeberQueueGroupIdAction(store, playLoad){
            store.commit('setRemeberQueueGroupId', playLoad);
            localStorage.setItem('remeberQueueGroupId', playLoad);
        },

        setRemeberQueueGroupNetworkAction(store, playLoad){
            store.commit('setRemeberQueueGroupNetwork', playLoad);
            localStorage.setItem('remeberQueueGroupNetwork', playLoad);
        },

        setRemeberApplicationsTypeBannerAction(store, playLoad){
            store.commit('setRemeberApplicationsTypeBanner', playLoad);
            localStorage.setItem('remeberApplicationsTypeBanner', playLoad);
        },

        setRemeberAdunitTypeBannerAction(store, playLoad){
            store.commit('setRemeberAdunitTypeBanner', playLoad);
            localStorage.setItem('remeberAdunitTypeBanner', playLoad);
        },

        setRemeberExchangeTypeBannerAction(store, playLoad){
            store.commit('setRemeberExchangeTypeBanner', playLoad);
            localStorage.setItem('remeberExchangeTypeBanner', playLoad);
        },

        // group
        setRemeberGroupTypeBannerAction(store, playLoad){
            store.commit('setRemeberGroupTypeBanner', playLoad);
            localStorage.setItem('remeberGroupTypeBanner', playLoad);
        },

        setRemeberGroupNetworkAction(store, playLoad){
            store.commit('setRemeberGroupNetwork', playLoad);
            localStorage.setItem('remeberGroupNetwork', playLoad);
        },

        setRemeberGroupSearchAction(store, playLoad){
            store.commit('setRemeberGroupSearch', playLoad);
            localStorage.setItem('remeberGroupSearch', playLoad);
        },

        setRemeberAdunitSearchAction(store, playLoad){
            store.commit('setRemeberAdunitSearch', playLoad);
            localStorage.setItem('remeberAdunitSearch', playLoad);
        },

        setRemeberApplicationsSearchAction(store, playLoad){
            store.commit('setRemeberApplicationsSearch', playLoad);
            localStorage.setItem('remeberApplicationsSearch', playLoad);
        },

        // pagination
        setRemeberPaginationTypeAction(store, playLoad){
            store.commit('setRemeberPaginationType', playLoad);
            localStorage.setItem('remeberPaginationType', playLoad);
        },

        setRemeberPaginationTypePageAction(store, playLoad){
            store.commit('setRemeberPaginationTypePage', playLoad);
            localStorage.setItem('remeberPaginationTypePage', playLoad);
        },

        setRemeberPaginationNetworkAction(store, playLoad){
            store.commit('setRemeberPaginationNetwork', playLoad);
            localStorage.setItem('remeberPaginationNetwork', playLoad);
        },

        setRemeberPaginationNetworkPageAction(store, playLoad){
            store.commit('setRemeberPaginationNetworkPage', playLoad);
            localStorage.setItem('remeberPaginationNetworkPage', playLoad);
        },

        setRemeberPaginationRelationTypeNetAction(store, playLoad){
            store.commit('setRemeberPaginationRelationTypeNet', playLoad);
            localStorage.setItem('remeberPaginationRelationTypeNet', playLoad);
        },

        setRemeberPaginationRelationTypeNetPageAction(store, playLoad){
            store.commit('setRemeberPaginationRelationTypeNetPage', playLoad);
            localStorage.setItem('remeberPaginationRelationTypeNetPage', playLoad);
        },

        setRemeberPaginationStatInappsAction(store, playLoad){
            store.commit('setRemeberPaginationStatInapps', playLoad);
            localStorage.setItem('remeberPaginationStatInapps', playLoad);
        },

        setRemeberPaginationStatInappsPageAction(store, playLoad){
            store.commit('setRemeberPaginationStatInappsPage', playLoad);
            localStorage.setItem('remeberPaginationStatInappsPage', playLoad);
        },

        setRemeberPaginationStatClicksAction(store, playLoad){
            store.commit('setRemeberPaginationStatClicks', playLoad);
            localStorage.setItem('remeberPaginationStatClicks', playLoad);
        },

        setRemeberPaginationStatClicksPageAction(store, playLoad){
            store.commit('setRemeberPaginationStatClicksPage', playLoad);
            localStorage.setItem('remeberPaginationStatClicksPage', playLoad);
        },

        setRemeberPaginationStatAdunitsAction(store, playLoad){
            store.commit('setRemeberPaginationStatAdunits', playLoad);
            localStorage.setItem('remeberPaginationStatAdunits', playLoad);
        },

        setRemeberPaginationStatAdunitsPageAction(store, playLoad){
            store.commit('setRemeberPaginationStatAdunitsPage', playLoad);
            localStorage.setItem('remeberPaginationStatAdunitsPage', playLoad);
        },

        setRemeberPaginationStatInstallsAction(store, playLoad){
            store.commit('setRemeberPaginationStatInstalls', playLoad);
            localStorage.setItem('remeberPaginationStatInstalls', playLoad);
        },

        setRemeberPaginationStatInstallsPageAction(store, playLoad){
            store.commit('setRemeberPaginationStatInstallsPage', playLoad);
            localStorage.setItem('remeberPaginationStatInstallsPage', playLoad);
        },

        setRemeberPaginationExchangeAction(store, playLoad){
            store.commit('setRemeberPaginationExchange', playLoad);
            localStorage.setItem('remeberPaginationExchange', playLoad);
        },

        setRemeberPaginationExchangePageAction(store, playLoad){
            store.commit('setRemeberPaginationExchangePage', playLoad);
            localStorage.setItem('remeberPaginationExchangePage', playLoad);
        },

        setRemeberPaginationAdunitAction(store, playLoad){
            store.commit('setRemeberPaginationAdunit', playLoad);
            localStorage.setItem('remeberPaginationAdunit', playLoad);
        },

        setRemeberPaginationAdunitPageAction(store, playLoad){
            store.commit('setRemeberPaginationAdunitPage', playLoad);
            localStorage.setItem('remeberPaginationAdunitPage', playLoad);
        },

        setRemeberPaginationApplicationsAction(store, playLoad){
            store.commit('setRemeberPaginationApplications', playLoad);
            localStorage.setItem('remeberPaginationApplications', playLoad);
        },

        setRemeberPaginationApplicationsPageAction(store, playLoad){
            store.commit('setRemeberPaginationApplicationsPage', playLoad);
            localStorage.setItem('remeberPaginationApplicationsPage', playLoad);
        },

        setRemeberPaginationResetAccountAction(store, playLoad){
            store.commit('setRemeberPaginationResetAccount', playLoad);
            localStorage.setItem('remeberPaginationResetAccount', playLoad);
        },

        setRemeberPaginationResetAccountPageAction(store, playLoad){
            store.commit('setRemeberPaginationResetAccountPage', playLoad);
            localStorage.setItem('remeberPaginationResetAccountPage', playLoad);
        },
        /*--- end remeber settings ---*/
    },
    modules: {
        modals: Modals,
        logos: Logos
    }
});
