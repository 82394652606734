<template>
    <section>
        <div class="panel">
            <div ref="filter_graphic">
                <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-group table-group-header" id="datatable-group">
                    <thead>
                        <tr>
                            <th class="col_img">
                                <td class="col-md-6" style="padding-left: 0px; padding-bottom: 0px;">

                                </td>
                                <td class="col-md-3" style="word-break: break-word;">

                                </td>
                                <td class="col-md-3">

                                </td>
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="panel-body">
                <div class="toc-tocify">
                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <div class="flexbox">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Lib from '../mixins/lib';
import MultiSelect from '../plugins/MultiSelect';
import DatePicker from 'vue2-datepicker';
import ChartBar from '../plugins/ChartBar.js';

export default {
    mixins: [Authentication, Lib],

    data() {
        return {
            pageId: 27,

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 61,
            othersHeight: 40,
            filterHeight: 80,

            datacollectionBar: null,

            applicationListDB: [],
            cagortDataDB: [],

            // calendar
            mxDatepickerPopup: null,
            mxDatepickerTop: null,
            mxDatepickerBtnConfirm: null,
            mxCalendars: null,
            styleWidthSm: 'width: 190px;',
            styleDisplayNone: 'display: none;',

            statistics: {
                filters: {
                    app: {
                        value: [],
                        alt: ''
                    }
                },

                dateFrom: "",
                dateTo: "",
            },

            time: null,

            status: {
                app: false
            },
        };
    },
    destroyed(){
        //this.$store.commit('setIsPreloader', false);
    },
    created(){
        if (this.getImmitation == 'true') {
            let isVisibleP = this.getPageVisiblePromise(this.pageId);

            isVisibleP.then(res => {
                if (res) {

                } else {
                    this.$router.push({name: '404'});
                }
            });
        } else {

        }
    },
    mounted() {

    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },
    },
    methods: {

    },
    components: {
        MultiSelect,
        DatePicker,
        ChartBar
    }
}
</script>

<style scoped>
    .panel{
        box-shadow: none;
    }

    .panel-body{
        padding-top: 0px;
        padding-left: 13px;
    }

    .small {
        height: 260px;
        max-height: 260px;
        margin:  20px 0px 0px;
        display: flex;
    }

    .scroll{
        overflow: auto;
    }

    .table td{
        border-top: 0px none;
    }

    .font-gray{
        color: #c2c2c2 !important;
    }

    .col_img{
        padding: 14px 13px 5px!important;
        border-bottom-width: 1px;
        height: 55px;
    }

    .cagort_table_tr{
        width: 100%;
        display: flex;
    }

        .cagort_table_tr_td{
            padding: 5px 10px;
            border-top: 1px solid #ccc;
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-top: -1px;
        }

        .width7perc{
            width: 7%;
            position: relative;
        }

        .width93perc{
            width: 93%;
            height: 260px;
            max-height: 260px;
        }

        .title_col {
            font-size: 11px;
            width: 7%;
            min-width: 7%;
            border: 1px solid #ccc;
            background-color: #eee;
            word-break: keep-all;
            white-space: nowrap;
        }

        .data_col {
            font-size: 11px;
            padding: 5px 0px;
            width: 3%;
            min-width: 3%;
            text-align: center;
            color: #505050;
        }

    /* patepicker */
    .mx-datepicker-popup{
        right: 0px!important;
    }

        .mx-datepicker-popup>div{
            display: flex;
        }

    .mx-datepicker-top{
        word-break: break-all;
        text-align: center!important;
        order: 2;
        line-height: 23px!important;
    }

        .mx-datepicker-top > span{
            padding: 5px 15px;
            background-color: #eee;
            border-radius: 10px;
            margin: 3px 5px;
            word-break: keep-all;
            white-space: nowrap!important;
            display: inline-block;
            width: 100%;
        }

        .mx-datepicker-top > span::after{
            content: ''!important;
            margin: 0px!important;
        }

    .mx-datepicker-btn{
        width: 200px;
        position: absolute;
        bottom: 30px;
        color: #fff!important;
        height: 40px;
    }

    .mx-datepicker-btn-close{
        left: 230px;
        width: 100px;
        background-color: #e67e22!important;
    }

    .mx-datepicker-btn-confirm{
        left: 10px;
        background: #eee!important;
        color: #777!important;
    }

    .mx-calendar{
        order: 1;
    }

        .mx-calendar-header .mx-calendar__prev-icon:first-child{
            display: none;
        }

        .mx-calendar-header .mx-calendar__next-icon:first-child, .mx-calendar-header .mx-calendar__next-icon:nth-child(3){
            display: none;
        }

            .mx-calendar-header .mx-calendar__prev-icon, .mx-calendar-header .mx-calendar__next-icon{
                width: 30px;
                border-radius: 3px;
                margin-right: 3px;
            }

            .mx-calendar-header .mx-calendar__prev-icon{
                background: #eee!important;
            }

            .mx-calendar-header .mx-calendar__next-icon{
                background: #ddd!important;
            }

    span.active{
        background: #ccc;
    }

    tr .info__column.not_active{
        background-color: #eee;
        color: #333;
    }

    .mx-calendar-table td.current{
        color:#fff!important;
        background-color:#1284e7!important;
    }

    .table-striped tr:nth-child(2n) > td{
        background: transparent!important;
    }
    /* end patepicker */
</style>
