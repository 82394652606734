<template>
    <section>
        <div class="panel">
            <div>
                <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-group table-group-header" id="datatable-group">
                    <thead>
                        <tr>
                            <th class="col_img">

                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="panel-body">
                <div class="toc-tocify">
                    <h3 class="mrg20B">Graphics</h3>
                    <div>&nbsp;</div>
                    <div class="flexbox">
                        <div>
                            <h3 class="mrg20B">Basic</h3>
                            <div class="small">
                                <chart-basic :chart-data="getDatacollectionBasic"></chart-basic>
                                <button @click="fillDataBasic()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Multi-axis</h3>
                            <div class="small">
                                <chart-multi :chart-data="getDatacollectionMulti"></chart-multi>
                                <button @click="fillDataMulti()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Radar</h3>
                            <div class="small">
                                <chart-radar :chart-data="getDatacollectionRadar"></chart-radar>
                                <button @click="fillDataRadar()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Polar Area</h3>
                            <div class="small">
                                <chart-polar-area :chart-data="getDatacollectionPolarArea"></chart-polar-area>
                                <button @click="fillDataPolarArea()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Doughnut</h3>
                            <div class="small">
                                <chart-doughnut :chart-data="getDatacollectionDoughnut"></chart-doughnut>
                                <button @click="fillDataDoughnut()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Pie</h3>
                            <div class="small">
                                <chart-pie :chart-data="getDatacollectionPie"></chart-pie>
                                <button @click="fillDataPie()">Randomize</button>
                            </div>
                        </div>
                        <div>
                            <h3 class="mrg20B">Google Map</h3>
                            <GChart
                                :settings="{ packages: ['geochart'], 'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY' }"
                                type="GeoChart"
                                :data="chartData"
                                :options="chartOptions"
                            />
                        </div>
                        <div style="width: 1000px!important;">
                            <h3 class="mrg20B">Google Bar</h3>
                            <GChart
                                :settings="{ packages: ['corechart'] }"
                                type="BarChart"
                                :data="chartDataBar"
                                :options="chartOptionsBar"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import ChartBasic from '../plugins/ChartBasic.js';
    import ChartMulti from '../plugins/ChartMulti.js';
    import ChartRadar from '../plugins/ChartRadar.js';
    import ChartPolarArea from '../plugins/ChartPolarArea.js';
    import ChartDoughnut from '../plugins/ChartDoughnut.js';
    import ChartPie from '../plugins/ChartPie.js';
    import { GChart } from 'vue-google-charts';

    export default {
        components: {
            ChartMulti, ChartBasic, ChartRadar, ChartPolarArea, ChartDoughnut, ChartPie, GChart
        },
        data() {
            return {
                datacollectionBasic: null,
                datacollectionMulti: null,
                datacollectionRadar: null,
                datacollectionPolarArea: null,
                datacollectionDoughnut: null,
                datacollectionPie: null,

                chartData: [
                    ['Country', 'Popularity'],
                    ['Germany', 200],
                    ['United States', 300],
                    ['Brazil', 400],
                    ['Canada', 500],
                    ['France', 600],
                    ['RU', 700]

                ],
                chartOptions: {
                    // showTooltip: true,
                    // showInfoWindow: true,
                    // chart: {
                    //     title: 'Company Performance',
                    //     subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                    // }
                },
                chartDataBar: [
                    ['Element', 'Density', { role: 'style' }, { role: 'annotation' } ],
                    ['Copper', 8.94, '#b87333', 'Cu' ],
                    ['Silver', 10.49, 'silver', 'Ag' ],
                    ['Gold', 19.30, 'gold', 'Au' ],
                    ['Platinum', 21.45, 'color: #e5e4e2', 'Pt' ]
                ],
                chartOptionsBar: {
                    // chart: {
                    //     title: 'Company Performance',
                    //     subtitle: 'Sales, Expenses, and Profit: 2014-2017',
                    // }
                }
            };
        },
        mounted() {
            this.fillDataBasic();
            this.fillDataMulti();
            this.fillDataRadar();
            this.fillDataPolarArea();
            this.fillDataDoughnut();
            this.fillDataPie();
        },
        computed: {
            getDatacollectionBasic() {
                return this.datacollectionBasic;
            },
            getDatacollectionMulti() {
                return this.datacollectionMulti;
            },
            getDatacollectionRadar() {
                return this.datacollectionRadar;
            },

            getDatacollectionPolarArea() {
                return this.datacollectionPolarArea;
            },

            getDatacollectionDoughnut() {
                return this.datacollectionDoughnut;
            },

            getDatacollectionPie() {
                return this.datacollectionPie;
            },
        },
        methods: {
            fillDataBasic() {
            this.datacollectionBasic = {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [
                {
                    label: 'Data One',
                    borderColor: '#f87979',
                    pointBackgroundColor: '#f87979',
                    pointBorderColor: '#f87979',
                    backgroundColor: 'transparent',
                    data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    borderWidth: 3,
                    pointRadius: 4,
                    pointHitRadius: 10,
                }, {
                    label: 'Data Two',
                    borderColor: '#ab47bc',
                    pointBackgroundColor: '#ab47bc',
                    pointBorderColor: '#ab47bc',
                    backgroundColor: 'transparent',
                    data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    borderWidth: 3,
                    pointRadius: 4,
                    pointHitRadius: 10,
                }
                ]
            };
            },
            fillDataMulti() {
            this.datacollectionMulti = {
                labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                datasets: [
                {
                    label: 'Data One',
                    yAxisID: 'One',
                    borderColor: '#f87979',
                    pointBackgroundColor: '#f87979',
                    pointBorderColor: '#f87979',
                    backgroundColor: 'transparent',
                    data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    borderWidth: 3,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    spanGaps: false,
                }, {
                    label: 'Data Two',
                    yAxisID: 'Two',
                    borderColor: '#ab47bc',
                    pointBackgroundColor: '#ab47bc',
                    pointBorderColor: '#ab47bc',
                    backgroundColor: 'transparent',
                    data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    borderWidth: 3,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    spanGaps: false,
                }
                ]
            };
            },
            fillDataRadar() {
            this.datacollectionRadar = {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Oktober', 'November', 'December'],
                datasets: [
                    {
                        label: 'Data One',
                        yAxisID: 'One',
                        borderColor: '#f87979',
                        pointBackgroundColor: '#f87979',
                        pointBorderColor: '#f87979',
                        backgroundColor: 'rgba(248, 121, 121, .2)',
                        data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                        borderWidth: 3,
                        pointRadius: 4,
                        pointHitRadius: 10,
                        spanGaps: false,
                    }, {
                        label: 'Data Two',
                        yAxisID: 'Two',
                        borderColor: '#ab47bc',
                        pointBackgroundColor: '#ab47bc',
                        pointBorderColor: '#ab47bc',
                        backgroundColor: 'rgba(171, 71, 188, .2)',
                        data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                        borderWidth: 3,
                        pointRadius: 4,
                        pointHitRadius: 10,
                        spanGaps: false,
                    }
                ]
            };
            },

            fillDataPolarArea() {
                this.datacollectionPolarArea = {
                    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
                    datasets: [
                    {
                        backgroundColor: ['rgba(255, 0, 0, .8)', 'rgba(0, 128, 0, .8)', 'rgba(255, 255, 0, .8)', 'rgba(128, 128, 128, .8)', 'rgba(0, 0, 255, .8)'],
                        data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    }]
                };
            },
            fillDataDoughnut() {
                this.datacollectionDoughnut = {
                    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
                    datasets: [
                    {
                        backgroundColor: ['rgba(255, 0, 0, .8)', 'rgba(0, 128, 0, .8)', 'rgba(255, 255, 0, .8)', 'rgba(128, 128, 128, .8)', 'rgba(0, 0, 255, .8)'],
                        data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    }]
                };
            },
            fillDataPie() {
                this.datacollectionPie = {
                    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
                    datasets: [
                    {
                        backgroundColor: ['rgba(255, 0, 0, .8)', 'rgba(0, 128, 0, .8)', 'rgba(255, 255, 0, .8)', 'rgba(128, 128, 128, .8)', 'rgba(0, 0, 255, .8)'],
                        data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    }]
                };
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            }
        }
    }
</script>

<style scoped>
    .small {
        max-width: 400px;
        height: 400px;
        max-height: 400px;
        margin:  20px auto;
    }

    .flexbox{
        display: flex;
        flex-wrap: wrap;
    }

    .flexbox>div{
        width: 400px;
        padding-bottom: 30px;
        margin-right: 30px;
    }
</style>
