<template>
    <section>
        <div class="panel">
            <div>
                <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-group table-group-header" id="datatable-group">
                    <thead>
                        <tr>
                            <th class="col_img">

                            </th>
                        </tr>
                    </thead>
                </table>
            </div>

            <div class="panel-body">
                <div class="toc-tocify">
                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <h3 class="mrg20B">{{ getLang.sdk.getSdk }}</h3>
                        <p class="mrg20B font-gray-dark" style="line-height: 2em">
                            <ul class="download-list">
                                <li>{{ getLang.sdk.download }} <a href="http://img.yovoads.com/sdk/yovoplugin-release.aar" target="_blank">android SDK</a></li>
                                <li>{{ getLang.sdk.download }} <a href="http://img.yovoads.com/sdk/yovoAds_v4.0.2.unitypackage" target="_blank">unity package</a></li>
                                <li>{{ getLang.sdk.download }} <a href="https://github.com/googleads/googleads-mobile-unity/releases/tag/v3.15.0" target="_blank">google Mobile SDK</a></li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Lang from '../lang/message';

export default {
    data(){
        return {
            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 100,
        }
    },
    mounted(){
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        }
    },
    methods: {
        // set height for table list exchange
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },
    },
}
</script>

<style>
a.ui-accordion-header, a.ui-accordion-header-active{
    text-decoration: none;
}

a.ui-accordion-header:hover{
    text-decoration: none;
    color: #888;
}
a.ui-accordion-header-active:hover{
    color: #fff;
}

.table-group-header{
    margin-bottom: 0px;
    border-bottom: 0px none;
}

    .table-group-header th{
        padding: 6px 13px 5px;
        /* border-bottom: 0px none; */
        border-bottom-width: 1px;
        height: 56px;
    }

ul.download-list{
    list-style-type: none;
}

    ul.download-list a{
        text-decoration: underline;
        color: blue;
    }

.scroll{
    overflow: auto;
}

/* animation */
.color-enter{
    border-color: #00a792;
    background: #00a792;
    color: #fff;
}
.color-enter-active{
    transition: all .5s;
}
.color-enter-to{
    border-color: #dfe8f1;
    background: #fff;
    color: #555a60;
}
.color-leave-active{
    transition: all .5s reverse;
}
.color-leave-to{
    border-color: #00a792;
    background: #00a792;
    color: #fff;

}

.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    transition: all .5s reverse;
}
.fade-leave-to{
    opacity: 0;
}
</style>
