<template>
    <div id="page-sidebar" :style="getCloseSidebar ? 'width: 64px;' : ''">
        <input type="hidden" :value="updateActive">
        <div class="scroll-sidebar" :style="'overflow:auto; height: ' + getWindowHeight + 'px;'">
            <!-- <ul id="sidebar-menu" :class="getCloseSidebar ? 'close_sidebar' : ''" ref="sidebars" v-if="tutorialLinks.find(val => val.toLowerCase() == $route.name.toLowerCase())">
                <li class="header" v-show="!getCloseSidebar"><span>{{ getLang.menu.tutorial.toLowerCase() }}</span></li>

                <li v-show="getCloseSidebar">
                    <div>
                        <router-link :to="{name: 'tutorial', query: {hash: 'android'}}"  :title="getLang.menu.android">
                            <i class="glyph-icon icon-book"></i>
                        </router-link>
                    </div>
                </li>
                <li v-show="getCloseSidebar">
                    <div>
                        <router-link :to="{name: 'getsdk'}" :title="getLang.menu.getsdk">
                            <i class="glyph-icon icon-cloud-download"></i>
                        </router-link>
                    </div>
                </li>
                <li v-show="!getCloseSidebar">
                    <router-link :to="{name: 'howItWorks'}"  :title="getLang.menu.howItWorks">
                        <i class="glyph-icon icon-book"></i>
                        <span>{{ getLang.menu.howItWorks }}</span>
                    </router-link>
                    <div class="sidebar-menu">
                        <ul>
                            <li>
                                <router-link :to="{name: 'tutorial', query: {hash: 'android'}}"  :title="getLang.menu.android">
                                    <span>{{ getLang.menu.android }}</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{name: 'tutorial', query: {hash: 'ios'}}" :title="getLang.menu.ios">
                                    <span>{{ getLang.menu.ios }}</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="{name: 'tutorial', query: {hash: 'unity'}}" :title="getLang.menu.unity">
                                    <span>{{ getLang.menu.unity }}</span>
                                </router-link>
                            </li>

                            <li>
                                <router-link :to="{name: 'getsdk'}" :title="getLang.menu.getsdk" class="with_icon">
                                    <i class="glyph-icon icon-cloud-download"></i><span>{{ getLang.menu.getsdk }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li class="divider"></li>
            </ul> -->
            <!--  v-else -->
            <ul id="sidebar-menu" :class="getCloseSidebar ? 'close_sidebar' : ''" ref="sidebars">

                <li class="header" v-show="!getCloseSidebar"><span>{{ getLang.menu.reports }}</span></li>

                <li v-if="getPageVisible(17) === true">
                    <router-link :to="{ name: 'online' }" :title="getLang.menu.online">
                        <i class="glyph-icon icon-comments-o"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.online }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(18) === true" ref="sidebarApplication">
                    <router-link :to="{name: 'usersreports'}" :title="getLang.menu.users">
                        <i class="glyph-icon icon-linecons-mobile"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.users }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(19) === true">
                    <router-link :to="{name: 'applications'}"  :title="getLang.menu.application" class="with_icon">
                        <i class="glyph-icon icon-pie-chart"></i><span v-show="!getCloseSidebar">{{ getLang.menu.application }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(36) === true">
                    <router-link :to="{name: 'rateappreports'}" :title="getLang.menu.rateApp">
                        <i class="glyph-icon icon-line-chart"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.rateApp }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(34) === true">
                    <router-link :to="{name: 'ratemessagereports'}" :title="getLang.menu.rateMessage">
                        <i class="glyph-icon icon-signal"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.rateMessage }}</span>
                    </router-link>
                </li>
                <!-- <li>
                    <router-link :to="{name: 'statisticsAdunit'}"  :title="getLang.menu.adUnit" class="with_icon">
                        <i class="glyph-icon icon-area-chart"></i><span v-show="!getCloseSidebar">{{ getLang.menu.adUnit }}</span>
                    </router-link>
                </li>

                <li v-show="getCloseSidebar">
                    <router-link :to="{name: 'statisticsShows'}"  :title="getLang.menu.application">
                        <i class="glyph-icon icon-pie-chart"></i>
                    </router-link>
                </li>
                <li v-show="getCloseSidebar">
                    <router-link :to="{name: 'statisticsAdunit'}"  :title="getLang.menu.adUnit">
                        <i class="glyph-icon icon-area-chart"></i>
                    </router-link>
                </li>

                <li v-show="getCloseSidebar">
                    <router-link :to="{name: 'statisticsInstalls'}" :title="getLang.menu.installs">
                        <i class="glyph-icon icon-download"></i>
                    </router-link>
                </li>
                <li v-show="!getCloseSidebar">
                    <router-link :to="{name: 'statisticsInstalls'}" :title="getLang.menu.installs">
                        <i class="glyph-icon icon-download"></i><span>{{ getLang.menu.installs }}</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'cagorts'}" :title="getLang.menu.cagorts">
                        <i class="glyph-icon icon-th"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.cagorts }}</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name: 'graphicUsers'}" :title="getLang.menu.users">
                        <i class="glyph-icon icon-user"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.users }}</span>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{ name: 'graphics' }" :title="getLang.menu.graphics">
                        <i class="glyph-icon icon-bar-chart"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.graphics }}</span>
                    </router-link>
                </li>
                <li class="divider"></li> -->

                <li class="header" v-show="!getCloseSidebar"><span>{{ getLang.menu.inventory }}</span></li>

                <li v-if="getPageVisible(20) === true" ref="sidebarApplication">
                    <router-link :to="{name: 'users'}" :title="getLang.menu.users">
                        <i class="glyph-icon icon-linecons-mobile"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.users }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(20) === true" ref="sidebarApplication">
                    <router-link :to="{name: 'moderationusers'}" :title="getLang.menu.moderationUsers">
                        <i class="glyph-icon icon-linecons-mobile"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.moderationUsers }}</span>
                    </router-link>
                </li>
                <!-- <li ref="sidebarAdunit">
                    <router-link :to="{name: 'adunit'}" :title="getLang.menu.adUnit">
                        <i class="glyph-icon icon-image"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.adUnit }}</span>
                    </router-link>
                </li>
                <li ref="sidebarGroup">
                    <router-link :to="{name: 'group'}" :title="getLang.menu.rules">
                        <i class="glyph-icon icon-group"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.rules }}</span>
                    </router-link>
                </li>
                <li ref="sidebarQueue">
                    <router-link :to="{name: 'queue'}" :title="getLang.menu.scenario">
                        <i class="glyph-icon icon-laptop"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.scenario }}</span>
                    </router-link>
                </li> -->
                <li v-if="getPageVisible(21) === true">
                    <router-link :to="{name: 'exchange'}" :title="getLang.menu.exchange">
                        <i class="glyph-icon icon-building-o"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.exchange }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(22) === true">
                    <router-link :to="{name: 'advertiser'}" :title="getLang.menu.advertiser">
                        <i class="glyph-icon icon-file-audio-o"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.advertiser }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(35) === true">
                    <router-link :to="{name: 'ratemessageinventory'}" :title="getLang.menu.rateMessage">
                        <i class="glyph-icon icon-signal"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.rateMessage }}</span>
                    </router-link>
                </li>
                <li class="divider"></li>

                <li class="header" v-show="!getCloseSidebar"><span>{{ getLang.menu.userSettings }}</span></li>

                <li v-if="getPageVisible(26) === true">
                    <router-link :to="{name: 'userInfo'}" :title="getLang.menu.userInfo">
                        <i class="glyph-icon icon-user-md"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.userInfo }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(27) === true">
                    <router-link :to="{name: 'cardPay'}" :title="getLang.menu.cardPay">
                        <i class="glyph-icon icon-money"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.cardPay }}</span>
                    </router-link>
                </li>
                <li v-if="getPageVisible(28) === true">
                    <router-link :to="{name: 'reportError'}" :title="getLang.menu.reportAnError">
                        <i class="glyph-icon icon-file-text-o"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.reportAnError }}</span>
                    </router-link>
                </li>

                <li class="header" v-if="(getRole.toLowerCase() === $store.getters.getRoleSuperAdmin) && !getCloseSidebar"><span>{{ getLang.menu.globalSettings }}</span></li>

                <li v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin">
                    <router-link :to="{ name: 'relationTypeNetwork' }" :title="getLang.menu.relationTypeNetwork">
                        <i class="glyph-icon icon-chain"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.relationTypeNetwork }}</span>
                    </router-link>
                </li>
                <li v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin">
                    <router-link :to="{ name: 'resetAccount' }" :title="getLang.menu.resetAccount">
                        <i class="glyph-icon icon-eraser"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.resetAccount }}</span>
                    </router-link>
                </li>
                <li v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin">
                    <router-link :to="{ name: 'moderator' }" :title="getLang.menu.moderator">
                        <i class="glyph-icon icon-thumbs-o-up"></i>
                        <span v-show="!getCloseSidebar">{{ getLang.menu.moderator }}</span>
                    </router-link>
                </li>
            </ul><!-- #sidebar-menu -->
        </div>
    </div>
</template>

<script>
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';

export default {
    mixins: [Authentication],
    data(){
        return {
            lang: Lang,
            windowHeight: 0,
            headerHeight: 70,
            closeSidebar: this.$store.getters.getCloseSidebar,

            tutorialLinks: ['howItWorks', 'tutorial', 'getsdk'],

            sidebars: '',
            sidebarAdunit: '',
        }
    },
    computed: {
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },
        getWindowHeight(){
            this.setWindowHeight();

            return this.windowHeight;
        },
        getCloseSidebar(){
            this.closeSidebar = this.$store.getters.getCloseSidebar;

            return this.closeSidebar;
        },

        updateActive(){
            if (this.sidebars) {
                for(let i = 0; i < this.sidebars.childNodes.length; i++){
                    this.sidebars.childNodes[i].className = '';
                }
            }

            if (this.sidebarAdunit && this.sidebarGroup && this.sidebarApplication && this.sidebarQueue) {

                switch(this.$route.name){
                    case 'adunitAdd':
                    case 'adunitEdit':
                        this.sidebarAdunit.className = 'router-link-active';
                        break;

                    case 'groupAdd':
                    case 'groupEdit':
                        this.sidebarGroup.className = 'router-link-active';
                        break;

                    case 'applicationAdd':
                    case 'applicationEdit':
                        this.sidebarApplication.className = 'router-link-active';
                        break;

                    case 'queueAdd':
                    case 'queueEdit':
                        this.sidebarQueue.className = 'router-link-active';
                        break;
                }
            }
        }
    },
    methods: {
        setWindowHeight(){
            this.windowHeight = window.innerHeight - this.headerHeight;

            if(this.windowHeight < 0) this.windowHeight = 0;
        }
    },
    mounted(){
        this.$nextTick(function() {
            let _this = this;

            window.addEventListener('resize', function(){
                _this.setWindowHeight()
            });

            this.sidebars = this.$refs.sidebars;
            this.sidebarAdunit = this.$refs.sidebarAdunit;
            this.sidebarGroup = this.$refs.sidebarGroup;
            this.sidebarApplication = this.$refs.sidebarApplication;
            this.sidebarQueue = this.$refs.sidebarQueue;
        })
    }
}
</script>

<style scoped>
#sidebar-menu li a{
    border: 1px solid #fff;
    word-break: keep-all;
    white-space: nowrap;
}

#sidebar-menu li > span{
    font-size: 17px;
    font-weight: bold;
}

#sidebar-menu li a:hover{
    border: 1px solid rgb(201, 201, 201)!important;
}

    .close_sidebar li a:after, .close_sidebar li a:hover:after{
        right: auto!important;
        width: auto!important;
    }

/* a.router-link-active */

.router-link-exact-active.router-link-active, li.router-link-active a{
    border-radius: 3px;
    border: 1px solid rgb(201, 201, 201)!important;
}

a.router-link-active:after, li.router-link-active a:after{
    position: absolute;
    line-height: 21px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    top: 50%;
    right: 5px;
    font-family: FontAwesome;
    content: '\F105';
}

.router-link-exact-active.router-link-active:after, #sidebar-menu li a:hover:after{
    position: absolute;
    line-height: 21px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    top: 50%;
    right: 5px;
    font-family: FontAwesome;
    content: '\f105';
}

#sidebar-menu .sidebar-menu .router-link-exact-active.router-link-active.with_icon:before, #sidebar-menu li a.with_icon:before{
    content: '';
    width: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

#sidebar-menu .sidebar-menu .router-link-exact-active.router-link-active:after, #sidebar-menu li a:after{
    right: -4px!important;
}

.sidebar-menu ul{
    padding-left: 20px;
    list-style-type: none;
}

#page-sidebar{
    transition: all .7s;
}

#page-sidebar li a.sf-with-ul::after{
    content: '';
}

#sidebar-menu li a.title_list_menu:hover{
    border: 1px solid #fff!important;
    cursor: default;
}

.title_list_menu:hover:after{
    content: ''!important;
}
</style>
