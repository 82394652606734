<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_group"
                                                ref="saveGroup"
                                                @click.prevent="saveGroup()"
                                                :disabled="!isValidForm"
                                        >
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right reopen_group"
                                                ref="reopenGroup"
                                                @click.prevent="reopenGroup()"
                                                :disabled="!isValidForm"
                                        >
                                            <span>{{ getLang.reopen }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <!-- <button class="btn btn-alt btn-hover btn-warning float-right close_group"
                                                @click.prevent="closeGroupForm()"
                                        >
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button> -->
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9" style="text-align: right;">
                                    <label class="control-label label_on_input"
                                            style="top: -18px;"
                                            :style="$store.getters.getLanguageActive == 1 ? 'right: 61px;' : 'right: 70px;'"
                                            :title="groupData.enabled.alt"
                                    >
                                        {{ getLang.group.enabled }}
                                    </label>
                                    <div :class="groupData.enabled.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                        @click.prevent="groupData.enabled.value = !groupData.enabled.value"
                                        style="margin-top: 5px;"
                                        >
                                        <div class="bootstrap-switch-container">
                                            <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                            <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>
                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="getLang.group.name + ' (' + getLang.maxChars + ')'">
                                        {{ getLang.group.name }}
                                    </label>
                                    <input class="form-control outlight" id="" :placeholder="''" type="text" v-model="groupData.name.value">
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.name + ' (' + getLang.maxChars + ')'" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="groupData.typeBanner.alt">
                                        {{ getLang.group.type }}
                                    </label>
                                    <!-- <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTypeBanner }}</span>
                                        <select class="custom-select" v-model="groupData.typeBanner.value" @change="selectType($event)">
                                            <option :value="el.id" v-for="(el, ind) in getTypeBannerView" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div> -->
                                    <MultiSelectIcons categories-alt="type banner list"
                                            :categories-title="''"
                                            :get-category-list="getTypeBannerList"
                                            :setDefaultCategory="groupData.typeBanner.value"
                                            :countItem="1"
                                            :placeholder="getLang.statistics.adtype"
                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                            styleBlockSelect="margin-bottom: 4px;"
                                            :is-required="false"
                                            @setCategory="val => groupData.typeBanner.value = val"
                                            @removeCategory="val => groupData.typeBanner.value = val"
                                            >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.type" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input">
                                        {{ getLang.adUnit.chooseOS }}
                                    </label>
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getOsActive }}</span>
                                        <select class="custom-select" v-model="groupData.os.value">
                                            <option :value="item.id" v-for="(item, key) in getOsList" :key="key">{{ item.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.adUnit.chooseOS" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <div class="form-group form_group_wrap">
                                <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                <div class="col-sm-9">
                                    <span class="icon_required" :title="getLang.required">*</span>
                                    <label class="control-label label_on_input" :title="groupData.network.alt">
                                        {{ getLang.group.network }}
                                    </label>
                                    <!-- <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getNetworkText }}</span>
                                        <select class="custom-select" @change="selectNetwork($event)" v-model="groupData.network.value">
                                            <option :value="el.id" v-for="(el, ind) in getNetworkList" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div> -->
                                    <MultiSelectIcons categories-alt="network list"
                                                :categories-title="''"
                                                :get-category-list="getNetworkList"
                                                :setDefaultCategory="groupData.network.value"
                                                :countItem="1"
                                                :placeholder="getLang.statistics.network"
                                                styleInput="padding-right: 0px; padding-left: 0px;"
                                                styleBlockSelect="margin-bottom: 4px;"
                                                :isUpdateCategoryList="true"
                                                :is-required="false"
                                                @setCategory="val => groupData.network.value = val"
                                                @removeCategory="val => groupData.network.value = val"
                                    >
                                    </MultiSelectIcons>
                                    <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.network" data-original-title=".icon-question"></i>
                                </div>
                                <div class="col-sm-1">&nbsp;</div>
                            </div>

                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="!getCheckCrosspromo">
                                    <div class="col-sm-2" style="text-align: right;">
                                        <label class="control-label label_on_input"
                                                style="top: -18px;"
                                                :style="$store.getters.getLanguageActive == 1 ? 'right: 52px;' : 'right: 76px;'"
                                                :title="groupData.infinity.alt"
                                        >
                                            {{ getLang.group.infinity }}
                                        </label>
                                        <div :class="groupData.infinity.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                            @click.prevent="groupData.infinity.value = !groupData.infinity.value"
                                            style="margin-top: 5px;"
                                            >
                                            <div class="bootstrap-switch-container">
                                                <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9">
                                        <span class="icon_required" :title="getLang.required">*</span>
                                        <label class="control-label label_on_input" :title="groupData.networkCount.alt">{{ getLang.group.networkCount }}</label>
                                        <input class="form-control outlight" type="number" step="1" min="1" :disabled="groupData.infinity.value" @input="setNetworkCount($event)" :value="getNetworkCount" required>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.networkCount" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>

                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="getCheckCrosspromo">
                                    <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                    <div class="col-sm-9">
                                        <label class="control-label label_on_input" :title="groupData.showType.alt">{{ getLang.group.showType }}</label>
                                        <div class="selector" style="width: 82px;">
                                            <span style="width: 60px; -moz-user-select: none;">{{ getShowTypeText }}</span>
                                            <select class="custom-select" @change="selectShowType($event)" v-model="groupData.showType.value">
                                                <option :value="el.value" v-for="(el, ind) in getShowTypeList" :key="ind">{{ el.name }}</option>
                                            </select>
                                            <i class="glyph-icon icon-caret-down"></i>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showType" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>

                            <!-- AdUnit price for Admob, Facebook + Banner, Inter, Rewarded -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="(getCheckAdmob || getCheckFacebook) && (getTypeBanner === 'interstitial' || getTypeBanner === 'banner' || getTypeBanner === 'rewarded')">
                                    <div class="col-sm-2" style="text-align: center;">&nbsp;</div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <label class="control-label label_on_input" :title="groupData.adUnitPrice.alt">{{ getLang.group.adUnitPriceScrollbar }}</label>
                                                <input class="form-control" type="text" :disabled="true" :value="groupData.adUnitPrice.value" required>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 0"
                                                >
                                                    <span>{{ getLang.btnStateLow }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 1 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 1"
                                                >
                                                    <span>{{ getLang.btnStateMedium }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-3">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.adUnitPrice.value == 2 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.adUnitPrice.value = 2"
                                                >
                                                    <span>{{ getLang.btnStateHigh }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.adUnitPriceScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End AdUnit price for Admob, Facebook + Banner, Inter, Rewarded -->

                            <!-- Show time for Banner + Crosspromo, Exchange, Advertisiment -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="isBannerCrosspromo || isBannerAdmobFacebook">
                                    <div class="col-sm-2" style="text-align: center;">
                                        <div class="alert alert-danger margin-top-20" v-if="groupData.showTime.value < 3">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-20" v-if="groupData.showTime.value > 120">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <label class="control-label label_on_input" :title="groupData.showTime.alt">{{ getLang.group.showTime }}</label>
                                        <input class="form-control outlight" type="number" step="1" min="3" max="120" v-model="groupData.showTime.value" required>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTime" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show time for Banner + Crosspromo, Exchange, Advertisiment -->

                            <!-- Show closed for Inter + Crosspromo, Exchange, Advertisiment -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="isInterCrosspromo">
                                    <div class="col-sm-2" style="text-align: center;">
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value < 0">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value > 5">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label label_on_input" :title="groupData.showTime.alt">{{ getLang.group.showClosed }}</label>
                                                <input class="form-control" :disabled="true" v-model="groupData.showTime.value" required>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 0"
                                                >
                                                    <span>{{ getLang.btnStateLow }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 1 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 1"
                                                >
                                                    <span>{{ getLang.btnStateMedium }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 3 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 3"
                                                >
                                                    <span>{{ getLang.btnStateHigh }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 5 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 5"
                                                >
                                                    <span>{{ getLang.btnStateCritical }}</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTimeScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show closed for Inter + Crosspromo, Exchange, Advertisiment -->

                            <!-- Show closed for Rewarded + Crosspromo, Exchange, Advertisiment -->
                            <transition name="bounce" mode="out-in">
                                <div class="form-group form_group_wrap" v-show="isRewardedCrosspromo">
                                    <div class="col-sm-2" style="text-align: center;">
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value < 0">{{ getLang.group.showTimeMin }}</div>
                                        <div class="alert alert-danger margin-top-30" v-if="groupData.showTime.value > 10">{{ getLang.group.showTimeMax }}</div>
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <label class="control-label label_on_input" :title="groupData.showTime.alt">{{ getLang.group.showClosed }}</label>
                                                <input class="form-control" :disabled="true" v-model="groupData.showTime.value" required>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 0 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 0"
                                                >
                                                    <span>0</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 5 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 5"
                                                >
                                                    <span>5</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 8 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 8"
                                                >
                                                    <span>8</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                            <div class="col-sm-2">
                                                <button class="btn btn-alt btn-hover btn-default state_group"
                                                        :class="groupData.showTime.value == 10 ? 'active' : ''"
                                                        style="width: 100%;"
                                                        @click.prevent="groupData.showTime.value = 10"
                                                >
                                                    <span>10</span> <i class="glyph-icon icon-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.group.showTimeScrollbar" data-original-title=".icon-question"></i>
                                    </div>
                                    <div class="col-sm-1">&nbsp;</div>
                                </div>
                            </transition>
                            <!-- End Show closed for Rewarded + Crosspromo, Exchange, Advertisiment -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import Lib from '../mixins/lib';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            logos: null,

            sendFormStatus: '',
            checkCrosspromo: false,
            checkAdmob: false,
            checkFacebook: false,
            checkOtherNetwork: false,

            default: {
                showTime: 30,
                adUnitPrice: 0
            },

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 84,

            backPrevLink: '',

            saveGroupDiv: '',
            reopenGroupDiv: '',

            status: {
                network: false,
                adtype: false
            },

            networkList: [],
            osList: [],
            typeBannerList: [],
            typeBannerView: [],

            networkTypeRelationDB: [],

            stateCrosspromo: 'CrossPromotion',
            stateExchange: 'Exchange',
            stateAdvertisement: 'Advertisement',
            stateUnityAds: 'UnityAds',
            stateAdmob: 'Admob',
            stateFacebook: 'Facebook',

            stateTypeBanner: 'banner',
            stateTypeInter: 'interstitial',
            stateTypeRewarded: 'rewarded',

            groupData: {
                name: {
                    value: '',
                    alt: ''
                },
                typeBanner: {
                    value: [],
                    text: '',
                    alt: ''
                },
                network: {
                    value: [],
                    text: '',
                    alt: 'network'
                },
                networkCount: {
                    value: '',
                    alt: ''
                },
                os: {
                    value: null
                },
                showType: {
                    value: '',
                    alt: '',
                    text: ''
                },
                showTime: {
                    value: 30,
                    alt: ''
                },
                adUnitPrice: {
                    value: 0,
                    alt: ''
                },
                enabled: {
                    value: true,
                    alt: ''
                },
                infinity: {
                    value: false,
                    alt: ''
                }
            },
            logosNetworkAndType: null
        }
    },
    created(){
        this.getNetworkTypeRelationDB(); // получаем с БД связь network и adtype

        this.$store.commit('setBackPrevLink', 'group');
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы group

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(function(){
            this.saveGroupDiv = this.$refs.saveGroup;
            this.reopenGroupDiv = this.$refs.reopenGroup;
        });
    },
    destroyed(){
        this.$store.commit('setBackPrevLink', '');
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        // network list
        getNetworkList(){
            this.networkList = this.setCloneArrayWithObj(this.$store.getters.getNetworkList);

            this.networkList.forEach((item, i , arr) => {
                arr[i] = {id: item.id, name: item.name, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogosNetworkAndType[item.name]};
            });

            this.networkList = this.networkList.filter(val => {
                if(val.name == this.stateUnityAds) return (this.getTypeBanner !== this.stateTypeBanner && this.getTypeBanner !== this.stateTypeInter);

                return true;
            });

            return this.networkList;
        },

        getLogosNetworkAndType() {
            this.logosNetworkAndType = this.$store.state.logos.networkAndTypeIcons;

            return this.logosNetworkAndType;
        },

        // network value
        // getNetworkText(){
        //     return this.groupData.network.text;
        // },

        getOsList(){
            this.osList = this.$store.getters.getOsList;

            return this.osList;
        },

        getOsActive(){
            let os = this.getOsList.find(val => val.id == this.groupData.os.value);

            return (os != undefined && os.name != undefined ? os.name : '');
        },

        // get show type list
        getShowTypeList(){
            return [
                {name: 'Ordered', value: 'ordered'},
                {name: 'Random', value: 'random'},
                {name: 'Once', value: 'once'},
            ];
        },

        // check active show type and Time
        getCheckCrosspromo(){
            if(this.groupData.network.text == this.stateCrosspromo) this.checkCrosspromo = true;
            else  this.checkCrosspromo = false;

            return this.checkCrosspromo;
        },

        // check active Admob
        getCheckAdmob(){
            if(this.groupData.network.text == this.stateAdmob) this.checkAdmob = true;
            else  this.checkAdmob = false;

            return this.checkAdmob;
        },

        // check active Facebook
        getCheckFacebook(){
            if(this.groupData.network.text == this.stateFacebook) this.checkFacebook = true;
            else  this.checkFacebook = false;

            return this.checkFacebook;
        },

        // check active show Time
        getCheckOtherNetwork(){
            if(this.groupData.network.text == this.stateExchange || this.groupData.network.text == this.stateAdvertisement) this.checkOtherNetwork = true;
            else this.checkOtherNetwork = false;

            return this.checkOtherNetwork;
        },

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                let tBannerList = [...this.$store.getters.getTypeBannerList];

                tBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.name]};
                });
            }
            return this.typeBannerList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons; // Local

            return this.logos;
        },

        // get type banner view
        // getTypeBannerView(){
        //     if(this.typeBannerView.length == 0) this.typeBannerView = this.getTypeBannerList;

        //     return this.typeBannerView;
        // },

        // get value type banner (default first option from list)
        getTypeBanner(){
            //return this.groupData.typeBanner.text;
            let res = '';

            if (this.groupData.typeBanner.value.length > 0) {
                if (this.groupData.typeBanner.value[0] === 0) res = 'banner';
                if (this.groupData.typeBanner.value[0] === 1) res = 'interstitial';
                if (this.groupData.typeBanner.value[0] === 2) res = 'rewarded';
            }

            return res;
        },

        // show type value
        getShowTypeText(){
            if (this.getShowTypeList.length && this.groupData.showType.value == '' && this.groupData.showType.text == '') {
                this.groupData.showType.value = this.getShowTypeList[0].value;
                this.groupData.showType.text = this.getShowTypeList[0].name;
            }

            return this.groupData.showType.text;
        },

        // network count
        getNetworkCount(){
            if(this.groupData.infinity.value) this.groupData.networkCount.value = -1;
            else if(this.groupData.networkCount.value == -1) this.groupData.networkCount.value = 1;

            return this.groupData.networkCount.value;
        },

        // get relation network and array adtypes by id network
        getNetworkTypeRelation(){
            let relation = [];

            this.networkTypeRelationDB.forEach(function(item, i){
                if(relation[item.network] == undefined) relation[item.network] = [];

                relation[item.network][item.adtype] = item.adtype;
            });

            return relation;
        },

        isBannerCrosspromo(){
            let status = false;

            if ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner === 'banner') {
                status = true;
                this.groupData.showTime.value = this.default.showTime;
            } else {
                status = false;
            }

            return status;
        },

        isBannerAdmobFacebook(){
            let status = false;

            if ((this.getCheckFacebook || this.getCheckAdmob) && this.getTypeBanner === 'banner') {
                status = true;
                this.groupData.showTime.value = this.default.showTime;
            } else {
                status = false;
            }

            return status;
        },

        isInterCrosspromo(){
            let status = false;

            if ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner === 'interstitial') {
                status = true;
                this.groupData.showTime.value = 0;
            } else {
                status = false;
            }

            return status;
        },

        isRewardedCrosspromo(){
            let status = false;

            if ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner === 'rewarded') {
                status = true;
                this.groupData.showTime.value = 0;
            } else {
                status = false;
            }

            return status;
        },

        // validation form
        isValidForm(){
            let result = false;

            result = (this.groupData.name.value != '') && this.groupData.typeBanner.value.length > 0 && this.groupData.network.value.length > 0 && this.groupData.os.value != null;

            if (result && !this.getCheckCrosspromo) result = this.groupData.networkCount.value != '';

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork || this.getCheckFacebook || this.getCheckAdmob) && this.getTypeBanner === 'banner')) {
                result = this.groupData.showTime.value >= 3 && this.groupData.showTime.value <= 120;
            }

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner === 'interstitial')) {
                result = this.groupData.showTime.value >= 0 && this.groupData.showTime.value <= 5;
            }

            if (result && ((this.getCheckCrosspromo || this.getCheckOtherNetwork) && this.getTypeBanner === 'rewarded')) {
                result = this.groupData.showTime.value >= 0 && this.groupData.showTime.value <= 10;
            }

            if (result && (this.getCheckAdmob || this.getCheckFacebook) && (this.getTypeBanner === 'interstitial' || this.getTypeBanner === 'banner' || this.getTypeBanner === 'rewarded')) {
                result = this.groupData.adUnitPrice.value >= 0 && this.groupData.adUnitPrice.value <= 2;
            }

            return result;
        }
    },
    methods: {
        backToPrev(){
            this.$router.back();
        },

        clearStatus(){
            this.status.adtype = false;
            this.status.network = false;
        },

        /*--- update status ---*/
        updateStatusNetwork(val){
            this.clearStatus();

            this.status.network = val;
        },

        updateStatusAdtype(val){
            this.clearStatus();

            this.status.adtype = val;
        },

        // Network
        // selectNetwork(e){
        //     let option = (e.target.selectedOptions[0] != undefined ? e.target.selectedOptions[0].innerText : '');

        //     this.groupData.network.text = option;
        // },

        // Type
        // selectType(e){
        //     let option = (e.target.selectedOptions[0] != undefined ? e.target.selectedOptions[0].innerText : '');

        //     this.groupData.typeBanner.text = option;
        // },

        // get relation network with type banner
        getNetworkTypeRelationDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/network/relations/?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.networkTypeRelationDB = response.data;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get relation network and adtype');
            });
        },

        // show type
        selectShowType(e){
            let option = e.target.selectedOptions[0];

            this.groupData.showType.text = option.innerText;
        },

        // set network count
        setNetworkCount(e){
            this.groupData.networkCount.value = e.target.value;
        },

        // default form
        setFormDefault(){
            this.groupData.name.value = '';
            this.groupData.typeBanner.value = [];
            //this.groupData.typeBanner.text = '';
            this.groupData.network.value = []; //this.getNetworkList[0].id;
            //this.groupData.network.text = ''; //this.getNetworkList[0].name;
            this.groupData.networkCount.value = 1;
            this.groupData.showType.value = this.getShowTypeList[0].value;
            this.groupData.showType.text = this.getShowTypeList[0].name;
            this.groupData.showTime.value = this.default.showTime;
            this.groupData.adUnitPrice.value = this.default.adUnitPrice;
            this.groupData.enabled.value = true;
            this.groupData.infinity.value = false;
            this.groupData.os.value = null;
        },

        // close Group form
        closeGroupForm(){
            this.$router.push({ name: 'group' });
        },

        saveGroup(){
            this.saveGroupDiv.disabled = true;

            this.setGroupDB('save');
        },

        reopenGroup(){
            this.reopenGroupDiv.disabled = true;

            this.setGroupDB('reopen');
        },

        // save form data and add new application to DB
        setGroupDB(status){
            let data = {
                name: this.groupData.name.value,
                type: Number(this.groupData.typeBanner.value[0]),
                network: String(this.groupData.network.value[0]),
                enabled: Number(this.groupData.enabled.value),
                osid: Number(this.groupData.os.value)
            };

            if ((this.getCheckAdmob || this.getCheckFacebook) && (this.getTypeBanner === 'interstitial' || this.getTypeBanner === 'banner' || this.getTypeBanner === 'rewarded')) {
                data.adunitPrice = Number(this.groupData.adUnitPrice.value);
            } else {
                data.adunitPrice = 0;
            }

            if (this.checkCrosspromo) {
                data.show_time = parseInt(this.groupData.showTime.value) ? parseInt(this.groupData.showTime.value) : (parseInt(this.groupData.showTime.value) === 0 ? 0 : this.default.showTime);
                data.show_type = this.groupData.showType.value;
                data.network_count = -1;
            } else if (this.checkOtherNetwork || this.getCheckFacebook || this.getCheckAdmob) {
                data.show_time = parseInt(this.groupData.showTime.value) ? parseInt(this.groupData.showTime.value) : (parseInt(this.groupData.showTime.value) === 0 ? 0 : this.default.showTime);
                data.show_type = "";
                data.network_count = parseInt(this.groupData.networkCount.value) ? parseInt(this.groupData.networkCount.value) : -1;
            } else {
                data.show_time = this.default.showTime;
                data.show_type = ""; //this.getShowTypeList[0].value;
                data.network_count = parseInt(this.groupData.networkCount.value) ? parseInt(this.groupData.networkCount.value) : -1;
            }

            data = JSON.stringify(data);

//console.log(data);

            axios({
                url: this.$store.getters.getBaseUrl+'/api/group/add?at='+this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                if (status == 'save') {
                    this.saveGroupDiv.disabled = false;

                    this.$router.push({ name: 'group' });
                } else if (status == 'reopen') {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Group added!'});
                    this.setFormDefault();

                    this.reopenGroupDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

            })
            .catch(e => {
                if (e.response != undefined && e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveGroupDiv.disabled = false;
                    this.reopenGroupDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error add group to DB");
            });
        },

        // set height for table form Group
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },

    components: {
        VueSlider,
        MultiSelectIcons
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }

    .icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        height: 80px;
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 80px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px;
            float: none;
            display: inline-block;
            color: #0093d9;
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important;
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        padding-bottom: 13px;
    }

    .add_group{
        padding: 0 52px;
    }

    .close_group, .reopen_group{
        margin-right: 65px;
    }

    .state_group{
        overflow: hidden;
        background-color: #53eec9;
    }

    .state_group.active{
        background-color: #20bb96;
    }

    .margin-top-20{
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .margin-top-30{
        margin-top: 30px;
        margin-bottom: 0px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
