<template>
    <div class="form-group">
        <label class="col-sm-3 col-md-3 control-label">
            <img :src="getImgUrl" v-if="getImgUrl && imageFileStatus"/>{{ setImageUrl }}
            <img :src="viewFile" v-if="viewFile && !imageFileStatus"/>{{ isEmptyDataInFile }}
        </label>
        <div class="col-sm-9 col-md-9">
            <label class="control-label float-right" v-if="isRequired"><span class="bs-label label-danger">{{ getLang.required }}</span>&nbsp;</label>
            <label class="control-label float-right" v-if="isMaxSize"><span class="bs-label label-primary">{{ getLang.max500kb }}</span>&nbsp;</label>
            <nav>
                <ul class="nav nav-tabs">
                    <li :class="imageFileClass[+imageFileStatus]"><a href="" @click.prevent="changeImageStatus($event, true)">{{ getLang.app.iconUrl }}: </a></li>
                    <li :class="imageFileClass[+!imageFileStatus]"><a href="" @click.prevent="changeImageStatus($event, false)">{{ getLang.app.iconFile }}: </a></li>
                </ul>
            </nav>
            <div class="form-group" v-if="imageFileStatus">
                <input class="form-control" id="" :placeholder="getLang.app.iconUrl+'...'" type="text" v-model="imgUrl">
            </div>
            <div class="form-group" v-else>
                <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                    <div class="form-control" data-trigger="fileinput">
                        <i class="glyphicon glyphicon-file fileinput-exists"></i>
                        <span class="fileinput-filename">{{ nameFile }}</span>
                    </div>
                    <span class="input-group-addon btn btn-primary btn-file">
                        <span class="fileinput-new">{{ getLang.app.selectFile }}</span>
                        <span class="fileinput-exists">Change</span>
                        <input type="file" name="..." @change="setImageFile">
                    </span>
                    <a href="#" class="input-group-addon btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import Lang from '../lang/message';

export default {
    props: {
        imageUrl: String,
        valueInFile: String,
        typeInInput: String,
        nameInFile: String,
        viewInFile: String,
        imageInFileStatus: Boolean,
        isRequired: {
            type: Boolean,
            default: false
        },
        isMaxSize: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            lang: Lang,

            imgUrl: this.imageUrl,

            imageFileStatus: this.imageInFileStatus,
            imageFileClass: ['file', 'file_active active'],

            valueFile: '',
            typeInput: '',
            nameFile: '',
            viewFile: '',
            check: false
        }
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getImgUrl(){
            this.$emit('setUrl', this.imgUrl);

            return this.imgUrl;
        },

        setImageUrl(){
            this.imgUrl = this.imageUrl;
        },
        isEmptyDataInFile(){
            if(this.valueInFile == '') this.valueFile = this.valueInFile;
            if(this.typeInInput == '') this.typeInput = this.typeInInput;
            if(this.nameInFile == '') this.nameFile = this.nameInFile;
            if(this.viewInFile == '') this.viewFile = this.viewInFile;
        }
    },
    methods: {
        // libs
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        setDataFiles(str){
            let data = str.replace('base64,', '').replace('data:image/', '').split(';');
            return data;
        },

        // files
        setImageFile(e){
            let _this = this;

            this.nameFile = e.target.files[0].name;
            this.$emit('setName', this.nameFile);

            this.getBase64(e.target.files[0]).then(data => {
                _this.viewFile = data;
                this.$emit('setView', _this.viewFile);
                data = this.setDataFiles(data);

                _this.valueFile = data[1];
                _this.typeInput = data[0];
                this.$emit('setValue', _this.valueFile);
                this.$emit('setType', _this.typeInput);
            });
        },

        // change status
        changeImageStatus(e, status){
            this.$emit('setStatus', status);
            this.imageFileStatus = status;
        }
    }
}
</script>

<style scoped>
.fileinput{
    margin-bottom: 0px;
}

.form-group .control-label img{
    max-height: 80px;
}
</style>
