<template>
    <section>

        <div class="panel">
            <div class="panel-body">
                <div class="example-box-wrapper">

                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>
                                
                                <tr>
                                    <th class="col_back_to_prev">
                                        <div>
                                            <a href="#" class="back_to_prev" @click.prevent="backToPrev()" v-if="getBackPrevLink">
                                                <i class="glyph-icon tooltip-button demo-icon icon-reply back_icon" title="" data-original-title=".icon-reply"></i>
                                            </a>
                                        </div>
                                    </th>
                                    <th class="col_action">
                                        <button class="btn btn-alt btn-hover btn-danger delete_adunit" 
                                                data-toggle="modal" 
                                                data-target=".bs-example-modal-sm" 
                                                @click.prevent="setPopupDelete($event)"
                                                >
                                            <span>{{ getLang.delete }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-primary float-right add_adunit" ref="saveAdunit" @click.prevent="editAdUnitDB()" :disabled="!isValidForm">
                                            <span>{{ getLang.save }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                        <button class="btn btn-alt btn-hover btn-warning float-right close_adunit" @click.prevent="closeAdUnitForm">
                                            <span>{{ getLang.close }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="pannel__form scroll" :style="'height: ' + adunitFormHeight + 'px;'">
                        <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="adUnitData.packageName.alt"><span class="bs-label label-primary">{{ getLang.max200Chars }}</span> {{ getLang.adUnit.productsURLPackage }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.packageName.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <input class="form-control" id="" :placeholder="getLang.adUnit.productsURLPackage+'...'" type="text" v-model="adUnitData.packageName.value" required>
                                </div>
                            </div>
                            
                            <div class="form-group no-border">
                                <label class="col-sm-3 control-label">{{ getLang.adUnit.adLocale }}: <i class="glyph-icon font-gray icon-question" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">

                                    <div class="row space_around">
                                        <div>
                                            <button class="btn btn-success btn-add" @click.prevent="addCountryLocale()" :disabled="getCountryList.length < 1">
                                                <i class="glyph-icon icon-plus"></i>
                                            </button>
                                        </div>

                                        <div class="selector" style="width:100%!important;">
                                            <span style="-moz-user-select: none;">{{ getCountryText }}</span>
                                            <select class="custom-select" @change="selectCountry($event)" v-model="adUnitData.country.value">
                                                <option :value="el.code" v-for="(el, idx) in getCountryList" :key="idx">{{ el.country }} ({{ el.code }})</option>
                                            </select>
                                            <i class="glyph-icon icon-caret-down"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group no-border no-padding-bottom">
                                <label class="col-sm-3 control-label">&nbsp;</label>
                                <div class="col-sm-9">
                                    <ul class="nav-responsive nav nav-tabs locale_list">
                                        <li :class="val.active ? 'active' : ''" v-for="(val, idx) in countryData" :key="idx">
                                            <a href="#" data-toggle="tab" @click.prevent="setActiveCountryLocale($event, val.value)">{{ val.text }} <span class="bs-badge badge-absolute float-right badge-danger" @click.stop.prevent="deleteCountryLocale(idx)" v-if="idx!=0"><i class="glyph-icon icon-close"></i></span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group no-border">
                                <label class="col-sm-3 control-label"><span class="bs-label label-primary">{{ getLang.max50Chars }}</span> {{ getLang.adUnit.title }}: <i class="glyph-icon font-gray icon-question" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <input class="form-control" id="" :placeholder="getLang.adUnit.title+'...'" type="text" v-model="countryData[countryActive].title">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label"><span class="bs-label label-primary">{{ getLang.maxChars }}</span> {{ getLang.adUnit.description }}: <i class="glyph-icon font-gray icon-question" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <input class="form-control" id="" :placeholder="getLang.adUnit.description+'...'" type="text" v-model="countryData[countryActive].description">
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="adUnitData.typeBanner.alt">{{ getLang.adUnit.type }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.typeBanner.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTypeBannerValue }}</span>
                                        <select name="" class="custom-select" v-model="adUnitData.typeBanner.value">
                                            <option :value="el.name" v-for="(el, ind) in getTypeBannerList" :key="ind">{{ el.name }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="adUnitData.imgOnly.alt">{{ getLang.adUnit.imageOnly }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.imgOnly.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <div class="checkbox">
                                        <input type="checkbox" class="form-group" v-model="adUnitData.imgOnly.value">
                                    </div>
                                </div>
                            </div>
                            <transition name="bounce" mode="out-in">
                                <div class="form-group" v-if="checkIconFile">
                                    <label class="col-sm-3 col-md-3 control-label">
                                        <img :src="adUnitData.iconUrl.value" v-if="adUnitData.iconUrl.value && iconFileStatus"/>
                                        <img :src="adUnitData.iconFile.viewFile" v-if="adUnitData.iconFile.viewFile && !iconFileStatus"/>
                                    </label>
                                    <div class="col-sm-9 col-md-9">
                                        <label class="control-label float-right"><span class="bs-label label-danger">{{ getLang.required }}</span>&nbsp;</label>
                                        <label class="control-label float-right"><span class="bs-label label-primary">{{ getLang.max500kb }}</span>&nbsp;</label>
                                        <nav>
                                            <ul class="nav nav-tabs">
                                                <li :class="iconFileClass[+iconFileStatus]"><a href="" @click.prevent="iconFileStatus = true">{{ getLang.adUnit.iconUrl }}: </a></li>
                                                <li :class="iconFileClass[+!iconFileStatus]"><a href="" @click.prevent="iconFileStatus = false">{{ getLang.adUnit.iconFile }}: </a></li>
                                            </ul>
                                        </nav>
                                        <div class="form-group" v-if="iconFileStatus">
                                            <input class="form-control" id="" :placeholder="getLang.adUnit.iconUrl+'...'" type="text" v-model="adUnitData.iconUrl.value">
                                        </div>
                                        <div class="form-group" v-else>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                                                <div class="form-control" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename">{{ adUnitData.iconFile.nameFile }}</span>
                                                </div>
                                                <span class="input-group-addon btn btn-primary btn-file">
                                                    <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                    <span class="fileinput-exists">Change</span>
                                                    <input type="file" name="..." @change="setIconFile">
                                                </span>
                                                <a href="#" class="input-group-addon btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <transition name="bounce" mode="out-in">
                                <div class="form-group" v-if="checkImageFile">
                                    <label class="col-sm-3 col-md-3 control-label">
                                        <img :src="adUnitData.imageUrl.value" v-if="adUnitData.imageUrl.value && imageFileStatus"/>
                                        <img :src="adUnitData.imageFile.viewFile" v-if="adUnitData.imageFile.viewFile && !imageFileStatus"/>
                                    </label>
                                    <div class="col-sm-9 col-md-9">
                                        <label class="control-label float-right"><span class="bs-label label-danger">{{ getLang.required }}</span>&nbsp;</label>
                                        <label class="control-label float-right"><span class="bs-label label-primary">{{ getLang.max500kb }}</span>&nbsp;</label>
                                        <nav>
                                            <ul class="nav nav-tabs">
                                                <li :class="imageFileClass[+imageFileStatus]"><a href="" @click.prevent="imageFileStatus = true">{{ getLang.adUnit.imageUrl }}: </a></li>
                                                <li :class="imageFileClass[+!imageFileStatus]"><a href="" @click.prevent="imageFileStatus = false">{{ getLang.adUnit.imageFile }}: </a></li>
                                            </ul>
                                        </nav>
                                        <div class="form-group" v-if="imageFileStatus">
                                            <input class="form-control" id="" :placeholder="getLang.adUnit.imageUrl+'...'" type="text" v-model="adUnitData.imageUrl.value">
                                        </div>
                                        <div class="form-group" v-else>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                                                <div class="form-control" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename">{{ adUnitData.imageFile.nameFile }}</span>
                                                </div>
                                                <span class="input-group-addon btn btn-primary btn-file">
                                                    <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                    <span class="fileinput-exists">Change</span>
                                                    <input type="file" name="..." @change="setImageFile">
                                                </span>
                                                <a href="#" class="input-group-addon btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <transition  name="bounce" mode="out-in">
                                <div class="form-group" v-if="checkPortraitFile">
                                    <label class="col-sm-3 col-md-3 control-label">
                                        <img :src="adUnitData.portraitUrl.value" v-if="adUnitData.portraitUrl.value && portraitFileStatus"/>
                                        <img :src="adUnitData.portraitFile.viewFile" v-if="adUnitData.portraitFile.viewFile && !portraitFileStatus"/>
                                    </label>
                                    <div class="col-sm-9 col-md-9">
                                        <label class="control-label float-right"><span class="bs-label label-danger">{{ getLang.required }}</span>&nbsp;</label>
                                        <label class="control-label float-right"><span class="bs-label label-primary">{{ getLang.max500kb }}</span>&nbsp;</label>
                                        <nav>
                                            <ul class="nav nav-tabs">
                                                <li :class="portraitFileClass[+portraitFileStatus]"><a href="" @click.prevent="portraitFileStatus = true">{{ getLang.adUnit.portraitUrl }}: </a></li>
                                                <li :class="portraitFileClass[+!portraitFileStatus]"><a href="" @click.prevent="portraitFileStatus = false">{{ getLang.adUnit.portraitFile }}: </a></li>
                                            </ul>
                                        </nav>
                                        <div class="form-group" v-if="portraitFileStatus">
                                            <input class="form-control" id="" :placeholder="getLang.adUnit.portraitUrl+'...'" type="text" v-model="adUnitData.portraitUrl.value">
                                        </div>
                                        <div class="form-group" v-else>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                                                <div class="form-control" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename">{{ adUnitData.portraitFile.nameFile }}</span>
                                                </div>
                                                <span class="input-group-addon btn btn-primary btn-file">
                                                    <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                    <span class="fileinput-exists">Change</span>
                                                    <input type="file" name="..." @change="setPortraitFile">
                                                </span>
                                                <a href="#" class="input-group-addon btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <transition  name="bounce" mode="out-in">
                                <div class="form-group" v-if="checkLandscapeFile">
                                    <label class="col-sm-3 col-md-3 control-label">
                                        <img :src="adUnitData.landscapeUrl.value" v-if="adUnitData.landscapeUrl.value && landscapeFileStatus"/>
                                        <img :src="adUnitData.landscapeFile.viewFile" v-if="adUnitData.landscapeFile.viewFile && !landscapeFileStatus"/>
                                    </label>
                                    <div class="col-sm-9 col-md-9">
                                        <label class="control-label float-right"><span class="bs-label label-danger">{{ getLang.required }}</span>&nbsp;</label>
                                        <label class="control-label float-right"><span class="bs-label label-primary">{{ getLang.max500kb }}</span>&nbsp;</label>
                                        <nav>
                                            <ul class="nav nav-tabs">
                                                <li :class="landscapeFileClass[+landscapeFileStatus]"><a href="" @click.prevent="landscapeFileStatus = true">{{ getLang.adUnit.landscapeUrl }}: </a></li>
                                                <li :class="landscapeFileClass[+!landscapeFileStatus]"><a href="" @click.prevent="landscapeFileStatus = false">{{ getLang.adUnit.landscapeFile }}: </a></li>
                                            </ul>
                                        </nav>
                                        <div class="form-group" v-if="landscapeFileStatus">
                                            <input class="form-control" id="" :placeholder="getLang.adUnit.landscapeUrl+'...'" type="text" v-model="adUnitData.landscapeUrl.value">
                                        </div>
                                        <div class="form-group" v-else>
                                            <div class="fileinput fileinput-new input-group" data-provides="fileinput">
                                                <div class="form-control" data-trigger="fileinput">
                                                    <i class="glyphicon glyphicon-file fileinput-exists"></i>
                                                    <span class="fileinput-filename">{{ adUnitData.landscapeFile.nameFile }}</span>
                                                </div>
                                                <span class="input-group-addon btn btn-primary btn-file">
                                                    <span class="fileinput-new">{{ getLang.adUnit.selectFile }}</span>
                                                    <span class="fileinput-exists">Change</span>
                                                    <input type="file" name="..." @change="setLandscapeFile">
                                                </span>
                                                <a href="#" class="input-group-addon btn btn-default fileinput-exists" data-dismiss="fileinput">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                            <transition  name="bounce" mode="out-in">
                                <div class="form-group" v-if="checkVideoUrl">
                                    <label class="col-sm-3 control-label" :title="adUnitData.videoUrl.alt"><span class="bs-label label-danger">{{ getLang.required }}</span> {{ getLang.adUnit.videoUrl }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.videoUrl.alt" data-original-title=".icon-question"></i></label>
                                    <div class="col-sm-9">
                                        <input class="form-control" id="" :placeholder="getLang.adUnit.videoUrl+'...'" type="text" v-model="adUnitData.videoUrl.value">
                                    </div>
                                </div>
                            </transition>

                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="adUnitData.trackSystem.alt">{{ getLang.adUnit.trackSystems }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.trackSystem.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <div class="selector" style="width: 82px;">
                                        <span style="width: 60px; -moz-user-select: none;">{{ getTrackSystemTextInput }}</span>
                                        <select name="" class="custom-select" @change="selectTrackSystem($event)" v-model="adUnitData.trackSystem.value">
                                            <option :value="el.id" v-for="(el, ind) in getTrackSystemList" :key="ind">{{ el.system }}</option>
                                        </select>
                                        <i class="glyph-icon icon-caret-down"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label class="col-sm-3 control-label" :title="adUnitData.utmContent.alt"><span class="bs-label label-primary">{{ getLang.max50Chars }}</span> {{ getLang.adUnit.UTMContent }}: <i class="glyph-icon font-gray icon-question" :title="adUnitData.utmContent.alt" data-original-title=".icon-question"></i></label>
                                <div class="col-sm-9">
                                    <input class="form-control" id="" :placeholder="getLang.adUnit.UTMContent+'...'" type="text" v-model="adUnitData.utmContent.value">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <popup :popup-title="countryData[0].title" 
                        :edit-id="getExchangeEditId" 
                        url-delete="/api/adblock/delete/" 
                        url-redirect="adunit"
                        :title="getLang.adUnit.deleteTitle"
                        :message="getLang.adUnit.deleteMessage"
                        >
                </popup>

            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Popup from '../plugins/Popup';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            lang: Lang,

            sendFormStatus: '',
            
            iconFileStatus: false,
            imageFileStatus: false,
            landscapeFileStatus: false,
            portraitFileStatus: false,

            iconFileClass: ['file', 'file_active active'],
            imageFileClass: ['file', 'file_active active'],
            landscapeFileClass: ['file', 'file_active active'],
            portraitFileClass: ['file', 'file_active active'],

            adunitFormHeight: 0,
            headerHeight: 60,
            othersHeight: 80,

            backPrevLink: '',

            saveAdunitDiv: '',

            typeBannerList: {},
            trackSystemList: {},
            countryList: [],
            countryListOldLength: 0,

            countryActive: 0,
            countryData: [
                {
                    value: 'US',
                    text: 'United States (US)',
                    active: true,
                    title: '',
                    description: ''
                },
            ],

            exchangeEditId: 0,

            adUnitData: {
                packageName: {
                    value: '',
                    alt: 'apps.formFieldProductHelp'
                },
                trackSystem: {
                    value: 0,
                    textInput: '',
                    alt: 'apps.formFieldTrackSystemsHelp'
                },
                
                country: {
                    text: '',
                    value: ''
                },

                typeBanner: {
                    value: '',
                    alt: 'apps.formFieldTypeHelp'
                },
                imgOnly: {
                    value: false,
                    alt: 'apps.formFieldImageOnlyHelp'
                },
                // icon
                iconUrl: {
                    value: '',
                    alt: ''
                },
                iconFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: '',
                    check: true
                },
                // image
                imageUrl: {
                    value: '',
                    alt: ''
                },
                imageFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: '',
                    check: true
                },
                // portrait
                portraitUrl: {
                    value: '',
                    alt: ''
                },
                portraitFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: '',
                    check: false
                },
                // landscape
                landscapeUrl: {
                    value: '',
                    alt: ''
                },
                landscapeFile: {
                    value: '',
                    typeInput: '',
                    nameFile: '',
                    viewFile: '',
                    alt: '',
                    check: false
                },

                videoUrl: {
                    value: '',
                    alt: 'Video Url',
                    check: false
                },
                utmContent: {
                    value: '',
                    alt: 'apps.formUtmFieldHelp'
                }
            },
        }
    },
    created(){
        this.getAdunitByID();
        
        this.$store.commit('setBackPrevLink', 'adunit');
    },
    mounted(){
        let _this = this;
        this.setAdunitFormHeight(); // задаем высоту для формы adunit

        window.addEventListener('resize', function(){
            _this.setAdunitFormHeight();
        });

        this.$nextTick(function(){
            this.saveAdunitDiv = this.$refs.saveAdunit;
        });
    },
    destroyed(){
        this.$store.commit('setBackPrevLink', '');
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getBackPrevLink(){
            this.backPrevLink = this.$store.getters.getBackPrevLink;

            return this.backPrevLink;
        },

        // country list
        getCountryList(){
            let _this = this;
            this.countryList = this.setCloneArrayWithObj(this.$store.getters.getCountryList);

            this.countryList = this.countryList.filter(function(item){
                
                let fl = true;

                _this.countryData.forEach(function(el, idx, arr){
                    if(el.value == item.code) fl = false;
                });

                return fl;
            });
            
            return this.countryList;
        },

        // country value
        getCountryText(){
            if ((this.getCountryList.length && this.adUnitData.country.value == '' && this.adUnitData.country.text == '') || (this.countryListOldLength > this.getCountryList.length && this.getCountryList[0] != undefined)) {
                this.adUnitData.country.value = this.getCountryList[0].code;
                this.adUnitData.country.text = this.getCountryList[0].country + ' (' + this.getCountryList[0].code + ')';

                this.countryListOldLength = this.getCountryList.length;
            }

            return this.adUnitData.country.text;
        },

        /* type banner list */
        getTypeBannerList(){
            this.typeBannerList = this.$store.getters.getTypeBannerList;

            return this.typeBannerList;
        },
        getTypeBannerValue(){
            if(!this.adUnitData.typeBanner.value) {
                this.adUnitData.typeBanner.value = this.getTypeBannerList.length != 0 ? this.getTypeBannerList[0].name : '';
            }
            
            return this.adUnitData.typeBanner.value;
        },
        /* end type */

        /* tracking systems */
        getTrackSystemList(){
            this.trackSystemList = this.$store.getters.getTrackingList;

            return this.trackSystemList;
        },
        getTrackSystemTextInput(){
            if(!this.adUnitData.trackSystem.value && !this.adUnitData.trackSystem.textInput && this.trackSystemList.length > 0){
                this.adUnitData.trackSystem.value = this.trackSystemList[0].id;
                this.adUnitData.trackSystem.textInput = this.trackSystemList[0].system;
            }
            
            return this.adUnitData.trackSystem.textInput;
        },
        /* end tracking */

        // get id exchange
        getExchangeEditId(){
            this.exchangeEditId = this.$store.getters.getExchangeEditId;

            if(this.exchangeEditId == 0) this.$router.push({name: 'exchange'});

            return this.exchangeEditId;
        },

        /* get image and type */
        getIconFile(){
            return 'value = '+this.adUnitData.iconFile.value + '; type = ' + this.adUnitData.iconFile.typeInput;
        },
        getImageFile(){
            return 'value = '+this.adUnitData.imageFile.value + '; type = ' + this.adUnitData.imageFile.typeInput;
        },
        getPortraitFile(){
            return 'value = '+this.adUnitData.portraitFile.value + '; type = ' + this.adUnitData.portraitFile.typeInput;
        },
        getLandscapeFile(){
            return 'value = '+this.adUnitData.landscapeFile.value + '; type = ' + this.adUnitData.landscapeFile.typeInput;
        },
        /* end get image and type */

        /* check visible input fields */
        checkIconFile(){
            this.adUnitData.iconFile.check = (this.adUnitData.typeBanner.value == 'banner' || this.adUnitData.typeBanner.value == 'inter') && !this.adUnitData.imgOnly.value;
            return this.adUnitData.iconFile.check;
        },
        checkImageFile(){
            this.adUnitData.imageFile.check = this.adUnitData.typeBanner.value == 'banner' || (this.adUnitData.typeBanner.value == 'inter' && !this.adUnitData.imgOnly.value);
            return this.adUnitData.imageFile.check;
        },
        checkPortraitFile(){
            this.adUnitData.portraitFile.check = this.adUnitData.typeBanner.value == 'inter' && this.adUnitData.imgOnly.value;
            return this.adUnitData.portraitFile.check;
        },
        checkLandscapeFile(){
            this.adUnitData.landscapeFile.check = this.adUnitData.typeBanner.value == 'inter' && this.adUnitData.imgOnly.value;
            return this.adUnitData.landscapeFile.check;
        },
        checkVideoUrl(){
            this.adUnitData.videoUrl.check = (this.adUnitData.typeBanner.value == 'rewarded');
            return this.adUnitData.videoUrl.check;
        },
        /* end check */

        // validation form
        isValidForm(){
            let result = false;

            if(this.checkImageFile){
                if(this.imageFileStatus) result = (this.adUnitData.imageUrl.value != '');
                else result = (this.adUnitData.imageFile.value != '');
            }
            
            if(this.checkIconFile && result){
                if(this.iconFileStatus) result = (this.adUnitData.iconUrl.value != '');
                else result = (this.adUnitData.iconFile.value != '');
            }

            if(this.checkPortraitFile){
                if(this.portraitFileStatus) result = (this.adUnitData.portraitUrl.value != '');
                else result = (this.adUnitData.portraitFile.value != '');
            }

            if(this.checkLandscapeFile && result){
                if(this.landscapeFileStatus) result = (this.adUnitData.landscapeUrl.value != '');
                else result = (this.adUnitData.landscapeFile.value != '');
            }

            if(this.checkVideoUrl){
                result = (this.adUnitData.videoUrl.value != '');
            }

            return result;
        }
    },
    methods: { 
        // libs
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });
        },
        setDataFiles(str){
            let data = str.replace('base64,', '').replace('data:image/', '').split(';');
            if(data[0] == 'jpeg') data[0] = 'jpg';
            
            return data;
        },

        backToPrev(){
            //this.$router.push({name: this.getBackPrevLink});
            this.$router.back();
        },

        // files
        setIconFile(e){
            let _this = this;
            this.adUnitData.iconFile.nameFile = e.target.files[0].name;

            this.getBase64(e.target.files[0]).then(data => {
                _this.adUnitData.iconFile.viewFile = data;
                data = this.setDataFiles(data);

                this.adUnitData.iconFile.value = data[1];
                this.adUnitData.iconFile.typeInput = data[0];
            });
        },
        setImageFile(e){
            let _this = this;
            this.adUnitData.imageFile.nameFile = e.target.files[0].name;

            this.getBase64(e.target.files[0]).then(data => {
                _this.adUnitData.imageFile.viewFile = data;
                data = this.setDataFiles(data);

                this.adUnitData.imageFile.value = data[1];
                this.adUnitData.imageFile.typeInput = data[0];
            });
        },
        setPortraitFile(e){
            let _this = this;
            this.adUnitData.portraitFile.nameFile = e.target.files[0].name;
            
            this.getBase64(e.target.files[0]).then(data => {
                _this.adUnitData.portraitFile.viewFile = data;
                data = this.setDataFiles(data);

                this.adUnitData.portraitFile.value = data[1];
                this.adUnitData.portraitFile.typeInput = data[0];
            });
        },
        setLandscapeFile(e){
            let _this = this;
            this.adUnitData.landscapeFile.nameFile = e.target.files[0].name;
            
            this.getBase64(e.target.files[0]).then(data => {
                _this.adUnitData.landscapeFile.viewFile = data;
                data = this.setDataFiles(data);

                this.adUnitData.landscapeFile.value = data[1];
                this.adUnitData.landscapeFile.typeInput = data[0];
            });
        },

        // Country
        selectCountry(e){
            let option = e.target.selectedOptions[0].innerText; 

            this.adUnitData.country.text = option;
        },

        // add country to locale
        addCountryLocale(){
            this.countryData.map(function(item){
                return item.active = false;
            });
            
            this.countryData.push({active: true, text: this.adUnitData.country.text, value: this.adUnitData.country.value, title: '', description: ''});
            this.countryActive = this.countryData.length-1;

            this.adUnitData.country.text = '';
            this.adUnitData.country.value = '';
        },

        // delete country locale
        deleteCountryLocale(idx){
            
            let fl = true;
            this.countryData.splice(idx, 1);

            for(let i = 0; i < this.countryData.length; i++){
                if (this.countryData[i].active == true) {
                    fl = false;
                    this.countryActive = i;
                }
            }

            if (fl) {
                this.countryActive = 0;
                this.countryData[this.countryActive].active = true;
            }
        },

        // set active country item
        setActiveCountryLocale(e, value){
            let _this = this;

            this.countryData.map(function(item, idx){
                if (item.value == value) {
                    _this.countryActive = idx;
                    return item.active = true;
                }
                
                return item.active = false;
            });

        },

        // select
        selectTrackSystem(e){
            let option = e.target.selectedOptions[0]; 
            
            this.adUnitData.trackSystem.textInput = option.innerText;
        },

        // close adUnit form
        closeAdUnitForm(){
            this.$router.push({ name: 'adunit' });
        },

        // edit adUnit to DB
        editAdUnitDB(){
            let data = {
                id: this.getExchangeEditId,
                url: this.adUnitData.packageName.value,
                name: this.countryData[0].title, 
                description: this.countryData[0].description, 
                type: this.adUnitData.typeBanner.value, 
                image_only: this.adUnitData.imgOnly.value,
                utm_content: this.adUnitData.utmContent.value,
                tracking: parseInt(this.adUnitData.trackSystem.value)
            };

            this.saveAdunitDiv.disabled = true;

            if (this.iconFileStatus) {
                data.iconurl = this.adUnitData.iconUrl.value;
            } else {
                data.iconbase64 = this.adUnitData.iconFile.value;
                data.iconext = this.adUnitData.iconFile.typeInput;
            }

            if (this.imageFileStatus) {
                data.imageurl = this.adUnitData.imageUrl.value;
            } else {
                data.imagebase64 = this.adUnitData.imageFile.value;
                data.imageext = this.adUnitData.imageFile.typeInput;
            }

            if (this.portraitFileStatus) {
                data.portraiturl = this.adUnitData.portraitUrl.value;
            } else {
                data.portraitbase64 = this.adUnitData.portraitFile.value;
                data.portraitext = this.adUnitData.portraitFile.typeInput;
            }

            if (this.landscapeFileStatus) {
                data.landscapeurl = this.adUnitData.landscapeUrl.value;
            } else {
                data.landscapebase64 = this.adUnitData.landscapeFile.value;
                data.landscapeext = this.adUnitData.landscapeFile.typeInput;
            }
            
            data = JSON.stringify(data);

            //console.log(data);
            
            // edit adunit
            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblock/edit?at='+this.getAccessToken, 
                method: 'POST', 
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.saveAdunitDiv.disabled = false;

                this.$router.push({ name: 'adunit' });
            })
            .catch(e => {
                if (e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.saveAdunitDiv.disabled = false;
                    
                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error edit adunit");
            })

            /* ----- locale ----- */
            if (this.countryData.length > 1) {
                let dataLocale = {
                    adblock_id: this.getExchangeEditId,
                };

                let locales = [];

                for(let i = 1; i < this.countryData.length; i++){
                    locales.push({
                        name: this.countryData[i].title,
                        description: this.countryData[i].description,
                        locale: this.countryData[i].value
                    });
                }

                dataLocale.locales = locales;
                
                dataLocale = JSON.stringify(dataLocale);

                //console.log(dataLocale);

                // edit locale in adunit
                axios({
                    url: this.$store.getters.getBaseUrl+'/api/adblock/locales/edit?at='+this.getAccessToken, 
                    method: 'POST', 
                    data: dataLocale,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(response => {})
                .catch(e => {
                    if (e.response.data.Error != undefined) {
                        let _this = this;
                        let uniqueId = this.getUniqueId();
                        this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});
                        
                        setTimeout(function(){
                            _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                        }, 3000);
                    }

                    console.log("error edit locale adunit");
                })
            }
            /*----- end locale -----*/
        },

        // get name type adUnit by ID
        getTypeBannerByID(inx){
            let res = this.getTypeBannerList.filter(function(item){
                return item.id == inx;
            });

            return res[0].name;
        },

        // get system Tracking by ID
        getTrackSystemByID(inx){
            let res = this.trackSystemList.filter(function(item){
                return item.id == inx
            });

            return res[0].system;
        },

        // get exchange by ID
        getAdunitByID(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblock/id/' + this.getExchangeEditId + '?at='+this.getAccessToken, 
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adUnitData.packageName.value = response.data.url;
                this.countryData[0].title = response.data.name;
                this.countryData[0].description = response.data.description;
                
                this.adUnitData.trackSystem.value = response.data.tracking;
                this.adUnitData.trackSystem.textInput = this.getTrackSystemByID(response.data.tracking);
                this.adUnitData.typeBanner.value = this.getTypeBannerByID(response.data.type);

                this.adUnitData.imgOnly.value = response.data.image_only;
                this.adUnitData.utmContent.value = response.data.utm_content;

                this.adUnitData.iconUrl.value = response.data.icon;
                this.imageFileStatus = true;
                this.adUnitData.imageUrl.value = response.data.image;
                this.iconFileStatus = true;
                this.adUnitData.portraitUrl.value = response.data.image_portrait;
                this.portraitFileStatus = true;
                this.adUnitData.landscapeUrl.value = response.data.image_landscape;
                this.landscapeFileStatus = true;

                if (response.data.locales.length > 0) {
                    
                    for(let i = 0; i < response.data.locales.length; i++){
                        let locale = this.getCountryList.find(val => val.code == response.data.locales[i].locale);

                        this.countryData.push({
                            title: response.data.locales[i].name, 
                            description: response.data.locales[i].description, 
                            value: response.data.locales[i].locale, 
                            text: locale['country'] + ' (' + response.data.locales[i].locale + ')', 
                            active: false
                        });
                    }
                    
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error get adunit by id');
            });
        },

        // popup
        setPopupDelete(e){
            this.$store.commit('modals/setPopupDelete', true);
        },

        // set height for table form Adunit
        setAdunitFormHeight(){
            this.adunitFormHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.adunitFormHeight < 0) this.adunitFormHeight = 0;

            return this.adunitFormHeight;
        },
    },

    components: {
        Popup
    }
}
</script>

<style scoped>
    .alert{
        transition-property: opacity;
        transition-duration: 1s;
    }
    .alert .alert-content{
        height: 100%;
    }
    .alert .alert-content h4{
        line-height: 34px;
    }

    .center{
        text-align: center;
    }

    .modal-content p{
        word-wrap: break-word;
    }

    .panel-body{
        padding: 0px;
    }

        .panel-body .pannel__form{
            padding: 0px 20px;
        }

    .col_back_to_prev{
        vertical-align: middle;
        width: 20%;
    }

    .example-box-wrapper .nav{
        margin-bottom: 10px;
    }

    .no-padding-bottom{
        padding-bottom: 0px;
    }

    .col_action{
        width: 80%;
        vertical-align: middle;
        text-align: center;
    }
    
    .back_to_prev{
        display: inline-block;
        margin: 0 10px;
    }

        .back_to_prev .back_icon{
            margin: 0px; 
            float: none; 
            display: inline-block;
            color: #0093d9; 
        }

        .back_to_prev:hover .back_icon{
            border-color: #0093d9!important; 
        }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-adunit-header th input{
                /* width: 233px; */
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    .scroll{
        overflow: auto;
        padding-right: 10px;
        margin-right: 10px;
        box-sizing: border-box;
    }

    .fileinput{
        margin-bottom: 0px;
    }

    .bordered-row > .form-group{
        border-top: 0px none;
        border-bottom: 1px dashed #dfe8f1;
    }

    .bordered-row > .form-group:last-child{
        border-bottom: 0px none;
        padding-bottom: 13px;
    }

        .form-group .control-label img{
            max-height: 80px;
        }

    .locale_list li{
        margin-right: 20px;
    }

        .locale_list li a{
            border: 1px solid #eee;
            border-radius: 5px;
        }

    .add_adunit{
        padding: 0 52px;
    }

    .close_adunit{
        margin-right: 65px;
    }

    /* flex */

    .space_around{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
    }

    .space_around button{
        margin-right: 15px;
    }

    /* animation */
    .fade-enter{
        opacity: 0;
    }
    .fade-enter-active{
        transition: all .5s;
    }
    .fade-enter-to{
        opacity: 1;
    }
    .fade-leave-active{
        opacity: 0;
        transition: all .5s reverse;
    }
    .bounce-enter-active {
        animation: bounce-in .5s;
    }
    .bounce-leave-active {
        animation: bounce-in .5s reverse;
    }
    @keyframes bounce-in {
        0% {
            opacity: 0;
            transform: scale(0);
            height: 0px;
        }
        50% {
            transform: scale(1.1);
            height: 50%;
        }
        100% {
            opacity: 1;
            transform: scale(1);
            height: 100%;
        }
    }
</style>
