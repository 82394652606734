<template>
    <section>
        <div class="panel app-list">
            <div class="panel-body">
                <div class="app-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-header" id="datatable-app">
                            <thead>

                                <tr>
                                    <th class="col_title">
                                        <div class="table-head-content">
                                            {{ getLang.title }}
                                        </div>
                                    </th>

                                    <th class="col_state">
                                        <div class="table-head-content">
                                            {{ getLang.account.state }}
                                        </div>
                                    </th>

                                    <th class="col_role">
                                        <div class="table-head-content">
                                            {{ getLang.account.role }}
                                        </div>
                                    </th>

                                    <th class="col_action">
                                        <div class="table-head-content">
                                            {{ getLang.action }}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-content" id="datatable-app2">
                            <tbody id="app_list">

                                <tr class="odd gradeX" v-for="(item, index) in getAccountView" :key="index">
                                    <td class="v-bottom col_title">
                                        <div class="short_text">
                                            {{ item.email }}
                                        </div>
                                    </td>

                                    <td class="v-bottom col_state">
                                        <div class="short_text">
                                            {{ item.state }}
                                        </div>
                                    </td>

                                    <td class="v-bottom col_role">
                                        <div class="short_text">
                                            {{ item.role }}
                                        </div>
                                    </td>

                                    <td class="right v-top col_action">
                                        <button class="btn btn-alt btn-hover btn-warning btn-edit"
                                                data-toggle="modal"
                                                data-target=".bs-example-modal-sm"
                                                @click.prevent="resetAccount($event, item.id)"
                                                >
                                            <span>{{ getLang.clear }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.accountStart = val"
                                @setEnd="val => pagination.accountEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                                >
                    </pagination>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            accountList: [],
            accountView: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 74,

            //resetAccountDiv: '',

            // pagination
            pagination: {
                accountStart: 0,
                accountEnd: 0,
                countLength: 0,
                countList: [5,10,25,50,100],
                activeCount: 0,
                activePage: 0
            }
        }
    },
    watch:{
        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationResetAccountAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationResetAccountPageAction', parseInt(this.pagination.activePage));
        }
    },
    created(){
        this.pagination.activeCount = this.$store.getters.getRemeberPaginationResetAccount;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationResetAccountPage;

        if(!this.isAdmin()) this.$router.push({name: 'index'});
        else this.getAccounts();
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        getAccountView(){
            this.accountView = this.accountList.slice(this.pagination.accountStart, this.pagination.accountEnd);

            return this.accountView;
        },
    },
    methods: {
        isAdmin(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() === this.$store.getters.getRoleSuperAdmin);
        },

        // set height for table list accounts
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        getAccounts(){
            axios({
                url: this.$store.getters.getBaseUrl+'/acc/admin/get?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.accountList = response.data;
                this.pagination.countLength = this.accountList.length; // paginations
            })
            .catch(e => {
                this.errorsMessage(e, 'error get accounts');
            });
        },

        resetAccount(e, id){
            let elem = e.currentTarget;
            elem.disabled = true;

            axios({
                url: this.$store.getters.getBaseUrl+'/acc/admin/reset/acc/' + id + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'The account cleared!'});

                elem.disabled = false;

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error reset account');
            });
        },
    },
    components: {
        Pagination
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    /*---*/
    .col_action{
        width: 11%;
        width: calc(180px);
    }

    .col_title{
        width: 59%;
    }

    .col_state{
        width: 15%;
    }

    .col_role{
        width: 15%;
    }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .app-list{
        border: 0px none;
    }

        .app-list .panel-body{
            padding: 0px;
        }

    .app-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 144px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 144px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 233px;
        }

    .table-app th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-app-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-app-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-app-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-app-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-app-content tr:first-child td{
        border-top: 0px none;
    }

    .table-app-content td.v-top, .table-app-content th.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-app-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-app-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
            background-color: #ffff00!important;
        }

    /* animation */

    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter-active{
        transition: all .3s;
    }
    .width-leave-active{
        transition: all .3s reverse;
    }

</style>
