<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
                >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
                    >
            <div class="modal-dialog" @click.stop="''" style="width: 950px!important; max-width: 950px!important;">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader">
                        <!-- <button type="button"
                                class="close float-left"
                                id="close_modal"
                                data-dismiss="modal"
                                aria-hidden="true"
                                @click.prevent="setPopupAddHide()"
                                >
                            ×
                        </button> -->
                        <h4 class="modal-title nowrap">{{ title }}</h4>
                        <button class="btn btn-alt btn-hover btn-danger" style="margin-top: 5px;" @click.prevent="removeItem()">{{ getLang.remove }}</button>
                        <div class="action_popup">
                            <button type="button"
                                    class="btn btn-primary"
                                    @click.prevent="editItem()"
                                    :style="!isValid ? 'opacity: 0.5; cursor: default;' : ''"
                                    :disabled="!isValidForm"
                                    v-if="isValid"
                                    >
                                {{ getLang.save }}
                            </button>
                            <button type="button"
                                    class="btn btn-danger"
                                    @click.prevent="setPopupAddHide()"
                                    v-else
                                    >
                                {{ getLang.close }}
                            </button>
                        </div>
                    </div>
                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="form_adunit">
                                    <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>

                                        <div class="form-group no-border form_group_wrap">
                                            <div class="col-md-7">
                                                <div class="adunit_item">
                                                    <div style="overflow: hidden; padding-left: 0px; width: 170px;">
                                                        <span v-if="adunitAdvertiserData.icon">
                                                            <img :src="adunitAdvertiserData.icon" class="icon" style="margin-right: 10px;" alt="">
                                                        </span><span v-if="adunitAdvertiserData.image">
                                                            <img :src="adunitAdvertiserData.image" class="icon" alt="">
                                                        </span>
                                                    </div>
                                                    <div style="height: 100%; width: 300px; display: flex; align-items: flex-end;">
                                                        <div style="width: 100%; margin-bottom: 1px;">
                                                            <div class="short_text" v-if="adunitAdvertiserData.name" style="color: rgb(153, 153, 153); font-size: 11px; line-height: 11px;">({{ adunitAdvertiserData.name }})</div>
                                                            <div class="short_text"><strong>{{ adunitAdvertiserData.title }}</strong></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="row">
                                                    <div class="col-sm-3">&nbsp;</div>
                                                    <div class="col-sm-9">
                                                        <div class="row">
                                                            <div class="col-sm-5">
                                                                <label class="control-label label_on_input" style="text-align: left; width: 200px;" :title="getLang.exchange.unlimitedDuration"> {{ getLang.exchange.unlimitedDuration }}</label>
                                                                <div :class="adunitAdvertiserData.unlimitedDuration.state ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                                                    @click.prevent="changeUnlimitedDuration()"
                                                                >
                                                                    <div class="bootstrap-switch-container">
                                                                        <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                                        <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-7" style="display: flex; align-items: flex-end;">
                                                                {{ getFormatDate(new Date(adunitAdvertiserData.startdate), true) + '/' }}{{ getFormatDate(new Date(adunitAdvertiserData.enddate), true) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group no-border form_group_wrap">
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="icon_required" :title="getLang.required">*</span>
                                                        <label class="control-label label_on_input" :title="getLang.exchange.totalbudget">{{ getLang.exchange.totalbudget }}</label>
                                                        <input class="form-control outlight" type="number" step="1" min="1" v-model="adunitAdvertiserData.totalbudget" required>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.totalbudget" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="row">
                                                    <div class="col-sm-3">&nbsp;</div>
                                                    <div class="col-sm-9">
                                                        <label class="control-label label_on_input" :title="getLang.exchange.dataStart">{{ getLang.exchange.dataStart }}</label>
                                                        <date-picker lang="en"
                                                                    v-model="time[0]"
                                                                    format="yyyy-MM-dd"
                                                                    confirm
                                                                    :first-day-of-week="1"
                                                                    :editable="true"
                                                                    :disabled="!adunitAdvertiserData.unlimitedDuration.state"
                                                                    class="float-right stat_datepicker"
                                                                    id="sidebarInstall"
                                                                    style="width: 100%; margin-right: 0px;"
                                                                    >
                                                        </date-picker>
                                                        <input type="hidden" :value="getStatDate"/>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.dataStart" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group no-border form_group_wrap">
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="icon_required" :title="getLang.required">*</span>
                                                        <label class="control-label label_on_input" :title="getLang.exchange.dailybudget">{{ getLang.exchange.dailybudget }}</label>
                                                        <input class="form-control outlight" type="number" step="1" min="1" v-model="adunitAdvertiserData.dailybudget" required>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.dailybudget" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="row">
                                                    <div class="col-sm-3">&nbsp;</div>
                                                    <div class="col-sm-9">
                                                        <label class="control-label label_on_input" :title="getLang.exchange.dataStop">{{ getLang.exchange.dataStop }}</label>
                                                        <date-picker lang="en"
                                                                    v-model="time[1]"
                                                                    format="yyyy-MM-dd"
                                                                    confirm
                                                                    :first-day-of-week="1"
                                                                    :editable="true"
                                                                    :disabled="!adunitAdvertiserData.unlimitedDuration.state"
                                                                    class="float-right stat_datepicker"
                                                                    id="sidebarInstall"
                                                                    style="width: 100%; margin-right: 0px;"
                                                        >
                                                        </date-picker>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.dataStop" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group no-border form_group_wrap" style="padding-bottom: 13px;">
                                            <div class="col-md-7">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <span class="icon_required" :title="getLang.required">*</span>
                                                        <label class="control-label label_on_input" :title="getLang.exchange.priceper1000">{{ getLang.exchange.priceper1000 }}</label>
                                                        <input class="form-control outlight" type="number" step="1" min="1" v-model="adunitAdvertiserData.priceper1000" required>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.priceper1000" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-5">
                                                <div class="row">
                                                    <div class="col-sm-3">&nbsp;</div>
                                                    <div class="col-sm-9">
                                                        <label class="control-label label_on_input" :title="getLang.exchange.status">{{ getLang.exchange.status }}</label>
                                                        <button class="btn btn-alt btn-hover btn-primary advertiser_enabled"
                                                                @click.prevent="adunitAdvertiserData.enabled = !adunitAdvertiserData.enabled"
                                                                v-if="!adunitAdvertiserData.enabled"
                                                                >
                                                            {{ getLang.enable }}
                                                        </button>
                                                        <button class="btn btn-alt btn-hover btn-warning advertiser_enabled"
                                                                @click.prevent="adunitAdvertiserData.enabled = !adunitAdvertiserData.enabled"
                                                                v-else
                                                                >
                                                            {{ getLang.disable }}
                                                        </button>
                                                        <i class="glyph-icon font-gray icon-question-circle" :title="getLang.exchange.status" data-original-title=".icon-question"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">

                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelect from '../plugins/MultiSelect';
import DatePicker from 'vue2-datepicker';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        urlAdded: String,
        urlRedirect: String,
        title: String,
        advertiser: Object,
        opened: String
    },

    data(){
        return {
            lang: Lang,

            otherHeight: 60,
            modalBodyHeight: 200,
            adunitListHeight: 0,

            timeout: 0,

            time: [new Date(), new Date()],
            timeStart: '2019-01-01 00:00:00',
            timeEnd: '2099-01-01 00:00:00',

            stateList: {
                new: 'New',
                notConfirmed: 'NotConfirmed',
                confirmed: 'Confirmed',
                blocked: 'Blocked',
            },

            statusList: {
                enabled: 'Enabled',
                disabled: 'Disabled',
                deleted: 'Deleted'

            },

            adunitAdvertiserData: {
                id: null,
                title: null,
                name: null,
                icon: null,
                image: null,
                enabled: false,
                state: 'NotConfirmed',
                per_days: 0,
                shows_count: 55,
                totalbudget: 1000,
                dailybudget: 50,
                priceper1000: 0,
                startdate: '2019-01-01 00:00:00',
                enddate: '2099-01-01 00:00:00',
                unlimitedDuration: {
                    state: true,
                }
            },

            defaultAdvertiserData: {
                enabled: false,
                state: 'NotConfirmed',
                per_days: 0,
                shows_count: 55,
                totalbudget: 1000,
                dailybudget: 50,
                priceper1000: 0,
                startdate: '2019-01-01 00:00:00',
                enddate: '2099-01-01 00:00:00',
            },

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    mounted(){
        let _this = this;
        this.setAdunitListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setAdunitListHeight();
        });
    },

    watch: {
        advertiser(){
            this.defaultAdvertiserData.enabled = this.adunitAdvertiserData.enabled = false;
            this.defaultAdvertiserData.state = this.adunitAdvertiserData.state = 'NotConfirmed';
            this.defaultAdvertiserData.per_days = this.adunitAdvertiserData.per_days = 0;
            this.defaultAdvertiserData.shows_count = this.adunitAdvertiserData.shows_count = 55;
            this.defaultAdvertiserData.totalbudget = this.adunitAdvertiserData.totalbudget = 1000;
            this.defaultAdvertiserData.dailybudget = this.adunitAdvertiserData.dailybudget = 50;
            this.defaultAdvertiserData.priceper1000 = this.adunitAdvertiserData.priceper1000 = 0;
            this.defaultAdvertiserData.startdate = this.adunitAdvertiserData.startdate = this.timeStart;
            this.defaultAdvertiserData.enddate = this.adunitAdvertiserData.enddate = this.timeEnd;

            this.getAdunitAdvertiser();
        },

        opened(){
            this.defaultAdvertiserData.enabled = this.adunitAdvertiserData.enabled = false;
            this.defaultAdvertiserData.state = this.adunitAdvertiserData.state = 'NotConfirmed';
            this.defaultAdvertiserData.per_days = this.adunitAdvertiserData.per_days = 0;
            this.defaultAdvertiserData.shows_count = this.adunitAdvertiserData.shows_count = 55;
            this.defaultAdvertiserData.totalbudget = this.adunitAdvertiserData.totalbudget = 1000;
            this.defaultAdvertiserData.dailybudget = this.adunitAdvertiserData.dailybudget = 50;
            this.defaultAdvertiserData.priceper1000 = this.adunitAdvertiserData.priceper1000 = 0;
            this.defaultAdvertiserData.startdate = this.adunitAdvertiserData.startdate = this.timeStart;
            this.defaultAdvertiserData.enddate = this.adunitAdvertiserData.enddate = this.timeEnd;

            this.getAdunitAdvertiser();
        }
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        getStatDate(){
            if (!this.adunitAdvertiserData.unlimitedDuration.state && this.time[0]) {
                this.adunitAdvertiserData.startdate = this.getFormatDate(this.time[0]);
            }

            if (!this.adunitAdvertiserData.unlimitedDuration.state && this.time[1]) {
                this.adunitAdvertiserData.enddate = this.getFormatDate(this.time[1]);
            }

            return this.adunitAdvertiserData.startdate + ' ' + this.adunitAdvertiserData.enddate;
        },

        // is valid form add Adunit
        isValid(){
            return (this.adunitAdvertiserData.shows_count != this.defaultAdvertiserData.shows_count ||
                    this.adunitAdvertiserData.enabled != this.defaultAdvertiserData.enabled ||
                    this.adunitAdvertiserData.state != this.defaultAdvertiserData.state ||
                    this.adunitAdvertiserData.totalbudget != this.defaultAdvertiserData.totalbudget ||
                    this.adunitAdvertiserData.dailybudget != this.defaultAdvertiserData.dailybudget ||
                    this.adunitAdvertiserData.priceper1000 != this.defaultAdvertiserData.priceper1000 ||
                    this.adunitAdvertiserData.startdate != this.defaultAdvertiserData.startdate ||
                    this.adunitAdvertiserData.enddate != this.defaultAdvertiserData.enddate);
        },

        // getOnlyDate(){
        //     return
        // },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupEdit;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                this.$nextTick(function(){
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.modalBodyHeight = (this.$refs.modalHeader.offsetHeight + mdlBodyHeight);
                });
            }

            return this.popupAdd;
        },

        // validation form
        isValidForm(){
            let result = true;

            result = (this.adunitAdvertiserData.totalbudget > 0 || this.adunitAdvertiserData.totalbudget === 0 || this.adunitAdvertiserData.totalbudget === '0') && (this.adunitAdvertiserData.dailybudget > 0 || this.adunitAdvertiserData.dailybudget === 0 || this.adunitAdvertiserData.dailybudget === '0') && (this.adunitAdvertiserData.priceper1000 > 0 || this.adunitAdvertiserData.priceper1000 === 0 || this.adunitAdvertiserData.priceper1000 === '0');

            return result;
        }
    },
    methods: {
        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        // set default form
        setFormDefault(){
            this.adunitAdvertiserData.enabled = this.defaultAdvertiserData.enabled;
            this.adunitAdvertiserData.state = this.defaultAdvertiserData.state;
            this.adunitAdvertiserData.per_days = this.defaultAdvertiserData.per_days;
            this.adunitAdvertiserData.shows_count = this.defaultAdvertiserData.shows_count;
            this.adunitAdvertiserData.totalbudget = this.defaultAdvertiserData.totalbudget;
            this.adunitAdvertiserData.dailybudget = this.defaultAdvertiserData.dailybudget;
            this.adunitAdvertiserData.priceper1000 = this.defaultAdvertiserData.priceper1000;
            this.adunitAdvertiserData.startdate = this.defaultAdvertiserData.startdate;
            this.adunitAdvertiserData.enddate = this.defaultAdvertiserData.enddate;
        },

        getAdunitAdvertiser(){
            this.adunitAdvertiserData.id = this.advertiser.adblock;
            this.adunitAdvertiserData.title = this.advertiser.title;
            this.adunitAdvertiserData.name = this.advertiser.name;
            this.adunitAdvertiserData.icon = this.advertiser.icon;
            this.adunitAdvertiserData.image = this.advertiser.image;

            if(this.advertiser.country_cats != undefined) {
                this.defaultAdvertiserData.enabled = this.adunitAdvertiserData.enabled = (this.advertiser.country_cats[0].status === this.statusList.enabled ? true : false);
                this.defaultAdvertiserData.state = this.adunitAdvertiserData.state = this.advertiser.country_cats[0].state;
                this.defaultAdvertiserData.per_days = this.adunitAdvertiserData.per_days = this.advertiser.country_cats[0].per_days;
                this.defaultAdvertiserData.shows_count = this.adunitAdvertiserData.shows_count = this.advertiser.country_cats[0].shows_count;

                this.defaultAdvertiserData.totalbudget = this.adunitAdvertiserData.totalbudget = this.advertiser.country_cats[0].totalbudget;
                this.defaultAdvertiserData.dailybudget = this.adunitAdvertiserData.dailybudget = this.advertiser.country_cats[0].dailybudget;
                this.defaultAdvertiserData.priceper1000 = this.adunitAdvertiserData.priceper1000 = this.advertiser.country_cats[0].priceper1000;

                this.defaultAdvertiserData.startdate = this.adunitAdvertiserData.startdate = this.advertiser.country_cats[0].startdate;
                this.defaultAdvertiserData.enddate = this.adunitAdvertiserData.enddate = this.advertiser.country_cats[0].enddate;
                this.time[0] = new Date(this.advertiser.country_cats[0].startdate);
                this.time[1] = new Date(this.advertiser.country_cats[0].enddate);

                if(this.adunitAdvertiserData.startdate == this.timeStart && this.adunitAdvertiserData.enddate == this.timeEnd) this.adunitAdvertiserData.unlimitedDuration.state = true;
                else this.adunitAdvertiserData.unlimitedDuration.state = false;
            }

            return this.adunitAdvertiserData;
        },

        changeUnlimitedDuration(){
            this.adunitAdvertiserData.unlimitedDuration.state = !this.adunitAdvertiserData.unlimitedDuration.state;

            if (this.adunitAdvertiserData.unlimitedDuration.state) {
                this.adunitAdvertiserData.startdate = this.timeStart;
                this.adunitAdvertiserData.enddate = this.timeEnd;
            }
        },

        // get formatted date (YYYY-mm-dd)
        getFormatDate(time, flag = false){
            let month = (time.getMonth() + 1);
            let date = time.getDate();
            let hours = time.getHours();
            let minutes = time.getMinutes();
            let seconds = time.getSeconds();

            if(month < 10) month = '0' + month;
            if(date < 10) date = '0' + date;

            if(hours < 10) hours = '0' + hours;
            if(minutes < 10) minutes = '0' + minutes;
            if(seconds < 10) seconds = '0' + seconds;

            if(flag === true) return time.getFullYear() + '-' + month + '-' + date;
            return time.getFullYear() + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds;
        },

        /* popup */
        setPopupAddHide(){
            if (this.isValid) {
                if (confirm('Are you sure you want to close the popup without saving the data?')) {
                    this.$store.commit('modals/setPopupEdit', false);
                }
            } else {
                this.$store.commit('modals/setPopupEdit', false);
            }
        },

        editItem(e){
            if(!this.isValid) return;

            let data = JSON.stringify({
                adblock: Number(this.adunitAdvertiserData.id),
                shows_count: Number(this.adunitAdvertiserData.shows_count),
                per_days: Number(this.adunitAdvertiserData.per_days),
                enabled: this.adunitAdvertiserData.enabled,
                status: (this.adunitAdvertiserData.enabled ? this.statusList.enabled : this.statusList.disabled),
                state: this.adunitAdvertiserData.state,
                totalbudget: this.adunitAdvertiserData.totalbudget,
                dailybudget: this.adunitAdvertiserData.dailybudget,
                priceper1000: this.adunitAdvertiserData.priceper1000,
                startdate: (this.adunitAdvertiserData.unlimitedDuration.state ? this.timeStart : this.getFormatDate(this.time[0])),
                enddate: (this.adunitAdvertiserData.unlimitedDuration.state ? this.timeEnd : this.getFormatDate(this.time[1])),
            });

            this.editItemDB(data);

            this.setPopupAddHide();
        },

        addItemDB(data, done){
            axios({
                url: this.$store.getters.getBaseUrl + '/api/advblocks/add?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (done) {
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your country added to Advertiser!'});
                    let _this = this;

                    this.$emit('setUpdata', true);
                    this.isUpdate = true;

                    setTimeout(function(){
                        _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }
            })
            .catch(e => {
                this.errorsMessage(e, 'error added adunit to advertiser');
            });
        },

        editItemDB(data){
            axios({
                url: this.$store.getters.getBaseUrl + '/api/advblocks/edit?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Adunit edited in Advertiser!'});
                let _this = this;

                this.$emit('setUpdata', true);
                this.isUpdate = true;

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .catch(e => {
                this.errorsMessage(e, 'error edited adunit in advertiser');
            });
        },

        removeItem(){
            let isRemove = confirm('Are you sure?');

            if(isRemove) this.removeItemDB(this.$store.getters.getBaseUrl + '/api/advblocks/delete/'+this.adunitAdvertiserData.id+'?at=' + this.getAccessToken);
        },

        removeItemDB(url){
            axios({
                url: url,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Advertiser is removed!'});
                let _this = this;

                this.$emit('setUpdata', true);
                this.isUpdate = true;

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);

                this.setPopupAddHide();
            })
            .catch(e => {
                this.errorsMessage(e, 'error remove advertiser');
            });
        },

        // set height for adinut list in group
        setAdunitListHeight(){
            this.adunitListHeight = window.innerHeight - (this.otherHeight + this.modalBodyHeight);

            if(this.adunitListHeight < 0) this.adunitListHeight = 0;
            if(this.adunitListHeight >500) this.adunitListHeight = 450;

            return this.adunitListHeight;
        }
    },
    components: {
        MultiSelect,
        DatePicker
    }
}
</script>

<style>
.mx-datepicker-popup{
    height: 365px!important;
}
</style>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.scroll{
    overflow: auto;
    margin-right: 10px;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.icon_required{
        color: red;
        position: absolute;
        left: 0px;
        top: 0px;
    }

    .icon-question-circle{
        font-size: 16px;
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 100;
    }

    .padding_for_icon{
        padding-right: 0px;
    }

    .label_on_input{
        font-size: 10px;
        color: #AFAFAF;
        font-weight: normal;
        position: absolute;
        top: -23px;
        left: 22px;
    }

    .form_group_wrap, .form_group_wrap_big{
        display: flex;
        align-items: flex-end;
        padding: 20px 0 10px;
    }

    .form_group_wrap{
        height: 65px;
    }

    .form_group_wrap_big{
        height: 130px;
    }

.modal-dialog{
    margin: 30px auto 0px;
}

.modal-body{
    max-height: 560px;
}

.modal-content{
    position: relative;
    background-color: #fff;
    z-index: 1000;
}

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 15px 20px 10px;
    }

.w100{
    width: 100%;
}

.w50{
    width: 450px;
    box-sizing: border-box;
}

.close{
    margin-right: 20px;
}

.action_popup{
    text-align: right;
    position: relative;
    margin: 5px 5px 0;
}

.filter{
    display: flex;
}

    .filter div{
        width: 220px;
        margin: 0 5px;
    }

    .filter__search{
            margin-right: 8px;
            position: relative;
            top: 0px;
            right: 0px;
            height: 44px;
            width: 144px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 44px;
                width: 144px;
                position: absolute;
                right: 0px;
                transition: all .5s;
            }

            .filter__search input:focus{
                width: 233px;
            }

.form_adunit{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
}

.modal-header{
    display: flex;
    align-items: flex-start;
    height: 63px;
    padding: 9px 20px 9px 0px;
}

.modal-footer{
    padding: 9px 15px;
}

.modal-footer>div{
    width: 100%;
}

.adunit_in_group{
    width: 410px;
}

    td.icon{
        width: 140px;
    }

        img.icon{
            height: 51px;
            width: auto;
        }

    .info{
        width: 260px;
        box-sizing: border-box;
        padding: 0 10px;
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 190px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

.modal-title{
    position: relative;
    font-size: 13px;
    color: rgb(175, 175, 175);
    left: 28px;
    top: -8px;
}

.advertiser_enabled{
    /* margin-right: 10px; */
    /* min-width: 200px; */
    width: 100%;
}

#close_modal{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.flex-block-adunit-group{
    padding: 0 13px 10px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

.bordered-row > .form-group{
    padding: 0 0 13px;
}

.adunit_item{
    padding: 0px!important;
    /* margin-bottom: 13px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 54px;
    display: flex;
    align-items: center;
}

.bordered-row > .form-group:last-child{
    padding: 0 0 0;
}

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
