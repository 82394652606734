<template>
    <div>
        <div class="col-sm-6">
            <div class="dataTables_info" id="datatable-example_info" role="status" aria-live="polite">{{ getLang.adUnit.showing }} {{ getPngcountLength ? getAddUnitStart+1 : getPngcountLength }} {{ getLang.adUnit.to }} {{ getAddUnitEnd }} {{ getLang.adUnit.of }} {{ getPngcountLength }} {{ getLang.adUnit.entries }}</div>
            <div class="selector" style="width: 80px!important;">
                <span style="width: 60px; -moz-user-select: none;">{{ getPgnCountList[getPgnActiveCount] }}</span>
                <select name="" class="custom-select" @change.prevent="setPgnActiveCount($event)">
                    <option v-for="(item, index) in getPgnCountList" :key="index" :value="index" :selected="index == getPgnActiveCount">{{ item }}</option>
                </select>
                <i class="glyph-icon icon-caret-down"></i>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="dataTables_paginate paging_bootstrap" id="datatable-example_paginate" v-if="getPngcountLength > 0">
                <ul class="pagination">
                    <li class="previous" :class="getPgnActiveLink == 0 ? 'disabled' : ''">
                        <a href="#" @click.prevent="setPngPrevPage" :disabled="getPgnActiveLink == 0">{{ getLang.previous }}</a>
                    </li>
                    <li v-for="(item, index) in getPgnPages" :key="getPgnPages[index]" :class="getPgnActiveLink+1 == getPgnPages[index] ? 'active' : ''">
                        <span v-if="item == '...'">{{ item }}</span>
                        <span v-else-if="item == '....'">{{ item }}</span>
                        <a href="#" @click.prevent="setPgnActiveLink($event, getPgnPages[index]-1)" v-else>{{ item }}</a>
                    </li>
                    <li class="next" :class="getPgnActiveLink == getPngPagesLength-1 ? 'disabled' : ''">
                        <a href="#" @click.prevent="setPngNextPage" :disabled="getPgnActiveLink == getPngPagesLength-1">{{ getLang.next }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import Lang from '../lang/message';

export default {
    props: ['countLength', 'countList', 'activeCount', 'activePage'],
    data(){
        return {
            lang: Lang,

            addUnitStart: 0,
            addUnitEnd: 0,

            rangeCount: 5,
            rangeMax: 20,

            pagination: {
                activeCount: null,
                activeLink: null,
                pages: 0
            }
        }
    },
    computed: {
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getAddUnitStart(){
            this.addUnitStart = this.pagination.activeLink * this.countList[this.getPgnActiveCount];
            this.$emit('setStart', this.addUnitStart);

            return this.addUnitStart;
        },
        getAddUnitEnd(){
            this.addUnitEnd = this.getAddUnitStart + this.countList[this.getPgnActiveCount];
            if(this.addUnitEnd > this.countLength) this.addUnitEnd = this.countLength;
            this.$emit('setEnd', this.addUnitEnd);

            return this.addUnitEnd;
        },

        /* paginations */
        getPgnCountList(){
            return this.countList;
        },
        getPngcountLength(){
            return this.countLength;
        },
        getPgnActiveCount(){
            if(this.pagination.activeCount == null) this.pagination.activeCount = this.activeCount;

            return this.pagination.activeCount;
        },
        getPgnActiveLink(){
            let pagesLength = this.getPngPagesLength ? (this.getPngPagesLength - 1) : this.getPngPagesLength;

            if(this.pagination.activeLink == null) this.pagination.activeLink = this.activePage;

            if(this.pagination.activeLink > pagesLength){
                this.pagination.activeLink = pagesLength;
                this.$emit('setActivePagePagination', pagesLength);
            }

            return this.pagination.activeLink;
        },
        getPgnPages(){
            this.pagination.pages = Math.ceil(this.getPngcountLength / this.getPgnCountList[this.getPgnActiveCount]);
            let tmp = [];
            let res = [];

            for(let i=0; i<this.pagination.pages; i++){
                tmp[i] = i+1;
            }

            if (this.pagination.pages > this.rangeMax) {
                let rangeStart = this.rangeCount;
                let rangeEnd = this.pagination.pages - rangeStart;
                let rangeActiveFrom = this.pagination.activeLink - rangeStart;
                let rangeActiveTo = this.pagination.activeLink + rangeStart;

                let rangeBefore = rangeActiveFrom - rangeStart;
                let rangeAfter = rangeEnd - rangeActiveTo;

                if (rangeEnd > rangeActiveTo && rangeAfter > 0) {
                    tmp.splice(rangeActiveTo, rangeAfter, '....');
                }

                if (rangeStart < rangeActiveFrom && rangeBefore > 0) {
                    tmp.splice(rangeStart, rangeBefore, '...');
                }
            }

            return tmp;
        },
        getPngPagesLength(){
            //return this.getPgnPages.length;
            return this.getPgnPages[this.getPgnPages.length-1];
        }
        /* end paginations */
    },
    methods: {
        /* paginations */
        setPgnActiveCount(e){
            this.$emit('setActiveCountPagination', e.target.value);
            this.pagination.activeCount = e.target.value;

            this.pagination.activeLink = 0;
            this.$emit('setActivePagePagination', 0);
        },
        setPgnActiveLink(e, index){
            this.pagination.activeLink = index;
            this.$emit('setActivePagePagination', index);
        },
        setPngNextPage(e){
            this.pagination.activeLink++;
            this.$emit('setActivePagePagination', this.pagination.activeLink);
        },
        setPngPrevPage(e){
            if(this.pagination.activeLink > 0) {
                this.pagination.activeLink--;
                this.$emit('setActivePagePagination', this.pagination.activeLink);
            }
        },
        /* end paginations */
    }
}
</script>

<style scoped>
dataTables_info{
    display: inline-block;
    vertical-align: middle;
}

.dataTables_info{
    margin-left: 3px;
    margin-right: 20px;
}

.dataTables_info, .selector{
    display: inline-block;
    vertical-align: middle!important;
}

div.selector, div.selector span, div.selector select{
    line-height: 25px;
    height: 25px;
}

.dataTables_paginate{
    float: right;
    margin-right: 11px;
}

ul.pagination{
    margin-bottom: 0px;
}

.pagination > li > a{
    padding: 2px 12px;
}
</style>
