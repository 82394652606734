<template>
    <transition name="bounce" mode="out-in">
        <div class="form-group" v-if="needBackground">
            <label class="col-sm-3 control-label" :title="backgroundAlt">{{ getLang.app.backgroundColor }}: <i class="glyph-icon font-gray icon-question" :title="backgroundAlt" data-original-title=".icon-question"></i></label>
            <div class="col-sm-1">
                <div style="height: 34px; border: 1px solid #ddd; border-radius: 3px;" :style="'background-color:' + getBackground">&nbsp;</div>
            </div>
            <div class="col-sm-8" @click.stop="''">
                <input class="form-control" 
                        :placeholder="getLang.app.backgroundColor+'...'" 
                        type="text" 
                        v-model="background.value.hex" 
                        @focus="check = true"
                        >
                <transition name="bounce" mode="out-in">
                    <sketch-picker v-model="background.value" v-show="check"/>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
import Lang from '../lang/message';
import { Sketch } from 'vue-color';

export default {
    props: {
        needBackground: Boolean,
        backgroundAlt: {
            type: String,
            default: 'background default'
        },
        backgroundValue: String
    },
    data(){
        return {
            lang: Lang,

            check: false,
            background: {
                value: {
                    hex: this.backgroundValue,
                    hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
                    hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
                    rgba: { r: 25, g: 77, b: 51, a: 1 },
                    a: 1
                },
            },
        }
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        // colors
        getBackground(){
            if(this.needBackground && (this.background.value.hex == '')) this.background.value.hex = '#000000';
            if(!this.needBackground) this.background.value.hex = '';
            
            this.$emit('setBackground', this.background.value.hex);

            return this.background.value.hex;
        }
    },
    watch: {
        backgroundValue(){
            this.background.value.hex = this.backgroundValue;
        }
    },
    mounted(){
        let _this = this;

        document.addEventListener('click', function(){
            if(_this.check) _this.check = false;
        });
    },
    components: {
        'sketch-picker': Sketch,
    }
}
</script>
