<template>
    <section>
        <div class="panel adunit-list">
            <div class="panel-body">
                <div class="adunit-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-header" id="datatable-adunit">
                            <thead>

                                <tr>
                                    <th class="col_img">
                                        <button class="btn btn-success btn-add" @click.prevent="setAdunitAdvertiserAdd($event, getLang.advertiser.addPopupAdvertiserTitle, '')">
                                            <i class="glyph-icon icon-plus"></i>
                                        </button>
                                    </th>

                                    <th class="col_state">
                                        <div class="table-head-content" style="margin-bottom: 0px;">
                                            <MultiSelectIcons :categories-alt="getLang.exchange.state"
                                                            :categories-title="''"
                                                            :get-category-list="getStateFilterList"
                                                            :is-update-category-list="true"
                                                            :setDefaultCategory="stateFilter"
                                                            :is-default-required="false"
                                                            :count-item="1"
                                                            :placeholder="getLang.exchange.state"
                                                            styleInput="padding-right: 10px; padding-left: 0px;"
                                                            :style-block-select="'margin-bottom: 0px;'"
                                                            :is-disabled-close="true"
                                                            :categoriesStatus="status.stateFilter"
                                                            @setCategoryStatus="val => status.stateFilter = val"
                                                            @setCategory="val => advertiserData.stateFilter.value = val"
                                                            @removeCategory="val => advertiserData.stateFilter.value = val"
                                            >
                                            </MultiSelectIcons>
                                        </div>
                                    </th>

                                    <th class="col_status">
                                        <div class="table-head-content short_text">
                                            {{ getLang.exchange.status }}
                                        </div>
                                    </th>

                                    <th class="col_packName">
                                        <div class="table-head-content">
                                            {{ getLang.exchange.packageName }}
                                        </div>
                                    </th>

                                    <th class="col_budget">
                                        <div class="table-head-content">
                                            {{ getLang.advertiser.totalBudget }}
                                        </div>
                                    </th>

                                    <th class="col_budget">
                                        <div class="table-head-content">
                                            {{ getLang.advertiser.dailyBudget }}
                                        </div>
                                    </th>

                                    <th class="col_budget">
                                        <div class="table-head-content">
                                            {{ getLang.advertiser.dailyBudgetLost }}
                                        </div>
                                    </th>

                                    <th class="col_rating_comment">
                                        <div class="block_select_search">
                                            <div class="col-sm-5" style="padding-left: 0px; padding-right: 0px;">
                                                <MultiSelectIcons categories-alt="Type banner"
                                                            :categories-title="''"
                                                            :get-category-list="getTypeBannerList"
                                                            :is-update-category-list="true"
                                                            :setDefaultCategory="getTypeBanner"
                                                            :is-default-required="false"
                                                            :count-item="1"
                                                            :placeholder="getLang.statistics.adtype"
                                                            styleInput="padding-right: 0px; padding-left: 0px;"
                                                            :style-block-select="'margin-bottom: 0px;'"
                                                            :is-disabled-close="true"
                                                            :categoriesStatus="status.adtype"
                                                            @setCategoryStatus="val => updateStatusAdtype(val)"
                                                            @setCategory="val => setTypeBanner(val)"
                                                            @removeCategory="val => setTypeBanner(val)"
                                                            >
                                                </MultiSelectIcons>
                                            </div>

                                            <div id="datatable_filter" class="dataTables_filter col-sm-6">
                                                <input class="form-control outlight"
                                                        style="padding-right: 30px; z-index: 100;"
                                                        :placeholder="getLang.search.search+'...'"
                                                        aria-controls="datatable-example"
                                                        type="search"
                                                        ref="searchInput"
                                                        @input="searchAdvertiser($event)"
                                                        @blur="searchBlurAdvertiser($event)"
                                                >
                                                <a class="btn search_clear"
                                                    v-if="searchValue.length > 0"
                                                    @click.prevent="searchClear()"
                                                    >
                                                    <i class="glyph-icon icon-remove"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-adunit table-adunit-content" id="datatable-adunit">
                            <tbody id="adunit_list">

                                <tr class="odd gradeX"
                                    v-for="(item, index) in getAdvertiserView"
                                    :key="index"
                                    :style="item.country_cats[0].status === statusList.deleted ? 'opacity: .5' : ''"
                                >
                                    <td class="v-top col_img" style="overflow: visible;">
                                        <div style="overflow: hidden;">
                                            <img :src="item.icon" class="icon" v-if="item.icon"><img :src="item.image" v-if="item.image">
                                            <i class="glyph-icon tooltip-button demo-icon icon-question" v-if="!item.icon && !item.image"></i>
                                        </div>
                                    </td>

                                    <td class="v-bottom col_state">
                                        <div class="short_text">
                                            <span class="bs-badge badge-success float-center" v-if="item.country_cats[0].state === stateList.confirmed" :title="item.country_cats[0].state + (item.country_cats[0].state_comment.length > 0 ? ' - ' + item.country_cats[0].state_comment : '')">&nbsp;</span>
                                            <span class="bs-badge badge-warning float-center" v-else-if="item.country_cats[0].state === stateList.notConfirmed" :title="item.country_cats[0].state + (item.country_cats[0].state_comment.length > 0 ? ' - ' + item.country_cats[0].state_comment : '')">&nbsp;</span>
                                            <span class="bs-badge badge-new float-center" v-else-if="item.country_cats[0].state === stateList.new" :title="item.country_cats[0].state + (item.country_cats[0].state_comment.length > 0 ? ' - ' + item.country_cats[0].state_comment : '')">&nbsp;</span>
                                            <span class="bs-badge badge-danger float-center" v-else-if="item.country_cats[0].state === stateList.blocked" :title="item.country_cats[0].state + (item.country_cats[0].state_comment.length > 0 ? ' - ' + item.country_cats[0].state_comment : '')">&nbsp;</span>
                                            <span class="bs-badge badge-black float-center" style="opacity: .7;" v-else :title="item.country_cats[0].state + (item.country_cats[0].state_comment.length > 0 ? ' - ' + item.country_cats[0].state_comment : '')">&nbsp;</span>
                                            <a href="#"
                                                @click.prevent="setAdvertiserStatePopup($event, getLang.exchange.state, '/api/advblocks/state/edit', item)"
                                                style="margin-left: 5px;"
                                            >
                                                <i class="glyph-icon tooltip-button icon-pencil"></i>
                                            </a>
                                        </div>
                                    </td>

                                    <td class="v-bottom col_status">
                                        <div class="short_text">
                                            <span class="bs-badge badge-success float-center" v-if="item.country_cats[0].status === statusList.enabled">&nbsp;</span>
                                            <span class="bs-badge badge-warning float-center" v-else-if="item.country_cats[0].status === statusList.disabled">&nbsp;</span>
                                            <span class="bs-badge badge-black float-center" style="opacity: .5;" v-else>&nbsp;</span>
                                        </div>
                                    </td>

                                    <td class="col_packName">
                                        <div class="short_text" :title="item.name" style="font-size: 11px; line-height: 11px;">{{ getShortTitle(item.name) }}</div>
                                        <div :title="item.packageName"><strong>{{ getShortTitle(item.packageName) }}</strong></div>
                                    </td>

                                    <td class="col_budget">
                                        <div class="short_text">{{ item.country_cats[0].totalbudgetlost }} / {{ item.country_cats[0].totalbudget }}</div>
                                    </td>

                                    <td class="col_budget">
                                        <div class="short_text">{{ item.country_cats[0].dailybudget }} ({{ item.country_cats[0].dailybudgetprev }})</div>
                                    </td>

                                    <td class="col_budget">
                                        <div class="short_text">{{ Math.round(item.country_cats[0].dailybudgetlost) }}</div>
                                    </td>

                                    <td class="col_rating_comment">
                                        <button class="btn btn-alt btn-hover btn-default btn-edit"
                                                @click.prevent="setAdunitAdvertiserEdit($event, getLang.advertiser.editPopupAdvertiserTitle, '/api/exblock/edit', item)"
                                                :disabled="item.country_cats[0].status === statusList.deleted"
                                        >
                                            <span>{{ getLang.edit }}</span>
                                            <i class="glyph-icon icon-arrow-right"></i>
                                        </button>

                                        <div class="short_text" style="margin-right: 120px; display: flex;">
                                            <div style="width: 40px; display: table; margin-right: 10px;">
                                                <a href="#" @click.prevent="setAdvertiserRatingPopup($event, getLang.advertiser.rating, '/api/advertiser/rating/edit', item)">
                                                    <i class="glyph-icon tooltip-button icon-pencil"></i>
                                                </a>
                                                {{ item.country_cats[0].rating }}
                                            </div>
                                            <div class="short_text">
                                                <a href="#" @click.prevent="setAdvertiserCommentPopup($event, getLang.advertiser.comment, '/api/advertiser/comment/edit', item)">
                                                    <i class="glyph-icon tooltip-button icon-pencil"></i>
                                                </a>
                                                {{ item.country_cats[0].comment }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.advertiserStart = val"
                                @setEnd="val => pagination.advertiserEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                                >
                    </pagination>
                </div>

                <popupAdunitAdvertiser
                        :url-added="popupAddUrlAdd"
                        url-redirect="advertiser"
                        :title="popupAddTitle"
                        :opened="opened"
                        @setUpdata="val => isAdd = val"
                >
                </popupAdunitAdvertiser>

                <popupAdunitAdvertiserEdit
                        :url-added="popupEditUrlAdd"
                        url-redirect="advertiser"
                        :title="popupEditTitle"
                        :advertiser="getPopupAdvertiser"
                        @setUpdata="val => isUpdate = val"
                        :opened="openedEdit"
                >
                </popupAdunitAdvertiserEdit>

                <popupAdvertiserRating
                        :url-added="popupRatingUrlAdd"
                        url-redirect="advertiser"
                        :title="popupRatingTitle"
                        :exchange="getPopupAdvertiserRating"
                        @setUpdata="val => isUpdateRating = val"
                        :opened="openedRating"
                >
                </popupAdvertiserRating>

                <popupAdvertiserComment
                        :url-added="popupCommentUrlAdd"
                        url-redirect="advertiser"
                        :title="popupCommentTitle"
                        :exchange="getPopupAdvertiserComment"
                        @setUpdata="val => isUpdateComment = val"
                        :opened="openedComment"
                >
                </popupAdvertiserComment>

                <popupAdvertiserState
                        :url-added="popupStateUrlAdd"
                        url-redirect="advertiser"
                        :title="popupStateTitle"
                        :exchange="getPopupAdvertiserState"
                        @setUpdata="val => isUpdateState = val"
                        :opened="openedState"
                >
                </popupAdvertiserState>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import PopupAdunitAdvertiser from '../plugins/PopupAdunitAdvertiser';
import PopupAdunitAdvertiserEdit from '../plugins/PopupAdunitAdvertiserEdit';
import PopupAdvertiserRating from '../plugins/PopupAdvertiserRating';
import PopupAdvertiserComment from '../plugins/PopupAdvertiserComment';
import PopupAdvertiserState from '../plugins/PopupAdvertiserState';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            pageId: 22,

            advertiserList: [],
            advertiserView: [],
            advertiserListForSearch: [],

            lang: Lang,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 75,

            logos: null,
            statesLogos: null,

            osActive: 0,

            searchValue: '',

            advertiserData: {
                osDev: {
                    value: []
                },
                typeBanner: {
                    value: [0]
                },
                stateFilter: {
                    value: []
                }
            },

            stateList: {
                new: 'New',
                notConfirmed: 'NotConfirmed',
                confirmed: 'Confirmed',
                blocked: 'Blocked',
                changed: 'Changed'
            },
            stateFilterList: [
                {id: 'New', name: 'New'},
                {id: 'NotConfirmed', name: 'Reject'},
                {id: 'Confirmed', name: 'Confirmed'},
                {id: 'Blocked', name: 'Blocked'},
                {id: 'Changed', name: 'Changed'},
            ],
            stateFilter: [],

            statusList: {
                enabled: 'Enabled',
                disabled: 'Disabled',
                deleted: 'Deleted'
            },

            categoryList: [],
            typeBannerList: [],
            adunitList: [],

            osDev: [],

            isUpdate: false,
            isUpdateRating: false,
            isUpdateComment: false,
            isUpdateState: false,
            isAdd: false,
            opened: null,
            openedState: null,
            openedRating: null,
            openedComment: null,
            openedEdit: null,

            status: {
                os: false,
                adtype: false,
                stateFilter: false
            },

            // popup
            popupAdvertiser: {},
            popupCountry: [],
            popupEditUrlAdd: '',
            popupEditTitle: '',
            popupAdvertiserRating: {},
            popupRatingUrlAdd: '',
            popupRatingTitle: '',
            popupAdvertiserComment: {},
            popupCommentUrlAdd: '',
            popupCommentTitle: '',

            popupAdvertiserState: {},
            popupStateUrlAdd: '',
            popupStateTitle: '',

            popupAddUrlAdd: '',
            popupAddTitle: '',
            popupAdunitGroup: {
                value: [],
                alt: ''
            },

            // pagination
            pagination: {
                advertiserStart: 0,
                advertiserEnd: 0,
                countLength: 0,
                countList: [10,15,25,50,100],
                activeCount: 1,
                activePage: 0
            }
        }
    },
    watch: {
        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationExchangeAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationExchangePageAction', parseInt(this.pagination.activePage));
        },

        isUpdate(){
            if(this.isUpdate) this.getAdunitListDB();

            this.isUpdate = false;
        },

        isUpdateRating(){
            if(this.isUpdateRating) this.getAdunitListDB();

            this.isUpdateRating = false;
        },

        isUpdateComment(){
            if(this.isUpdateComment) this.getAdunitListDB();

            this.isUpdateComment = false;
        },

        isUpdateState(){
            if(this.isUpdateState) this.getAdunitListDB();

            this.isUpdateState = false;
        },

        isAdd(){
            if(this.isAdd) this.getAdunitListDB();

            this.isAdd = false;
        },
    },
    created(){
        if (this.getRole != null && this.getRole.toLowerCase() != this.$store.getters.getRoleSuperAdmin && this.getRole.toLowerCase() != this.$store.getters.getRoleAdmin && this.getRole.toLowerCase() != this.$store.getters.getRoleModerator) this.$router.push({ name: 'comingSoon' });

        this.pagination.activeCount = this.$store.getters.getRemeberPaginationExchange;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationExchangePage;

        let isVisibleP = this.getPageVisiblePromise(this.pageId);

        isVisibleP.then(res => {
            if (res) {
                this.getAdunitListDB();
            } else {
                this.$router.push({name: '404'});
            }
        });
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        getOsDev(){
            this.osDev = this.$store.getters.getOsDev;

            return this.osDev;
        },

        getOsActive(){
            this.osActive = this.$store.getters.getOsActive;

            return this.osActive;
        },

        getPopupAdvertiser(){
            return this.popupAdvertiser;
        },

        getPopupAdvertiserRating(){
            return this.popupAdvertiserRating;
        },

        getPopupAdvertiserComment(){
            return this.popupAdvertiserComment;
        },

        getPopupAdvertiserState(){
            return this.popupAdvertiserState;
        },

        // category lists
        getCategoryList(){
            this.categoryList = this.$store.getters.getCategoryList;

            return this.categoryList;
        },

        /* advertiser */
        getAdvertiserList(){
            let _this = this;
            let res = [];

            res = this.advertiserList.filter(val => {
                let fl = true;

                if((_this.advertiserData.typeBanner.value.length > 0) && (_this.advertiserData.typeBanner.value.indexOf(val.type) == -1)) fl = false;

                return fl;
            });

            this.pagination.countLength = res.length; // paginations

            return res;
        },
        getAdvertiserView(){
            this.advertiserView = this.getAdvertiserList.slice(this.pagination.advertiserStart, this.pagination.advertiserEnd);

            this.advertiserView = this.advertiserView.filter(val => {
                let res = true;

                res = val.country_cats[0].osid == this.getOsActive;
                if (this.advertiserData.stateFilter.value.length > 0 && res) res = val.country_cats[0].state === this.advertiserData.stateFilter.value[0];
                return res;
            });

            return this.advertiserView;
        },
        /* end advertiser */

        // get type banner list
        getTypeBannerList(){
            if (this.typeBannerList.length == 0) {
                this.typeBannerList = [...this.$store.getters.getTypeBannerList];

                this.typeBannerList.forEach((val, index) => {
                    this.typeBannerList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getLogos[val.name]};
                });
            }

            return this.typeBannerList;
        },

        getStateFilterList(){
            this.stateFilterList.forEach((val, index) => {
                this.stateFilterList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getStateLogos[val.name]};
            });

            return this.stateFilterList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons;

            return this.logos;
        },

        getStateLogos(){
            this.statesLogos = this.$store.state.logos.statesIcons;

            return this.statesLogos;
        },

        getTypeBanner(){
            this.advertiserData.typeBanner.value = this.$store.getters.getRemeberExchangeTypeBanner;

            return this.advertiserData.typeBanner.value;
        },
    },
    methods: {
        // set height for table list advertiser
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        clearStatus(){
            this.status.adtype = false;
            this.status.os = false;
        },

        getShortTitle(title){
            if(title == undefined) return '';

            if (title.length >= 40) {
                title = "..." + title.slice(-40);
            }

            return title;
        },

        getShowPerDay(totalbudget, dailybudget){
            let res = (totalbudget / dailybudget);

            if(isNaN(res)) return 0;

            if(res % 1 === 0) return res;
            else return res.toFixed(1);
        },

        getCategoryNames(countryCats){
            let title = '', split = ' ';

            if (countryCats[0].country == '-') {
                this.$store.getters.getCountryList.forEach(item => title += item.code + split);

                return title;
            }

            countryCats.forEach((item) => title += item.country + split);

            return title;
        },

        /*--- update status ---*/
        updateStatusOs(val){
            this.clearStatus();

            this.status.os = val;
        },

        updateStatusAdtype(val){
            this.clearStatus();

            this.status.adtype = val;
        },
        /*--- end update status ---*/

        getCategoryName(id){
            let name = this.getCategoryList.find(val => val.id == id);

            if(name != undefined && name.name != undefined) return name.name;
            return name;
        },

        // get name banner by id
        getTypeBannerName(inx){
            let item = '';

            if(this.getTypeBannerList.length) {
                item = this.getTypeBannerList.find(x => x.id === inx);
                if(item != undefined && item.name != undefined) item = item.name;
            }

            return item;
        },

        /* search by table data */
        searchAdvertiser(e){
            let _this = this;
            let res;

            if(!this.advertiserListForSearch.length) this.advertiserListForSearch = this.advertiserList;

            res = this.advertiserListForSearch.filter(function(item){
                let isPackageName = false;
                let isName = false;
                _this.searchValue = e.target.value || '';

                if(item.packageName != null) isPackageName = (item.packageName.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);
                if(item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isPackageName || isName;
            });

            this.advertiserList = res;
            this.pagination.countLength = this.advertiserList.length; // paginations
        },
        searchBlurAdvertiser(e){
            if(e.target.value == '' && this.advertiserListForSearch.length > 0) {
                this.advertiserListForSearch = [];
            }
        },
        /* end search table data */

        searchClear(){
            this.$refs.searchInput.value = '';
            this.searchAdvertiser({target: {value: ''}});
        },

        // get list Adunit
        getAdunitListDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adunitList = response.data.Adblock.map(function(item){
                    return {title: item.apptitle, name: item.name, id: item.id, type: item.type, icon: item.icon, image: item.image, state: item.state};
                });

                return this.getAdvertiserListDB();
            })
            .then(response => {
                return response.data.adv_blocks;
            })
            .then(response => {
                if (response) {
                    let _this = this;

                    this.advertiserList = response.map(function(item){
                        let adunit = _this.adunitList.find(val => val.id == item.adblock);

                        item.title = adunit.title;
                        if(adunit.name) item.name = adunit.name;

                        item.packageName = adunit.title;
                        if(adunit.name) item.name = adunit.name;
                        item.type = adunit.type;
                        item.icon = adunit.icon;
                        item.image = adunit.image;
                        item.image_landscape = adunit.image_landscape;
                        item.image_portrait = adunit.image_portrait;

                        return item;
                    });

                    this.advertiserView = this.advertiserList;
                    this.pagination.countLength = this.advertiserList.length; // paginations
                }
                else {
                    reject('not done!');
                }
            })
            .catch(e => {
                this.errorsMessage(e, "error list adblock");
            });
        },

        // get and set list Advertiser
        getAdvertiserListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/advblocks/my?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        },

        // add id Advertiser for edit
        setAdvertiserEdit(e, id){
            this.$store.commit('setExchangeEditId', id);
            this.$router.push({name: 'exchangeEdit'});
        },

        // set type banner filter
        setTypeBanner(val){
            this.$store.dispatch('setRemeberExchangeTypeBannerAction', val);
            this.advertiserData.typeBanner.value = val;
        },

        setAdunitAdvertiserAdd(e, title, urlAdd){
            this.popupAddTitle = title;
            this.popupAddUrlAdd = urlAdd;

            this.opened = this.getUniqueId();

            this.$store.commit('modals/setPopupDelete', true);
        },

        setAdunitAdvertiserEdit(e, title, urlAdd, item){
            this.popupEditTitle = title;
            this.popupEditUrlAdd = urlAdd;
            this.popupAdvertiser = item;
            this.popupCountry = [];

            for(let i = 0; i < item.country_cats.length; i++){
                this.popupCountry[i] = item.country_cats[i].country;
            }

            this.openedEdit = this.getUniqueId();

            this.$store.commit('modals/setPopupEdit', true);
        },

        setAdvertiserRatingPopup(e, title, urlAdd, item){
            this.popupRatingTitle = title;
            this.popupRatingUrlAdd = urlAdd;
            this.popupAdvertiserRating = item;

            this.openedRating = this.getUniqueId();

            this.$store.commit('modals/setPopupAdvertiserRating', true);
        },

        setAdvertiserCommentPopup(e, title, urlAdd, item){
            this.popupCommentTitle = title;
            this.popupCommentUrlAdd = urlAdd;
            this.popupAdvertiserComment = item;

            this.openedComment = this.getUniqueId();

            this.$store.commit('modals/setPopupAdvertiserComment', true);
        },

        setAdvertiserStatePopup(e, title, urlAdd, item){
            this.popupStateTitle = title;
            this.popupStateUrlAdd = urlAdd;
            this.popupAdvertiserState = item;

            this.openedState = this.getUniqueId();

            this.$store.commit('modals/setPopupAdvertiserState', true);
        }
    },
    components: {
        Pagination,
        MultiSelectIcons,
        PopupAdunitAdvertiser,
        PopupAdunitAdvertiserEdit,
        PopupAdvertiserRating,
        PopupAdvertiserComment,
        PopupAdvertiserState,
        MultiSelect
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    .block_select_search{
        width: 335px;
        position: absolute;
        top: 6px;
        right: 13px;
    }

    /*---*/
    .col_img{
        width: calc(180px);
        width: 17%;
    }

        .col_img .icon-question{
            height: 44px;
            width: 44px;
            margin: 0 10px 0 0;
        }

    .col_status, .col_state{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .col_status{
        width: 6%;
    }

    .col_state{
        width: 12%;
    }

    th.col_packName, td.col_packName{
        width: 14%;
        padding-left: 0px!important;
    }

    .col_budget{
        width: 10%;
        position: relative;
    }

    .col_rating_comment{
        width: 21%;
        position: relative;
    }

    td.col_rating_comment, td.col_packName, td.col_budget{
        vertical-align: middle!important;
    }

    .col_amount_times{
        width: 10%;
    }

    .col_country_count{
        width: 7%;
    }

    .col_cat1{
        width: 11%;
    }

    .col_cat2{
        width: 11%;
    }

    .col_cat3{
        width: 11%;
    }

    .row_disabled td{
        border-top-width: 0px;
        color: #beb4bb;
    }

    .row_disabled .col_status, .row_disabled .col_state, .row_disabled .col_action, .row_disabled .col_img{
        opacity: .5;
    }

    .bs-badge{
        cursor: default;
    }

    .badge-new{
        border-color: yellow;
        background-color: yellow;
    }

    /* Custom */
    @media only screen and (max-width: 1200px) {
        .col_packName{
            width: 19%;
        }

        .col_cat1{
            width: 10%;
        }

        .col_cat2{
            width: 10%;
        }

        .col_cat3{
            width: 10%;
        }
    }
    /* end sizes */

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .adunit-list{
        border: 0px none;
    }

        .adunit-list .panel-body{
            padding: 0px;
        }

    .adunit-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 170px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 170px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 170px;
        }

    .dataTables_info, .selector{
        display: inline-block;
        vertical-align: middle!important;
    }
    .dataTables_info{
        margin-left: 3px;
        margin-right: 20px;
    }

    .dataTables_paginate{
        float: right;
        margin-right: 11px;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        padding: 0px;
    }

    .btn-add{
        width: 144px;
    }

    .btn-edit{
        width: 100px;
        margin-right: 8px;
        position: absolute;
        top: 3px;
        right: 13px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-adunit-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-adunit-header th{
            padding: 6px 13px 5px;
            border-bottom-width: 1px;
            height: 55px;
            vertical-align: bottom;
            word-break: normal;
        }

            .table-adunit-header th input{
                width: 170px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-adunit-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-adunit-content tr:first-child td{
        border-top: 0px none;
    }

    .table-adunit-content td.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-adunit-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-adunit-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .z_index100{
        position: relative;
        z-index: 100;
    }

    .search_clear{
        position: absolute;
        right: 10px;
        z-index: 100;
        top: 12px;
        font-size: 18px;
        line-height: 18px;
        height: 18px;
        width: auto;
        min-width: auto;
        padding: 0px;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
        }

    /* animation */

    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter-active{
        transition: all .3s;
    }

    .width-leave-active{
        transition: all .3s reverse;
    }

</style>
