<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
    >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    @click="setPopupAddHide()"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
        >
            <div class="modal-dialog" @click.stop="clearStatus()">
                <div class="modal-content">
                    <div class="modal-header" ref="modalHeader">
                        <div style="display: flex;">
                            <button type="button"
                                    class="close float-left"
                                    id="close_modal"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    @click.prevent="setPopupAddHide()"
                            >
                                ×
                            </button>
                            <h4 class="modal-title nowrap" style="font-size: 13px; color: #AFAFAF; position: relative; left: 28px; top: -8px;">
                                {{ title }} - <strong>{{ getAccountEmail }}</strong>:&nbsp;<a href="#" @click.prevent="copyData(getAccountEmail)"><i class="glyph-icon tooltip-button icon-copy"></i></a>
                            </h4>
                        </div>

                        <div class="filter">
                            <div class="filter__add row">
                                <button type="button"
                                        :class="'btn-danger'"
                                        class="btn btn-add"
                                        @click.prevent="setPopupAddHide()"
                                        v-if="isNotChangedAll"
                                >
                                    <i class="glyph-icon icon-close"></i>
                                </button>
                                <button type="button"
                                        :class="'btn-success'"
                                        class="btn btn-add"
                                        @click.prevent="addItem()"
                                        v-else
                                >
                                    <i class="glyph-icon icon-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div
                                    class="w100px pt10"
                                    style="border-right: 1px dashed rgb(223, 232, 241);"
                                    v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin.toLowerCase() ||
                                        getRole.toLowerCase() === $store.getters.getRoleAdmin.toLowerCase()"
                                >
                                    <div class="h30" v-if="isNotSuperadminRole"><h2>{{ getLang.users.roles }}</h2></div>
                                    <div style="margin-bottom: 10px;" v-if="isNotSuperadminRole">
                                        <MultiSelectIcons categories-alt="category roles list"
                                                    :categories-title="''"
                                                    :get-category-list="getUserRoleList"
                                                    :categoryIdOut="rolesOutData"
                                                    :is-update-category-list="true"
                                                    :opened="opened"
                                                    :setDefaultCategory="getUserRole"
                                                    :is-default-required="false"
                                                    :count-item="1"
                                                    styleInput="padding-right: 0px; padding-left: 0px;"
                                                    :style-block-select="'margin-bottom: 0px;'"
                                                    :is-disabled-close="true"
                                                    :categoriesStatus="status.roleFilter"
                                                    @setCategoryStatus="val => status.roleFilter = val"
                                                    @setCategory="val => userRole = val"
                                                    @removeCategory="val => userRole = val"
                                        >
                                        </MultiSelectIcons>
                                    </div>
                                    <div v-if="isNotSuperadminRole">
                                        <MultiSelectIcons categories-alt="category state list"
                                                    :categories-title="''"
                                                    :get-category-list="getUserStatesList"
                                                    :is-update-category-list="true"
                                                    :opened="opened"
                                                    :setDefaultCategory="getUserState"
                                                    :is-default-required="false"
                                                    :count-item="1"
                                                    styleInput="padding-right: 0px; padding-left: 0px;"
                                                    :style-block-select="'margin-bottom: 0px;'"
                                                    :is-disabled-close="true"
                                                    :categoriesStatus="status.stateFilter"
                                                    @setCategoryStatus="val => status.stateFilter = val"
                                                    @setCategory="val => userState = val"
                                                    @removeCategory="val => userState = val"
                                        >
                                        </MultiSelectIcons>
                                    </div>
                                    <div style="margin-bottom: 10px;">
                                        <div class="checkbox checkbox-primary">
                                            <label :style="adminsChat.length < 1 ? 'cursor: text;' : ''">
                                                <div class="checker" id="uniform-inlineCheckbox110">
                                                    <span :class="userIsChat ? 'checked' : ''">
                                                        <input type="checkbox" id="inlineCheckbox110" @click.prevent="() => userIsChat = !userIsChat" :disabled="adminsChat.length < 1" class="custom-checkbox" />
                                                        <i class="glyph-icon icon-check"></i>
                                                    </span>
                                                </div>
                                                <h3>{{ getLang.account.isChat }}</h3>
                                                <div class="alert alert-warning" v-if="adminsChat.length < 1">{{ getLang.account.lastChatAdmin }}</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="w100px pt10"
                                    style="border-right: 1px dashed rgb(223, 232, 241);"
                                    v-else
                                >&nbsp;</div>

                                <div class="w50 pt10 pl10" style="border-right: 1px dashed rgb(223, 232, 241);"
                                    v-if="isNotSuperadminRole"
                                >
                                    <div class="h30">
                                        <div class="title_category_page"><h2>Dash</h2></div>
                                        <div class="title_moder">{{ getLang.visiblePage }}</div>
                                        <div class="title_moder">{{ getLang.editPage }}</div>
                                        <div class="title_moder">{{ getLang.addPage }}</div>
                                        <div class="title_moder">{{ getLang.deletePage }}</div>
                                        <div class="title_page">{{ getLang.titlePage }}</div>
                                    </div>

                                    <div class="scroll" :style="'max-height: ' + pagesListHeight + 'px;'">
                                        <div class="roles_label">
                                            <div class="checkbox checkbox-primary">
                                                <label>
                                                    <div class="checker" id="uniform-inlineCheckbox110">
                                                        <span :class="labels.reports ? 'checked' : ''">
                                                            <input type="checkbox" id="inlineCheckbox110" @click.prevent="setReportsFlag()" class="custom-checkbox" />
                                                            <i class="glyph-icon icon-check"></i>
                                                        </span>
                                                    </div>
                                                    <h3>{{ getLang.menu.reports }}</h3>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.online.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.online.visible)"
                                                    @update="val => {accountsRolesData.online.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.onlinePage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.online.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.online.editPage)"
                                                @update="val => {accountsRolesData.online.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.online.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.online.addPage)"
                                                @update="val => {accountsRolesData.online.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.online.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.online.deletePage)"
                                                @update="val => {accountsRolesData.online.deletePage = val; setGroupPageFlag('online')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applicationsReports.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReports.visible)"
                                                    @update="val => {accountsRolesData.applicationsReports.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.applicationPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.applicationsReports.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReports.editPage)"
                                                @update="val => {accountsRolesData.applicationsReports.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.applicationsReports.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReports.addPage)"
                                                @update="val => {accountsRolesData.applicationsReports.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.applicationsReports.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReports.deletePage)"
                                                @update="val => {accountsRolesData.applicationsReports.deletePage = val; setGroupPageFlag('applicationsReports')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.adunitsReports.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.adunitsReports.visible)"
                                                    @update="val => {accountsRolesData.adunitsReports.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.adUnitPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.adunitsReports.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.adunitsReports.editPage)"
                                                @update="val => {accountsRolesData.adunitsReports.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.adunitsReports.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.adunitsReports.addPage)"
                                                @update="val => {accountsRolesData.adunitsReports.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.adunitsReports.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.adunitsReports.deletePage)"
                                                @update="val => {accountsRolesData.adunitsReports.deletePage = val; setGroupPageFlag('adunitsReports')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.installs.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.installs.visible)"
                                                    @update="val => {accountsRolesData.installs.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.installsPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.installs.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.installs.editPage)"
                                                @update="val => {accountsRolesData.installs.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.installs.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.installs.addPage)"
                                                @update="val => {accountsRolesData.installs.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.installs.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.installs.deletePage)"
                                                @update="val => {accountsRolesData.installs.deletePage = val; setGroupPageFlag('installs')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.retention.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.retention.visible)"
                                                    @update="val => {accountsRolesData.retention.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.cagortsPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.retention.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.retention.editPage)"
                                                @update="val => {accountsRolesData.retention.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.retention.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.retention.addPage)"
                                                @update="val => {accountsRolesData.retention.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.retention.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.retention.deletePage)"
                                                @update="val => {accountsRolesData.retention.deletePage = val; setGroupPageFlag('retention')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersGraphics.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersGraphics.visible)"
                                                    @update="val => {accountsRolesData.usersGraphics.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.usersGraphicsPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.usersGraphics.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.usersGraphics.editPage)"
                                                @update="val => {accountsRolesData.usersGraphics.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.usersGraphics.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.usersGraphics.addPage)"
                                                @update="val => {accountsRolesData.usersGraphics.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.usersGraphics.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.usersGraphics.deletePage)"
                                                @update="val => {accountsRolesData.usersGraphics.deletePage = val; setGroupPageFlag('usersGraphics')}"
                                            /> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.graphics.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.graphics.visible)"
                                                    @update="val => {accountsRolesData.graphics.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.graphicsPage }}</h4>
                                            </div>
                                            <!-- <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.graphics.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.graphics.editPage)"
                                                @update="val => {accountsRolesData.graphics.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.graphics.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.graphics.addPage)"
                                                @update="val => {accountsRolesData.graphics.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.graphics.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.graphics.deletePage)"
                                                @update="val => {accountsRolesData.graphics.deletePage = val; setGroupPageFlag('graphics')}"
                                            /> -->
                                        </div>

                                        <div class="roles_label">
                                            <div class="checkbox checkbox-primary">
                                                <label class="short_text">
                                                    <div class="checker" id="uniform-inlineCheckbox110">
                                                        <span :class="labels.inventory ? 'checked' : ''">
                                                            <input type="checkbox" id="inlineCheckbox110" @click.prevent="setInventoryFlag()" class="custom-checkbox" />
                                                            <i class="glyph-icon icon-check"></i>
                                                        </span>
                                                    </div>
                                                    <h3>{{ getLang.menu.inventory }}</h3>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applications.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applications.visible)"
                                                    @update="val => {accountsRolesData.applications.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applications.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applications.editPage)"
                                                    @update="val => {accountsRolesData.applications.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applications.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applications.addPage)"
                                                    @update="val => {accountsRolesData.applications.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applications.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applications.deletePage)"
                                                    @update="val => {accountsRolesData.applications.deletePage = val; setGroupPageFlag('applications')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.applicationPage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.adunits.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.adunits.visible)"
                                                    @update="val => {accountsRolesData.adunits.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.adunits.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.adunits.editPage)"
                                                    @update="val => {accountsRolesData.adunits.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.adunits.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.adunits.addPage)"
                                                    @update="val => {accountsRolesData.adunits.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.adunits.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.adunits.deletePage)"
                                                    @update="val => {accountsRolesData.adunits.deletePage = val; setGroupPageFlag('adunits')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.adUnitPage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.rules.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.rules.visible)"
                                                    @update="val => {accountsRolesData.rules.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.rules.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.rules.editPage)"
                                                    @update="val => {accountsRolesData.rules.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.rules.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.rules.addPage)"
                                                    @update="val => {accountsRolesData.rules.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.rules.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.rules.deletePage)"
                                                    @update="val => {accountsRolesData.rules.deletePage = val; setGroupPageFlag('rules')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.rulesPage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.scenarios.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.scenarios.visible)"
                                                    @update="val => {accountsRolesData.scenarios.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.scenarios.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.scenarios.editPage)"
                                                    @update="val => {accountsRolesData.scenarios.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.scenarios.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.scenarios.addPage)"
                                                    @update="val => {accountsRolesData.scenarios.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.scenarios.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.scenarios.deletePage)"
                                                    @update="val => {accountsRolesData.scenarios.deletePage = val; setGroupPageFlag('scenarios')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.scenarioPage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchange.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchange.visible)"
                                                    @update="val => {accountsRolesData.exchange.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchange.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchange.editPage)"
                                                    @update="val => {accountsRolesData.exchange.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchange.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchange.addPage)"
                                                    @update="val => {accountsRolesData.exchange.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchange.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchange.deletePage)"
                                                    @update="val => {accountsRolesData.exchange.deletePage = val; setGroupPageFlag('exchange')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.exchangePage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiser.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiser.visible)"
                                                    @update="val => {accountsRolesData.advertiser.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiser.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiser.editPage)"
                                                    @update="val => {accountsRolesData.advertiser.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiser.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiser.addPage)"
                                                    @update="val => {accountsRolesData.advertiser.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiser.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiser.deletePage)"
                                                    @update="val => {accountsRolesData.advertiser.deletePage = val; setGroupPageFlag('advertiser')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.advertiserPage }}</h4>
                                            </div>
                                        </div>

                                        <div v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin.toLowerCase() ||
                                                    getRole.toLowerCase() === $store.getters.getRoleAdmin.toLowerCase()"
                                        >
                                            <div class="roles_label">
                                                <div class="checkbox checkbox-primary">
                                                    <label class="short_text">
                                                        <div class="checker" id="uniform-inlineCheckbox110">
                                                            <span :class="labels.userSettingsDash ? 'checked' : ''">
                                                                <input type="checkbox" id="inlineCheckbox110" @click.prevent="setUserSettingsDash()" class="custom-checkbox" />
                                                                <i class="glyph-icon icon-check"></i>
                                                            </span>
                                                        </div>
                                                        <h3>{{ getLang.menu.userSettings }}</h3>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoDash.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoDash.visible)"
                                                        @update="val => {accountsRolesData.userInfoDash.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoDash.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoDash.editPage)"
                                                        @update="val => {accountsRolesData.userInfoDash.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoDash.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoDash.addPage)"
                                                        @update="val => {accountsRolesData.userInfoDash.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoDash.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoDash.deletePage)"
                                                        @update="val => {accountsRolesData.userInfoDash.deletePage = val; setGroupPageFlag('userInfoDash')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.userInfo }}</h4>
                                                </div>
                                            </div>

                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPayDash.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPayDash.visible)"
                                                        @update="val => {accountsRolesData.cardPayDash.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPayDash.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPayDash.editPage)"
                                                        @update="val => {accountsRolesData.cardPayDash.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPayDash.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPayDash.addPage)"
                                                        @update="val => {accountsRolesData.cardPayDash.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPayDash.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPayDash.deletePage)"
                                                        @update="val => {accountsRolesData.cardPayDash.deletePage = val; setGroupPageFlag('cardPayDash')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.cardPay }}</h4>
                                                </div>
                                            </div>

                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorDash.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorDash.visible)"
                                                        @update="val => {accountsRolesData.reportErrorDash.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorDash.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorDash.editPage)"
                                                        @update="val => {accountsRolesData.reportErrorDash.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorDash.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorDash.addPage)"
                                                        @update="val => {accountsRolesData.reportErrorDash.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorDash.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorDash.deletePage)"
                                                        @update="val => {accountsRolesData.reportErrorDash.deletePage = val; setGroupPageFlag('reportErrorDash')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.reportAnError }}</h4>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="roles_label">
                                            <div class="checkbox checkbox-primary">
                                                <label class="short_text">
                                                    <div class="checker" id="uniform-inlineCheckbox110">
                                                        <span :class="labels.admin ? 'checked' : ''">
                                                            <input type="checkbox" id="inlineCheckbox110" @click.prevent="setAdminFlag()" class="custom-checkbox" />
                                                            <i class="glyph-icon icon-check"></i>
                                                        </span>
                                                    </div>
                                                    <h3>{{ getLang.menu.admin }}</h3>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <InputRoles
                                                :labelPage="getLang.visiblePage"
                                                :dataKeyPage="Boolean(accountsRolesData.relTypeNetwork.visible)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.relTypeNetwork.visible)"
                                                @update="val => {accountsRolesData.relTypeNetwork.visible = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.relTypeNetwork.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.relTypeNetwork.editPage)"
                                                @update="val => {accountsRolesData.relTypeNetwork.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.relTypeNetwork.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.relTypeNetwork.addPage)"
                                                @update="val => {accountsRolesData.relTypeNetwork.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.relTypeNetwork.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.relTypeNetwork.deletePage)"
                                                @update="val => {accountsRolesData.relTypeNetwork.deletePage = val; setGroupPageFlag('relTypeNetwork')}"
                                            />
                                            <h4>{{ getLang.menu.relationTypeNetworkPage }}</h4>
                                        </div>
                                        <div class="roles_item">
                                            <InputRoles
                                                :labelPage="getLang.visiblePage"
                                                :dataKeyPage="Boolean(accountsRolesData.resetTheAccount.visible)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.resetTheAccount.visible)"
                                                @update="val => {accountsRolesData.resetTheAccount.visible = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.resetTheAccount.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.resetTheAccount.editPage)"
                                                @update="val => {accountsRolesData.resetTheAccount.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.resetTheAccount.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.resetTheAccount.addPage)"
                                                @update="val => {accountsRolesData.resetTheAccount.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.resetTheAccount.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.resetTheAccount.deletePage)"
                                                @update="val => {accountsRolesData.resetTheAccount.deletePage = val; setGroupPageFlag('resetTheAccount')}"
                                            />
                                            <h4>{{ getLang.menu.resetAccountPage }}</h4>
                                        </div>
                                        <div class="roles_item">
                                            <InputRoles
                                                :labelPage="getLang.visiblePage"
                                                :dataKeyPage="Boolean(accountsRolesData.moderator.visible)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.moderator.visible)"
                                                @update="val => {accountsRolesData.moderator.visible = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.editPage"
                                                :dataKeyPage="Boolean(accountsRolesData.moderator.editPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.moderator.editPage)"
                                                @update="val => {accountsRolesData.moderator.editPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.addPage"
                                                :dataKeyPage="Boolean(accountsRolesData.moderator.addPage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.moderator.addPage)"
                                                @update="val => {accountsRolesData.moderator.addPage = val}"
                                            />
                                            <InputRoles
                                                :labelPage="getLang.deletePage"
                                                :dataKeyPage="Boolean(accountsRolesData.moderator.deletePage)"
                                                :defaultKeyPage="Boolean(accountsRolesDefault.moderator.deletePage)"
                                                @update="val => {accountsRolesData.moderator.deletePage = val; setGroupPageFlag('moderator')}"
                                            />
                                            <h4>{{ getLang.menu.moderatorPage }}</h4>
                                        </div> -->
                                    </div>
                                </div>

                                <div class="form_adunit w50 pt10 pl10" v-if="isNotSuperadminRole && accRole !== rolesOutList[0]">
                                    <div class="h30" style="margin-right: 0px;">
                                        <div class="title_category_page"><h2>So</h2></div>
                                        <div class="title_moder">{{ getLang.visiblePage }}</div>
                                        <div class="title_moder">{{ getLang.editPage }}</div>
                                        <div class="title_moder">{{ getLang.addPage }}</div>
                                        <div class="title_moder">{{ getLang.deletePage }}</div>
                                        <div class="title_page">{{ getLang.titlePage }}</div>
                                    </div>

                                    <div class="scroll" :style="'max-height: ' + pagesListHeight + 'px; margin-right: 0px;'">
                                        <div class="roles_label">
                                            <div class="checkbox checkbox-primary">
                                                <label>
                                                    <div class="checker" id="uniform-inlineCheckbox110">
                                                        <span :class="labels.reportsSo ? 'checked' : ''">
                                                            <input type="checkbox" id="inlineCheckbox110" @click.prevent="setReportsSoFlag()" class="custom-checkbox" />
                                                            <i class="glyph-icon icon-check"></i>
                                                        </span>
                                                    </div>
                                                    <h3>{{ getLang.menu.reports }}</h3>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.onlineReports.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.onlineReports.visible)"
                                                    @update="val => {accountsRolesData.onlineReports.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.onlinePage }}</h4>
                                            </div>
                                            <!-- <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.onlineReports.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.onlineReports.editPage)"
                                                    @update="val => {accountsRolesData.onlineReports.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.onlineReports.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.onlineReports.addPage)"
                                                    @update="val => {accountsRolesData.onlineReports.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.onlineReports.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.onlineReports.deletePage)"
                                                    @update="val => {accountsRolesData.onlineReports.deletePage = val; setGroupPageFlag('onlineReports')}"
                                                />
                                            </div> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersReports.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersReports.visible)"
                                                    @update="val => {accountsRolesData.usersReports.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.usersPage }}</h4>
                                            </div>
                                            <!-- <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersReports.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersReports.editPage)"
                                                    @update="val => {accountsRolesData.usersReports.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersReports.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersReports.addPage)"
                                                    @update="val => {accountsRolesData.usersReports.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersReports.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersReports.deletePage)"
                                                    @update="val => {accountsRolesData.usersReports.deletePage = val; setGroupPageFlag('usersReports')}"
                                                />
                                            </div> -->
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applicationsReportsSo.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReportsSo.visible)"
                                                    @update="val => {accountsRolesData.applicationsReportsSo.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_moder"></div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.applicationPage }}</h4>
                                            </div>
                                            <!-- <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applicationsReportsSo.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReportsSo.editPage)"
                                                    @update="val => {accountsRolesData.applicationsReportsSo.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applicationsReportsSo.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReportsSo.addPage)"
                                                    @update="val => {accountsRolesData.applicationsReportsSo.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.applicationsReportsSo.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.applicationsReportsSo.deletePage)"
                                                    @update="val => {accountsRolesData.applicationsReportsSo.deletePage = val; setGroupPageFlag('applicationsReportsSo')}"
                                                />
                                            </div> -->
                                        </div>

                                        <div class="roles_label">
                                            <div class="checkbox checkbox-primary">
                                                <label class="short_text">
                                                    <div class="checker" id="uniform-inlineCheckbox110">
                                                        <span :class="labels.inventorySo ? 'checked' : ''">
                                                            <input type="checkbox" id="inlineCheckbox110" @click.prevent="setInventorySoFlag()" class="custom-checkbox" />
                                                            <i class="glyph-icon icon-check"></i>
                                                        </span>
                                                    </div>
                                                    <h3>{{ getLang.menu.inventory }}</h3>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersInventory.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersInventory.visible)"
                                                    @update="val => {accountsRolesData.usersInventory.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersInventory.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersInventory.editPage)"
                                                    @update="val => {accountsRolesData.usersInventory.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersInventory.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersInventory.addPage)"
                                                    @update="val => {accountsRolesData.usersInventory.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.usersInventory.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.usersInventory.deletePage)"
                                                    @update="val => {accountsRolesData.usersInventory.deletePage = val; setGroupPageFlag('usersInventory')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.usersPage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchangeInventory.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchangeInventory.visible)"
                                                    @update="val => {accountsRolesData.exchangeInventory.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchangeInventory.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchangeInventory.editPage)"
                                                    @update="val => {accountsRolesData.exchangeInventory.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchangeInventory.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchangeInventory.addPage)"
                                                    @update="val => {accountsRolesData.exchangeInventory.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.exchangeInventory.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.exchangeInventory.deletePage)"
                                                    @update="val => {accountsRolesData.exchangeInventory.deletePage = val; setGroupPageFlag('exchangeInventory')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.exchangePage }}</h4>
                                            </div>
                                        </div>
                                        <div class="roles_item">
                                            <div class="title_category_page"></div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiserInventory.visible)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiserInventory.visible)"
                                                    @update="val => {accountsRolesData.advertiserInventory.visible = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiserInventory.editPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiserInventory.editPage)"
                                                    @update="val => {accountsRolesData.advertiserInventory.editPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiserInventory.addPage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiserInventory.addPage)"
                                                    @update="val => {accountsRolesData.advertiserInventory.addPage = val}"
                                                />
                                            </div>
                                            <div class="title_moder">
                                                <InputRoles
                                                    :labelPage="''"
                                                    :dataKeyPage="Boolean(accountsRolesData.advertiserInventory.deletePage)"
                                                    :defaultKeyPage="Boolean(accountsRolesDefault.advertiserInventory.deletePage)"
                                                    @update="val => {accountsRolesData.advertiserInventory.deletePage = val; setGroupPageFlag('advertiserInventory')}"
                                                />
                                            </div>
                                            <div class="title_page">
                                                <h4>{{ getLang.menu.advertiserPage }}</h4>
                                            </div>
                                        </div>

                                        <div v-if="getRole.toLowerCase() === $store.getters.getRoleSuperAdmin.toLowerCase() ||
                                                    getRole.toLowerCase() === $store.getters.getRoleAdmin.toLowerCase()"
                                        >
                                            <div class="roles_label">
                                                <div class="checkbox checkbox-primary">
                                                    <label class="short_text">
                                                        <div class="checker" id="uniform-inlineCheckbox110">
                                                            <span :class="labels.userSettingsSo ? 'checked' : ''">
                                                                <input type="checkbox" id="inlineCheckbox110" @click.prevent="setUserSettingsSo()" class="custom-checkbox" />
                                                                <i class="glyph-icon icon-check"></i>
                                                            </span>
                                                        </div>
                                                        <h3>{{ getLang.menu.userSettings }}</h3>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoSo.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoSo.visible)"
                                                        @update="val => {accountsRolesData.userInfoSo.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoSo.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoSo.editPage)"
                                                        @update="val => {accountsRolesData.userInfoSo.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoSo.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoSo.addPage)"
                                                        @update="val => {accountsRolesData.userInfoSo.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.userInfoSo.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.userInfoSo.deletePage)"
                                                        @update="val => {accountsRolesData.userInfoSo.deletePage = val; setGroupPageFlag('userInfoSo')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.userInfo }}</h4>
                                                </div>
                                            </div>

                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPaySo.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPaySo.visible)"
                                                        @update="val => {accountsRolesData.cardPaySo.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPaySo.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPaySo.editPage)"
                                                        @update="val => {accountsRolesData.cardPaySo.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPaySo.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPaySo.addPage)"
                                                        @update="val => {accountsRolesData.cardPaySo.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.cardPaySo.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.cardPaySo.deletePage)"
                                                        @update="val => {accountsRolesData.cardPaySo.deletePage = val; setGroupPageFlag('cardPaySo')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.cardPay }}</h4>
                                                </div>
                                            </div>

                                            <div class="roles_item">
                                                <div class="title_category_page"></div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorSo.visible)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorSo.visible)"
                                                        @update="val => {accountsRolesData.reportErrorSo.visible = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorSo.editPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorSo.editPage)"
                                                        @update="val => {accountsRolesData.reportErrorSo.editPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorSo.addPage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorSo.addPage)"
                                                        @update="val => {accountsRolesData.reportErrorSo.addPage = val}"
                                                    />
                                                </div>
                                                <div class="title_moder">
                                                    <InputRoles
                                                        :labelPage="''"
                                                        :dataKeyPage="Boolean(accountsRolesData.reportErrorSo.deletePage)"
                                                        :defaultKeyPage="Boolean(accountsRolesDefault.reportErrorSo.deletePage)"
                                                        @update="val => {accountsRolesData.reportErrorSo.deletePage = val; setGroupPageFlag('reportErrorSo')}"
                                                    />
                                                </div>
                                                <div class="title_page">
                                                    <h4>{{ getLang.menu.reportAnError }}</h4>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="col-md6" style="padding-right: 35px;">&nbsp;</div>
                        <div class="col-md6">&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelectIcons from '../plugins/MultiSelectIcons';
import MultiSelect from '../plugins/MultiSelect';
import InputRoles from '../plugins/InputRoles';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        urlAdded: String,
        urlRedirect: String,
        title: String,
        opened: String,
        accId: Number,
        accEmail: String,
        accIsChat: {
            type: Number,
            default: 0
        },
        accRole: {
            type: String,
            default: 'User'
        },
        accState: {
            type: String,
            default: 'New'
        }
    },

    data(){
        return {
            lang: Lang,

            rolesOutData: 'Admin',
            rolesOutList: ['User', 'Moderator', 'Admin'],

            pagesList: [],
            userRoleUser: 'User',
            userRoleList: [
                {id: 'User', name: 'User'},
                {id: 'Moderator', name: 'Moderator'},
                {id: 'Admin', name: 'Admin'}
            ],
            userStatesList: [
                {id: 'New', name: 'New'},
                {id: 'Confirmed', name: 'Confirmed'},
                {id: 'Blocked', name: 'Blocked'}
            ],
            //pagesView: [],

            email: '',

            otherHeight: 60,
            differentHeight: 67,
            modalBodyHeight: 2000,
            pagesListHeight: 200,

            logos: null,
            rolesLogos: null,
            statesLogos: null,

            searchValue: '',

            timeout: 0,

            userId: null,
            userIsChat: 0,
            userRole: [],
            userState: [],

            userList: [],
            adminsChat: [],
            anotherUsersChat: [],

            exchangeData: {
                typeBanner: {
                    value: [0],
                },
                osDev: {
                    value: [],
                },
            },

            accountsRolesData: {
                online: {pageId: 1, visible: true, addPage: true, editPage: true, deletePage: true},
                applicationsReports: {pageId: 2, visible: true, addPage: true, editPage: true, deletePage: true},
                adunitsReports: {pageId: 3, visible: true, addPage: true, editPage: true, deletePage: true},
                installs: {pageId: 4, visible: true, addPage: true, editPage: true, deletePage: true},
                retention: {pageId: 5, visible: true, addPage: true, editPage: true, deletePage: true},
                usersGraphics: {pageId: 6, visible: true, addPage: true, editPage: true, deletePage: true},
                graphics: {pageId: 7, visible: true, addPage: true, editPage: true, deletePage: true},
                applications: {pageId: 8, visible: true, addPage: true, editPage: true, deletePage: true},
                adunits: {pageId: 9, visible: true, addPage: true, editPage: true, deletePage: true},
                rules: {pageId: 10, visible: true, addPage: true, editPage: true, deletePage: true},
                scenarios: {pageId: 11, visible: true, addPage: true, editPage: true, deletePage: true},
                exchange: {pageId: 12, visible: true, addPage: true, editPage: true, deletePage: true},
                advertiser: {pageId: 13, visible: true, addPage: true, editPage: true, deletePage: true},
                relTypeNetwork: {pageId: 14, visible: true, addPage: true, editPage: true, deletePage: true},
                resetTheAccount: {pageId: 15, visible: true, addPage: true, editPage: true, deletePage: true},
                moderator: {pageId: 16, visible: true, addPage: true, editPage: true, deletePage: true},

                userInfoDash: {pageId: 23, visible: true, addPage: true, editPage: true, deletePage: true},
                cardPayDash: {pageId: 24, visible: true, addPage: true, editPage: true, deletePage: true},
                reportErrorDash: {pageId: 25, visible: true, addPage: true, editPage: true, deletePage: true},

                onlineReports: {pageId: 17, visible: false, addPage: false, editPage: false, deletePage: false},
                usersReports: {pageId: 18, visible: false, addPage: false, editPage: false, deletePage: false},
                applicationsReportsSo: {pageId: 19, visible: false, addPage: false, editPage: false, deletePage: false},
                usersInventory: {pageId: 20, visible: false, addPage: false, editPage: false, deletePage: false},
                exchangeInventory: {pageId: 21, visible: false, addPage: false, editPage: false, deletePage: false},
                advertiserInventory: {pageId: 22, visible: false, addPage: false, editPage: false, deletePage: false},

                userInfoSo: {pageId: 26, visible: false, addPage: false, editPage: false, deletePage: false},
                cardPaySo: {pageId: 27, visible: false, addPage: false, editPage: false, deletePage: false},
                reportErrorSo: {pageId: 28, visible: false, addPage: false, editPage: false, deletePage: false},
            },

            accountsRolesDefault: {
                online: {pageId: 1, visible: true, addPage: true, editPage: true, deletePage: true},
                applicationsReports: {pageId: 2, visible: true, addPage: true, editPage: true, deletePage: true},
                adunitsReports: {pageId: 3, visible: true, addPage: true, editPage: true, deletePage: true},
                installs: {pageId: 4, visible: true, addPage: true, editPage: true, deletePage: true},
                retention: {pageId: 5, visible: true, addPage: true, editPage: true, deletePage: true},
                usersGraphics: {pageId: 6, visible: true, addPage: true, editPage: true, deletePage: true},
                graphics: {pageId: 7, visible: true, addPage: true, editPage: true, deletePage: true},
                applications: {pageId: 8, visible: true, addPage: true, editPage: true, deletePage: true},
                adunits: {pageId: 9, visible: true, addPage: true, editPage: true, deletePage: true},
                rules: {pageId: 10, visible: true, addPage: true, editPage: true, deletePage: true},
                scenarios: {pageId: 11, visible: true, addPage: true, editPage: true, deletePage: true},
                exchange: {pageId: 12, visible: true, addPage: true, editPage: true, deletePage: true},
                advertiser: {pageId: 13, visible: true, addPage: true, editPage: true, deletePage: true},
                relTypeNetwork: {pageId: 14, visible: true, addPage: true, editPage: true, deletePage: true},
                resetTheAccount: {pageId: 15, visible: true, addPage: true, editPage: true, deletePage: true},
                moderator: {pageId: 16, visible: true, addPage: true, editPage: true, deletePage: true},

                userInfoDash: {pageId: 23, visible: true, addPage: true, editPage: true, deletePage: true},
                cardPayDash: {pageId: 24, visible: true, addPage: true, editPage: true, deletePage: true},
                reportErrorDash: {pageId: 25, visible: true, addPage: true, editPage: true, deletePage: true},

                onlineReports: {pageId: 17, visible: false, addPage: false, editPage: false, deletePage: false},
                usersReports: {pageId: 18, visible: false, addPage: false, editPage: false, deletePage: false},
                applicationsReportsSo: {pageId: 19, visible: false, addPage: false, editPage: false, deletePage: false},
                usersInventory: {pageId: 20, visible: false, addPage: false, editPage: false, deletePage: true},
                exchangeInventory: {pageId: 21, visible: false, addPage: false, editPage: false, deletePage: false},
                advertiserInventory: {pageId: 22, visible: false, addPage: false, editPage: false, deletePage: false},

                userInfoSo: {pageId: 26, visible: false, addPage: false, editPage: false, deletePage: false},
                cardPaySo: {pageId: 27, visible: false, addPage: false, editPage: false, deletePage: false},
                reportErrorSo: {pageId: 28, visible: false, addPage: false, editPage: false, deletePage: false},
            },

            labels: {
                reports: true,
                inventory: true,
                userSettingsDash: true,
                admin: true,
                reportsSo: false,
                inventorySo: false,
                userSettingsSo: false,
            },

            exchangeList: [],
            typeBannerList: [],
            osDev: [],

            status: {
                os: false,
                adtype: false,
                userRoleCategoryStatus: false,
                stateFilter: false,
                roleFilter: false
            },

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    watch: {
        opened(){
            this.getAccountRolesDB();
            this.getUsersListDB();
            this.userId = this.accId;
            this.userIsChat = this.accIsChat;
            this.userRole[0] = this.accRole;
            this.userState[0] = this.accState;

            if (this.rolesOutList.includes(this.getRole)) this.rolesOutData = 'Admin';
            else this.rolesOutData = '';
        },
    },

    created(){
        this.getPagesListDB();
    },

    mounted(){
        let _this = this;
        this.setPagesListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setPagesListHeight();
        });
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getAccountEmail(){
            this.email = this.accEmail;

            return this.email;
        },

        isNotSuperadminRole(){
            return (this.accRole && this.accRole.toLowerCase() !== this.$store.getters.getRoleSuperAdmin.toLowerCase());
        },

        isNotChangedAll(){
            let notChanged = true;
            let keys = Object.keys(this.accountsRolesData);

            for(let i = 0; i < keys.length; i++){
                notChanged = (Boolean(this.accountsRolesDefault[keys[i]]['visible']) === Boolean(this.accountsRolesData[keys[i]]['visible'])
                            && Boolean(this.accountsRolesDefault[keys[i]]['addPage']) === Boolean(this.accountsRolesData[keys[i]]['addPage'])
                            && Boolean(this.accountsRolesDefault[keys[i]]['editPage']) === Boolean(this.accountsRolesData[keys[i]]['editPage'])
                            && Boolean(this.accountsRolesDefault[keys[i]]['deletePage']) === Boolean(this.accountsRolesData[keys[i]]['deletePage'])
                            && notChanged
                        );
            }

            if (notChanged) {
                notChanged = Number(this.accIsChat) === Number(this.userIsChat);
            }

            if (notChanged && this.userRole.length) {
                notChanged = this.accRole === this.userRole[0];
            }

            if (notChanged && this.userState.length) {
                notChanged = this.accState === this.userState[0];
            }

            return notChanged;
        },

        getUserRole(){
            if (this.userRole.length < 1) this.userRole[0] = this.accRole;

            return this.userRole;
        },

        getUserState(){
            if (this.userState.length < 1) this.userState[0] = this.accState;

            return this.userState;
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        getUserRoleList(){
            this.userRoleList.forEach((val, index) => {
                this.userRoleList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getRolesLogos[val.name]};
            });

            return this.userRoleList;
        },

        getUserStatesList(){
            this.userStatesList.forEach((val, index) => {
                this.userStatesList[index] = {...val, iconActive: this.$store.getters.getBaseUrl + '/' + this.getStatesLogos[val.name]};
            });

            return this.userStatesList;
        },

        getLogos(){
            this.logos = this.$store.state.logos.networkAndTypeIcons;

            return this.logos;
        },

        getRolesLogos(){
            this.rolesLogos = this.$store.state.logos.rolesIcons;

            return this.rolesLogos;
        },

        getStatesLogos(){
            this.statesLogos = this.$store.state.logos.statesIcons;

            return this.statesLogos;
        },

        /*getTypeBanner(){
            return this.exchangeData.typeBanner.value;
        },*/

        // is valid form add Adunit
        isValid(){
            return this.accountsRolesData.length > 0;
        },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupDelete;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                let heightFilter = 0;
                if(this.pagesList.length > 0) heightFilter = -20;

                this.$nextTick(() => {
                    //this.modalBodyHeight = (this.$refs.modalHeader.offsetHeight + this.$refs.modalBody.offsetHeight);
                    let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.pagesListHeight = mdlBodyHeight - (this.differentHeight + heightFilter);
                });
            }

            return this.popupAdd;
        },
    },
    methods: {
        isChanged(key, type){
            return this.accountsRolesData[key][type] !== this.accountsRolesDefault[key][type];
        },

        copyData(link){
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
console.log('clipboard');
                    })
                    .catch(err => {
                        console.log('Something went wrong', err);
                    });
            } else if (document.queryCommandSupported('copy')) {
                let textArea = document.createElement("textarea");
                textArea.value = link;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                let res = document.execCommand('copy');

                document.body.removeChild(textArea);
console.log('exec command copy');
            }
        },

        setGroupPageFlag(type){
            //this.accountsRolesData[type].deletePage = !this.accountsRolesData[type].deletePage;

            if (this.accountsRolesData[type].deletePage) {
                this.accountsRolesData[type].visible = this.accountsRolesData[type].deletePage;
                this.accountsRolesData[type].editPage = this.accountsRolesData[type].deletePage;
                this.accountsRolesData[type].addPage = this.accountsRolesData[type].deletePage;
                this.accountsRolesData[type].deletePage = this.accountsRolesData[type].deletePage;
            }
        },

        setReportsFlag(){
            this.labels.reports = !this.labels.reports;

            this.accountsRolesData.online.visible = this.labels.reports;
            // this.accountsRolesData.online.editPage = this.labels.reports;
            // this.accountsRolesData.online.addPage = this.labels.reports;
            // this.accountsRolesData.online.deletePage = this.labels.reports;

            this.accountsRolesData.applicationsReports.visible =  this.labels.reports;
            // this.accountsRolesData.applicationsReports.editPage = this.labels.reports;
            // this.accountsRolesData.applicationsReports.addPage = this.labels.reports;
            // this.accountsRolesData.applicationsReports.deletePage = this.labels.reports;

            this.accountsRolesData.adunitsReports.visible =  this.labels.reports;
            // this.accountsRolesData.adunitsReports.editPage = this.labels.reports;
            // this.accountsRolesData.adunitsReports.addPage = this.labels.reports;
            // this.accountsRolesData.adunitsReports.deletePage = this.labels.reports;

            this.accountsRolesData.installs.visible =  this.labels.reports;
            // this.accountsRolesData.installs.editPage = this.labels.reports;
            // this.accountsRolesData.installs.addPage = this.labels.reports;
            // this.accountsRolesData.installs.deletePage = this.labels.reports;

            this.accountsRolesData.retention.visible =  this.labels.reports;
            // this.accountsRolesData.retention.editPage = this.labels.reports;
            // this.accountsRolesData.retention.addPage = this.labels.reports;
            // this.accountsRolesData.retention.deletePage = this.labels.reports;

            this.accountsRolesData.usersGraphics.visible =  this.labels.reports;
            // this.accountsRolesData.usersGraphics.editPage = this.labels.reports;
            // this.accountsRolesData.usersGraphics.addPage = this.labels.reports;
            // this.accountsRolesData.usersGraphics.deletePage = this.labels.reports;

            this.accountsRolesData.graphics.visible =  this.labels.reports;
            // this.accountsRolesData.graphics.editPage = this.labels.reports;
            // this.accountsRolesData.graphics.addPage = this.labels.reports;
            // this.accountsRolesData.graphics.deletePage = this.labels.reports;
        },

        setReportsSoFlag(){
            this.labels.reportsSo = !this.labels.reportsSo;

            this.accountsRolesData.onlineReports.visible = this.labels.reportsSo;
            // this.accountsRolesData.onlineReports.editPage = this.labels.reportsSo;
            // this.accountsRolesData.onlineReports.addPage = this.labels.reportsSo;
            // this.accountsRolesData.onlineReports.deletePage = this.labels.reportsSo;

            this.accountsRolesData.usersReports.visible =  this.labels.reportsSo;
            // this.accountsRolesData.usersReports.editPage = this.labels.reportsSo;
            // this.accountsRolesData.usersReports.addPage = this.labels.reportsSo;
            // this.accountsRolesData.usersReports.deletePage = this.labels.reportsSo;

            this.accountsRolesData.applicationsReportsSo.visible =  this.labels.reportsSo;
            // this.accountsRolesData.applicationsReportsSo.editPage = this.labels.reportsSo;
            // this.accountsRolesData.applicationsReportsSo.addPage = this.labels.reportsSo;
            // this.accountsRolesData.applicationsReportsSo.deletePage = this.labels.reportsSo;
        },

        setInventoryFlag(){
            this.labels.inventory = !this.labels.inventory;

            this.accountsRolesData.applications.visible = this.labels.inventory;
            this.accountsRolesData.applications.editPage = this.labels.inventory;
            this.accountsRolesData.applications.addPage = this.labels.inventory;
            this.accountsRolesData.applications.deletePage = this.labels.inventory;

            this.accountsRolesData.adunits.visible =  this.labels.inventory;
            this.accountsRolesData.adunits.editPage = this.labels.inventory;
            this.accountsRolesData.adunits.addPage = this.labels.inventory;
            this.accountsRolesData.adunits.deletePage = this.labels.inventory;

            this.accountsRolesData.rules.visible =  this.labels.inventory;
            this.accountsRolesData.rules.editPage = this.labels.inventory;
            this.accountsRolesData.rules.addPage = this.labels.inventory;
            this.accountsRolesData.rules.deletePage = this.labels.inventory;

            this.accountsRolesData.scenarios.visible =  this.labels.inventory;
            this.accountsRolesData.scenarios.editPage = this.labels.inventory;
            this.accountsRolesData.scenarios.addPage = this.labels.inventory;
            this.accountsRolesData.scenarios.deletePage = this.labels.inventory;

            this.accountsRolesData.exchange.visible =  this.labels.inventory;
            this.accountsRolesData.exchange.editPage = this.labels.inventory;
            this.accountsRolesData.exchange.addPage = this.labels.inventory;
            this.accountsRolesData.exchange.deletePage = this.labels.inventory;

            this.accountsRolesData.advertiser.visible =  this.labels.inventory;
            this.accountsRolesData.advertiser.editPage = this.labels.inventory;
            this.accountsRolesData.advertiser.addPage = this.labels.inventory;
            this.accountsRolesData.advertiser.deletePage = this.labels.inventory;
        },

        setInventorySoFlag(){
            this.labels.inventorySo = !this.labels.inventorySo;

            this.accountsRolesData.usersInventory.visible = this.labels.inventorySo;
            this.accountsRolesData.usersInventory.editPage = this.labels.inventorySo;
            this.accountsRolesData.usersInventory.addPage = this.labels.inventorySo;
            this.accountsRolesData.usersInventory.deletePage = this.labels.inventorySo;

            this.accountsRolesData.exchangeInventory.visible =  this.labels.inventorySo;
            this.accountsRolesData.exchangeInventory.editPage = this.labels.inventorySo;
            this.accountsRolesData.exchangeInventory.addPage = this.labels.inventorySo;
            this.accountsRolesData.exchangeInventory.deletePage = this.labels.inventorySo;

            this.accountsRolesData.advertiserInventory.visible =  this.labels.inventorySo;
            this.accountsRolesData.advertiserInventory.editPage = this.labels.inventorySo;
            this.accountsRolesData.advertiserInventory.addPage = this.labels.inventorySo;
            this.accountsRolesData.advertiserInventory.deletePage = this.labels.inventorySo;
        },

        setUserSettingsDash(){
            this.labels.userSettingsDash = !this.labels.userSettingsDash;

            this.accountsRolesData.userInfoDash.visible = this.labels.userSettingsDash;
            this.accountsRolesData.userInfoDash.editPage = this.labels.userSettingsDash;
            this.accountsRolesData.userInfoDash.addPage = this.labels.userSettingsDash;
            this.accountsRolesData.userInfoDash.deletePage = this.labels.userSettingsDash;

            this.accountsRolesData.cardPayDash.visible = this.labels.userSettingsDash;
            this.accountsRolesData.cardPayDash.editPage = this.labels.userSettingsDash;
            this.accountsRolesData.cardPayDash.addPage = this.labels.userSettingsDash;
            this.accountsRolesData.cardPayDash.deletePage = this.labels.userSettingsDash;

            this.accountsRolesData.reportErrorDash.visible = this.labels.userSettingsDash;
            this.accountsRolesData.reportErrorDash.editPage = this.labels.userSettingsDash;
            this.accountsRolesData.reportErrorDash.addPage = this.labels.userSettingsDash;
            this.accountsRolesData.reportErrorDash.deletePage = this.labels.userSettingsDash;
        },

        setUserSettingsSo(){
            this.labels.userSettingsSo = !this.labels.userSettingsSo;

            this.accountsRolesData.userInfoSo.visible = this.labels.userSettingsSo;
            this.accountsRolesData.userInfoSo.editPage = this.labels.userSettingsSo;
            this.accountsRolesData.userInfoSo.addPage = this.labels.userSettingsSo;
            this.accountsRolesData.userInfoSo.deletePage = this.labels.userSettingsSo;

            this.accountsRolesData.cardPaySo.visible = this.labels.userSettingsSo;
            this.accountsRolesData.cardPaySo.editPage = this.labels.userSettingsSo;
            this.accountsRolesData.cardPaySo.addPage = this.labels.userSettingsSo;
            this.accountsRolesData.cardPaySo.deletePage = this.labels.userSettingsSo;

            this.accountsRolesData.reportErrorSo.visible = this.labels.userSettingsSo;
            this.accountsRolesData.reportErrorSo.editPage = this.labels.userSettingsSo;
            this.accountsRolesData.reportErrorSo.addPage = this.labels.userSettingsSo;
            this.accountsRolesData.reportErrorSo.deletePage = this.labels.userSettingsSo;
        },

        setAdminFlag(){
            this.labels.admin = !this.labels.admin;

            this.accountsRolesData.relTypeNetwork.visible = this.labels.admin;
            this.accountsRolesData.relTypeNetwork.editPage = this.labels.admin;
            this.accountsRolesData.relTypeNetwork.addPage = this.labels.admin;
            this.accountsRolesData.relTypeNetwork.deletePage = this.labels.admin;

            this.accountsRolesData.resetTheAccount.visible =  this.labels.admin;
            this.accountsRolesData.resetTheAccount.editPage = this.labels.admin;
            this.accountsRolesData.resetTheAccount.addPage = this.labels.admin;
            this.accountsRolesData.resetTheAccount.deletePage = this.labels.admin;

            this.accountsRolesData.moderator.visible =  this.labels.admin;
            this.accountsRolesData.moderator.editPage = this.labels.admin;
            this.accountsRolesData.moderator.addPage = this.labels.admin;
            this.accountsRolesData.moderator.deletePage = this.labels.admin;
        },

        clearStatus(){
            this.status.adtype = false;
            this.status.os = false;
            this.status.roleFilter = false;
            this.status.stateFilter = false;
        },

        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        setIsEscClose(val){
            this.$store.commit('setIsEscClose', val);
        },

        /*--- update status ---*/
        updateStatusOs(val){
            this.clearStatus();

            this.status.os = val;
        },

        updateStatusAdtype(val){
            this.clearStatus();

            this.status.adtype = val;
        },
        /*--- end update status ---*/

        searchClear(){
            this.$refs.searchInput.value = '';
        },

        // get list PagesList
        getPagesListDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/pages/get?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.pagesList = response.data.pages;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get pages');
            });
        },

        /* popup */
        setPopupAddHide(){
            if (this.isValid) {
                if (confirm('Are you sure you want to close the popup without saving the data?')) {
                    this.$emit('setOpenAdd', false);
                    this.$store.commit('modals/setPopupDelete', false);
                }
            } else {
                this.$emit('setOpenAdd', false);
                this.$store.commit('modals/setPopupDelete', false);
            }
        },

        getAccountRolesDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/roles/get/'+this.accId+'?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                let keys = Object.keys(this.accountsRolesData);

                for(let i = 0; i < keys.length; i++){
                    let item = res.data.accounts_roles.find(val => {
                        return val.pageid === this.accountsRolesData[keys[i]]['pageId'];
                    });

                    if (item !== undefined) {
                        this.accountsRolesDefault[keys[i]]['visible'] = this.accountsRolesData[keys[i]]['visible'] = item['visible'];
                        this.accountsRolesDefault[keys[i]]['addPage'] = this.accountsRolesData[keys[i]]['addPage'] = item['addPage'];
                        this.accountsRolesDefault[keys[i]]['editPage'] = this.accountsRolesData[keys[i]]['editPage'] = item['editPage'];
                        this.accountsRolesDefault[keys[i]]['deletePage'] = this.accountsRolesData[keys[i]]['deletePage'] = item['deletePage'];
                    } else {
                        this.accountsRolesDefault[keys[i]]['visible'] = this.accountsRolesData[keys[i]]['visible'] = true;
                        this.accountsRolesDefault[keys[i]]['addPage'] = this.accountsRolesData[keys[i]]['addPage'] = true;
                        this.accountsRolesDefault[keys[i]]['editPage'] = this.accountsRolesData[keys[i]]['editPage'] = true;
                        this.accountsRolesDefault[keys[i]]['deletePage'] = this.accountsRolesData[keys[i]]['deletePage'] = true;
                    }
                }
            })
            .catch(e => {
                    this.errorsMessage(e, 'error get roles');
            });
        },

        getUsersListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/accounts?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.userList = response.data;
                this.adminsChat = this.userList.filter(v => v.is_chat === 1 && v.id !== this.accId);

                this.anotherUsersChat = [];
                this.getAnotherUsersChatDB(this.accId);
            })
            .catch(e => {
                this.errorsMessage(e, 'error get users');
            });
        },

        getAnotherUsersChatDB(accId){
            axios({
                url: this.$store.getters.getBaseUrl+'/admin/api/chat/user/' + accId + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let userChat = response.data.chat_users;

                userChat.forEach(v => {
                    if (v.visible === 1) {
                        this.$set(this.anotherUsersChat, this.anotherUsersChat.length, {adminId: accId, userId: v.id});
                    }
                });
            })
            .catch(e => {
                this.errorsMessage(e, 'error get users for admin chat');
            });
        },

        addItem(){
            if (confirm('Are you sure?')) {
                if (!this.isNotSuperadminRole) {
                    //this.updateAdminChatDB();

                    return;
                }

                if ((this.anotherUsersChat.length > 0 && Number(this.userIsChat) === 0)) {
                    if (confirm('The chat administrator has users. Do you want to make the chat administrator a regular user?')) {
                        this.addItemDB();
                    }
                } else {
                    this.addItemDB();
                }
            }
        },

        addItemDB(){
            let tmp = [];
            let keys = Object.keys(this.accountsRolesData);
            for(let i = 0; i < keys.length; i++) {
                if (this.userRole[0] === this.userRoleUser && ['onlineReports', 'usersReports', 'applicationsReportsSo', 'usersInventory', 'exchangeInventory', 'advertiserInventory', 'userInfoSo', 'cardPaySo', 'reportErrorSo'].includes(keys[i])) {
                    tmp.push({...this.accountsRolesData[keys[i]],
                                visible: 0,
                                readPage: 0,
                                addPage: 0,
                                editPage: 0,
                                deletePage: 0,
                                accid: this.accId});
                } else {
                    tmp.push({...this.accountsRolesData[keys[i]],
                                visible: Number(this.accountsRolesData[keys[i]].visible),
                                readPage: Number(this.accountsRolesData[keys[i]].readPage),
                                addPage: Number(this.accountsRolesData[keys[i]].addPage),
                                editPage: Number(this.accountsRolesData[keys[i]].editPage),
                                deletePage: Number(this.accountsRolesData[keys[i]].deletePage),
                                accid: this.accId});
                }
            }

            let data = JSON.stringify({
                roles: tmp,
                accId: this.accId
            });

            //console.log(data);

            if (this.accRole !== this.userRole[0] || this.accState !== this.userState[0] || Number(this.accIsChat) !== Number(this.userIsChat)) {
                axios({
                    url: this.$store.getters.getBaseUrl + '/acc/admin/edit?at=' + this.getAccessToken,
                    method: 'POST',
                    data: JSON.stringify({id: this.userId, role: this.userRole[0], state: this.userState[0], is_chat: Number(this.userIsChat), adminChat: this.accId}),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .catch(e => {
                    this.errorsMessage(e, 'error change roles account');
                });
            }

            axios({
                url: this.$store.getters.getBaseUrl + '/admin/api/roles/edit?at=' + this.getAccessToken,
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your Roles changed!'});

                this.$emit('setUpdata', true);
                this.isUpdate = true;

                setTimeout(() => { this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''}) }, 3000);

                this.setPopupAddHide();
            })
            .catch(e => {
                this.errorsMessage(e, 'error change roles');
                this.setPopupAddHide();
            });
        },
        /* end popup */

        // set height for adinut list in group
        setPagesListHeight(){
            this.$nextTick(function(){
                let heightFilter = 0;
                let otherHeight = 124;
                let maxHeight = 650;
                let winHeight = window.innerHeight;
                let mdlBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                let currentHeight = mdlBodyHeight;

                if(this.pagesList.length > 0) heightFilter = -20;
                if(currentHeight == 0) currentHeight = maxHeight;

                if (this.$refs.modalBody) {
                    if(winHeight < currentHeight + otherHeight) this.$refs.modalBody.style.height = (winHeight - otherHeight) + 'px';
                    else this.$refs.modalBody.style.height = maxHeight+'px';
                }

                this.pagesListHeight = currentHeight - (37 + heightFilter);

                if(this.pagesListHeight < 0) this.pagesListHeight = 0;
            });
        }
    },
    components: {
        MultiSelectIcons,
        MultiSelect,
        InputRoles,
    }
}
</script>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.yellow_outline{
    box-shadow: 1px 1px 5px 1px rgba(255,255,0,.9);
}

.search_clear{
    position: absolute;
    right: 20px;
    z-index: 100;
    top: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0px;
}

.scroll{
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 10px;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.modal-dialog{
    margin: 30px auto 0px;
    width: 1190px!important;
    max-width: 1190px!important;
    /* height: 680px; */
}

    .modal-content{
        /* height: 680px; */
        position: relative;
        background-color: #fff;
        z-index: 1000;
    }

        .modal-header{
            height: 63px;
            padding: 9px 20px 9px 0px;
            align-items: flex-start;
        }

        .modal-body{
            /* height: 650px; */
            padding: 0 15px;
        }

        .modal-footer{
            /* height: 29px; */
            padding: 0 15px;
        }

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 0px;
    }

.w100{
    width: 100%;
}

.w100px{
    width: 200px;
}

.w50{
    width: 500px;
    box-sizing: border-box;
}

.pl10{
    padding-left: 10px;
    margin-left: 0px!important;
}

.pt10{
    padding-top: 10px;
}

.h30{
    height: 30px;
    margin-right: 20px;
}

.title_category_page{
    float: left;
    width: 20%;
}

.title_page{
    float: left;
    width: 32%;
}

.title_moder{
    float: left;
    width: 12%;
}

.modal-body .w50:last-child{
    width: 420px;
    padding-right: 10px;
}

.close{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
}

.action_popup{
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.filter{
    display: flex;
}

    .filter__item, .filter__add{
        margin: 0 5px;
    }

        .filter__add .btn-add{
            width: 42px;
            height: 42px;
            padding: 0 10px;
        }

            .filter__add .btn-add i{
                line-height: 42px;
                font-size: 24px;
            }

    .filter__item{
        width: 217px;
        position: absolute;
        left: 243px;
        top: 9px;
    }

        .form-group{
            margin-bottom: 0px;
        }

    .filter__search{
            margin-right: 8px;
            position: absolute;
            top: 19px;
            left: 14px;
            height: 34px;
            padding: 0px 10px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 34px;
                width: 100%;
                transition: all .5s;
            }

.form_adunit{
    margin-left: 6px;
    margin-right: 10px;
}

.roles_item{
    margin-left: 0px!important;
}

/*--- modal window ---*/
    .title_and_close{
        display: flex;
        align-items: flex-end;
        height: 44px;
    }

        .title_and_close h4{
            margin-left: 43px;
        }

.modal-footer>div{
    width: 100%;
}

.add_adunit_in_group{
    line-height: 30px;
    height: 30px;
    min-width: 56px!important;
    width: 56px!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .add_adunit_in_group i::before{
        font-size: 18px;
    }

.adunit_in_group{
    width: 570px;
    min-height: 54px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex!important;
}

.form_adunit .adunit_in_group{
    width: 100%;
}

    .adunit_in_group tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    td.icon{
        width: 170px;
        /* height: 42px; */
        overflow: hidden;
    }

        img.icon{
            height: 51px;
            width: auto;
            margin-right: 10px;
        }

        img.image{
            width: 102px;
            height: auto;
            vertical-align: middle;
        }

    .adunit_in_group .info{
        width: 338px;
        padding-right: 10px;
        display: flex;
        align-items: flex-end;
    }

    .form_adunit .adunit_in_group .info{
        width: 265px;
        box-sizing: border-box;
        /* margin: 0 10px 0 5px; */
        display: flex;
        align-items: flex-end;
        /* padding: 0 10px; */
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 270px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-left: -4px;
            margin-bottom: 1px;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

    .form_adunit .action{
        justify-content: flex-end;
        align-items: flex-end;
    }

    .action{
        width: 60px;
        display: flex;
        align-items: center;
        /* justify-content: flex-end; */
    }


        .action i.glyph-icon{
            font-size: 24px;
        }

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.form_adunit .flex-block-adunit-group{
    padding: 0 0 4px 13px;
}

.flex-block-adunit-group{
    padding: 0 13px 4px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    /* height: 44px; */
    /* margin-bottom: 3px; */

}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

.roles_label h3{
    float: left;
    margin-right: 5px;
}

.roles_item{
    margin: 5px 0 5px 15px;
    display: flex;
}

    .roles_item h4 {
        margin-top: 5px;
    }

    .roles_item .checkbox {
        margin-top: 5px;
        margin-right: 20px;
    }

    .roles_item .checker {
        margin-right: 5px;
    }

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
