import {Line} from 'vue-chartjs'

export default {
    extends: Line,
    props: ['data', 'labels'],
    data () {
        return {
            gradient: null,
            gradient2: null,
            gradient3: null,
            gradient4: null,
            gradient5: null,
        }
    },
    watch: {
        data(){
            if (this.$data._chart) {
                this.$data._chart.destroy();
            }

            let tmpData = [];
            let chartRender = [];
            let position = 'left';

            if (this.data.count) {
                tmpData.push({
                    label: 'Count',
                    // yAxisID: 'Count',
                    borderColor: '#4285f4',
                    pointBackgroundColor: '#4285f4',
                    borderWidth: 3,
                    pointBorderColor: '#4285f4',
                    backgroundColor: this.gradient,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.count,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Count'
                    },
                    // gridLines: {
                    //     color: 'rgba(0, 0, 255, 1)'
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'Count',
                    ticks: {
                        fontColor: '#4285f4',
                        //max: 600,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            if (this.data.shows) {
                tmpData.push({
                    label: 'Shows',
                    // yAxisID: 'Shows',
                    borderColor: '#4285f4',
                    pointBackgroundColor: '#4285f4',
                    borderWidth: 3,
                    pointBorderColor: '#4285f4',
                    backgroundColor: this.gradient,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.shows,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Shows'
                    },
                    // gridLines: {
                    //     color: 'rgba(0, 0, 255, 1)'
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'Shows',
                    ticks: {
                        fontColor: '#4285f4',
                        //max: 600,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            if (this.data.clicks) {
                tmpData.push({
                    label: 'Clicks',
                    // yAxisID: 'Clicks',
                    borderColor: 'rgb(15, 157, 88)',
                    pointBackgroundColor: 'rgb(15, 157, 88)',
                    pointBorderColor: 'rgb(15, 157, 88)',
                    borderWidth: 3,
                    backgroundColor: this.gradient2,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.clicks,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Clicks'
                    },
                    // gridLines: {
                    //     color: 'rgba(0, 255, 0, 1)'
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'Clicks',
                    ticks: {
                        fontColor: 'rgb(15, 157, 88)',
                        //max: 500,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            if (this.data.ctr) {
                tmpData.push({
                    label: 'CTR',
                    // yAxisID: 'CTR',
                    borderColor: '#f4b400',
                    pointBackgroundColor: '#f4b400',
                    pointBorderColor: '#f4b400',
                    borderWidth: 3,
                    backgroundColor: this.gradient3,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.ctr,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'CTR'
                    },
                    // gridLines: {
                    //     color: 'rgba(255, 0, 0, 1)',
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'CTR',
                    ticks: {
                        fontColor: '#f4b400',
                        //max: 600,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            if (this.data.failed) {
                tmpData.push({
                    label: 'Failed',
                    // yAxisID: 'Failed',
                    borderColor: '#db4437',
                    pointBackgroundColor: '#db4437',
                    pointBorderColor: '#db4437',
                    borderWidth: 3,
                    backgroundColor: this.gradient4,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.failed,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Failed'
                    },
                    // gridLines: {
                    //     color: 'rgba(255, 0, 0, 1)',
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'Failed',
                    ticks: {
                        fontColor: '#db4437',
                        //max: 200,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            if (this.data.rewarded) {
                tmpData.push({
                    label: 'Rewarded',
                    // yAxisID: 'Rewarded',
                    borderColor: '#ab47bc',
                    pointBackgroundColor: '#ab47bc',
                    pointBorderColor: '#ab47bc',
                    borderWidth: 3,
                    backgroundColor: this.gradient5,
                    pointRadius: 3,
                    pointHitRadius: 3,
                    lineTension: 0.3,
                    data: this.data.rewarded,
                    spanGaps: false
                });

                chartRender.push({
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Rewarded'
                    },
                    // gridLines: {
                    //     color: 'rgba(255, 0, 0, 1)',
                    // },
                    type: 'linear',
                    stacked: true,
                    position: position,
                    id: 'Rewarded',
                    ticks: {
                        fontColor: '#ab47bc',
                        //max: 300,
                        min: 0,
                        maxTicksLimit: 5,
                        callback: function(value, index, values) {
                            if(parseInt(value) >= 1000){
                                const t = 1000, m = 1000000, b = 1000000000;

                                if (value >= t && value < m) {
                                    return (value / t) + 'K';
                                } else if (value >= m && value < b) {
                                    return (value / m) + 'M';
                                } else if (value >= b) {
                                    return (value / b) + 'B';
                                }

                                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            } else {
                                return value;
                            }
                        }
                    }
                });

                position = 'right';
            }

            this._chart = this.renderChart(
                {
                    labels: this.labels,
                    datasets: tmpData
                },
                {
                    layout: {
                        padding: {
                            left: 15,
                            right: 20,
                        }
                    },
                    scaleLabel: "<%=value+'$'%>",
                    responsive: true,
                    //tooltipFillColor: "rgba(0,0,0,0.4)",
                    //multiTooltipTemplate: "<%= datasetLabel %> - <%= value %>",
                    tooltips: {
                        //mode: 'x'
                        //mode: 'point'
                        //mode: 'dataset'
                        //mode: 'nearest'
                        //intersect: true
                        //axis: 'x'

                        position: 'nearest',
                        mode: 'index',
                        callbacks: {
                            title: function(tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function(tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label;
                                let labelData = tooltipItem.yLabel;

                                if (labelData % 1 !== 0) labelData = labelData.toFixed(2);

                                if(parseInt(labelData) >= 1000){
                                    labelData = labelData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                }

                                return label + ' ' + labelData;
                            }
                        }
                    },
                    maintainAspectRatio: false,
                    type: 'line',
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                callback: function(value, index, values) {
                                    let monthVal = [
                                        'Jan',
                                        'Feb',
                                        'March',
                                        'May',
                                        'April',
                                        'June',
                                        'July',
                                        'Aug',
                                        'Sep',
                                        'Oсt',
                                        'Nov',
                                        'Dec',
                                    ];
                                    let date = new Date(value);

                                    if(value.toString().match(/^\d{2}$/)) return value;
                                    else return date.getDate() + ' ' + monthVal[date.getMonth()];

                                    //return value.toString().replace(/^\d{4}-/g, "");
                                }
                            }
                        }],
                        //yAxes: chartRender
                        yAxes: [{
                            display: true,
                            type: 'linear',
                            stacked: false,
                            ticks: {
                                fontColor: '#ab47bc',
                                min: 0,
                                maxTicksLimit: 50,
                                //skipRatio:
                                callback: function(value, index, values) {
                                    const maxVal = values[0];
                                    const t = 1000, m = 1000000, b = 1000000000;
                                    const countsAll = values.length - 1, countsHalf = Math.round(countsAll / 2), countsQuarter = Math.round(countsAll / 4);
                                    //const maxTicks = 5;
                                    //const step = maxVal / (maxTicks - 1);
                                    //let res = maxVal - step * index;

                                    if (index === 0 || index === countsAll || index === countsHalf || index === countsQuarter || index === (countsHalf + countsQuarter)) {

                                        if(parseInt(maxVal) >= parseInt(t)){
                                            if (maxVal >= t && maxVal < m) {
                                                return (value / t) + 'K';
                                            } else if (maxVal >= m && maxVal < b) {
                                                return (value / m) + 'M';
                                            } else if (maxVal >= b) {
                                                return (value / b) + 'B';
                                            }

                                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                        } else {
                                            return value;
                                        }

                                    }
                                }
                            }
                        }]
                    }
                }
            );
        }
    },
    mounted () {
        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient2 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient3 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient4 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
        this.gradient5 = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

        this.gradient.addColorStop(0, 'rgba(66, 133, 244, 0.2)');
        this.gradient.addColorStop(0.5, 'rgba(66, 133, 244, 0)');
        this.gradient.addColorStop(1, 'rgba(66, 133, 244, 0)');

        this.gradient2.addColorStop(0, 'rgba(15, 157, 88, 0.2)');
        this.gradient2.addColorStop(0.5, 'rgba(15, 157, 88, 0)');
        this.gradient2.addColorStop(1, 'rgba(15, 157, 88, 0)');

        this.gradient3.addColorStop(0, 'rgba(244, 180, 0, 0.2)');
        this.gradient3.addColorStop(0.5, 'rgba(244, 180, 0, 0)');
        this.gradient3.addColorStop(1, 'rgba(244, 180, 0, 0)');

        this.gradient4.addColorStop(0, 'rgba(219, 68, 55, 0.2)');
        this.gradient4.addColorStop(0.5, 'rgba(219, 68, 55, 0)');
        this.gradient4.addColorStop(1, 'rgba(219, 68, 55, 0)');

        this.gradient5.addColorStop(0, 'rgba(171, 71, 188, 0.2)');
        this.gradient5.addColorStop(0.5, 'rgba(171, 71, 188, 0)');
        this.gradient5.addColorStop(1, 'rgba(171, 71, 188, 0)');

        let tmpData = [];

        let chartRender = [];

        if (this.data.count) {
            tmpData.push({
                label: 'Count',
                // yAxisID: 'Count',
                borderColor: '#4285f4',
                pointBackgroundColor: '#4285f4',
                borderWidth: 3,
                pointBorderColor: '#4285f4',
                backgroundColor: this.gradient,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.3,
                data: this.data.count,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Count'
                },
                // gridLines: {
                //     color: 'rgba(0, 0, 255, 1)'
                // },
                type: 'linear',
                stacked: true,
                position: 'left',
                id: 'Count',
                ticks: {
                    fontColor: '#4285f4',
                    //max: 600,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        if (this.data.shows) {
            tmpData.push({
                label: 'Shows',
                // yAxisID: 'Shows',
                borderColor: '#4285f4',
                pointBackgroundColor: '#4285f4',
                borderWidth: 3,
                pointBorderColor: '#4285f4',
                backgroundColor: this.gradient,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.3,
                data: this.data.shows,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Shows'
                },
                // gridLines: {
                //     color: 'rgba(0, 0, 255, 1)'
                // },
                type: 'linear',
                stacked: true,
                position: 'left',
                id: 'Shows',
                ticks: {
                    fontColor: '#4285f4',
                    //max: 600,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        if (this.data.clicks) {
            tmpData.push({
                label: 'Clicks',
                // yAxisID: 'Clicks',
                borderColor: 'rgb(15, 157, 88)',
                pointBackgroundColor: 'rgb(15, 157, 88)',
                pointBorderColor: 'rgb(15, 157, 88)',
                borderWidth: 3,
                backgroundColor: this.gradient2,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.3,
                data: this.data.clicks,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Clicks'
                },
                // gridLines: {
                //     color: 'rgba(0, 255, 0, 1)'
                // },
                type: 'linear',
                stacked: true,
                position: 'right',
                id: 'Clicks',
                ticks: {
                    fontColor: 'rgb(15, 157, 88)',
                    //max: 500,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        if (this.data.ctr) {
            tmpData.push({
                label: 'CTR',
                // yAxisID: 'CTR',
                borderColor: '#f4b400',
                pointBackgroundColor: '#f4b400',
                pointBorderColor: '#f4b400',
                borderWidth: 3,
                backgroundColor: this.gradient3,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.3,
                data: this.data.ctr,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'CTR'
                },
                // gridLines: {
                //     color: 'rgba(255, 0, 0, 1)',
                // },
                type: 'linear',
                stacked: true,
                position: 'right',
                id: 'CTR',
                ticks: {
                    fontColor: '#f4b400',
                    //max: 600,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        if (this.data.failed) {
            tmpData.push({
                label: 'Failed',
                // yAxisID: 'Failed',
                borderColor: '#db4437',
                pointBackgroundColor: '#db4437',
                pointBorderColor: '#db4437',
                borderWidth: 3,
                backgroundColor: this.gradient4,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.1,
                data: this.data.failed,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Failed'
                },
                // gridLines: {
                //     color: 'rgba(255, 0, 0, 1)',
                // },
                type: 'linear',
                stacked: true,
                position: 'right',
                id: 'Failed',
                ticks: {
                    fontColor: '#db4437',
                    //max: 200,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        if (this.data.rewarded) {
            tmpData.push({
                label: 'Rewarded',
                // yAxisID: 'Rewarded',
                borderColor: '#ab47bc',
                pointBackgroundColor: '#ab47bc',
                pointBorderColor: '#ab47bc',
                borderWidth: 3,
                backgroundColor: this.gradient5,
                pointRadius: 3,
                pointHitRadius: 3,
                lineTension: 0.1,
                data: this.data.rewarded,
                spanGaps: false
            });

            chartRender.push({
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Rewarded'
                },
                // gridLines: {
                //     color: 'rgba(255, 0, 0, 1)',
                // },
                type: 'linear',
                stacked: true,
                position: 'right',
                id: 'Rewarded',
                ticks: {
                    fontColor: '#ab47bc',
                    //max: 300,
                    min: 0,
                    maxTicksLimit: 5,
                    callback: function(value, index, values) {
                        if(parseInt(value) >= 1000){
                            const t = 1000, m = 1000000, b = 1000000000;

                            if (value >= t && value < m) {
                                return (value / t) + 'K';
                            } else if (value >= m && value < b) {
                                return (value / m) + 'M';
                            } else if (value >= b) {
                                return (value / b) + 'B';
                            }

                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                        } else {
                            return value;
                        }
                    }
                }
            });
        }

        this._chart = this.renderChart(
            {
                labels: this.labels,
                datasets: tmpData
            },
            {
                layout: {
                    padding: {
                        left: 15,
                        right: 20,
                    }
                },
                scaleLabel: "<%=value+'$'%>",
                responsive: true,
                // tooltipFillColor: "rgba(0,0,0,0.4)",
                //multiTooltipTemplate: "<%= datasetLabel %> - <%= value %>",
                tooltips: {
                    //mode: 'x'
                    //mode: 'point'
                    //mode: 'dataset'
                    //mode: 'nearest'
                    //intersect: true
                    //axis: 'x'

                    position: 'nearest',
                    mode: 'index',
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']];
                        },
                        label: function(tooltipItem, data) {
                            let label = data.datasets[tooltipItem.datasetIndex].label;
                            let labelData = tooltipItem.yLabel;

                            if (labelData % 1 !== 0) labelData = labelData.toFixed(2);

                            if(parseInt(labelData) >= 1000){
                                labelData = labelData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            }

                            return label + ' ' + labelData;
                        }
                    }
                },
                maintainAspectRatio: false,
                type: 'line',
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: {
                            callback: function(value, index, values) {
                                let monthVal = [
                                    'Jan',
                                    'Feb',
                                    'March',
                                    'May',
                                    'April',
                                    'June',
                                    'July',
                                    'Aug',
                                    'Sep',
                                    'Oсt',
                                    'Nov',
                                    'Dec',
                                ];
                                let date = new Date(value);

                                if(value.toString().match(/^\d{2}$/)) return value;
                                else return date.getDate() + ' ' + monthVal[date.getMonth()];

                                //return value.toString().replace(/^\d{4}-/g, "");
                            }
                        }
                    }],
                    //yAxes: chartRender
                    yAxes: [{
                        display: true,
                        type: 'linear',
                        stacked: false,
                        ticks: {
                            fontColor: '#ab47bc',
                            min: 0,
                            maxTicksLimit: 50,
                            callback: function(value, index, values) {
                                const maxVal = values[0];
                                const t = 1000, m = 1000000, b = 1000000000;
                                const countsAll = values.length - 1, countsHalf = Math.round(countsAll / 2), countsQuarter = Math.round(countsAll / 4);
                                //const maxTicks = 5;
                                //const step = maxVal / (maxTicks - 1);
                                //let res = maxVal - step * index;

                                if (index === 0 || index === countsAll || index === countsHalf || index === countsQuarter || index === (countsHalf + countsQuarter)) {

                                    if(parseInt(maxVal) >= parseInt(t)){
                                        if (maxVal >= t && maxVal < m) {
                                            return (value / t) + 'K';
                                        } else if (maxVal >= m && maxVal < b) {
                                            return (value / m) + 'M';
                                        } else if (maxVal >= b) {
                                            return (value / b) + 'B';
                                        }

                                        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                    } else {
                                        return value;
                                    }

                                }
                            }
                        }
                    }]
                }
            }
        );
    }
}
