<template>
    <transition name="custom-classes-transition"
                enter-active-class="animated bounceInRight"
                leave-active-class="animated bounceOutRight"
                >
        <div class="modal fade bs-example-modal-sm"
                    :class="getPopupAdd ? 'show' : ''"
                    :style="getPopupAdd ? 'display: block;' : ''"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="mySmallModalLabel"
                    aria-hidden="true"
                    v-hotkey="keymap"
                    v-show="getPopupAdd"
                    >
            <div class="modal-dialog" style="z-index: 1800!important;">
                <div class="modal-content" ref="modalContent">
                    <div class="modal-header" ref="modalHeader">
                        <div class="title_and_close">
                            <button type="button"
                                    class="close float-left"
                                    id="close_modal"
                                    data-dismiss="modal"
                                    aria-hidden="true"
                                    @click.prevent="setPopupAddHide()"
                                    >
                                ×
                            </button>
                        </div>
                    </div>

                    <div class="modal-body" ref="modalBody">
                        <div class="panel">
                            <div class="panel-body">
                                <div class="w50">
                                    <div ref="modalBodyHeader">
                                        <h4 class="title">{{ getLang.queue.adunit }}</h4>
                                        <div class="row" ref="modalBodyFilter">
                                            <label class="control-label text-left hidden_group" style="padding-left: 0px; padding-right: 0px; display: flex; align-items: center;">
                                                <div :class="hideBlock.otherBlocks ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                                    @click.prevent="hideBlock.otherBlocks = !hideBlock.otherBlocks"
                                                    style="margin-top: 5px; margin-right: 5px; margin-left: 10px; float: left;"
                                                    >
                                                    <div class="bootstrap-switch-container">
                                                        <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff;">Off</span>
                                                        <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                                    </div>
                                                </div>
                                                {{ getLang.queue.showNotExists }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="scroll" :style="'max-height: ' + adunitListHeight + 'px;'">
                                        <div v-for="(item, idx) in getAdunitList"
                                            :key="idx"
                                            class="flex-block-adunit-group list-complete-item"
                                            >
                                            <table class="adunit_in_group"
                                                    :style="adunitExchangeData.id == item.id ? 'opacity: .5; cursor: default;' : ''"
                                                    @click.prevent="findAdunitInRule(item)"
                                            >
                                                <tr>
                                                    <td class="icon">
                                                        <span v-if="item.icon">
                                                            <img :src="item.icon" class="icon" alt="">
                                                        </span>
                                                        <span v-if="item.image">
                                                            <img :src="item.image" class="icon" alt="">
                                                        </span>
                                                        <span v-if="item.image_landscape">
                                                            <img :src="item.image_landscape" class="icon" alt="">
                                                        </span>
                                                    </td>
                                                    <td class="info">

                                                        <div class="info__description">

                                                            <div style="width: 100%;">
                                                                <div class="short_text">
                                                                    <span v-if="item.name" style="color: #999;">
                                                                        ({{ getShortTitle(item.name, 40) }})
                                                                    </span>
                                                                </div>
                                                                <div class="short_text">
                                                                    {{ getShortTitle(item.title, 40) }}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_adunit w50">
                                    <form class="form-horizontal bordered-row" enctype="multipart/form-data" novalidate>
                                        <h4 class="title">{{ getLang.queue.rulesQueue }}</h4>
                                        <div class="scroll" :style="'max-height: ' + adunitInRuleListHeight + 'px;'">
                                            <div class="flex-block-adunit-group list-complete-item"
                                                style="width: 100%;"
                                                v-for="(rule, idx) in rules"
                                                :key="idx"
                                                >
                                                <div class="short_text float-right">
                                                    <span v-if="rule.show_type" style="color: #999; margin-right: 10px;">
                                                        ({{ rule.show_type }})
                                                    </span>
                                                    <span class="bs-badge" :class="rule.enabled ? 'badge-success' : 'badge-danger'">&nbsp;</span>
                                                </div>
                                                <div class="short_text title" style="margin-top: 10px; font-size: 14px;">
                                                    {{ getShortTitle(rule.name, 55) }}
                                                </div>
                                                <div style="display: flex; flex-wrap: wrap;" :style="adunitInRuleList[rule.idx] != undefined && adunitInRuleList[rule.idx].length > 0 ? 'height: 60px;' : ''" class="scroll">
                                                    <table class="adunit_in_group"
                                                            v-for="(adunit, idx) in getAdunitInRuleList(rule.idx)"
                                                            style="cursor: default; width: 25%;"
                                                            :style="adunit.adblock == adunitExchangeData.id ? 'opacity: 0.5' : ''"
                                                            :key="idx"
                                                    >
                                                        <tr style="justify-content: flex-start;">
                                                            <td class="icon">
                                                                <div class="info__description" style="width: 100%;">

                                                                    <div style="width: 100%;">
                                                                        <div class="short_text">
                                                                            {{ getShortTitle(adunit.adblockData.title, 18) }}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <span v-if="adunit.adblockData.icon">
                                                                    <img :src="adunit.adblockData.icon" class="icon" alt="">
                                                                </span>
                                                                <span v-if="adunit.adblockData.image">
                                                                    <img :src="adunit.adblockData.image" class="icon" alt="">
                                                                </span>
                                                                <span v-if="adunit.adblockData.image_landscape">
                                                                    <img :src="adunit.adblockData.image_landscape" class="icon" alt="">
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="modal-footer">
                        <div class="col-md12">&nbsp;</div>
                    </div>
                </div>

            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import MultiSelect from '../plugins/MultiSelect';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],

    props: {
        opened: String,
        queueId: Number,
        rules: Array
    },

    data(){
        return {
            lang: Lang,

            adunitList: [],
            adunitView: [],

            adunitInRuleList: {},

            otherHeight: 40,
            modalBodyHeight: 680,
            modalBodyHeaderHeight: 0,
            modalBodyFilterHeight: 0,
            adunitListHeight: 0,
            adunitInRuleListHeight: 0,

            timeout: 0,

            idAdunitsInRule: [],

            hideBlock: {
                otherBlocks: true,
            },

            adunitExchangeData: {
                id: null
            },

            // popup
            popupAdd: false,
            isUpdate: false,
            isEscClose: true
        }
    },

    created(){
        // получаем список adunit с DB
        new Promise((resolve) => resolve(this.getAdunitListDB()))
        .then((resolve) => resolve(this.getAdunitsInRuleList()));
    },
    mounted(){
        let _this = this;
        this.setAdunitListHeight(); // задаем высоту для блока adunit in group

        window.addEventListener('resize', function(){
            _this.setAdunitListHeight();
        });
    },

    watch: {
        isUpdate(){
            if (this.isUpdate) {
                this.updateExchange(); // обновляем adunit in exchange
            }

            this.isUpdate = false;
        },

        opened(){
            this.updateExchange(); // обновляем adunit in exchange
        }
    },

    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        keymap(){
            return {
                'esc': this.escClose
            };
        },

        getIsEscClose(){
            this.isEscClose = this.$store.getters.getIsEscClose;

            return this.isEscClose;
        },

        // get adunit list by id group for select
        getAdunitList(){
            if (this.hideBlock.otherBlocks) {
                let _this = this;
                this.adunitView = this.adunitList.filter((val) => {
                    return _this.idAdunitsInRule.indexOf(val.id) == -1;
                });
            } else {
                this.adunitView = this.adunitList;
            }

            return this.adunitView;
        },

        // visible popup add
        getPopupAdd(){
            this.popupAdd = this.$store.state.modals.popupAdd;

            // если попап активный тогда получаем и выводим Adunit
            if (this.popupAdd) {
                this.$nextTick(function(){
                    //this.modalBodyHeight = (this.$refs.modalHeader.offsetHeight + this.$refs.modalBody.offsetHeight);
                    this.modalBodyHeight = (this.$refs.modalBody ? this.$refs.modalBody.offsetHeight : 0);
                    this.modalBodyHeaderHeight = (this.$refs.modalBodyHeader ? this.$refs.modalBodyHeader.offsetHeight : 0);
                    this.modalBodyFilterHeight = (this.$refs.modalBodyFilter ? this.$refs.modalBodyFilter.offsetHeight : 0);

                    this.setAdunitListHeight();
                });
            }

            return this.popupAdd;
        },
    },
    methods: {

        escClose(){
            if(this.getIsEscClose && this.getPopupAdd) this.setPopupAddHide();
        },

        getShortTitle(title, long = 30){
            if (title.length >= long) {
                title = "..." + title.slice(-long);
            }

            return title;
        },

        findAdunitInRule(item){
            this.adunitExchangeData.id = item.id;
        },

        removeAdunitInRule(){
            this.adunitExchangeData.id = null;
        },

        updateExchange(){
            this.removeAdunitInRule();

            new Promise((resolve) => resolve(this.getAdunitListDB()))
            .then((resolve) => resolve(this.getAdunitsInRuleList()));
        },

        getAdunitInRuleList(id){
            if (this.adunitInRuleList[id] != undefined) {
                return this.adunitInRuleList[id];
            } else {
                return [];
            }
        },

        getAdunitsInRuleList(){
            this.adunitInRuleList = {};

            for(let i = 0; i < this.rules.length; i++) {
                new Promise((resolve) => resolve(this.getAdunitInRuleListDB(this.rules[i].idx)));
            }
        },

        getAdunitInRuleListDB(id){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/group/adblocks/' + id + '?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                let adblocksTmp = [];
                adblocksTmp = response.data.Adblock;
                for(let i = 0; i < adblocksTmp.length; i++) {
                    let adblockTmp = this.adunitList.find((val) => {
                        return val.id == adblocksTmp[i].adblock;
                    });

                    if(this.idAdunitsInRule.indexOf(adblocksTmp[i].adblock) == -1) this.idAdunitsInRule.push(adblocksTmp[i].adblock);

                    adblocksTmp[i].adblockData = adblockTmp;
                }

                return adblocksTmp;
            })
            .then(response => {
                this.$set(this.adunitInRuleList, id, response);

                return this.adunitInRuleList;
            });
        },

        // get list Adunit
        getAdunitListDB(){
            return axios({
                url: this.$store.getters.getBaseUrl+'/api/adblocks?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.adunitList = response.data.Adblock.map(function(item){
                    let title = item.url;

                    return {title: title, name: item.name, id: item.id, type: item.type, icon: item.icon, image: item.image, image_landscape: item.image_landscape};
                });

                this.adunitView = this.adunitList;

                return this.adunitList;
            })
            .catch(e => {
                this.errorsMessage(e, 'error get adunit');
            });
        },

        /* popup */
        setPopupAddHide(){
            this.$emit('setOpenAdd', false);

            this.$store.commit('modals/setPopupAdd', false);
        },
        /* end popup */

        // set height for adinut list in group
        setAdunitListHeight(){
            //this.adunitListHeight = window.innerHeight - (this.otherHeight + this.modalBodyHeight + this.modalBodyHeaderHeight);
            this.adunitListHeight = this.modalBodyHeight - (this.otherHeight + this.modalBodyHeaderHeight);
            this.adunitInRuleListHeight = this.adunitListHeight + this.modalBodyFilterHeight;

            if(this.adunitListHeight < 0) this.adunitListHeight = 0;
            if(this.adunitListHeight > 565) this.adunitListHeight = 561;

            if(this.adunitInRuleListHeight < 0) this.adunitInRuleListHeight = 0;
            if(this.adunitInRuleListHeight > 565 + this.modalBodyFilterHeight) this.adunitInRuleListHeight = 561 + this.modalBodyFilterHeight;

            return this.adunitListHeight;
        }
    },
    components: {
        MultiSelect,
    }
}
</script>

<style scoped>
.short_text{
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap{
    word-break: keep-all;
    white-space: nowrap;
}

.scroll{
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.input_snipper{
    width: 58px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 4px 8px;
}

.modal-dialog{
    margin: 30px auto 0px;
    width: 1038px!important;
    max-width: 1038px!important;
    height: 680px;
}

    .modal-content{
        height: 680px;
    }

        .modal-header{
            height: 44px;
            padding: 11px 5px 10px 0px;
            align-items: flex-start;
            /* border-bottom: 0px none; */
        }

        .modal-body{
            height: 585px;
        }

        .modal-footer{
            height: 29px;
        }

.panel{
    margin-bottom: 0px;
    box-shadow: none;
}

    .panel-body{
        display: flex;
        padding: 0px;
    }

.w100{
    width: 100%;
}

.w50:first-child{
    margin-right: 10px;
    width: 430px;
}

.w50{
    width: 550px;
    box-sizing: border-box;
}

.close{
    padding: 10px;
    margin-right: 10px;
    position: absolute;
    top: 0px;
}

.action_popup{
    text-align: left;
    margin-bottom: 10px;
    position: relative;
    top: 0px;
}

.filter{
    display: flex;
}

    .filter__item, .filter__add{
        margin: 0 5px;
    }

        .filter__add .btn-add{
            width: 42px;
            height: 42px;
            padding: 0 10px;
        }

            .filter__add .btn-add i{
                line-height: 42px;
                font-size: 24px;
            }

    .filter__item{
        width: 217px;
    }

        .form-group{
            margin-bottom: 0px;
        }

    .filter__search{
            margin-right: 8px;
            position: relative;
            top: 0px;
            right: 0px;
            height: 44px;
            padding: 0px 10px;
        }

            .filter__search input{
                padding-top: 0px;
                padding-bottom: 0px;
                box-sizing: border-box;
                height: 42px;
                width: 100%;
                transition: all .5s;
            }

.form_adunit{
    margin-left: 6px;
    margin-right: 10px;
}

/*--- modal window ---*/
    .title_and_close{
        display: flex;
        align-items: flex-end;
    }

        .title_and_close h4{
            margin-left: 43px;
        }

.title{
    margin-bottom: 10px;
}

.modal-footer>div{
    width: 100%;
}

.adunit_in_group{
    width: 100%;
    cursor: pointer;
}

    .adunit_in_group tr{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    td.icon{
        width: 134px;
        /* height: 42px; */
        overflow: hidden;
    }

        img.icon{
            height: 40px;
            width: auto;
        }

    .info{
        box-sizing: border-box;
    }

        .info>input{
            margin-top: 10px;
        }

        .info__description{
            width: 270px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

            .info__name{
                width: 50%;
                text-align: right;
            }

            .info__description input{
                margin-top: 5px;
            }

            .info__description div input{
                margin-bottom: 10px;
            }

    .action{
        width: 60px;
        vertical-align: middle;
    }


        .action i.glyph-icon{
            font-size: 24px;
        }

.del_adunit_in_group{
    line-height: 22px;
    height: 22px;
    min-width: 20px!important;
    width: 20px!important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

    .del_adunit_in_group i::before{
        font-size: 16px;
    }

.flex-block-adunit-group{
    padding: 0 0px 0px;
    width: 414px;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    border: 1px solid #ddd;
    border-radius: 5px;
    /* height: 44px; */
    margin-bottom: 3px;

}

    .flex-block-adunit-group:hover .edit_adunit{
        display: inline;
    }

    .edit_adunit{
        display: none;
        margin-left: 8px;
    }

        .edit_adunit i::before{
            font-size: 18px;
        }

    .flex-block-adunit-group>table{
        display: block;
        padding: 0;
    }

        .flex-block-adunit-group div>button{
            margin-bottom: 5px;
        }

        .flex-block-adunit-group div:last-child>button{
            margin-bottom: 0px;
        }

/* animation */
.fade-enter{
    opacity: 0;
}
.fade-enter-active{
    transition: all .5s;
}
.fade-enter-to{
    opacity: 1;
}
.fade-leave-active{
    opacity: 0;
    transition: all .5s reverse;
}

.list-complete-item {
    transition: all .5s;
    display: inline-block;
}

.list-complete-enter, .list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
}

.list-complete-move {
    transition: transform .5s;
}
</style>
