<template>
    <div class="checkbox checkbox-primary">
        <label class="short_text">
            <div
                class="checker"
                :class="isChanged ? 'yellow_outline' : ''"
                id="uniform-inlineCheckbox110"
            >
                <span :class="getDataPage ? 'checked' : ''" :style="disabled ? 'background-color: #ddd; border-color: #bbb;' : ''">
                    <input type="checkbox" id="inlineCheckbox110" @click.prevent="setDataPage" class="custom-checkbox" :disabled="disabled" />
                    <i class="glyph-icon icon-check"></i>
                </span>
            </div>
            {{ labelPage }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        labelPage: String,
        dataKeyPage: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultKeyPage: Boolean
    },

    data(){
        return {
            dataPage: true,
        }
    },

    watch: {
        dataKeyPage(){
            this.dataPage = this.dataKeyPage;
        }
    },

    computed: {
        isChanged(){
            return this.defaultKeyPage !== this.dataPage;
        },

        getDataPage(){
            this.dataPage = this.dataKeyPage;

            return this.dataPage;
        },
    },

    methods: {
        setDataPage(){
            this.dataPage = !this.dataPage;
            this.$emit('update', this.dataPage);
        }
    },
}
</script>

<style>
.yellow_outline{
    box-shadow: 0px 0px 6px 1px rgba(255,255,0,1);
}
</style>
