<template>
    <section>
        <div class="panel app-list">
            <div class="panel-body">
                <div class="app-box-wrapper">
                    {{ auth.error }}
                    <div>
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-header" id="datatable-app">
                            <thead>

                                <tr>
                                    <th class="col_action">
                                        <div class="table-head-content">
                                            <button class="btn btn-success btn-add"
                                                    data-toggle="modal"
                                                    data-target=".bs-example-modal-sm"
                                                    ref="relation"
                                                    @click.prevent="saveRelationDB($event)"
                                                    :disabled="!isChanged"
                                            >
                                                <i class="glyph-icon icon-plus"></i>
                                            </button>
                                        </div>
                                    </th>

                                    <th class="col_title text-right">

                                        <router-link :to="{ name: 'type' }" :title="getLang.menu.type" class="btn btn-alt btn-hover btn-success btn-add" tag="button">
                                            <i class="glyph-icon icon-folder"></i>
                                            <span>{{ getLang.menu.type }}</span>
                                        </router-link>
                                        <router-link :to="{ name: 'network' }" :title="getLang.menu.network" class="btn btn-alt btn-hover btn-success btn-add" tag="button">
                                            <i class="glyph-icon icon-sitemap"></i>
                                            <span>{{ getLang.menu.network }}</span>
                                        </router-link>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>

                    <div class="scroll" :style="'height: ' + tableListHeight + 'px;'">
                        <table style="width: 100%" cellpadding="0" cellspacing="0" border="0" class="table table-striped table-app table-app-content" id="datatable-app2">
                            <tbody id="app_list">
                                <tr class="odd gradeX" v-for="(type, index) in getTypeView" :key="index">
                                    <td class="v-bottom col_action">
                                        <div class="short_text" v-html="type.name"></div>
                                    </td>

                                    <td class="right v-top col_title network_list">
                                        <label class="control-label" :title="network.name" v-for="(network, idx) in relationData[type.id]" :key="idx">
                                            {{ network.name }}:
                                            <div :class="network.value ? 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-on' : 'bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-medium bootstrap-switch-animate bootstrap-switch-off'"
                                                @click.prevent="network.value = !network.value"
                                            >
                                                <div class="bootstrap-switch-container">
                                                    <span class="bootstrap-switch-handle-on bootstrap-switch-primary">On</span><label class="bootstrap-switch-label">&nbsp;</label><span class="bootstrap-switch-handle-off bootstrap-switch-default" style="background-color: #ff0000; color: #fff; top: 0px; position: absolute; right: 0px;">Off</span>
                                                    <input type="checkbox" data-on-color="primary" name="checkbox-example-1" class="input-switch" checked="" data-size="medium" data-on-text="On" data-off-text="Off">
                                                </div>
                                            </div>
                                        </label>

                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <pagination :count-length="pagination.countLength"
                                :count-list="pagination.countList"
                                :active-count="pagination.activeCount"
                                :active-page="pagination.activePage"
                                @setStart="val => pagination.typeStart = val"
                                @setEnd="val => pagination.typeEnd = val"
                                @setActiveCountPagination="val => pagination.activeCount = val"
                                @setActivePagePagination="val => pagination.activePage = val"
                    >
                    </pagination>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';
import Authentication from '../mixins/authentication';
import Lang from '../lang/message';
import Pagination from '../plugins/Pagination';
import Lib from '../mixins/lib';

export default {
    mixins: [Authentication, Lib],
    data(){
        return {
            typeList: [],
            typeView: [],

            networkList: [],

            relation: [],
            relationDB: [],
            relationDBFilter: {},
            relationData: {},
            relationDataDefault: {},

            relationValue: false,

            lang: Lang,
            osActive: 1,

            tableListHeight: 0,
            headerHeight: 70,
            othersHeight: 83,

            relationDiv: '',

            // pagination
            pagination: {
                typeStart: 0,
                typeEnd: 0,
                countLength: 0,
                countList: [5,10,25,50,100],
                activeCount: 0,
                activePage: 0
            }
        }
    },
    watch: {
        getPaginationActiveCount(){
            this.$store.dispatch('setRemeberPaginationRelationTypeNetAction', parseInt(this.pagination.activeCount));
        },

        getPaginationActivePage(){
            this.$store.dispatch('setRemeberPaginationRelationTypeNetPageAction', parseInt(this.pagination.activePage));
        },

        getOsActive(){
            this.getRelationData();
        }
    },
    created(){
        this.pagination.activeCount = this.$store.getters.getRemeberPaginationRelationTypeNet;
        this.pagination.activePage = this.$store.getters.getRemeberPaginationRelationTypeNetPage;

        if(!this.isAdmin()) this.$router.push({name: 'index'});
        else this.getRelationDB();
    },
    mounted(){
        let _this = this;
        this.setTableListHeight();

        window.addEventListener('resize', function(){
            _this.setTableListHeight();
        });

        this.$nextTick(function(){
            this.relationDiv = this.$refs.relation;
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getOsActive(){
            this.osActive = this.$store.getters.getOsActive;

            return this.osActive;
        },

        getPaginationActiveCount(){
            return this.pagination.activeCount;
        },

        getPaginationActivePage(){
            return this.pagination.activePage;
        },

        isChanged(){
            let changed = false;

            let tmpType = this.getTypeList;
            let tmpNetwork = this.getNetworkList;

            for(let i = 0; i < tmpType.length; i++){

                if (!this.relationData[tmpType[i].id]) break;

                for(let j = 0; j < tmpNetwork.length; j++){
                    if (this.relationData[tmpType[i].id][j]['value'] !== this.relationDataDefault[tmpType[i].id][j]['value']) {
                        changed = true;
                        break;
                    }
                }
            }


            return changed;
        },

        /* networks */
        getNetworkList(){
            this.networkList = this.setCloneArrayWithObj(this.$store.getters.getNetworkList);

            return this.networkList;
        },

        /* types */
        getTypeList(){
            this.typeList = this.$store.getters.getTypeBannerList;
            this.pagination.countLength = this.typeList.length; // paginations

            return this.typeList;
        },
        getTypeView(){
            this.typeView = this.getTypeList.slice(this.pagination.typeStart, this.pagination.typeEnd);

            return this.typeView;
        },
        /* end types */
    },
    methods: {
        isAdmin(){
            if(this.getRole == undefined) return false;

            return (this.getRole.toLowerCase() === this.$store.getters.getRoleSuperAdmin);
        },

        // set height for table list Types
        setTableListHeight(){
            this.tableListHeight = window.innerHeight - (this.othersHeight + this.headerHeight);

            if(this.tableListHeight < 0) this.tableListHeight = 0;

            return this.tableListHeight;
        },

        getTypes(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/queue/alltypes?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.typeList = response.data.types;

                this.$store.commit('setTypeBannerList', this.typeList);
            })
            .catch(e => {
                this.errorsMessage(e, 'error get types');
            });
        },

        getRelationDBFilter(){
            for(let i = 0; i < this.relationDB.length; i++){
                if(this.relationDBFilter[this.relationDB[i].adtype] == undefined) this.relationDBFilter[this.relationDB[i].adtype] = [];

                if(this.relationDB[i].osid === this.getOsActive) this.relationDBFilter[this.relationDB[i].adtype].push(this.relationDB[i].network);
            }
        },

        getRelationData(){
            let tmpType = this.getTypeList;
            let tmpNetwork = this.getNetworkList;

            for(let i = 0; i < tmpType.length; i++){

                this.$set(this.relationData, tmpType[i].id, []);
                this.$set(this.relationDataDefault, tmpType[i].id, []);

                for(let j = 0; j < tmpNetwork.length; j++){
                    this.$set(this.relationData[tmpType[i].id], j, {id: tmpNetwork[j].id, name: tmpNetwork[j].name});
                    this.$set(this.relationDataDefault[tmpType[i].id], j, {id: tmpNetwork[j].id, name: tmpNetwork[j].name});

                    if (this.relationDB.find(val => { return (val.adtype == tmpType[i].id) && (val.network == tmpNetwork[j].id) && (val.osid == this.getOsActive) })) {
                        this.$set(this.relationData[tmpType[i].id][j], 'value', true);
                        this.$set(this.relationDataDefault[tmpType[i].id][j], 'value', true);
                    } else {
                        this.$set(this.relationData[tmpType[i].id][j], 'value', false);
                        this.$set(this.relationDataDefault[tmpType[i].id][j], 'value', false);
                    }
                }
            }
        },

        saveRelationDB(e){
            let _this = this;
            let saveData = {
                relations: []
            };
            let deleteData = {
                relations: [],
                osid: this.getOsActive
            };

            this.relationDiv.disabled = true;

            deleteData.relations = this.relationDB.filter(val => {
                return _this.relationData[val.adtype].find(function(valData){
                    if (valData.id == val.network && valData.value == false) {
                        return true;
                    }

                    return false;

                });
            });

            for (let type in this.relationData) {
                let tmpData = this.relationData[type].filter(valData => {
                    return (this.relationDBFilter[type].indexOf(valData.id) == -1) && valData.value;
                });

                tmpData = tmpData.map(val => {
                    return {adtype: parseInt(type), network: val.id, osid: this.getOsActive};
                });

                saveData.relations = saveData.relations.concat(tmpData);
            }

            let deleteDataDB = JSON.stringify(deleteData);
            let saveDataDB = JSON.stringify(saveData);

            axios({
                url: this.$store.getters.getBaseUrl+'/admin/superadmin/sysmanage/relation/delete?at='+this.getAccessToken,
                method: 'POST',
                data: deleteDataDB,
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (saveData.relations.length > 0) {
                    axios({
                        url: this.$store.getters.getBaseUrl+'/admin/superadmin/sysmanage/relation/create?at='+this.getAccessToken,
                        method: 'POST',
                        data: saveDataDB,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            })
            .then(response => {
                let _this = this;
                let uniqueId = this.getUniqueId();
                this.$store.commit('setSendFormStatus', {key: uniqueId, value: 'Your relation update!'});

                this.relationDiv.disabled = false;

                setTimeout(function(){
                    _this.$store.commit('setSendFormStatus', {key: uniqueId, value: ''});
                }, 3000);
            })
            .then(response => {
                location.reload();
            })
            .catch(e => {
                if (e.response.data.Error != undefined) {
                    let _this = this;
                    let uniqueId = this.getUniqueId();
                    this.$store.commit('setErrorFormStatus', {key: uniqueId, value: e.response.data.Error});

                    this.relationDiv.disabled = false;

                    setTimeout(function(){
                        _this.$store.commit('setErrorFormStatus', {key: uniqueId, value: ''});
                    }, 3000);
                }

                console.log("error relation type/network in DB");
            });
        },

        getRelationDB(){
            axios({
                url: this.$store.getters.getBaseUrl+'/api/network/relations/?at='+this.getAccessToken,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                this.relationDB = [];
                this.relationDB = response.data;

                this.getRelationData();
                this.getRelationDBFilter();
            })
            .catch(e => {
                this.errorsMessage(e, 'error get relation');
            });
        }
    },
    components: {
        Pagination
    }
}
</script>

<style scoped>
    /* column sizes */
    table{
        width: 100%;
        table-layout: fixed;
    }

    /*---*/
    .col_action{
        width: 11%;
        width: calc(180px);
    }

    .col_title{
        width: 89%;
    }
    /* end sizes */

    div.checkbox{
        display: inline-block;
    }

    .network_list label{
        margin-right: 20px;
    }

    h2.title-hero{
        display: inline-block;
        padding-right: 20px;
    }
    .file_active{
        color: #333;
        text-decoration: none;
        cursor: default;
    }
    .file{
        text-decoration: none;
    }
    section section{
        width: 300px;
    }

    .app-list{
        border: 0px none;
    }

        .app-list .panel-body{
            padding: 0px;
        }

    .app-box-wrapper img{
        width: auto;
        height: 44px;
    }

    .dataTables_filter{
        float: right;
        margin-right: 8px;
        position: relative;
        top: 0px;
        right: 0px;
        width: 144px;
        height: 44px;
    }

        .dataTables_filter input{
            padding-top: 0px;
            padding-bottom: 0px;
            box-sizing: border-box;
            height: 44px;
            width: 144px;
            position: absolute;
            right: 0px;
            transition: all .5s;
        }

        .dataTables_filter input:focus{
            width: 233px;
        }

    .table-app th{
        vertical-align: bottom;
    }

    .btn-add, .btn-edit{
        height: 44px;
        line-height: 44px;
        width: 144px;
        padding: 0px;
    }

    .btn-edit{
        margin-right: 8px;
    }

    .btn-add{
        margin-right: 0px;
    }

    i.icon-plus:before{
        font-weight: bold;
        font-size: 24px;
    }

    .table-app-header{
        margin-bottom: 0px;
        border-bottom: 0px none;
    }

        .table-app-header th{
            padding: 6px 13px 5px;
            /* border-bottom: 0px none; */
            border-bottom-width: 1px;
            height: 55px;
        }

            .table-app-header th input{
                width: 144px;
                padding-left: 6px;
                padding-right: 6px;
                box-sizing: border-box;
            }

    .table-app-content td{
        padding: 0px 13px 0px 13px;
        height: 50px;
        overflow: hidden;
        vertical-align: bottom;
    }

    .table-app-content tr:first-child td{
        border-top: 0px none;
    }

    .table-app-content td.v-top{
        vertical-align: top;
        padding-top: 3px;
        box-sizing: border-box;
    }

    .table-app-content td.v-bottom{
        vertical-align: bottom;
        padding-bottom: 13px;
        box-sizing: border-box;
    }

    .table-app-content td.right{
        text-align: right;
    }

    td, th{
        word-break: break-all;
    }

        td img.icon{
            width: auto;
            height: 44px;
            margin-right: 10px;
        }

        th .table-head-content{
            margin-bottom: 8px;
        }

            th .table-head-content a:hover, th .table-head-content a:active, th .table-head-content a:focus{
                text-decoration: none;
            }

    td.center, th.center{
        text-align: center;
    }

    .scroll{
        overflow: auto;
    }

    .short_text{
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

        .short_text span{
            background-color: #ffff00!important;
        }

    /* animation */

    .list-complete-enter {
        opacity: 0;
    }
    .list-complete-enter-active {
        transition: all .3s;
    }
    .list-complete-enter-to {
        opacity: 1;
    }
    .list-complete-leave-active {
        transition: all .3s reverse;
    }
    .list-complete-leave-to {
        opacity: 0;
        width: 0;
    }
    .list-complete-move{
        transition: transform .5s;
    }

    .width-enter-active{
        transition: all .3s;
    }
    .width-leave-active{
        transition: all .3s reverse;
    }

</style>
