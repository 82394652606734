import { render, staticRenderFns } from "./UploadFile.vue?vue&type=template&id=f428b356&scoped=true&"
import script from "./UploadFile.vue?vue&type=script&lang=js&"
export * from "./UploadFile.vue?vue&type=script&lang=js&"
import style0 from "./UploadFile.vue?vue&type=style&index=0&id=f428b356&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f428b356",
  null
  
)

component.options.__file = "UploadFile.vue"
export default component.exports