<template>
    <div class="form-group" :class="noBorder ? 'no-border' : ''" :style="styleBlockSelect">
        <label class="col-sm-3 control-label"
                :title="categoriesAlt + (countItem == 3 ? ' (' + getLang.max3options + ')' : '')"
                :style="categoriesStyle"
                v-if="categoriesTitle.length > 0"
        >
            <span class="bs-label label-danger" v-if="isRequired && isDefaultRequired">{{ getLang.required }}</span>
            <span style="color: red;" :title="getLang.required" v-if="isRequired && isDefaultRequired == false">*</span>
            {{ categoriesTitle }}:
            <i class="glyph-icon font-gray icon-question" :title="categoriesAlt + (countItem == 3 ? ' (' + getLang.max3options + ')' : '')" data-original-title=".icon-question"></i>
        </label>

        <div :class="categoriesTitle.length > 0 ? 'col-sm-9' : 'col-sm-12'" :style="styleInput">
            <div :class='{ outlight_active: catStatus }' class="chosen-container chosen-container-multi chosen-with-drop chosen-container-active" @click.stop="''">

                <ul class="chosen-choices"
                    @click="setCategoryStatus()"
                    >
                    <li class="search-choice ms-hover" style="position: absolute;" :style="categories.length > 0 ? '' : 'padding-right: 5px;'" v-if="viewCount">
                        <span v-if="categories.length > 0">{{ categories.length }}</span>
                        <span v-else>0</span>
                        <a class="search-choice-close" @click.stop.prevent="categories = []; $emit('setCategory', categories); $refs.searchInput.focus()" v-if="categories.length > 0"></a>
                    </li>
                    <li class="search-choice ms-hover" v-for="(item, inx) in categories" :key="inx" style="display: flex; align-items: center;" v-else>
                        <img :src="getCategoryImage(item)" style="height: 18px; width: auto; margin-right: 10px;"/>
                        <i class="glyph-icon tooltip-button demo-icon icon-question" style="height: 18px; width: 18px; font-size: 13px; line-height: 18px; margin: 0px 3px 0px 0px;" v-if="!getCategoryImage(item) && isIcon"></i>
                        <span style="display: flex;" v-html="getCategory(item)"></span>
                        <a class="search-choice-close" @click.stop.prevent="removeCategory($event, inx)"></a>
                    </li>
                    <button class="btn btn-success btn-add float-right"
                            style="height: 35px; width: 40px; position: absolute; right: 0px; top: 3px;"
                            @click.stop.prevent="$emit('addGroup', categories);"
                            :disabled="categories.length < 1"
                    >
                        <i class="glyph-icon icon-plus" style="line-height: 35px;"></i>
                    </button>

                    <div style="width: 100%; display: inline-block; padding-right: 40px;"
                        :style="categories.length > 0 ? 'padding-left: 48px;' : 'padding-left: 35px;'"
                        v-if="viewCount"
                    >
                        <li class="search-field ms-hover" style="line-height: 38px; color: #bbb; width: 100%;">
                            <!-- {{ placeholder }} -->

                            <input type="text"
                                    ref="searchInput"
                                    value=""
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    style="width: 100%; padding-right: 18px;"
                                    v-model="searchValue"
                                    @keydown="keysSelect($event)"
                                    @input="searchCategory($event)"
                                    @blur="searchBlurCategory($event)"
                            >
                            <a class="btn search_clear"
                                v-if="searchValue.length > 0"
                                @click.stop.prevent="searchClear()"
                            >
                                <i class="glyph-icon icon-remove"></i>
                            </a>
                        </li>
                    </div>
                    <div class="col-sm-12" v-else>
                        <li class="search-field ms-hover" style="line-height: 38px; color: #bbb;" :style="categories.length < 1 ? 'width: 100%;' : 'width: 1px; position: absolute; top: 0px; right: 1px;'">
                            <input type="text"
                                    ref="searchInput"
                                    value=""
                                    :placeholder="placeholder"
                                    class="default"
                                    autocomplete="off"
                                    style="width: 100%; padding-right: 10px;"
                                    v-model="searchValue"
                                    @keydown="keysSelect($event)"
                                    @input="searchCategory($event)"
                                    @blur="searchBlurCategory($event)"
                            >
                            <a class="btn search_clear"
                                v-if="searchValue.length > 0 && categories.length < 1"
                                @click.stop.prevent="searchClear()"
                            >
                                <i class="glyph-icon icon-remove"></i>
                            </a>
                        </li>
                    </div>
                    <li class="clear"></li>
                </ul>

                <div class="chosen-drop" v-show="catStatus">
                    <ul class="chosen-results" ref="listItems">
                        <li class="active-result group-option"
                            v-for="(el, key) in getCategoryLoc"
                            :value="el.idx"
                            :key="key"
                            :style="el.icon || el.image || el.image_landscape || isBorder ? 'border: 1px solid #eee; border-radius: 5px; margin-right: 10px;' : ''"
                            :class="isCategory(el.idx)"
                            @click.prevent="setCategory($event, el.idx, key)"
                            >
                            <div>
                                <span v-html="clearHtmlTags(el.name, ['p', 'strong'])" v-if="el.name"></span>
                                <div class="logo">
                                    <span class="bs-badge badge-success float-right" v-if="el.enabled">&nbsp;</span>
                                    <span class="bs-badge badge-danger float-right" v-else>&nbsp;</span>
                                    <img style="height: 40px; width: auto;" :src="getNetworkLogo(el.network)"/>
                                </div>

                                <div class="short_text" v-if="getNetworkName(el.network) == values.network.crosspromo">{{ getLang.group.showType }}: {{ el.show_type }}</div>
                                <div class="short_text" v-if="getNetworkName(el.network) == values.network.crosspromo">{{ getLang.group.showTimeInBox }}: {{ el.show_time }}</div>
                                <div class="short_text" v-if="getNetworkName(el.network) != values.network.crosspromo">
                                    {{ getLang.queue.networkCount }}:
                                    <span v-if="el.network_count == -1">&infin;</span>
                                    <span v-else>{{ el.network_count }} </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
import Lang from '../lang/message';
import Lib from '../mixins/lib';

export default {
    mixins: [Lib],
    props: {
        getCategoryList: {
            type: Array,
            default: []
        },
        isUpdateCategoryList: {
            type: Boolean,
            default: false
        },
        categoriesAlt: String,
        setDefaultCategory: Array,
        isRequired: {
            type: Boolean,
            default: true
        },
        isDefaultRequired: {
            type: Boolean,
            default: true
        },
        isBorder: {
            type: Boolean,
            default: false
        },
        isSearch: {
            type: Boolean,
            default: true,
        },
        isIcon: {
            type: Boolean,
            default: false
        },
        categoryIdOut: {
            type: Number,
            default: 0
        },
        categoriesTitle: String,
        categoriesStyle: {
            type: String,
            default: ''
        },
        styleBlockSelect: {
            type: String,
            default: ''
        },
        countItem: {
            type: Number,
            default: 3
        },
        placeholder: {
            type: String,
            default: ''
        },
        categoriesStatus: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        styleInput: {
            type: String,
            default: ''
        },
        viewCount: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            lang: Lang,

            categoryList: [],
            categoryListForSearch: [],

            searchValue: '',

            listItemsLi: null,
            listItemIndex: 0,

            categories: this.setDefaultCategory,
            catStatus: this.categoriesStatus,

            values: {
                network: {
                    crosspromo: 'crosspromotion'
                }
            },
        }
    },
    mounted(){
        let _this = this;

        this.$nextTick(function(){
            document.addEventListener('click', function(){
                if(_this.catStatus) _this.catStatus = false;
            });

            _this.listItemsLi = _this.$refs.listItems;

            //_this.activeFocus(null, _this.listItemIndex);
        });
    },
    computed: {
        // get language
        getLang(){
            return this.lang[this.$store.getters.getLanguageActive];
        },

        getCategoryLoc(){
            if (this.isSearch == false) this.categoryList = this.getCategoryList;
            else if (this.categoryList.length == 0 && this.searchValue == '') this.categoryList = this.getCategoryList;

            return this.categoryList;
        },

        // get logo list
        getLogos(){
            this.logos = this.$store.state.logos.icons;

            return this.logos;
        },

        // get network list
        getNetworkList(){
            this.networkList = this.$store.getters.getNetworkList;

            return this.networkList;
        }
    },
    watch: {
        setDefaultCategory(){
            this.categories = this.setDefaultCategory;
        },
        categoriesStatus(){
            this.catStatus = this.categoriesStatus;

            if(this.catStatus && this.listItemIndex == 0) {
                this.activeFocus(null, this.listItemIndex);
            }
        },

        getCategoryList(){
            if(this.isUpdateCategoryList) {
                this.categoryList = this.getCategoryList;
                let catIds = [];

                for(let k in this.categoryList){
                    catIds.push(this.categoryList[k]['id']);
                }

                this.categories = this.categories.filter(val => catIds.indexOf(val) != -1);

                this.$emit('setCategory', this.categories);
            }
        }

        // listItemsLi(){
        //     if(this.listItemsLi.children != undefined && this.listItemsLi.children.length > 0) {
        //         this.activeFocus(null, this.listItemIndex);
        //     }
        // }
    },
    methods: {
        // get network name by id
        getNetworkName(id){
            let name = this.getNetworkList.find(item => item.id === id);

            name = ((name != undefined && name.name != undefined) ? name.name : '');
            name = name.toLowerCase();

            return name;
        },

        // get network by Id
        getNetworkLogo(inx){
            let name = '';
            let logo = '';

            if(this.getNetworkList.length){
                name = this.getNetworkList.find(item => item.id === inx);
                if(name != undefined && name.name != undefined) logo = this.getLogos[name.name];
            }

            return logo;
        },

        // category
        setCategory(e, key, ind = null){

            // set active focus
            if(ind != null) this.activeFocus(null, ind);

            if (this.categories.find(inx => inx == key) != undefined) {
                this.categories.splice(this.categories.indexOf(key), 1);
                if(this.$refs.searchInput != undefined) this.$refs.searchInput.focus();
                return;
            }

            //console.log(this.categoryListForSearch, this.getCategoryLoc);

            if(this.countItem == 1) {
                this.$set(this.categories, 0, key);
                //this.categories[0] = key;
                this.$emit('setCategory', this.categories);
            }

            if((this.categories.length > (this.countItem - 1)) && this.countItem != -1 ) return;

            if (this.categories.find(inx => inx == key) == undefined) {
                this.categories.push(key);

                this.$emit('setCategory', this.categories);
                if(this.$refs.searchInput != undefined) this.$refs.searchInput.focus();
            }
        },
        removeCategory(e, inx){
            this.categories.splice(inx, 1);
            this.$emit('removeCategory', this.categories);
            this.$refs.searchInput.focus();
        },
        isCategory(key){
            if(this.categories.find(inx => inx == key)) return 'highlighted';

            return '';
        },

        getShortTitle(title){
            if (title.length >= 30) {
                title = "..." + title.slice(-30);
            }

            return title;
        },

        keysSelect(e){
            if(this.catStatus) {
                // close Select
                if(e.keyCode == 27) {
                    this.catStatus = false;
                }

                // prev or next active focus item in select
                if((this.listItemsLi.children != undefined && this.listItemsLi.children.length > 0) && (e.keyCode == 40 || e.keyCode == 38)) {
                    if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.scrollTop = this.listItemsLi.children[this.listItemIndex].offsetTop - 100;

                    this.activeFocus(e);
                }

                // click enter and set active item
                if(e.keyCode == 13) {
                    e.preventDefault();

                    if(this.listItemsLi.children[this.listItemIndex] != undefined) {
                        let val = this.listItemsLi.children[this.listItemIndex].getAttribute('value');

                        if(val.match(/^\d+$/g)) val = Number(val);

                        this.setCategory(null, val);
                    }
                }
            } else {
                if(e.keyCode == 27) {
                    this.$store.commit('setIsEscClose', true);
                }
            }
        },

        activeFocus(event, index = null){
            let styleBorder = '';
            let styleActiveFocus = 'box-shadow: 0px 0px 3px 1px #888888;';

            if(this.isBorder) styleBorder = 'border: 1px solid #eee; border-radius: 5px; margin-right: 10px;';

            if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.children[this.listItemIndex].style = styleBorder;

            if(index != null) this.listItemIndex = index;
            else if(event.keyCode == 40 && this.listItemIndex < this.listItemsLi.children.length-1) this.listItemIndex++;
            else if(event.keyCode == 38 && this.listItemIndex > 0) this.listItemIndex--;

            if(this.listItemsLi.children[this.listItemIndex] != undefined) this.listItemsLi.children[this.listItemIndex].style = styleBorder + styleActiveFocus;
        },

        /* search by table data */
        searchCategory(e){
            let _this = this;
            let res;

            if(this.viewCount == false && this.categories.length > 0) return;

            if(!this.categoryListForSearch.length) this.categoryListForSearch = this.getCategoryLoc;

            res = this.categoryListForSearch.filter(function(item){
                let isName = false;

                if(item.name != null) isName = (item.name.toLowerCase().indexOf(_this.searchValue.toLowerCase()) >= 0);

                return isName;
            });
            this.categoryList = res;
        },

        searchBlurCategory(e){
            if(e.target.value == '' && this.categoryListForSearch.length > 0) {
                this.categoryList = this.categoryListForSearch;
                this.categoryListForSearch = [];
            }
        },

        searchClear(){
            this.searchValue = '';
            //this.$refs.searchInput.value = '';
            this.searchCategory({target: {value: ''}});

            this.$refs.searchInput.focus();
        },
        /* end search table data */

        // get name category by id
        getCategory(inx){
            let item = '';

            if (this.getCategoryLoc.length) {
                item = this.getCategoryLoc.find(x => x.idx === inx).name;
            }

            return item;
        },

        getCategoryImage(inx){
            let item = '';

            if (this.getCategoryLoc.length) {
                item = this.getCategoryLoc.find(x => x.idx === inx);
            }

            return item.icon || item.image || item.image_landscape;
        },
        /* end Category */

        setCategoryStatus(){
            this.catStatus = !this.catStatus;

            if(this.catStatus) {
                this.$refs.searchInput.focus();
                this.$store.commit('setIsEscClose', false);
            } else {
                this.$store.commit('setIsEscClose', true);
            }

            this.$emit('setCategoryStatus', this.catStatus);
        }
    },
}
</script>

<style scoped>
.chosen-container-multi .chosen-choices{
    z-index: 1;
}

.chosen-results li{
    display: flex!important;
    margin-left: 1px;
    margin-right: 1px;
}

.chosen-results li::-moz-focus-inner, .chosen-results li:focus{
    outline: 1px dashed #ccc;
}

    .chosen-results li div{
        word-break: break-all;
        word-wrap: break-word;
        width: 100%;
    }

.search_clear{
    position: absolute;
    right: 50px;
    top: 10px;
    font-size: 18px;
    line-height: 18px;
    height: 18px;
    width: auto;
    min-width: auto;
    padding: 0px;
}

.search-choice-close{
    z-index: 1;
}
</style>
